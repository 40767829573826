import { ModuleAPI } from "(apis)/(shared-apis)/modules-apis";
import { useState, useEffect } from "react";

const useModules = () => {
  const [modules, setModules] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(true);  // Optional: for loading state
  const [error, setError] = useState<string | null>(null); // Optional: for error state

  async function fetchData() {
    try {
      const response = await ModuleAPI("getModules");
      if (response.status === 200) {
        const data = response?.data?.modules || [];
        const formattedData = data.map((module: any) => ({
          ...module,
          key: module._id,
          label: module.moduleName,
          value: module.moduleName,
        }));
        setModules(formattedData);
      } else {
        throw new Error("Failed to fetch modules");
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return { modules, loading, error };
};

export default useModules;
