import { X } from "@mui/icons-material";

const FullScreenPopup = ({ popup, setPopup }) => {
  return (
    <div className="w-full h-full flex items-center justify-center ">
      <div className="bg-white flex flex-col items-center p-8  rounded-xl shadow-xl relative max-w-md w-full text-center mt-20 ">
        <img
          src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/public/icons/ErrorIcon.svg"
          className="w-20 h-20"
          alt="Cancel"
        />

        {popup?.canDestroy && (
          <button
            className="w-8 h-8 absolute top-2 right-2"
            onClick={() =>
              setPopup({
                isOpen: true,
                message: null,
                canDestroy: false,
                heading: null,
              })
            }
          >
            <X />
          </button>
        )}

        <h1 className="font-bold text-xl md:text-2xl text-primary">
          {popup?.heading}
        </h1>
        <p className="text-xl">{popup?.message}</p>
      </div>
    </div>
  );
};

export default FullScreenPopup;
