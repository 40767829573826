import { useEffect, useState } from "react";

import { SearchIcon } from "../../shared-components//ui/Icons";
import { ButtonOutlined } from "../../shared-components//ui/CustomButtons";
import CustomDropDown from "../../shared-components//ui/CustomDropDown";
import ViewRequests from "./components/view-requests";
import { UniVerificationAPI } from "(apis)/(shared-apis)/university-verification-apis";

const UniversityVerificationManager = () => {
  const [data, setData] = useState([]);
  const [allFetchedData, setAllFetchedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectStatus, setSelectStatus] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [requestType, setRequestType] = useState("VERIFICATION"); // ["VERIFICATION", "CHANGE_REQUEST"],

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await UniVerificationAPI(
          "getAllUniVerificationRequests"
        );

        if (response.status === 200) {
          const data = response?.data?.data || [];
          // const filteredData = data;
          const filteredData = data.filter((item: any) => {
            return item?.requestType === requestType;
          });
          setData(filteredData);
          setAllFetchedData(data);
          setSelectStatus("All");
        }
      } catch (error) {
        console.error("Error getting data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    if (!allFetchedData) return;
    const NewData = allFetchedData?.filter((item) => {
      if (!item.username || !item.username) return false;

      const matchesSearch = item?.institution
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());

      return matchesSearch;
    });
    setData(NewData);
  }, [searchQuery]);

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setData(allFetchedData);
      return;
    }
  };

  useEffect(() => {
    if (selectStatus === "Pending" && allFetchedData) {
      const filteredData = allFetchedData.filter((item) => {
        return (
          item?.verificationStatus === "Pending" &&
          item?.requestType === requestType
        );
      });
      setData(filteredData);
    } else if (selectStatus === "Rejected" && allFetchedData) {
      const filteredData = allFetchedData?.filter((item) => {
        return (
          item?.verificationStatus === "Rejected" &&
          item?.requestType === requestType
        );
      });
      setData(filteredData);
    } else if (selectStatus === "Accepted" && allFetchedData) {
      const filteredData = allFetchedData?.filter((item) => {
        return (
          item?.verificationStatus === "Accepted" &&
          item?.requestType === requestType
        );
      });
      setData(filteredData);
    } else if (allFetchedData) {
      const filteredData = allFetchedData?.filter((item) => {
        return item?.requestType === requestType;
      });
      setData(filteredData);
    }
  }, [selectStatus, requestType]);

  const handleSelectStatusChange = (selectedStatus) => {
    setSelectStatus(selectedStatus);
  };

  return (
    <>
      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">
            University Verification Requests
          </h2>
          <ButtonOutlined
            handleClick={() => {
              setRefreshData((prev) => prev + 1);
            }}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                />
                <div className="flex flex-col md:flex-row md:items-center p-4 gap-3">
                  <CustomDropDown
                    label="Verification Status"
                    options={["All", "Pending", "Rejected", "Accepted"]}
                    onSelect={(selectedStatus) =>
                      handleSelectStatusChange(selectedStatus.toString())
                    }
                    width="min-w-[12rem]"
                    value={selectStatus}
                  />
                  <CustomDropDown
                    label="Request Type"
                    options={["University Verification", "Change University"]}
                    onSelect={(selectedStatus) => {
                      if (selectedStatus === "University Verification") {
                        setRequestType("VERIFICATION");
                      } else {
                        setRequestType("CHANGE_REQUEST");
                      }
                    }}
                    width="min-w-[15rem] max-w-full"
                    value={requestType}
                  />
                </div>

                <ViewRequests currentData={data} setData={setData} />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export const TableTop = ({ searchQuery, handleSearchQueryChange }) => {
  return (
    <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2">
        <form className="flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon color="text-gray-400" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
              placeholder="Search"
              required={true}
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UniversityVerificationManager;
