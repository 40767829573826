// @ts-ignore\
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "state-management/user-context";
import { message } from "antd";
import { apiRequest } from "(apis)/api-interface/api-handler";

export const ViewDoubt = ({
    ShowModel,
    SetShowModel,
    DoubtDisplay,
    SetDoubtDisplay

}) => {

    const history = useNavigate();
    const { user } = useContext(UserContext);
    const TakeDoubt = async (id: string) => {
        const username = user.username;
        if (username === "" || !username) {
            message.error("Please login to take doubt")
            return;
        }
        const { data } = await apiRequest("TakeDoubt", { id, username });
        if (data.success) {
            message.success(`${data?.message}`)
            setTimeout(() => {
                history(`/expert-interface/objective/${id}`)
            }, 2000);
        } else {
            message.error(`${data.message}`)
        }
    };


    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Doubt Details
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                SetShowModel(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="flex flex-col gap-2">
                        <div className="text-left">{DoubtDisplay?.questionID ? "Premed QBank" : "Own Question"} | {DoubtDisplay?.subject}</div>

                        <img
                            src={DoubtDisplay?.img}
                            className="object-fit"
                        />

                        <div className="text-left">{DoubtDisplay?.description}</div>

                        <div className={`flex gap-2 justify-between`}>
                            <button
                                className={`w-full bg-white text-gray-800 border border-gray-300 px-4 py-2 rounded-md hover:bg-gray-100 transition-all duration-300`}
                                onClick={() => {
                                    SetShowModel(false);
                                    SetDoubtDisplay(null);
                                }}
                            >
                                Back
                            </button>
                            <button
                                className={`w-full bg-primary text-white px-4 py-2 rounded-md border border-primary hover:bg-gray-800 transition-all duration-300`}
                                onClick={() => {
                                    TakeDoubt(DoubtDisplay._id);
                                }}
                            >
                                Solve Doubt
                            </button>
                        </div>

                    </div>






                </div>
            </div>
        </div>
    );
};
