
import type { ColumnsType } from 'antd/es/table';

// Define the type for user data
interface UserData {
  _id: string;
  username: string;
  fullname: string;
  phonenumber: string;
  city: string;
  school: string;
  accountcreateddate: string;
  province: string;
}
export const columns: ColumnsType<UserData> = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phonenumber',
      key: 'phonenumber',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: 'Account Created Date',
      dataIndex: 'accountcreateddate',
      key: 'accountcreateddate',
      render: (date: string) => new Date(date).toLocaleDateString(), // Format the date
    },
    {
      title: 'Province',
      dataIndex: 'province',
      key: 'province',
    },
  ];