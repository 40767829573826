import React, { useEffect, useState } from "react";
import { message, Select } from "antd";
// @ts-ignore
import { useParams } from "react-router-dom";

import { useContext } from "react";
import { notification } from "antd";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Loading from "shared-components/ui/Loading";
import Loader from "shared-components/Loader";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api";

export default function ExpertSubjectManage() {
  const { subject } = useParams();
  const [ExpertUsernames, SetExpertUsernames] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState("All");
  const [Doubts, setDoubts] = useState([]);
  const [Original, setOriginal] = useState([]);
  const { user } = useContext(UserContext);
  const [TotalSolved, SetTotalSolved] = useState(0);
  const [TotalPending, SetTotalPending] = useState(0);
  const [isFeedbackModal, setIsFeedbackModal] = useState(false);

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    // GetExpertEmails()
    //   .then((res) => {
    //     SetExpertUsernames(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    apiRequest("getexpertemails")
      .then((res) => {
        console.log("emails data", res.data);
        SetExpertUsernames(["All", ...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });

    apiRequest("getsubjectstats", { subject })
      .then((res) => {
        SetTotalSolved(res.data.totalsolved);
        SetTotalPending(res.data.totalpending);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleUsernameChange = async (value) => {
    setSelectedUsername(value);
    FetchDoubt(value);
  };
  const [loading, setLoading] = useState(false);
  const FetchDoubt = async (value) => {
    try {
      setLoading(true);
      const response = await apiRequest("GetExpertSolvedDoubts", {
        subject,
        username: value,
      });
      const data = response.data;
      if (data?.result?.length > 0) {
        setDoubts(data.result);
        setTotalDocCount(data.result.length);
      } else {
        message.error("Data Not Found");
      }
    } catch (error) {
      console.error("Error fetching doubts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchDoubt("all");
  }, [subject]);

  const ReviseDoubt = (id: string) => {
    apiRequest("redodoubt", { id }).then((res) => {
      console.log(res);
      if (res.data.success) {
        notification.success({
          message: "Success",
          description: "Doubt Revise Requested",
        });
        setDoubts([]);
        FetchDoubt(selectedUsername);
      }
    });
  };

  const Reset = () => {
    setDoubts([]);
    setOriginal([]);
    setSelectedUsername("All");
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalDocCount, setTotalDocCount] = useState<number>(0);
  const formik = useFormik({
    initialValues: {
      feedback: "",
      rating: 1,
      doubtId: "",
      videoLink:"",

    },
    validationSchema: Yup.object({
      feedback: Yup.string().required("Feedback is required"),
      rating: Yup.number()
        .required("Rating is required")
        .min(1, "Rating must be at least 1")
        .max(5, "Rating must be at most 5"),
    }),
    onSubmit: async (values :any) => {
      try {
        console.log("values", values)

        const res = await ExpertAPI("AddORUpdateFeedback", values?.doubtId, {
          feedback: values.feedback,
          rating: values.rating,
          videoLink:values?.videoLink,
          expertUsername: user?.username
        });
        console.log("res", res)
        notification.success({
          message: "Success",
          description: "Feedback Submitted",
        });
        setIsFeedbackModal(false);
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    },
  });
  return (
    <>
      <div className="w-full md:w-[90%] mx-auto">
        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">
            Admin Subjective Doubts
          </h2>
          <ButtonFill handleClick={Reset}>Reset</ButtonFill>
        </div>

        <div className="flex items-center md:flex-row flex-col justify-between gap-2 pb-1 mt-5">
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Subject</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {capitalizeFirstLetter(subject)}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Manager</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {user?.fullname}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Solved</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {TotalSolved}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Pending</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {TotalPending}
            </h6>
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center justify-between gap-2 pb-1 mt-5">
          <div className="w-full">
            <Select
              className="w-full h-14 bg-gray-50 rounded-lg shadow-lg border-none"
              showSearch
              placeholder="Select Username"
              optionFilterProp="children"
              onChange={handleUsernameChange}
              value={selectedUsername}
              bordered={false}
            >
              {ExpertUsernames &&
                ExpertUsernames?.map((username) => {
                  return (
                    <Select.Option value={username} key={username}>
                      {username}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="w-full bg-gray-50 p-4 rounded-lg shadow-lg">
            <h6>
              <b>Question Solved:</b> {Doubts.length}
            </h6>
          </div>
        </div>

        {loading && <Loader loading={loading} />}

        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className=" bg-white shadow-md sm:rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        SR No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Question Text
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Tags
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doubts.length === 0 && (
                      <h2 className="font-bold text-2xl mb-4">
                        No Doubts Solved Yet
                      </h2>
                    )}
                    {Doubts.slice(
                      page * rowsPerPage,
                      (page + 1) * rowsPerPage
                    ).map((doubt, index) => (
                      <tr className="border-b" key={doubt._id}>
                        <td className="px-4 py-4">
                          {page === 0
                            ? index + 1
                            : page * rowsPerPage + index + 1}
                        
                        </td>
                        <td className="px-4 py-4">
                          {doubt.QuestionText.slice(0, 45)
                            ?.replace("<p>", "")
                            .replace("</p>", "")
                            .replace("<span", "")
                            .replace("</span>", "") + "..."}
                        </td>
                        <td className="px-4 py-4 flex flex-wrap gap-2">
                          {doubt?.Tags?.map((tag: any) => (
                            <div className="bg-primary px-2 py-1 rounded border font-bold border-primary mr-2 text-sm text-white">
                              {tag.name}
                            </div>
                          ))}
                        </td>
                        <td className="px-4 py-4">
                          <div className="flex items-center justify-center gap-2">
                            <ButtonOutlined
                              handleClick={() => {
                                window.open(`${doubt?.videoLink}`, "_blank");
                              }}
                            >
                              Watch
                            </ButtonOutlined>
                            <ButtonFill
                              handleClick={() => {
                                setIsFeedbackModal(true);
                                formik.setFieldValue("doubtId", doubt._id);
                                formik.setFieldValue("videoLink", doubt.videoLink);
                                // ReviseDoubt(doubt._id)
                              }}
                            >
                              Revise
                            </ButtonFill>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <div className="flex items-center justify-center mt-4 text-center w-full gap-2">
                    <button
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <ChevronLeft />
                    </button>
                    {page + 1} -{" "}
                    {Math.min(totalDocCount, (page + 1) * rowsPerPage)} of{" "}
                    {totalDocCount}
                    <button
                      onClick={() => {
                        if (page < totalDocCount / rowsPerPage - 1) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      <CustomAntdModal
        isOpenModal={isFeedbackModal}
        setIsOpenModal={setIsFeedbackModal}
        formik={formik}
        width={800}
      >
        <div>Add Feedback </div>
        <div className="md:p-2 w-full   ">
          <CustomFormField
            name="feedback"
            type="textarea"
            label="Feedback"
            formik={formik}
          />
        </div>
        <div className="md:p-2 w-full   ">
          <CustomFormField
            name="rating"
            type="number"
            label="Rating"
            formik={formik}
          />
        </div>
      </CustomAntdModal>
    </>
  );
}
