import { apiRequest } from "(apis)/api-interface/api-handler";
import { useState, useEffect } from "react";

const useDisciplines = () => {
  const [disciplines, setDisciplines] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(true);  // Optional: for loading state
  const [error, setError] = useState<string | null>(null); // Optional: for error state

  async function fetchDisciplines() {
    try {
      const disciplinesResponse = await apiRequest("getAllDisciplines");
      if (disciplinesResponse.status === 200) {
        const modules = disciplinesResponse?.data?.disciplines?.map((item: any) => ({
          _id: item._id,
          value: item?.disciplineName,
          label: item?.disciplineName,
        }));
        setDisciplines(modules || []);
      } else {
        throw new Error("Failed to fetch disciplines");
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_CATEGORY === "MEDSCHOOL") {
      fetchDisciplines();
    } else {
      setLoading(false); // No need to show loading if the condition is not met
    }
  }, []);

  return { disciplines, loading, error };
};

export default useDisciplines;
