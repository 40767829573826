import { RANDOM_COLORS } from "./utils";

const ToggleSection = ({ Toggles, selectedToggle, setSelectedToggle, Theme }) => (
    <div className="md:w-3/4 mx-auto mt-8 flex justify-center">
        <div className="flex flex-row justify-between border-2 border-white rounded-full bg-white shadow-md">
            {Toggles?.map((toggle, index) => (
                <ToggleButton
                    key={toggle._id}
                    toggle={toggle}
                    isSelected={selectedToggle?._id === toggle._id}
                    onClick={() => setSelectedToggle(toggle)}
                    colorScheme={Theme.ColorScheme}
                    discountColor={RANDOM_COLORS[index % RANDOM_COLORS.length]}
                />
            ))}
        </div>
    </div>
);


const ToggleButton = ({ toggle, isSelected, onClick, colorScheme, discountColor }) => (
    <div
        className={`cursor-pointer flex flex-col justify-between items-center text-sm md:text-md hover:text-gray-800 p-2 px-4 rounded-full md:min-w-[12rem] md:font-bold text-center transition hover:bg-gray-100 hover:shadow-none hover:border-gray-200 duration-300 ${isSelected ? `text-white bg-[${colorScheme}] border-white` : 'text-gray-800 bg-white border-gray-200'
            }`}
        onClick={onClick}
    >
        {toggle.toggleValue}
        <div
            className="text-xs font-bold rounded-full mt-1 w-fit px-2 text-black"
            style={{ backgroundColor: discountColor }}
        >
            {toggle.discountAmount}
        </div>
    </div>
);

export default ToggleSection;