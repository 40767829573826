// import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import TargetModal from "./TargetModal";
import {
  getActiveData,
  getUserIdField,
  ROlE_WORKING_UNDER,
  // getUsersOfSpecificRole,
  // ROlES,
} from "shared-pages/latest-commission/utils";
import ViewUserTargetTable from "./view-user-target-table";
import MyTargetView from "./my-target-view";
import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import ViewAllTargets from "shared-pages/latest-commission/target-management/components/view-all-targets";

const TargetManagement = ({ supervisor, screen }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const initialModalState = {
    isModalOpen: false,
    data: null,
    // type: "ADD",
    // for: supervisor || ROlES.ASSOCIATE,
  };
  const [modal, setModal] = useState(initialModalState);
  const [targets, setTargets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setModal(initialModalState);
  };
  const openModal = () => {
    setModal({
      isModalOpen: true,
      data: null,
    });
  };

  useEffect(() => {
    if (supervisor) {
      let supervisorRole = supervisor?.role;
      let fieldName = getUserIdField(supervisorRole);
      if (fieldName) {
        let usersWorkingUnderCurrentSupervisor = supervisor[fieldName];
        setAllUsers(usersWorkingUnderCurrentSupervisor);
      }
    }
  }, [supervisor]);

  useEffect(() => {
    fetchTargets();
  }, []);

  async function fetchTargets() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getAllTargets");
      console.log("Response: ", response);
      if (response?.data?.success) {
        const data = response?.data?.data;
        const activeItems = getActiveData(data);
        setTargets(activeItems);
      }
    } catch (error) {
      console.error("Error fetching targets:", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      {screen === "Target Management" && (
        <>
          <h1 className="font-semibold p-3 text-slate-800 text-xl">
            Target for {ROlE_WORKING_UNDER[supervisor?.role]}S
          </h1>

          <TargetModal
            allUsers={allUsers}
            modal={modal}
            closeModal={closeModal}
            targets={targets}
            supervisor={supervisor}
          />
          <ViewAllTargets
            filteredTargets={targets}
            isActiveFilter={"Active"}
            setConfirmationModal={null}
            managerScreen={true}
            setModal={setModal}
          />
          <ViewUserTargetTable allUsers={allUsers} />
        </>
      )}
      {screen === "My Target" && <MyTargetView supervisor={supervisor} />}
    </div>
  );
};

export default TargetManagement;

// async function fetchData() {
//   setIsLoading(true);
//   try {
//     const [commissionUsersResponse] = await Promise.all([
//       CommissionAPI("getAllCommissionUsers"),
//     ]);

//     if (commissionUsersResponse.status === 200) {
//       const commissionUsersData = commissionUsersResponse?.data?.data || [];
//       let filterUserRole = ROlES.ASSOCIATE;
//       let supervisorRole = supervisor?.role;
//       console.log("commissionUsersData: ", commissionUsersData);
//       let field = getUserIdField(supervisorRole);

//       console.log(
//         "filterUserRole: ",
//         filterUserRole,
//         "supervisorRole : ",
//         supervisorRole,
//         "field: ",
//         field,
//         supervisor[field],
//         supervisor
//       );
//       if (supervisor?.role === ROlES.MANAGER) {
//         filterUserRole = ROlES.ASSOCIATE;
//       } else if (supervisor?.role === ROlES.DIRECTOR) {
//         filterUserRole = ROlES.MANAGER;
//       }
//       const filteredUsers = getUsersOfSpecificRole(
//         commissionUsersData,
//         filterUserRole
//       );

//       const usersWorkingUnderCurrentSupervisor = filteredUsers?.filter(
//         (user: any) => supervisor[field]?.includes(user?._id)
//       );

//       setAllUsers(usersWorkingUnderCurrentSupervisor);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   } finally {
//     setIsLoading(false);
//   }
// }

// useEffect(() => {
//   fetchData();
// }, [supervisor]);
