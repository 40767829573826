
import { useDrag, useDrop } from 'react-dnd';
import CustomBadges from '../../../../shared-components/ui/CustomBadges';
import { Switcher } from '../../../../shared-components/ui/CustomForm';
import { DeckGroupInterface } from '../interface';
import { DeckGroupAPI } from '../../../../(apis)/(shared-apis)/academics/deckgroups-api';
import toast from 'react-hot-toast';
import { CopyIcon, ManageIcon } from 'shared-components/ui/Icons';

interface DraggableQuestionProps {
    deckGroup: DeckGroupInterface;
    index: number;
    moveQuestion: (fromIndex: number, toIndex: number) => void;
    deckGroups: DeckGroupInterface[];
    setDeckGroups: (deckGroups: DeckGroupInterface[]) => void;
    categoryName: string;
    setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
    setMode: React.Dispatch<React.SetStateAction<string>>;
    setEditData: React.Dispatch<React.SetStateAction<{}>>;  
    GetCategory: () => void;  
}


export default function DraggableQuestion({ 
    deckGroup, 
    index,
    moveQuestion, 
    deckGroups, 
    setDeckGroups, 
    categoryName, 
    setShowModel, 
    setMode, 
    setEditData,
    GetCategory
}: DraggableQuestionProps) {

    const [, drag] = useDrag({
        type: 'DECKGROUP',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'DECKGROUP',
        hover: (draggedItem: any) => {
            if (draggedItem.index !== index) {
                moveQuestion(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const handlePublishedChange = async (id: string) => {
        const updatedDeckGroups = [...deckGroups]
        const index = updatedDeckGroups.findIndex((deckGroup) => deckGroup._id === id)
        updatedDeckGroups[index].isPublished = !updatedDeckGroups[index].isPublished
        setDeckGroups(updatedDeckGroups)

        const response = await DeckGroupAPI("handlePublish", id)
        if (response.data.success) {
            toast.success("DeckGroup Updated Successfully")
            GetCategory()
        }
        else {
            toast.error("DeckGroup Published Failed")
        }
    }



    return (
        <tr ref={(node) => drag(drop(node))} className="bg-white divide-y divide-gray-200 text-gray-800">
            <td className="px-4 py-4">
                {(index + 1)}
            </td>
            <td className="cursor-pointer">
                <div className='flex flex-row justify-center items-center space-x-3'
                    onClick={() => { navigator.clipboard.writeText(deckGroup._id); toast.success('Deck Group  ID Copied') }}>
                    <CopyIcon size='w-6 h-6' color='text-gray-500' />
                </div>
            </td>
            <td className="px-4 py-4">
                <img className="w-10 h-10 rounded-full" src={deckGroup.deckGroupImage} alt="" />
            </td>
            <td className="px-4 py-4">
                {deckGroup.deckGroupName}
            </td>
            <td className="px-4 py-4">
                <CustomBadges label={deckGroup.deckType} />
            </td>
            <td className="px-4 py-3 text-center">
                <Switcher
                    isActive={undefined}
                    for={deckGroup?._id}
                    onChange={() => handlePublishedChange(deckGroup._id)}
                    togglevalue={deckGroup?.isPublished}
                    size="small"
                />
            </td>
            <td className="
                          px-4 py-3 text-center space-x-3
                          flex
                          items-center
                          justify-center
                      "
                onClick={() => {
                    window.open(`/decks/${categoryName}/${deckGroup.deckGroupName}`, "_blank");
                }}
            >
                <div className='w-10 h-10'>
           <ManageIcon />

                </div>
            </td>
            <td className="
              px-4 py-3 text-center space-x-3
          "
            >
                <div className="flex flex-row justify-center items-center space-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"
                        onClick={() => {
                            setMode('edit')
                            setEditData(deckGroup)
                            setShowModel(true)
                        }}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                    </svg>
                </div>
            </td>
        </tr>
    );
};
