import React, { useState, useEffect, useContext, useRef } from "react";
import { notification, Modal, Button, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { fabric } from "fabric";
// @ts-ignore
// import Background from "./Background.png";
// @ts-ignore
import styles from "./styles.module.css";
import RecordRTC from "recordrtc";
import { useParams } from "react-router-dom";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";

export default function ExpertSolveRecordView(props) {
  const location = useLocation();
  const { id } = useParams();
  const Doubt = location.state?.Doubt;
  const Subject = location.state?.Params;
  const DoubtWithId = location.state?.DoubtWithId;
  const { user } = useContext(UserContext);
  const [timer, setTimer] = useState(180); // 3 minutes in seconds
  const [modalVisible, setModalVisible] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [webcamStream, setWebcamStream] = useState(null);
  const [showDoubt, setShowDoubt] = useState(false);
  const [strokeStyle, setStrokeStyle] = useState("#000");
  const [lineWidth, setLineWidth] = useState(2);
  const [erasing, setErasing] = useState(false);
  const [subtopic, setSubtopic] = useState("");
  const [SubtopicsModal, SetSubTopicModal] = useState(false);
  const [DoubtGet, setDoubtGet] = useState<any>();
  const [isErasing, setIsErasing] = useState(false);
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  useEffect(() => {
    const Check = async () => {
      if (!Doubt) {
        // const response = await fetch(`${backendUrl}/GetSubjectiveDoubt/${id}`, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
        const response = await apiRequest("GetSubjectiveDoubt", {}, [id]);
        const data = response.data;
        setDoubtGet(data?.Details);
      }
    };

    Check();
  }, []);

  let canvasRef = useRef(null);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  let interval;

  useEffect(() => {
    if (timer === 0) {
      clearInterval(interval);
      setModalVisible(true);
    }
  }, [timer]);

  useEffect(() => {
    if (webcamStream) {
      videoRef.current.srcObject = webcamStream;
    }
  }, [webcamStream]);

  const canvasInstance = useRef(null);
  const canvasInstance2 = useRef(null);
  const canvasInstance3 = useRef(null);
  const canvasInstance4 = useRef(null);

  useEffect(() => {
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      isDrawingMode: true,
      selection: false,
      preserveObjectStacking: false,
    });
    fabricCanvas.freeDrawingBrush.color = strokeStyle;
    fabricCanvas.freeDrawingBrush.width = lineWidth;
    canvasInstance.current = fabricCanvas;
    canvasInstance2.current = fabricCanvas;
    canvasInstance3.current = fabricCanvas;
    setCanvas(fabricCanvas);
    return () => {
      fabricCanvas.dispose();
    };
  }, []);
  const startTimer = () => {
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(interval);
  };
  const handleShowDoubt = () => {
    setShowDoubt(true);
  };
  const handleHideDoubt = () => {
    setShowDoubt(false);
  };

  const startRecording = async () => {
    try {
      setTimer(180);
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });

      const webcam = await navigator.mediaDevices.getUserMedia({
        //video: true,
        audio: true,
      });

      startTimer();

      const mergedStream = new MediaStream();

      screenStream.getTracks().forEach((track) => {
        mergedStream.addTrack(track);
      });

      webcam.getTracks().forEach((track) => {
        mergedStream.addTrack(track);
      });

      const mediaRecorder = new RecordRTC(mergedStream, {
        type: "video",
        mimeType: "video/mp4",
      });

      mediaRecorder.startRecording();

      setRecorder(mediaRecorder);
      setRecording(true);
    } catch (error) {
      console.error("Error accessing screen: ", error);
    }
  };

  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [uploadedBlob, setUploadedBlob] = useState(null);
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result], { type: file.type });
      setUploadedVideo(file);
      setUploadedBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const validateVideo = (video) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";

      videoElement.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoElement.src);
        const duration = videoElement.duration;
        const fileType = video.type.toLowerCase();

        if (duration <= 180 && fileType === "video/mp4") {
          resolve("Success");
        } else {
          message.error("Video is invalid or more than 3 minutes in duration");
          reject();
        }
      };
      videoElement.src = URL.createObjectURL(video);
    });
  };

  const ShowSubTopicModal = () => {
    SetSubTopicModal(true);
  };
  const HideSubTopicModal = () => {
    SetSubTopicModal(false);
  };

  const uploadVideo = async () => {
    try {
      let videoToUpload = recordedBlob;

      if (uploadedBlob) {
        await validateVideo(uploadedBlob);
        videoToUpload = uploadedBlob;
      }

      if (!videoToUpload) {
        message.info("Please record or upload a video first");
        return;
      }

      if (!subtopic) {
        ShowSubTopicModal();
        return;
      }

      const videoData = new FormData();
      videoData.append("video", videoToUpload);
      videoData.append("username", user.username);
      videoData.append("DoubtId", DoubtWithId._id  );
      videoData.append("subtopic", subtopic);
      videoData.append("resource", "Premed QBank");
     const res = await  apiRequest("uploadSubjective", videoData)
     if (res.data.success) {
      message.success(
        "Your video is uploaded successfully and will be reviewed by our students"
      );
      navigate(`/experts/${Subject}`);
    } else {
      console.log("res", res)
      message.error(res?.data?.message);
    }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    }
  };

  const stopRecording = () => {
    if (recorder && recorder.state === "recording") {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        setRecordedBlob(blob);
        setRecording(false);
        recorder.reset();
      });

      stopTimer();
      setTimer(0);
      handleRecordingComplete();
    }
  };

  const handlePenColorChange = (event) => {
    const canvas = canvasInstance3.current;
    if (canvas) {
      const newColor = event.target.value;
      setStrokeStyle(newColor);
      canvas.freeDrawingBrush.color = newColor;
    }
  };

  const handlePenWidthChange = (event) => {
    const canvas = canvasInstance2.current;
    if (canvas) {
      const newWidth = event.target.value;
      setLineWidth(newWidth);
      canvas.freeDrawingBrush.width = newWidth;
    }
  };

  const handleClearCanvas = () => {
    // const canvas = canvasInstance.current;
    if (canvas) {
      // canvas.clear();
      // canvas.renderAll();
      // Clear only the objects, not the background
      canvas.getObjects()?.forEach((obj) => {
        if (obj !== canvas.backgroundImage) {
          canvas.remove(obj);
        }
      });
    }
  };

  const handleToggleErasing = () => {
    // const canvas = canvasInstance.current;
    // if (canvas) {
    //   canvas.isDrawingMode = !erasing;
    //   setErasing(!erasing);
    // }
    if (canvas) {
      const eraserMode = !isErasing;
      setIsErasing(eraserMode);

      if (eraserMode) {
        canvas.freeDrawingBrush.color = "#FFFFFF"; // Match canvas background (assuming white eraser)
        canvas.freeDrawingBrush.width = 10; // Adjust eraser size
      } else {
        canvas.freeDrawingBrush.color = strokeStyle; // Switch back to the selected pencil color
        canvas.freeDrawingBrush.width = lineWidth; // Adjust back to normal drawing size
      }
    }
  };

  const [recordingComplete, setRecordingComplete] = useState(false);

  const handleRecordingComplete = () => {
    setRecordingComplete(true);
  };

  const [Preview, SetPreview] = useState(false);
  const handlePreviewRecording = () => {
    SetPreview(true);
    setShowDoubt(false);

    const container = document.createElement("div");
    const videoElement = document.createElement("video");
    videoElement.controls = true;

    videoElement.src = URL.createObjectURL(recordedBlob);

    videoElement.onloadedmetadata = () => {
      Modal.info({
        title: "Preview Recording",
        // @ts-ignore
        content: container, // Show the container element in the modal
        width: 600,
        onOk() {
          container.removeChild(videoElement); // Clean up after modal is closed
        },
      });
    };

    container.appendChild(videoElement); // Append video element to the container
  };

  const handleRecordAgain = () => {
    setRecordedBlob(null);
    clearInterval(interval);
    setTimer(0);
    SetPreview(false);
  };

  return (
    <>
      <div
        className="page-container"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(/Background.png)`,
          backgroundSize: "100% 100vh",
          backgroundRepeat: "repeat-y",
        }}
      >
        <div className={styles.options}>
          <div className={styles.penSettings}>
            <h5>WhiteBoard Settings</h5>
            <input
              id="pen-color"
              type="color"
              value={strokeStyle}
              onChange={handlePenColorChange}
            />
            <input
              id="pen-width"
              type="number"
              min="1"
              max="20"
              value={lineWidth}
              onChange={handlePenWidthChange}
            />
            <button className={styles.optionsBtn} onClick={handleToggleErasing}>
              {isErasing ? "Draw" : "Erase"}
            </button>
          </div>
          <div className={styles.OneMoreDiv}>
            <button className={styles.optionsBtn} onClick={handleClearCanvas}>
              Clear
            </button>
            {!showDoubt && (
              <button className={styles.optionsBtn} onClick={handleShowDoubt}>
                Show Question
              </button>
            )}
            {showDoubt && (
              <button className={styles.optionsBtn} onClick={handleHideDoubt}>
                Hide Question
              </button>
            )}
          </div>
          <div className={styles.OneMoreDiv}>
            {!recording ? (
              <button className={styles.optionsBtn} onClick={startRecording}>
                Start Recording
              </button>
            ) : (
              <button className={styles.optionsBtn} onClick={stopRecording}>
                Stop Recording
              </button>
            )}
          </div>
          <div className={styles.OneMoreDiv}>
            <input
              className={styles.optionsBtn}
              type="file"
              accept="video/mp4"
              onChange={handleVideoUpload}
            />
            <button className={styles.optionsBtn} onClick={uploadVideo}>
              Submit
            </button>
          </div>
          <div className={styles.OneMoreDiv}>
            {recordingComplete && (
              <button
                className={styles.optionsBtn}
                onClick={handlePreviewRecording}
                disabled={!recordedBlob}
              >
                Preview Recording
              </button>
            )}
          </div>
        </div>
        {webcamStream && (
          <div>
            <video className={styles.video} ref={videoRef} autoPlay controls />
          </div>
        )}
        <div style={{ flex: "1", position: "relative" }}>
          <canvas
            id="fabricCanvas"
            ref={canvasRef}
            width={window.innerWidth}
            height={window.innerHeight * 2}
          />
        </div>
        {showDoubt && (
          <div className={styles.FullDiv}>
            <div className={styles.DoubtDiv}>
              {Doubt && (
                <h6>
                  <span>Question :hey</span>{" "}
                  <div
                    dangerouslySetInnerHTML={{ __html: Doubt?.QuestionText }}
                    style={{
                      whiteSpace: "normal", // Allow text to wrap to the next line
                      wordWrap: "break-word", // Allow breaking long words
                      overflowWrap: "break-word", // Allow breaking mid-word when necessary
                    }}
                  />
                </h6>
              )}
              {DoubtGet && (
                <h6>
                  <span>Question :</span>{" "}
                  <div
                    dangerouslySetInnerHTML={{ __html: DoubtGet?.QuestionText }}
                    style={{
                      whiteSpace: "normal", // Allow text to wrap to the next line
                      wordWrap: "break-word", // Allow breaking long words
                      overflowWrap: "break-word", // Allow breaking mid-word when necessary
                    }}
                  />
                </h6>
              )}
              {Doubt && Doubt.QuestionImage && (
                <img
                  src={Doubt?.QuestionImage}
                  className={styles.QuestionImage}
                  style={{
                    marginRight: "1rem",
                    marginBottom: "1rem",
                    width: "150px",
                    height: "150px",
                  }}
                />
              )}
              {DoubtGet && DoubtGet.QuestionImage && (
                <img
                  src={DoubtGet?.QuestionImage}
                  className={styles.QuestionImage}
                  style={{
                    marginRight: "1rem",
                    marginBottom: "1rem",
                    width: "150px",
                    height: "150px",
                  }}
                />
              )}
            </div>

            <div className={styles.DoubtDiv}>
              {Doubt &&
                Doubt?.Options?.map((option) => (
                  <div className="container my-3">
                    <h5
                      id={
                        !option.IsCorrect
                          ? styles.options
                          : styles.correctoption
                      }
                    >
                      <span>
                        <b>Option: {option.OptionLetter}</b>
                      </span>{" "}
                      <div
                        dangerouslySetInnerHTML={{ __html: option.OptionText }}
                      />
                    </h5>
                  </div>
                ))}
              {DoubtGet &&
                DoubtGet?.Options?.map((option) => (
                  <div className="container my-3">
                    <h5
                      id={
                        !option.IsCorrect
                          ? styles.options
                          : styles.correctoption
                      }
                    >
                      <span>
                        <b>Option: {option.OptionLetter}</b>
                      </span>{" "}
                      <div
                        dangerouslySetInnerHTML={{ __html: option.OptionText }}
                      />
                    </h5>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <div id={styles.timer}>
        {timer > 0
          ? `${Math.floor(timer / 60)}:${timer % 60 < 10 ? "0" : ""}${
              timer % 60
            }`
          : "00:00"}
      </div>

      {!modalVisible && (
        <Modal
          className={styles.Modal}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button key="recordAgain" onClick={handleRecordAgain}>
              Record Again
            </Button>,
            <Button key="submitRecording" type="primary" onClick={uploadVideo}>
              Submit Recording
            </Button>,
          ]}
        >
          <p>The time has ended. What would you like to do?</p>
        </Modal>
      )}

      {Preview && recordedBlob && (
        <Modal
          className={styles.Modal}
          visible={Preview}
          onCancel={() => SetPreview(false)}
          footer={null}
          style={{
            width: "100%",
            height: "100%",
            top: "20%",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            borderRadius: "4px",
            zIndex: "9999",
          }}
        >
          <video controls style={{ width: "100%", height: "100%" }}>
            <source src={URL.createObjectURL(recordedBlob)} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button key="recordAgain" onClick={handleRecordAgain}>
              Record Again
            </Button>
            <Button key="submitRecording" type="primary" onClick={uploadVideo}>
              Submit Recording
            </Button>
            <Button key="close" onClick={() => SetPreview(false)}>
              Close
            </Button>
          </div>
        </Modal>
      )}

      <Modal
        className={styles.Modal}
        visible={SubtopicsModal}
        onCancel={HideSubTopicModal}
        footer={null}
        style={{
          width: "100%",
          height: "100%",
          top: "20%",
          left: "0",
          right: "0",
          bottom: "0",
          margin: "auto",
          borderRadius: "4px",
          zIndex: "9999",
        }}
      >
        <div className={styles.SubTopicModal}>
          <h5>
            <b>Tags</b>
          </h5>
          <div className={styles.DivTags}>
            {Doubt &&
              Doubt?.Tags?.map((tag) => <h5 id={styles.Tag}>{tag.name}</h5>)}
            {DoubtGet &&
              DoubtGet?.Tags?.map((tag) => <h5 id={styles.Tag}>{tag.name}</h5>)}
          </div>

          <h5>Add Sub-Topics</h5>
          <div>
            <input
              type="text"
              className={styles.topicsInput}
              placeholder="Enter Sub-Topic"
              onChange={(e) => setSubtopic(e.target.value)}
            />
          </div>

          <div className={styles.ModalButtons}>
            <button
              onClick={() => {
                HideSubTopicModal();
                setSubtopic("");
              }}
              className={styles.cancelButton}
            >
              Cancel
            </button>
            <button onClick={uploadVideo} className={styles.doneButton}>
              Done
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
