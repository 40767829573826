import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const JobAPIs: ApiInterface[] = [
  {
    url: "/api/job/getJobs",
    method: "GET",
    name: "getJobs",
  },
  {
    url: "/api/job/create",
    method: "POST",
    name: "createJob",
  },
  {
    url: "/api/job/id",
    method: "GET",
    name: "getJobById",
  },
  {
    url: "/api/job/getAllJobs",
    method: "GET",
    name: "getAllJobs",
  },
  {
    url: "/api/job/status",
    method: "POST",
    name: "updateJobPublishedStatus",
  },
  {
    url: "/api/job/update",
    method: "PUT",
    name: "editJob",
  },
];

export function JobsApi(
  name: string,
  params: string[] = [""],
  data: any = null,
  queryParams: Record<string, any> = {}
) {
  const api = JobAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url =
    backendUrl + api.url + (params.length ? "/" + params.join("/") : "");

  // Convert queryParams object to a query string
  const queryString = new URLSearchParams(queryParams).toString();

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url: queryString ? `${url}?${queryString}` : url,
    headers: {
      "Content-Type": name==="updateJobPublishedStatus"? "application/json": "multipart/form-data",
    },
  });
}
