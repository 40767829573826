import React, { useContext, useEffect, useState } from "react";
import { CommissionBanner, ROlES } from "../utils";
import CouponModal from "./components/coupon-modal";
import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import ViewCoupons from "./components/ViewCoupons";
import toast, { Toaster } from "react-hot-toast";
import { UserContext } from "state-management/user-context";
import FullScreenPopup from "../components/full-screen-popup";

const CouponManager = () => {
  const { user } = useContext(UserContext);
  const [refreshData, setRefreshData] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [popup, setPopup] = useState({
    isOpen: false,
    message: "",
    heading: "",
    canDestroy: false,
  });

  const [modal, setModal] = useState({
    isModalOpen: false,
    data: null,
  });
  async function fetchData() {
    setIsLoading(true);
    try {
      const [commissionUsersResponse] = await Promise.all([
        CommissionAPI("getAllCommissionUsers"),
      ]);

      if (commissionUsersResponse.status === 200) {
        const commissionUsersData = commissionUsersResponse?.data?.data || [];
        setAllUsers(commissionUsersData);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "something went wrong.";
      toast.error(message);
      setPopup({
        isOpen: true,
        message: message,
        canDestroy: false,
        heading: "",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchCoupons() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getAllCoupons");
      if (response?.data?.success) {
        const data = response?.data?.data;
        setCoupons(data);
      }
    } catch (error) {
      console.error("Error fetching coupons:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (user?.isloggedin) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    fetchCoupons();
  }, [refreshData]);

  const closeModal = () => {
    setModal({
      isModalOpen: false,
      data: null,
    });
  };

  return (
    <div className="w-full pb-20 px-5">
      <Toaster />
      <CommissionBanner
        title="Coupon Manager"
        setRefreshData={setRefreshData}
      />
      {/* <CouponModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        allUsers={allUsers}
        modal={modal}
      /> */}
      <ViewCoupons
        allUsers={allUsers}
        coupons={coupons}
        setCoupons={setCoupons}
      />
      <section className="my-5 antialiased">
        <div className="mx-auto"></div>
      </section>
    </div>
  );
};

export default CouponManager;
