
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { backendUrl } from "constants/config";
async function getBankAccountDetails() {
  //1 hour cache
  const response = await fetch(
    `${backendUrl}/api/bank-details/get-bank-account-details`,
    { cache: "no-store" }
  );
  const data = await response.json();
  return data;
}
const PaymentMethods = ({ paymentMethod }) => {
  const [banks, setBanks] = useState([]);
  const fetchBankAccountDetails = async () => {
    try {
      // let response 
      const response = await getBankAccountDetails();
      if (response && response?.success) {
        const data = response?.bankDetails || [];
        setBanks(data);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  useEffect(() => {
    fetchBankAccountDetails();
  }, []);

  return (
    <>
      <>
        {paymentMethod === "Bank Transfer" && (
          <>
            <h3 className="text-primary text-2xl font-bold mt-4 mb-2">
              Bank Transfer
            </h3>
            <h5 className="text-primary text-lg font-bold">Method</h5>
            <p className="text-gray-800">
              Transfer the Grand Total to the Bank Account, the details of which
              are provided below. Then upload the Paid Receipt below.
            </p>
            <h5 className="text-primary text-lg font-bold mb-2">
              Bank Account Details
            </h5>
            {banks
              .filter((bank) => bank?.Type === "BANK TRANSFER")
              .map((bank) => (
                <div key={bank?._id} className="my-2">
                  <div className="text-gray-800">
                    <b>Bank Name:</b> {bank?.BankName}
                  </div>
                  <div className="text-gray-800">
                    <b>Account Title:</b> {bank?.AccountTitle}
                  </div>
                  <div className="text-gray-800">
                    <b>Account Number:</b> 
                    {bank?.Type !== "BANK TRANSFER" ? "0" : null}                   
                    {bank?.AccountNumber}{" "}
                    <button>
                      <CopyIcon
                        clickFunction={() =>
                          navigator.clipboard.writeText(bank?.AccountNumber)
                        }
                      />
                    </button>
                  </div>
                  <div className="text-gray-800">
                    <b>IBAN:</b> {bank?.IBAN}{" "}
                    <button>
                      <CopyIcon
                        clickFunction={() =>
                          navigator.clipboard.writeText(bank?.IBAN)
                        }
                      />
                    </button>
                  </div>
                  <div className="border-b border-gray-200 my-2"></div>
                </div>
              ))}
          </>
        )}
        {paymentMethod === "Easypaisa" && (
          <>
            <h3 className="text-primary text-2xl font-bold mt-4 mb-2">
              Easypaisa
            </h3>
            <h5 className="text-primary text-lg font-bold">Method</h5>
            <p className="text-gray-800">
              Transfer the Grand Total to the Easypaisa Account, the details of
              which are provided below. Then upload the Paid Receipt below.
            </p>
            <h5 className="text-primary text-lg font-bold mb-2">
              Easypaisa Account Details
            </h5>
            {banks
              .filter((bank) => bank?.Type === "EASYPAISA")
              .map((bank) => (
                <div key={bank?._id} className="my-2">
                  <div className="text-gray-800">
                    <b>Account Title:</b> {bank?.AccountTitle}
                  </div>
                  <div className="text-gray-800">
                    <b>Account Number:</b> 
                    {bank?.Type !== "BANK TRANSFER" ? "0" : null}                   
                    {bank?.AccountNumber}{" "}
                    <button>
                      <CopyIcon
                        clickFunction={() =>
                          navigator.clipboard.writeText(
                            bank?.Type !== "BANK TRANSFER" ? `0${bank?.AccountNumber}` : bank?.AccountNumber
                          )                            
                        }
                      />
                    </button>
                  </div>
                  <hr className="my-2" />
                </div>
              ))}
          </>
        )}
        {paymentMethod === "JazzCash" && (
          <>
            <h3 className="text-primary text-2xl font-bold mt-4 mb-2">
              JazzCash
            </h3>
            <h5 className="text-primary text-lg font-bold">Method</h5>
            <p className="text-gray-800">
              Transfer the Grand Total to the JazzCash Account, the details of
              which are provided below. Then upload the Paid Receipt below.
            </p>
            <h5 className="text-primary text-lg font-bold mb-2">
              JazzCash Account Details
            </h5>
            {banks
              .filter((bank) => bank?.Type === "JAZZCASH")
              .map((bank) => (
                <div key={bank?._id} className="my-2">
                  <div className="text-gray-800">
                    <b>Account Title:</b> {bank?.AccountTitle}
                  </div>
                  <div className="text-gray-800">
                    <b>Account Number:</b> 
                    {bank?.Type !== "BANK TRANSFER" ? "0" : null}
                    {bank?.AccountNumber}{" "}
                    <button>
                      <CopyIcon
                        clickFunction={() =>
                          navigator.clipboard.writeText(
                            bank?.Type !== "BANK TRANSFER" ? `0${bank?.AccountNumber}` : bank?.AccountNumber
                          )                                                      
                        }
                      />
                    </button>
                  </div>
                  <hr className="my-2" />
                </div>
              ))}
          </>
        )}
      </>

      {/* {paymentMethod == "Bank Transfer" && (
        <>
          <h3 className="text-primary text-2xl font-bold mt-4 mb-2">
            {" "}
            Bank Transfer
          </h3>
          <h5 className="text-primary text-lg font-bold">Method</h5>
          <p className="text-gray-800">
            Transfer the Grand Total to the Bank Account, the details of which
            are provided below. Then upload the Paid Receipt below.
          </p>

          <h5 className="text-primary text-lg font-bold">
            {" "}
            Bank Account Details
          </h5>
          <div className="flex justify-between items-center mb-2">
            <div className="flex flex-col">
              <div className="text-gray-800">
                <b>Bank Name:</b> Meezan Bank
              </div>
              <div className="text-gray-800">
                <b>Account Title:</b> PREMED LLP
              </div>
              <div className="text-gray-800">
                <b>Account Number:</b> 10200109822295{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("99170105642737")
                    }
                  />
                </button>
              </div>
              <div className="text-gray-800">
                <b>IBAN:</b> PK13MEZN0010200109822295{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("PK50MEZN009917010564273")
                    }
                  />
                </button>
              </div>
              <div className="mt-2 mb-2 border"></div>
              <div className="text-gray-800">
                <b>Bank Name:</b> Habib Bank Limited (HBL)
              </div>
              <div className="text-gray-800">
                <b>Account Title:</b> MOHD HASNAIN
              </div>
              <div className="text-gray-800">
                <b>Account Number:</b> 05417988872303{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("99170105642737")
                    }
                  />
                </button>
              </div>
              <div className="text-gray-800">
                <b>IBAN:</b> PK90HABB0005417988872303{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("PK50MEZN009917010564273")
                    }
                  />
                </button>
              </div>
            </div>

            <img
              src="https://play-lh.googleusercontent.com/7r_CxADHDXrfNNnwqCFcbwRboziLhtm-KUhBvt03f0-uSA1_h9G8jpjWnbMzmSXA7IY=w600-h300-pc0xffffff-pd"
              alt="Bank Transfer Logo"
              className="w-1/4"
            />
          </div>
        </>
      )}
      {paymentMethod == "Easypaisa" && (
        <>
          <h3 className="text-primary text-2xl font-bold mt-4 mb-2">
            {" "}
            Easypaisa
          </h3>
          <h5 className="text-primary text-lg font-bold">Method</h5>
          <p className="text-gray-800">
            Transfer the Grand Total to the Easypaisa Account, the details of
            which are provided below. Then upload the Paid Receipt below.
          </p>
          <h5 className="text-primary text-lg font-bold">
            Easypaisa Account Details
          </h5>

          <div className="flex justify-between mb-2">
            <div className="flex flex-col">
              <div className="text-gray-800">
                <b>Account Title:</b> Fahd Niaz Shaikh
              </div>
              <div className="text-gray-800">
                <b>Account Number:</b> 0336-2542685{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("0336-2542685")
                    }
                  />
                </button>
              </div>
              <br />
              <div className="text-gray-800">
                <b>Account Title:</b> ABBAS ALI MANKANI
              </div>
              <div className="text-gray-800">
                <b>Account Number:</b> 03362541727{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("0336-2542685")
                    }
                  />
                </button>
              </div>
            </div>
            <img
              src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Courses/'Jazz%20Cash%201.svg"
              alt="Easypaisa Logo"
              className="w-1/4 h-1/4"
            />
          </div>
        </>
      )}
      {paymentMethod == "JazzCash" && (
        <>
          <h3 className="text-primary text-2xl font-bold mt-4 mb-2">
            {" "}
            JazzCash
          </h3>
          <h5 className="text-primary text-lg font-bold">Method</h5>
          <p className="text-gray-800">
            Transfer the Grand Total to the JazzCash Account, the details of
            which are provided below. Then upload the Paid Receipt below.
          </p>

          <h5 className="text-primary text-lg font-bold">
            JazzCash Account Details
          </h5>
          <div className="flex justify-between mb-2">
            <div className="flex flex-col">
              <div className="text-gray-800">
                <b>Account Title:</b> PreMedPK
              </div>
              <div className="text-gray-800">
                <b>Account Number:</b> 0302-8609690{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("0331-2176647")
                    }
                  />
                </button>
              </div>
              <br />
              <div className="text-gray-800">
                <b>Account Title:</b> Khawaja Muhammad Heean
              </div>
              <div className="text-gray-800">
                <b>Account Number:</b> 0331-2176647{" "}
                <button>
                  <CopyIcon
                    clickFunction={() =>
                      navigator.clipboard.writeText("0331-2176647")
                    }
                  />
                </button>
              </div>
            </div>
            <img
              src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Courses/'Jazz%20Cash%201%20(1).svg"
              alt="JazzCash Logo"
              className="w-1/4 h-1/4"
            />
          </div>
        </>
      )} */}
    </>
  );
};

const CopyIcon = (clickFunction) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-clipboard w-5 h-5"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#000000"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      onClick={() => {
        clickFunction.clickFunction();
        toast.success("Copied to Clipboard");
      }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
      <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
    </svg>
  );
};

export default PaymentMethods;
