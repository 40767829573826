import { Input, Label } from "../../../../shared-components/ui/CustomForm";
import { Image, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UserContext } from "../../../../state-management/user-context";
import toast, { Toaster } from "react-hot-toast";
import { FormEvent, useContext, useEffect, useState } from "react";
import { formatNumberWithCommas, REQUEST_STATUS } from "../../utils";
import { CommissionAPI } from "../../../../(apis)/(shared-apis)/latest-commission-api";
import CustomDropDown from "../../../../shared-components/ui/CustomDropDown";
import { ButtonOutlined } from "../../../../shared-components/ui/CustomButtons";
import { ButtonFill } from "../../../../shared-components/ui/CustomButtons";
import { CopyIcon } from "shared-components/ui/Icons";
import { Check } from "@material-ui/icons";

export const ReimbursementModal = (props) => {
  //   const minimumAmountUserCanRequest = 5000;
  const { user } = useContext(UserContext);
  const [requestedAmount, setRequestedAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [requestType, setRequestType] = useState("Approve Request");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const resetForm = () => {
    setRequestedAmount(0);
    setPendingAmount(0);
    setRequestType("Approve Request");
    setIsLoading(false);
    setData(null);
  };

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    if (props?.modal?.data) {
      console.log("Modal Data: ", props?.modal?.data);
      const pendingAmount = props?.modal?.data?.requestedAmount;
      setPendingAmount(pendingAmount);
      setRequestedAmount(pendingAmount);
      setData(props?.modal?.data);
    }
  }, [props?.modal?.data]);

  const handleInputChange = (name, value) => {
    setRequestedAmount(value);
  };

  const closeModal = () => {
    setRequestedAmount(0);
    setPendingAmount(0);
    props?.setModal({
      isOpen: false,
      data: null,
    });
    // props.setIsModalOpen(false);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Inside On submit: ", requestedAmount);
    if (!requestedAmount || requestedAmount <= 0) {
      toast.error("Invalid Amount");
      return;
    }

    setIsLoading(true);

    // =======================
    // REQUEST REJECTED
    // =======================
    if (requestType === "Reject Request") {
      try {
        const body = {
          id: data?._id,
          status: REQUEST_STATUS.Rejected,
          approvedBy: user?.username,
        };

        console.log("data: ", body);
        const response = await CommissionAPI(
          "updateReimbursementStatus",
          [],
          body
        );

        // const response = await updateReimbursementStatus(body);

        console.log(
          "updateReimbursementStatus Response: ",
          response,
          response?.data?.isSuccess
        );
        if (response?.data?.isSuccess) {
          toast.success("Rejected Successfully.");

          resetForm();
          setTimeout(() => {
            props?.setModal({
              isOpen: false,
              data: null,
            });
          }, 2000);
        }
      } catch (error) {
        console.error("Error updateReimbursementStatus:", error);
      } finally {
        setIsLoading(false);
      }
    }

    // =======================
    // REQUEST APPROVED
    // =======================
    else if (requestType === "Approve Request") {
      if (!file) {
        toast.error("Please upload reciept screenshot");
        return;
      }
      try {
        const formData = new FormData();
        formData.append("id", data?._id);
        formData.append("status", REQUEST_STATUS.Approved);
        formData.append("approvedBy", user?.username);
        formData.append("paidAmount", requestedAmount?.toString());

        if (file) {
          formData.append("screenShot", file);
        }

        console.log("formData: ", formData);
        const response = await CommissionAPI(
          "approveReimbursement",
          [],
          formData
        );
        // const response = await approveReimbursement(formData);

        console.log(
          "approveReimbursement Response: ",
          response,
          response?.data?.isSuccess
        );
        if (response?.data?.isSuccess) {
          toast.success("Approved Successfully.");

          resetForm();
          setTimeout(() => {
            // props?.setIsModalOpen(false);
            props?.setModal({
              isOpen: false,
              data: null,
            });
          }, 2000);
        }
      } catch (error) {
        console.error("Error updateReimbursementStatus:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // ========================
  // ===Screen Shot Logic ===
  // ========================

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState(null);
  const [screenshot, setScreenshot] = useState("");
  const [generatedPictureUrl, setGeneratedPictureUrl] = useState(null);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChangeImage = async (info) => {
    if (info?.file?.status === "removed") {
      setScreenshot("");
      setFile("");
      setGeneratedPictureUrl("");
    } else {
      const base64: any = await getBase64(info?.file?.originFileObj);
      setScreenshot(base64);
      setFile(base64);
    }
  };

  const uploadButtonStudentPicture = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload Screenshot</div>
    </div>
  );

  // ========================
  // ===Screen Shot Logic ===
  // ========================

  return (
    <div
      id="deleteModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props?.modal?.isOpen ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm`}
    >
      <Toaster />

      <div className="relative p-4 w-full max-w-lg ">
        {/* Modal content */}
        <div className="relative p-4 max-h-[90vh] overflow-y-scroll text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
          <button
            type="button"
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
            data-modal-toggle="deleteModal"
            onClick={() => closeModal()}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <p className="mb-4 text-gray-500 text-xl ddark:text-gray-300">
            Reimbursement Form
          </p>

          <form
            onSubmit={(e) => submit(e)}
            className="text-left my-5 space-y-5"
          >
            <CustomDropDown
              label="Select Request Type"
              options={[`Reject Request`, "Approve Request"]}
              onSelect={(type) => {
                setRequestType(type);
                if (type === "Reject Request") {
                  setRequestedAmount(pendingAmount);
                }
              }}
              width="w-full"
              value={requestType}
              isError={false}
              errorMessage={""}
            />

            {requestType === "Approve Request" ? (
              <>
                {/* <div className="space-y-2">
                  <Label>Amount</Label>
                  <Input
                    type="number"
                    value={requestedAmount}
                    name="requestedAmount"
                    onChange={handleInputChange}
                    isError={false}
                    errorMessage={""}
                    disabled
                  />
                </div> */}

                <BankDetailsDisplay
                  bankDetails={props?.modal?.data?.bankDetails}
                />

                <div className="md:p-2 w-full">
                  <Upload
                    listType="picture-card"
                    fileList={file ? [{ uid: "-1", url: file, name: "" }] : []}
                    onPreview={handlePreview}
                    onChange={handleChangeImage}
                    accept="image/*"
                    maxCount={1}
                  >
                    {file ? null : uploadButtonStudentPicture}
                  </Upload>
                  {previewImage && (
                    <Image
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        // afterClose: () => setPreviewImage(""),
                      }}
                      src={previewImage}
                      style={{ display: "none" }}
                    />
                  )}
                </div>
              </>
            ) : null}

            <p className="mb-4 text-gray-700 text-center font-semibold text-lg ddark:text-gray-300">
              Amount : {formatNumberWithCommas(requestedAmount)}
            </p>
            <div className="flex justify-center items-center space-x-4">
              <ButtonOutlined handleClick={() => closeModal()}>
                cancel
              </ButtonOutlined>

              <ButtonFill type="Submit" disabled={isLoading}>
                {isLoading ? "Loading.." : " Submit"}
              </ButtonFill>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const BankDetailsDisplay = ({ bankDetails }) => {
  const [copiedField, setCopiedField] = useState(null);

  const copyToClipboard = (text, field) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedField(field);
        toast.success(`${field} copied to clipboard`);

        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopiedField(null);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        toast.error("Failed to copy");
      });
  };

  const BankDetailRow = ({ label, value, field }) => (
    <div className="bg-gray-100 rounded-lg p-3 flex items-center justify-between space-x-4 hover:bg-gray-200 transition-colors duration-200">
      <div>
        <p className="text-sm text-gray-600 font-medium">{label}</p>
        <p className="text-lg font-semibold text-gray-800">
          {value || "Not Provided"}
        </p>
      </div>
      {value && (
        <button
          type="button"
          onClick={() => copyToClipboard(value, field)}
          className="text-gray-500 hover:text-blue-600 focus:outline-none"
        >
          {copiedField === field ? (
            <Check className="w-6 h-6 text-green-500" />
          ) : (
            <CopyIcon size="w-5 h-5" />
          )}
        </button>
      )}
    </div>
  );

  // If no bank details are provided
  if (!bankDetails || Object.values(bankDetails).every((val) => !val)) {
    return (
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <p className="text-yellow-700">No bank details available</p>
      </div>
    );
  }

  return (
    <div className="space-y-4  bg-white rounded-xl shadow-sm">
      <h2 className="text-xl font-bold text-gray-800 mb-4 border-b pb-2">
        Bank Details
      </h2>

      <div className="space-y-3">
        <BankDetailRow
          label="Account Title"
          value={bankDetails.accountTitle}
          field="Account Title"
        />

        <BankDetailRow
          label="Bank Name"
          value={bankDetails.bankName}
          field="Bank Name"
        />

        <BankDetailRow
          label="Account Number"
          value={bankDetails.accountNumber}
          field="Account Number"
        />
      </div>
    </div>
  );
};
