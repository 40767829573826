import { ApiInterface } from "../api-interface/types";

 export const blogApis:ApiInterface[] = [
   
    {
        url: "/api/blog/get-blog-issues",
        method: "GET",
        name: "getAllBlogIssues",
    },
    {
        url: "/api/blog/update-blog-issue-status/:id",
        method: "PUT",
        name: "UpdateBlogIssueStatus",
      },
   
]