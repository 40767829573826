import { Image, message, notification } from "antd";
import { useContext, useEffect, useState } from "react";
// @ts-ignore
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { UserContext } from "state-management/user-context";
import ExpertTable from "./Table";
import { BackIcon } from "shared-components/ui/Icons";
import CustomButton from "shared-components/form-fields/custom-button";
import Loader from "shared-components/Loader";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import DoubtsTable from "../experts/doubts-table";
import toast, { Toaster } from "react-hot-toast";
type Statistics = {
  Pendingcount: number;
  Solvedcount: number;
  UserRating: number;
  UserCoins: number;
};

type Doubt = {
  _id: string;
  resource?: string;
  subject?: string;
  topic?: string;
  description: string;
  questionID?: string;
  ExpireDate?: string;
  img?: any;
};

export default function ExpertDashboard() {
  const { user } = useContext(UserContext);
  const [statistics, setStatistics] = useState<Statistics | undefined>();
  const [doubts, setDoubts] = useState<Doubt[]>([]);
  const [viewDoubt, setViewDoubt] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [doubtDisplay, setDoubtDisplay] = useState<Doubt | undefined>();
  const [image, setImage] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [uCoins, setCoins] = useState<number>(0);

  const navigate = useNavigate();

  const getUserStats = async () => {
    if (!user || !user.username) {
      return;
    }
    const response = await apiRequest("ExpertStatistics", {
      username: user?.username,
    });
    const data = response.data;
    setStatistics(data);
    setRating(parseFloat(data?.UserRating?.toFixed(2) || 0.0));
    setCoins(data.UserCoins);
  };

  const fetchDoubts = async () => {
    try {
      setLoading(true);
      if (!user || !user.username) {
        return;
      }
      const response = await apiRequest("GetExpertsPendingDoubts", {
        username: user?.username,
      });
      const data = response.data;
      if (data?.length > 0) {
        setDoubts(data);
      } else {
        message.warning("No pending doubts found");
        setDoubts([]);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDoubts();
    getUserStats();
  }, [user]);

  const handleViewDoubt = (id: string) => {
    setViewDoubt(true);
  };


  const unTakeDoubt = async (id: string) => {
    const response = await apiRequest("UnTakeDoubt", {
      id,
    });
    const data = response.data;
    if (data.success) {
      toast.success("Doubt Returned to pool");
      setViewDoubt(false);
      setDoubtDisplay(undefined);
      setImage(null);
      fetchDoubts();
    } else {
      toast.error("Error in returning doubt");
    }
  };

  const [DoubtDisplay, SetDoubtDisplay] = useState(null);

  const handleDoubtImage = (id: string): void => {
    if (!user || !user.username) {
      toast.error("Please login to view doubt");
      // navigate("/login");
      return;
    }
    else{
      toast.success("Redirecting to Doubt Interface");
      navigate(`/expert-interface/objective/${id}`)
    }
  };

  return (
    <>
      <Toaster/>
      <div>
        <>
          <div className="w-full pb-20 px-5">
            <div className="flex items-center justify-between pb-1 mt-5">
              <h2 className="text-2xl font-bold text-gray-700">My Doubts Management</h2>
              <ButtonFill
                handleClick={() => {
                  navigate("/experts")
                }}
              >
                User Doubts 
              </ButtonFill>
            </div>
            <div className="flex items-center justify-between gap-2 pb-1 mt-5">
              <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
                <h6 className="text-gray-700 font-semibold text-lg">Pending Questions</h6>
                <h6 className="text-gray-950 font-extrabold text-3xl">{statistics?.Pendingcount}</h6>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
                <h6 className="text-gray-700 font-semibold text-lg">Solved Questions</h6>
                <h6 className="text-gray-950 font-extrabold text-3xl">{statistics?.Solvedcount}</h6>
              </div>
            </div>
            <DoubtsTable 
              data={doubts} 
              loading={loading} 
              setViewDoubt={handleDoubtImage} 
              type="MY-DOUBTS"
              deleteDoubt={()=>{}} 
              unTakeDoubt={unTakeDoubt}
              />
          </div>



        </>
      </div>

    
    </>
  );
}
