import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
// import { AddQuestion, GetDeckWhereQuestionExists, RevEditQuestion } from '../../../APIs/questionsAPI';
import Page1 from "./components/page1/question-essentials";
import Page2 from "./components/page2/main";
import Page3 from "./components/page3/main";
import Page2SAQ from "./components/saqs/main";

import { UserContext } from "../../../state-management/user-context";
import { modules } from "./utils";

import { apiRequest } from "(apis)/api-interface/api-handler";
import { Divider } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { DeckAPI } from "../../../(apis)/(shared-apis)/academics/decks-api";
import { QuestionReportingAPI } from "../../../(apis)/(shared-apis)/academics/question-reporting";
import PageHeader from "./components/header";
import NavigationButtons from "./components/navigation";
import PendingAuditQuestionReports from "./components/pending--audit-question-reports";
import { QuestionDefaultValues } from "./utils";

export default function EditQuestion() {
  const [mode, setMode] = useState("edit");
  const { id, reportId } = useParams();
  const [explanationText, setExplanationText] = useState("");
  const [fetching, setFetching] = useState(true);

  const { user } = useContext(UserContext);

  const [references, setReferences] = useState([]);
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState([]);
  const [form, setForm] = useState(QuestionDefaultValues);

  const [disabled, setDisabled] = useState(false);

  const Submit = async () => {
    setDisabled(true);
    if (!Validate()) {
      setDisabled(false);
      return;
    }

    const optionWithLetters = options.map((item, index) => {
      item.OptionLetter = String.fromCharCode(65 + index);
      return item;
    });

    let referencePdfs = references
      .filter((reference) => reference.refId)
      .map((reference) => ({
        board: reference.board,
        startPage: reference.startPage,
        endPage: reference.endPage || null,
        refId: reference.refId,
        pdfSelectedText: reference.pdfSelectedText,
      }));

    try {
      let tagsObject = [];
      tagsObject.push({ name: form?.subject, id: 4000 });
      tagsObject.push({ name: form?.topic, id: 4000 });
      form?.subTopic &&
        form?.subTopic.map((item) => {
          tagsObject.push({ name: item, id: 4000 });
        });
      if (!user || !user.username || user.username === "") {
        toast.error("User not found");
        return;
      }
      const Response = await apiRequest(
        "editQuestion",
        {
          form,
          options: optionWithLetters,
          references: referencePdfs,
          tags: tagsObject,
          user: user.username,
        },
        [id]
      );
      if (Response.data.success) {
        toast.success(Response.data.message);
      } else {
        toast.error(Response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error.message);
    }

    if (reportId) {
      const Response = await QuestionReportingAPI(
        "solveReport",
        [id, reportId],
        { fixedby: user.username }
      );
      if (Response.data.succes) {
        toast.success(Response.data.message);
      } else {
        toast.error(Response.data.message);
      }
    }
    setDisabled(false);
  };

  const [numberOfOptions, setNumberOfOptions] = React.useState(0);

  const GetQuestion = async () => {
    const Response = await apiRequest("getQuestionById", null, [id]);
    if (Response.data.success) {
      const options = Response.data.data.Options.map((item) => ({
        OptionText: item.OptionText,  
        IsCorrect: item.IsCorrect,
        ExplanationText: item.ExplanationText,
        optionImage: item.optionImage,
        explanationImage: item.explanationImage,
      }));
      setOptions(options);
      setNumberOfOptions(options.length);
      const references = Response.data.data.referencePdfs.map((item) => ({
        board: item.board,
        startPage: item.startPage,
        endPage: item.endPage || null,
        refId: item.refId,
        pdfSelectedText: item.pdfSelectedText,
      }));
      setReferences(references);
      setForm({ 
        id: Response.data.data._id,
        deckName: Response.data.data.DeckName,
        questionText: Response.data.data.QuestionText,
        QuestionImage: Response.data.data.QuestionImage,
        hints: Response.data.data.Hints,
        tip: Response.data.data.tip,
        ExplanationText: Response.data.data.ExplanationText,
        ExplanationImage: Response.data.data.ExplanationImage,
        isSAQ: Response.data.data.isSAQ,
        bookReferences: Response.data.data.bookReferences,
        SAQAnswer: Response.data.data.SAQAnswer,
        SAQImage: Response.data.data.SAQAnswerImage,
        type: Response.data.data.meta.type,
        university: Response.data.data.meta.university,
        year: Response.data.data.meta.year,
        entity: Response.data.data.meta.entity,
        category: Response.data.data.meta.category,
        board: Response.data.data.meta.board,
        subject: Response.data.data.meta.subject,
        topic: Response.data.data.meta.topic,
        subTopic: Response.data.data.meta.subTopics,
        miscTags: Response.data.data.meta.miscTags,
      });
      setExplanationText(Response.data.data.ExplanationText);
    }
    setFetching(false);
  };

  useEffect(() => {
    GetQuestion();
  }, []);

  const Reject = async () => {
    setDisabled(true);
    const Response = await QuestionReportingAPI(
      "rejectReport",
      [id, reportId],
      { fixedby: user.username }
    );
    if (Response.data.succes) {
      toast.success(Response.data.message);
    } else {
      toast.error(Response.data.message);
    }
    setDisabled(false);
  };

  const Validate = () => {
    if (form?.deckName === "") {
      toast.error("Deck Name is required");
      return false;
    }
    if (form?.entity === "") {
      toast.error("Entity is required");
      return false;
    }
    if (form?.category === "") {
      toast.error("Category is required");
      return false;
    }
    if (form?.board?.length === 0) {
      toast.error("Board is required");
      return false;
    }
    if (form?.subject === "") {
      toast.error("Subject is required");
      return false;
    }
    if (form?.type === "") {
      toast.error("Type is required");
      return false;
    }
    if (form?.topic === "") {
      toast.error("Topic is required");
      return false;
    }
    if (form?.questionText === "") {
      toast.error("Question Text is required");
      return false;
    }
    if (options.length < 2 && !form?.isSAQ) {
      toast.error("At least two options are required");
      return false;
    }
    if (options.filter((item) => item.IsCorrect).length !== 1 && !form?.isSAQ) {
      toast.error("Only one correct option is allowed");
      return false;
    }
    return true;
  };

  const [decksIncluded, setDecksIncluded] = useState([]);

  const FindDecks = async () => {
    try {
      const response = await apiRequest("findDeckByQuestionId", null, [id]);
      if (response.data.success) {
        setDecksIncluded(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      FindDecks();
    }
  }, []);

  const handlePublishedChange = async (deckId, questionId) => {
    const response = await DeckAPI("unPublishDeckQuestion", "", {
      deckId,
      questionId,
    });
    if (response.data.success) {
      toast.success("Question changed successfully");
      FindDecks();
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      {fetching ? (
        <div className="loader"></div>
      ) : (
        <>
          <Toaster />
          <div className="flex flex-col gap-10">
          <div className="w-full md:w-[95%] mx-auto">
            <div className="bg-stone-100 shadow-md sm:rounded-lg py-4">
              <PageHeader mode={mode} />
              {page === 1 && (
                <Page1
                  mode={mode}
                  form={form}
                  setForm={setForm}
                  modules={modules}
                />
              )}
              {page === 2 && !form?.isSAQ && (
                <Page2
                  numberOfOptions={numberOfOptions}
                  setNumberOfOptions={setNumberOfOptions}
                  options={options}
                  setOptions={setOptions}
                  modules={modules}
                />
              )}
              {page === 2 && form?.isSAQ && (
                <Page2SAQ form={form} setForm={setForm} modules={modules} />
              )}
              {page === 3 && (
                <Page3
                  mode={mode}
                  references={references}
                  setReferences={setReferences}
                  form={form}
                  setForm={setForm}
                  modules={modules}
                  explanationText={explanationText}
                />
              )}
              <NavigationButtons
                page={page}
                setPage={setPage}
                onSubmit={Submit}
                disabled={disabled}
                reportId={reportId}
                onReject={Reject}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center shadow-lg p-4 m-4 mb-10 rounded-lg">
                <h1 className="text-2xl font-bold">
                  Decks where this question is included
                </h1>
                <div className="w-[90%]">
                  {decksIncluded.map((deck, index) => (
                    <div
                      className="flex justify-between items-center p-2 border-b-2"
                      key={index}
                    >
                      <h1>{deck.deckName}</h1>
                      <label className="flex items-center justify-center cursor-pointer">
                        <div className="relative">
                          <input
                            type="checkbox"
                            className="sr-only"
                            checked={deck.questions.includes(id)}
                            onChange={() => handlePublishedChange(deck._id, id)}
                          />
                          <div
                            className={`block ${
                              deck.questions.includes(id)
                                ? "bg-green-400"
                                : "bg-gray-200"
                            } w-12 h-6 rounded-full`}
                          ></div>
                          <div
                            className={`dot absolute ${
                              !deck.questions.includes(id)
                                ? "left-1 top-1"
                                : "left-7 top-1"
                            } bg-white w-4 h-4 rounded-full transition`}
                          ></div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

          {id  && <div className="bg-stone-100 shadow-md sm:rounded-lg p-10">
          <Divider>Pending Issues</Divider>
          <PendingAuditQuestionReports questionId={id} />

          </div>}

          </div>
        </>
      )}
    </>
  );
}
