import { apiRequest } from "(apis)/api-interface/api-handler";
import { PlusOutlined } from "@ant-design/icons";
import { Image, message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Loader from "shared-components/Loader";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { EditIcon, EyeIcon } from "shared-components/ui/Icons";
import { topicalGuidesInitialValues } from "shared-pages/vault/components/initialvalues";
import { convertToFormData } from "utility/form/converttoformdata";
import filteroutGeneratedImagesUrls from "utility/form/filteroutGeneratedImagesUrls";
import { generateAntdImageObjectFromUrl } from "utility/form/generate-antd-image-object-from-url";
import { generateAntdPdfObjectFromUrl } from "utility/form/generate-antd-pdf-object-from-url";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import { columnsWithoutRender } from "./columns";
import AddAndUpdateModal from "./modal";
import { validationSchema } from "./schema";
import { TopicalGuide } from "./types";
const TopicalGuides: React.FC = () => {
  const [ShowModel, setShowModel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  type SelectOption = { value: string; label: string }[];
  const [boardsData, setBoardData] = useState<SelectOption>([]);
  const [subjectData, setSubjectData] = useState<SelectOption>([]);
  const [allSubTopicsData, setAllSubTopicsData] = useState<SelectOption>([]);
  const [topicalGuideData, setTopicalGuideData] = useState<TopicalGuide[]>([]);
  const [institutesData, setInstitutesData] = useState<SelectOption>([]);
  const [topicsData, setTopicsData] = useState<SelectOption>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: topicalGuidesInitialValues,
    validationSchema,
    onSubmit: async (values) => {
      const filteredPaginations = values.paginations?.filter(
        (pagination) => pagination.startPageNo && pagination.subTopic
      );
      const base64ThumbnailImage = await identifyAndConvertNewImagesToBase64(
        values?.thumbnailImage
      );
      const thumbnailImageUrl = filteroutGeneratedImagesUrls(
        values?.thumbnailImage
      );
      const updatedValues = {
        ...values,
        pdf: values?.pdf?.[0]?.originFileObj,
        thumbnailImageUrl: thumbnailImageUrl?.[0],
        thumbnailImage: base64ThumbnailImage?.[0],
        category: process.env.REACT_APP_CATEGORY,
        entity: process.env.REACT_APP_ENTITY,
        paginations:
          filteredPaginations?.length > 0 ? filteredPaginations : undefined,
      };
      const formData = convertToFormData(updatedValues);
      try {
        setLoading(true);
        let res;
        if (isEditMode) {
          res = await apiRequest("Updateguide", formData, [
            values?.topicalGuideId,
          ]);
        } else {
          res = await apiRequest("addquide", formData);
        }
        if (res?.status === 200) {
          setLoading(false);
          setIsEditMode(false);
          setShowModel(false);
          getTopicalGuidesData();
          message.success(res?.data?.message);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
        setLoading(false);
      }
    },
  });

  const { setValues, resetForm } = formik;
  const [modules, setModules] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const getTopicalGuidesData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getguides");
      if (res?.data?.success) {
        const resData = res?.data?.data;
        setTopicalGuideData(resData);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getAllGuidesAndModalDropdownData");
      const {
        topics,
        boards,
        modules,
        disciplines,
        subjects,
        institutes,
        guides,
      } = res?.data?.data;

      if (res?.data?.isSuccess) {
        if (guides?.length > 0) {
          setTopicalGuideData(guides);
        }
        if (modules?.length > 0) {
          const formattedData = modules?.map((module: any) => ({
            label: module.moduleName,
            value: module.moduleName,
          }));
          setModules(formattedData);
        }
        if (disciplines?.length > 0) {
          const disciplinesData = disciplines?.map((item: any) => ({
            value: item?.disciplineName,
            label: item?.disciplineName,
          }));
          setDisciplines(disciplinesData);
        }
        

        if (institutes?.length > 0) {
          const instituteData = institutes?.map((item: any) => ({
            value: item?.instituteName,
            label: item?.instituteName,
          }));
          setInstitutesData(instituteData);
        }

        if (topics?.length > 0) {
          const topicsData = topics;
          const formatedTopicsData = topicsData?.map((topic: any) => ({
            ...topic,
            value: topic.topicName,
            label: topic.topicName,
          }));
          let allSubTopics: any[] = [];
          const uniqueSubTopics = new Set(); // To track unique subTopicNames

          // Iterate over each topic
          topicsData?.forEach((topic: any) => {
            // Iterate over each subtopic of the current topic
            topic?.subTopics?.forEach((subTopic: any) => {
              // Check if subTopicName exists and is unique
              if (
                subTopic.subTopicName &&
                !uniqueSubTopics.has(subTopic.subTopicName)
              ) {
                uniqueSubTopics.add(subTopic.subTopicName); // Add to set if unique
                allSubTopics.push({
                  ...subTopic,
                  value: subTopic._id,
                  label: subTopic.subTopicName,
                });
              }
            });
          });

          setTopicsData(formatedTopicsData);
          setAllSubTopicsData(allSubTopics);
        }

        if (boards?.length > 0) {
          const boardsData = boards?.map((item: any) => ({
            value: item?.board,
            label: item?.board,
          }));
          setBoardData(boardsData);
        }

        if (subjects?.length > 0) {
          const subjectData = subjects?.map((subject: any) => ({
            value: subject?.name,
            label: subject?.name,
          }));
          setSubjectData(subjectData);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleIsPublish = async (id: string, isFreeForMarketing = false) => {
    try {
      // setLoading(true);
      const res = await apiRequest(
        "Updateguidepublish",
        { isFreeForMarketing },
        [id]
      );

      if (res?.data?.success) {
        message.success(res?.data?.message);
        getTopicalGuidesData();
        // setLoading(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleEdit = (data: any) => {
    resetForm();
    const { expandData, id, thumbnailImageUrl, pdfUrl } = data;
    const thb = generateAntdImageObjectFromUrl(thumbnailImageUrl);
    const pdfFormated = generateAntdPdfObjectFromUrl(pdfUrl);
    setValues({
      ...data,
      description: expandData,
      topicalGuideId: id,
      thumbnailImage: [thb],
      pdf: [pdfFormated],
    });
    setIsEditMode(true);
    setShowModel(true);
  };
  const handleViewRedirect = (data: any) => {
    const { pdfUrl } = data;
    if (!pdfUrl) {
      message.error("No file uploaded");
    } else if (pdfUrl) {
      window.open(pdfUrl);
    }
  };
  const Columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnailImageUrl",
      key: "thumbnailImageUrl",
      render: (thumbnailImageUrl: string) => (
        <Image width={50} height={50} src={thumbnailImageUrl} />
      ),
    },
    ...columnsWithoutRender,
    {
      title: "is Free For Marketing",
      dataIndex: "isFreeForMarketing",
      key: "isFreeForMarketing",
      render: (data: any) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.isFreeForMarketing}
            onChange={() => handleIsPublish(data?.id, true)}
          />
        </div>
      ),
    },
    {
      title: "Publish Status",
      dataIndex: "isPublished",
      key: "isPublished",
      render: (data: any) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.isPublished}
            onChange={() => handleIsPublish(data?.id, false)}
          />
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: TopicalGuide) => (
        <div className="flex  gap-2">
          <div onClick={() => handleViewRedirect(record)}>
            <EyeIcon size="w-6 h-6" />
          </div>
          <div onClick={() => handleEdit(record)}>
            <EditIcon size="w-6 h-6" />
          </div>
        </div>
      ),
    },
  ];
  const formatedTopicalGuideData = topicalGuideData?.map((item: any, i) => {
    return {
      key: i + 1,
      ...item,
      expandData: item?.description,
      description:
        item?.description?.length > 10
          ? `${item?.description.slice(0, 10)}...`
          : item?.description,

      id: item?._id,
      isPublished: {
        isPublished: item?.isPublished,
        id: item?._id,
      },
      isFreeForMarketing: {
        isFreeForMarketing: item?.isFreeForMarketing,
        id: item?._id,
      },
    };
  });
  return (
    <>
      <div className="container-fluid">
        {/* {<Loader loading={loading} />} */}
        <div className="mb-4 flex justify-end">
          <CustomButton
            onClick={() => {
              formik.resetForm();
              setShowModel(true);
            }}
            disabled={loading}
            text="Add New Topical Guide"
            icon={<PlusOutlined />}
          />
        </div>
        <GlobalTable
          data={formatedTopicalGuideData}
          columns={Columns}
          tableName="Topical Guides"
          loading={loading}
          updatedTableData={loading}
        />
        {ShowModel && (
          <AddAndUpdateModal
            formik={formik}
            modules={modules}
            institutesData={institutesData}
            isEditMode={isEditMode}
            loading={loading}
            ShowModel={ShowModel}
            boardsData={boardsData}
            allSubTopicsData={allSubTopicsData}
            subjectData={subjectData}
            topicsData={topicsData}
            setIsEditMode={setIsEditMode}
            setShowModel={setShowModel}
            disciplines={disciplines}
          />
        )}
      </div>
    </>
  );
};

export default TopicalGuides;
