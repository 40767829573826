// @ts-ignore\
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
export const ViewModal = ({
  data,
  open,
  SetViewDoubt,
  SetDoubtDisplay,
  SetQuestion,
  TakeDoubt,
}) => {
  return (
    <div
      id="default-modal"
      aria-hidden="true"
      className={`${
        open ? "block" : "hidden"
      }overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full mt-8`}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full flex justify-center items-center mx-auto my-4">
        <div className="relative bg-white rounded-lg shadow-lg w-full flex flex-col p-2">
          <div className="flex items-center justify-between p-4 pb-2 md:p-5 border-b rounded-t dark:border-gray-50">
            <h3 className="text-xl font-semibold text-gray-900">
              Doubt Details
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal"
              onClick={() => {
                SetViewDoubt(false);
                SetDoubtDisplay({});
                SetQuestion();
              }}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="flex flex-col gap-2 p-2 line-clamp-3">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className="font-semibold"></span>{" "}
              {data.Tags &&
                data?.Tags?.map((tag) => (
                  <span className="bg-primary px-2 py-1 font-bold rounded mr-2 text-sm text-white border border-rose-500">
                    {tag?.name}
                  </span>
                ))}
            </p>
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-800 flex items-center">
              <span
                className="font-semibold"
                dangerouslySetInnerHTML={{ __html: data?.QuestionText }}
              />
            </div>
            {data?.QuestionImage && (
              <img
                src={data?.QuestionImage}
                alt="Question Image"
                className="w-full h-auto"
              />
            )}
            <div className="flex flex-col gap-3 justify-between">
              {data?.Options?.map((option) => (
                <>
                  <div
                    className={`text-base leading-relaxed text-gray-500 dark:text-gray-400 flex flex-start
                    gap-2 ${
                      option.IsCorrect
                        ? "bg-green-500 text-white"
                        : "bg-white border border-gray-200"
                    } rounded`}
                  >
                    <div
                      className={`font-semibold p-2 rounded-l bg-gray-200 text-black flex items-center justify-center`}
                      style={{ width: "40px" }}
                      id="option-letter
                      "
                    >
                      {option?.OptionLetter}
                    </div>{" "}
                    <div
                      className={`${
                        option.IsCorrect
                          ? "text-white"
                          : "text-gray-500"
                      }`}
                      dangerouslySetInnerHTML={{ __html: option?.OptionText }}
                    />
                  </div>
                  {option?.OptionImage && (
                    <img
                      src={option?.OptionImage}
                      alt="Option Image"
                      className="w-full h-auto"
                    />
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="flex justify-between items-center gap-2 w-full">
              <ButtonOutlined              
                handleClick={() => {
                  SetViewDoubt(false);
                  SetDoubtDisplay({});
                  SetQuestion();
                }}
              >
                Back
              </ButtonOutlined>
              <ButtonFill
                handleClick={() => {
                  TakeDoubt();
                }}
              >
                Solve Doubt
              </ButtonFill>
          </div>
        </div>
      </div>
    </div>
  );
};
