import axios from "axios";
import { backendUrl } from "constants/config";
import { ApiInterface } from "interfaces/global-interfaces";

export const PromoCodeAPIs: ApiInterface[] = [
  {
    url: "/api/v2/promo-codes/medschool/add",
    method: "POST",
    name: "addPromoCode",
  },
  {
    url: "/api/v2/promo-codes/medschool/get-all",
    method: "GET",
    name: "getAllPromoCodes",
  },
  {
    url: "/api/v2/promo-codes/medschool/get",
    method: "GET",
    name: "getPromoCodeById",
  },
  {
    url: "/api/v2/promo-codes/medschool/update",
    method: "PUT",
    name: "updatePromoCode",
  },
  {
    url: "/api/v2/promo-codes/medschool/delete",
    method: "DELETE",
    name: "deletePromoCode",
  },
  {
    url: "/api/v2/promo-codes/medschool/update-status",
    method: "PUT",
    name: "updateActiveStatus",
  },
];

export function PromoCodeAPI(
  name: string,
  params: string[] = [""],
  data: any = null
) {
  const api = PromoCodeAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
