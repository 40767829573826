import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { notification } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { DeckAPI } from "../../../(apis)/(shared-apis)/academics/decks-api";
import { ButtonFill, ButtonOutlined } from "../../../shared-components/ui/CustomButtons";
import { DeckInterface } from "./interface";
import PublishedTable from "./components/published-table";
import UnpublishedTable from "./components/unpublished-table";
import SAQsTable from "./components/saqs-table";
import BulkAddModal from "./components/bulk-add-questions-modal";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "shared-components/ui/CustomModal";
import BulkAddSAQsModal from "./components/bulk-add-saqs";
import SAQsPublishedTable from "./components/saqs-published-table";
export default function ManageDecks() {
  const { deckName } = useParams();
  const [questions, setQuestions] = useState([]);
  const [deckInformation, setDeckInformation] = useState<DeckInterface>({});
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    type: "",
  });
  const [originalQuestions, setOriginalQuestions] = useState([]);
  const [unpublishedQuestions, setUnpublishedQuestions] = useState([]);
  const [SAQs, setSAQs] = useState([]);
  const [unpublishedSAQs, setUnpublishedSAQs] = useState([]);

  const navigate = useNavigate();

  const [notFoundIds, setNotFoundIds] = useState([]);
  const [ShowModel, setShowModel] = useState(false);
  const [ShowBulkAddSAQsModel, setShowBulkAddSAQsModel] = useState(false);
  const [pageView, setPageView] = useState("questions");
  const getQuestions = async () => {
    const Response = await DeckAPI("getDeckQuestions", deckName);
    if (Response.data.success) {
      setDeckInformation(Response.data.deck);
      setOriginalQuestions(Response.data.questions);
      setQuestions(Response.data.questions);
      setUnpublishedQuestions(Response.data.unpublishedQuestions);
      setSAQs(Response.data.SAQs);
      setUnpublishedSAQs(Response.data.unpublishedSAQs);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const [disabled, setDisabled] = useState(false);

  const UpdateOrderOfIds = async () => {
    setDisabled(true);
    const orderOfIds = questions.map((question) => {
      return question._id;
    });
    try {
      const response = await DeckAPI("updateOrder", "", {
        payload: orderOfIds,
        id: deckInformation._id,
      });
      if (response.data.success) {
        toast.success("Order of questions updated successfully");
        getQuestions();
      } else {
        toast.error("Error while updating order of questions");
      }
    } catch (error) {
      toast.error("Error while updating order of questions");
    }
    setDisabled(false);
  };

  const handleUnpublishAllQuestions = async () => {
    setDisabled(true);
    const questionIds = questions.map((question) => {
      return question._id;
    });
    try {
      const response = await DeckAPI("handleBulkPublishQuestions", "", {
        questionIds: questionIds,
        deckId: deckInformation._id,
        type: "unpublish",
      });
      if (response.data.success) {
        toast.success("Question Unpublished Successfully");
        getQuestions();
      } else {
        toast.error("Error while unpublishing");
      }
    } catch (error) {
      toast.error("Error while unpublishing");
    }
    setConfirmationModal({
      isOpen: false,
      type: "",
    });
    setDisabled(false);
  };

  const handlePublishAllQuestions = async () => {
    setDisabled(true);
    const questionIds = unpublishedQuestions.map((question) => {
      return question._id;
    });
    try {
      const response = await DeckAPI("handleBulkPublishQuestions", "", {
        questionIds: questionIds,
        deckId: deckInformation._id,
        type: "publish",
      });
      if (response.data.success) {
        toast.success("Question published Successfully");
        getQuestions();
      } else {
        toast.error("Error while publishing");
      }
    } catch (error) {
      toast.error("Error while publishing");
    }
    setConfirmationModal({
      isOpen: false,
      type: "",
    });
    setDisabled(false);
  };

  const clearUnpublishedQuestions = async () => {
    setDisabled(true);

    try {
      const response = await DeckAPI("clearUnpublishedQuestions", "", {
        deckId: deckInformation._id,
      });
      if (response.data.success) {
        toast.success("All Unpublished Questions Removed Successfully");
        getQuestions();
      } else {

        const message = response?.data?.message || "Error while unpublishing";
        toast.error(message);
      }
    } catch (error) {

      toast.error("Error while unpublishing");
    }
    setConfirmationModal({
      isOpen: false,
      type: "",
    });

    setDisabled(false);
  };

  const [sections, setSections] = useState("MCQs");

  return (
    <div className="w-[95%] mx-auto h-full">
      <Toaster />
      <div className="mx-auto p-12 pt-2 pb-2">
        {notFoundIds && notFoundIds?.length > 0 && (
          <div className=" my-4">
            Invalid Question Ids:
            <p className="text-red-600 font-semibold">
              {" "}
              {notFoundIds?.map((id) => {
                return id + " ,";
              })}
            </p>
          </div>
        )}

        <div className="flex flex-row flex-wrap items-center justify-between">
          <div className="flex flex-row flex-wrap items-center gap-4">
            <img
              className="w-16 h-16 bg-white rounded-full border border-gray-200 object-contain"
              src={deckInformation.deckLogo}
              alt="logo"
            />
            <h1 className="text-3xl text-gray-700 font-bold">
              {deckInformation.deckName}
            </h1>
          </div>

          <div className="flex flex-row flex-wrap items-center gap-2">
            {sections === "MCQs" ? (
              <ButtonFill
                handleClick={() => {
                  setSections("MCQs");
                  setPageView("questions");
                }}
              >
                MCQs
              </ButtonFill>
            ) : (
              <ButtonOutlined
                handleClick={() => {
                  setSections("MCQs");
                  setPageView("questions");
                }}
              >
                MCQs
              </ButtonOutlined>
            )}
            {sections === "SAQs" ? (
              <ButtonFill
                handleClick={() => {
                  setSections("SAQs");
                  setPageView("SAQs");
                }}
              >
                SAQs
              </ButtonFill>
            ) : (
              <ButtonOutlined
                handleClick={() => {
                  setSections("SAQs");
                  setPageView("SAQs");
                }}
              >
                SAQs
              </ButtonOutlined>
            )}
          </div>
        </div>



          <div className="flex flex-row flex-wrap items-center gap-2">
            {sections === "MCQs" && (
              <>
                <ButtonFill
                  handleClick={() => {
                    UpdateOrderOfIds();
                  }}
                  disabled={
                    JSON.stringify(originalQuestions) ===
                    JSON.stringify(questions) || disabled
                  }
                >
                  Update Deck
                </ButtonFill>
                <ButtonFill
                  handleClick={() => {
                    navigate(`/addquestion`, { state: { deckName: deckName } });
                  }}
                >
                  Add Questions
                </ButtonFill>
                <ButtonFill
                  handleClick={() => {
                    if (pageView === "questions") {
                      setPageView("unpublishedQuestions");
                    } else {
                      setPageView("questions");
                    }
                  }}
                >
                  {pageView === "questions"
                    ? "View UnPublished Questions"
                    : "View Published Questions"}
                </ButtonFill>
                <ButtonFill
                  handleClick={() => {
                    ShowModel ? setShowModel(false) : setShowModel(true);
                  }}
                >
                  Bulk Add MCQs
                </ButtonFill>
                <ButtonFill
                  handleClick={() => {
                    setConfirmationModal({
                      isOpen: true,
                      type: "Unpublish All Questions",
                    });
                  }}
                >
                  Unpublish All Questions
                </ButtonFill>
                <ButtonFill
                  handleClick={() => {
                    setConfirmationModal({
                      isOpen: true,
                      type: "Publish All Questions",
                    });
                  }}
                >
                  Publish All Questions
                </ButtonFill>
                <ButtonFill
                  disabled={unpublishedQuestions?.length <= 0}
                  handleClick={() => {
                    setConfirmationModal({
                      isOpen: true,
                      type: "Remove All Unpublished Questions",
                    });
                  }}
                >
                  Remove All Unpublished Questions
                </ButtonFill>
              </>
            )}

            {sections === "SAQs" && (
              <>
                <ButtonFill
                  handleClick={() => {
                    setShowBulkAddSAQsModel(true);
                  }}
                >
                  Bulk Add SAQs
                </ButtonFill>
                {SAQs.length > 0 && (
                  <ButtonFill
                    handleClick={() => {
                      setPageView("SAQs");
                    }}
                  >
                    Show SAQs
                  </ButtonFill>
                )}
                {SAQs.length > 0 && (
                  <ButtonFill
                    handleClick={() => {
                      setPageView("unpublishedSAQs");
                    }}
                  >
                    Show UnPublished SAQs
                  </ButtonFill>
                )}
              </>
            )}

          </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-12">
        <div className="bg-stone-100 p-4 rounded-lg shadow-md flex flex-col items-center justify-center">
          <h1 className="text-lg font-semibold text-gray-800">
            Total Questions
          </h1>
          <p className="text-4xl font-semibold text-gray-700">
            {questions.length}
          </p>
        </div>
        <div className="bg-stone-100 p-4 rounded-lg shadow-md flex flex-col items-center justify-center">
          <h1 className="text-lg font-semibold text-gray-800">
            Unpublished Questions
          </h1>
          <p className="text-4xl font-semibold text-gray-700">
            {unpublishedQuestions.length}
          </p>
        </div>
        <div className="bg-stone-100 p-4 rounded-lg shadow-md flex flex-col items-center justify-center">
          <h1 className="text-lg font-semibold text-gray-800">SAQs</h1>
          <p className="text-4xl font-semibold text-gray-700">{SAQs.length}</p>
        </div>
        <div className="bg-stone-100 p-4 rounded-lg shadow-md flex flex-col items-center justify-center">
          <h1 className="text-lg font-semibold text-gray-800">Unpublished SAQs</h1>
          <p className="text-4xl font-semibold text-gray-700">{unpublishedSAQs.length}</p>
        </div>
      </div>

      {sections === "MCQs" && pageView === "questions"  && (
        <PublishedTable
          questions={questions}
          setQuestions={setQuestions}
          deckInformation={deckInformation}
          getQuestions={getQuestions}
          deckName={deckName}
          />
        )}
        {sections === "MCQs" && pageView === "unpublishedQuestions" && (
          <UnpublishedTable
          unpublishedQuestions={unpublishedQuestions}
          setUnpublishedQuestions={setUnpublishedQuestions}
          deckInformation={deckInformation}
            getQuestions={getQuestions}
          />
        )}
        {sections === "SAQs" && pageView === "SAQs" && (
          // <SAQsTable SAQs={SAQs} getQuestions={getQuestions} deckId={deckInformation._id} />
          <SAQsPublishedTable
            questions={SAQs}
            setQuestions={setSAQs}
            deckInformation={deckInformation}
            getQuestions={getQuestions}
            deckName={deckName}
            pageView={pageView}
          />
        )}
        {sections === "SAQs" && pageView === "unpublishedSAQs" && (
          <SAQsPublishedTable
            questions={unpublishedSAQs}
            setQuestions={setUnpublishedSAQs}
            deckInformation={deckInformation}
            getQuestions={getQuestions}
            deckName={deckName}
            pageView={pageView}
          />
        )}
      <BulkAddModal
        ShowModel={ShowModel}
        setShowModel={setShowModel}
        deckId={deckInformation._id}
        getQuestions={getQuestions}
        SetNotFoundIds={setNotFoundIds}
      />
      <BulkAddSAQsModal
        ShowModel={ShowBulkAddSAQsModel}
        setShowModel={setShowBulkAddSAQsModel}
        deckId={deckInformation._id}
        getQuestions={getQuestions}
        SetNotFoundIds={setNotFoundIds}
      />

      <ConfirmationModal
        active={confirmationModal?.isOpen}
        message={`Are you sure you want to ${confirmationModal?.type}?`}
        onConfirm={() => {
          confirmationModal?.type === "Remove All Unpublished Questions"
            ? clearUnpublishedQuestions()
            : confirmationModal?.type === "Publish All Questions"
              ? handlePublishAllQuestions()
              : handleUnpublishAllQuestions();
        }}
        onCancel={() => {
          setConfirmationModal({
            isOpen: false,
            type: "",
          });
        }}
        isIcon={false}
      />
    </div>
  );
}
