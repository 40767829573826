import { ApiInterface } from "(apis)/api-interface/types";
import axios from "axios";
import { backendUrl } from "constants/config";

const expertAPIName = "api/expert-solutions";
export const expertApis: ApiInterface[] = [
  {
    url: `/${expertAPIName}/GetExpertDoubt`,
    method: "POST",
    name: "fetchDoubts",
    // contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/GetOnlySolvedExpertDoubt`,
    method: "POST",
    name: "GetOnlySolvedExpertDoubt",
    // contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/get-doubt-image`,
    method: "POST",
    name: "GetImage",
  },
  {
    url: `/${expertAPIName}/TakeDoubt`,
    method: "POST",
    name: "TakeDoubt",
  },
  {
    url: `/${expertAPIName}/GetSubjectiveExpertDoubts`,
    method: "POST",
    name: "GetSubjectiveExpertDoubts",
  },
  {
    url: `/${expertAPIName}/GetQuestion`,
    method: "GET",
    name: "GetQuestion",
  },
  {
    url: `/${expertAPIName}/ExpertLogs`,
    method: "POST",
    name: "ExpertLogs",
  },
  {
    url: `/${expertAPIName}/ExpertStatistics`,
    method: "POST",
    name: "ExpertStatistics",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/GetExpertsPendingDoubts`,
    method: "POST",
    name: "GetExpertsPendingDoubts",
    // contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/UnTakeDoubt`,
    method: "POST",
    name: "UnTakeDoubt",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/GetExpertSolvedDoubts`,
    method: "POST",
    name: "GetExpertSolvedDoubts",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/getexpertemails`,
    method: "GET",
    name: "getexpertemails",
    // contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/getsubjectstats`,
    method: "POST",
    name: "getsubjectstats",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/GetExpertSolvedDoubtsFirst`,
    method: "POST",
    name: "GetExpertSolvedDoubtsFirst",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/redodoubt`,
    method: "POST",
    name: "redodoubt",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/AddORUpdateFeedback`,
    method: "PUT",
    name: "AddORUpdateFeedback",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/GetSubjectiveDoubt/:doubtId`,
    method: "GET",
    name: "GetSubjectiveDoubt",
    // contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/get-user-doubt`,
    method: "GET",
    name: "get-user-doubt",
  },
  {
    url: `/${expertAPIName}/uploadSubjective`,
    method: "POST",
    name: "uploadSubjective",
    contentType: "multipart/form-data",
  },
  {
    url: `/${expertAPIName}/upload`,
    method: "POST",
    name: "uploadExpertVideo",
    contentType: "multipart/form-data",
  },
  {
    url: `/${expertAPIName}/deleteDoubt`,
    method: "DELETE",
    name: "deleteDoubt",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/deleteSubjectiveDoubt`,
    method: "DELETE",
    name: "DeleteSubjectiveDoubt",
    contentType: "application/json",
  },
  {
    url: `/${expertAPIName}/GetMySubjectiveDoubts`,
    method: "POST",
    name: "GetMySubjectiveDoubts",
  },
  {
    url: `/${expertAPIName}/AllocateSubjectiveDoubtToUser`,
    method: "POST",
    name: "AllocateSubjectiveDoubtToUser",
  },
];


export function ExpertAPI(name: string, params: string = "", data: any = null) {
  const api = expertApis.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? `/${encodeURIComponent(params)}` : "");

  return axios({
    method: api.method,
    data,
    url
  });
}
