
import axios from "axios";
import { backendUrl } from "../../../constants/config";
import { ApiInterface } from "../../../interfaces/global-interfaces";

const APIs: ApiInterface[] = [
  {
    url: "/api/categories/get-categories",
    method: "GET",
    name: "getCategories"
  },
  {
    url: "/api/categories/update-category",
    method: "PATCH",
    name: "updateCategory"
  },
  {
    url: "/api/categories/update-category-publish",
    method: "PATCH",
    name: "updateCategoryPublish"
  },
  {
    url: "/api/categories/add-category",
    method: "POST",
    name: "addCategory"
  },
  {
    url: "/api/categories/get-populated-categories",
    method: "GET",
    name: "getCategoryDeckGroups"
  },
  {
    url: "/api/categories/upload-bluck-deck-group",
    method: "POST",
    name: "uploadBluckDeckGroupsInCategory"
  },
  {
    url: "/api/categories/update-category-order",
    method: "PATCH",
    name: "updateCategoryOrder"
  }
];


export function CategoryAPI(name: string, params: string = "", data: any = null) {
  const api = APIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? `/${encodeURIComponent(params)}` : "");

  return axios({
    method: api.method,
    data,
    url
  });
}

// import axios from "axios";
// import { backendUrl } from "../Constants/appConstants";

// const apiURL = `${backendUrl}/api/categories`;

// export async function addCategoryApi(data) {1
//   return axios.post(`${apiURL}/add-category`, data);
// }

// export async function getCategoriesApi() {
//   return axios.get(`${apiURL}/get-categories`);
// }

// export async function updateCategory(id) {
//   return axios.patch(`${apiURL}/update-category`, { _id: id });
// }

// export async function getCategoryDeckGroupsApi(categoryName) {
//   return axios.get(`${apiURL}/get-populated-categories/${categoryName}`);
// }

// export async function updateCategoryOrderApi(categoryName  , payload){
//   return axios.patch(`${apiURL}/update-category-order`, { categoryName , payload });
// }

