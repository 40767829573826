import React from "react";
import { useState, useEffect, useContext } from "react";

import { message, notification, Pagination, Select, Tooltip } from "antd";

// @ts-ignore TODO
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "state-management/user-context";
import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler";
import { ViewModal } from "shared-pages/doubtquestion/components/view-modal";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { ChevronLeft, ChevronRight, Delete } from "@material-ui/icons";
import { EditIcon, ManageIcon } from "shared-components/ui/Icons";
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api";
import toast, { Toaster } from "react-hot-toast";
import Loading from "shared-components/ui/Loading";
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";

export default function MySubjectiveDoubts() {
  const { subject } = useParams();
  const { user } = useContext(UserContext);
  const [Doubts, setDoubts] = useState<any[]>([]);
  const [ViewDoubt, SetViewDoubt] = useState<Boolean>(false);
  const [Question, SetQuestion] = useState<Object | null>();
  const [DoubtWithId, SetDoubtWithId] = useState<any>();
  const [NumberofSolved, SetNumberofSolved] = useState(0);
  const [NumberofPending, SetNumberOfPending] = useState(0);

  const [DoubtDisplay, SetDoubtDisplay] = useState({});

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalDocCount, setTotalDocCount] = useState<number>(0);
  const fetchDoubts = async (pageNumber = 0) => {
    try {
      setLoading(true);
      const { data } = await apiRequest("GetMySubjectiveDoubts", {
        // subject: subject,
        // username: user?.username,
        page: pageNumber,
      });
      setTotalDocCount(data?.totalDocumentCount);
      setDoubts(data?.filteredDoubts);
      SetNumberofSolved(data.SolvedDocumentCounts);
      SetNumberOfPending(data.PendingDocumentCounts);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.username) {
      fetchDoubts(page + 1);
    }
  }, [page, user.username]);

  const handleViewDoubt = (data) => {
    apiHandler({ name: "GetQuestion", query: { question: data?._id } }).then(
      (res) => {
        if (res.data?.Error) {
          notification.error({
            message: "Error",
            description: res.data?.Error,
          });
        } else {
          SetQuestion(res.data);
        }
      }
    );
    SetViewDoubt(true);
    SetDoubtDisplay(data);
    SetDoubtWithId(data._id);
  };
  const history = useNavigate();

  const TakeDoubt = async () => {
    history(`/expert-interface/subjective/${DoubtWithId?._id}`);
    fetchDoubts();
  };

  const DeleteDoubt = async (id: string) => {
    try {
      const res = await ExpertAPI("DeleteSubjectiveDoubt", id);
      console.log(res);
      if (res.data.success) {
        toast.success("Doubt deleted successfully");
        fetchDoubts();
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error.message);
    }
  };

  const [skipModal, setSkipModal] = useState(false);
  const [skipCount, setSkipCount] = useState<number>(0);

  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });

  return (
    <>
      <div className="w-full md:w-[90%] mx-auto pb-20 px-5">
        {loading && <Loading />}
        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">
            Subjective Doubts
          </h2>
          <div className="flex items-center flex-end gap-2">
            <ButtonFill
              handleClick={() => {
                setSkipModal(true);
              }}
            >
              Skip to Page
            </ButtonFill>
            <ButtonFill
              handleClick={() => {
                history(`/expert-dashboard/${subject}`);
              }}
            >
              Dashboard
            </ButtonFill>
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between gap-2 pb-1 mt-5">
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">
              Pending Questions
            </h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {totalDocCount}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">
              Solved Questions
            </h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {NumberofSolved}
            </h6>
          </div>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className=" bg-white shadow-md sm:rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        SR No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        QuestionID
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Question Text
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Tags
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doubts.map((doubt: any, index: number) => (
                      <tr className="border-b" key={doubt?._id}>
                        <td className="px-4 py-4">
                          {page === 0
                            ? index + 1
                            : page * rowsPerPage + index + 1}{" "}
                          {doubt?.feedback && (
                            <Tooltip
                              title={`Rating is ${doubt?.rating} - ${doubt?.feedback}-${doubt?.expertUsername}`}
                            >
                              <div className="bg-primary px-2 py-1 rounded border font-bold border-primary mr-2 text-sm text-white">
                                Revised
                              </div>
                            </Tooltip>
                          )}
                        </td>
                        <td className="px-4 py-4">{doubt?.QuestionID}</td>
                        <td className="px-4 py-4 text-left">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                doubt?.QuestionText?.length > 100
                                  ? doubt?.QuestionText?.slice(0, 100) + "..."
                                  : doubt?.QuestionText,
                            }}
                          />
                        </td>
                        <td className="px-4 py-4 flex flex-wrap gap-2">
                          {doubt?.Tags?.map((tag: any) => (
                            <div className="bg-primary px-2 py-1 rounded border font-bold border-primary mr-2 text-sm text-white">
                              {tag.name}
                            </div>
                          ))}
                        </td>
                        <td className="px-4 py-3 text-center">
                          <div className="flex items-center justify-center gap-6">
                            <ManageIcon
                              size="w-6 h-6 hover:cursor-pointer hover:text-primary"
                              onClick={() => {
                                handleViewDoubt(doubt);
                                SetDoubtWithId(doubt);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <div className="flex items-center justify-center mt-4 text-center w-full gap-2">
                    <button
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <ChevronLeft />
                    </button>
                    {page + 1} -{" "}
                    {Math.min(totalDocCount, (page + 1) * rowsPerPage)} of{" "}
                    {totalDocCount}
                    <button
                      onClick={() => {
                        if (page < totalDocCount / rowsPerPage - 1) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {skipModal && (
        <div
          id="skip-modal"
          aria-hidden="true"
          className={`${
            skipModal ? "block" : "hidden"
          }overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full mt-8`}
        >
          <div className="relative p-4 w-full max-w-5xl max-h-full flex justify-center items-center mx-auto my-8">
            <div className="relative bg-white rounded-lg shadow-lg flex flex-col p-4">
              <div className="flex items-center justify-between p-4 pb-2 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Skip Questions
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="skip-modal"
                  onClick={() => {
                    setSkipModal(false);
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <input
                type="number"
                value={skipCount}
                placeholder="Enter to any page"
                className="p-2 border border-gray-100 dark:border-gray-600 rounded-md mt-2"
                onChange={(e) => {
                  if (parseInt(e.target.value) > totalDocCount) {
                    message.error(
                      "Page number exceeds total number of questions"
                    );
                  } else {
                    setSkipCount(parseInt(e.target.value));
                  }
                }}
              />
              <div className="flex items-center rounded-b w-full justify-between">
                <ButtonOutlined
                  handleClick={() => {
                    setSkipModal(false);
                  }}
                >
                  Back
                </ButtonOutlined>
                <ButtonFill
                  handleClick={() => {
                    fetchDoubts(skipCount);
                    setSkipModal(false);
                    setPage(skipCount - 1);
                    // SkipToPage(parseInt(skipCount));
                    // setSkipCount(0);
                  }}
                >
                  Skip
                </ButtonFill>
              </div>
            </div>
          </div>
        </div>
      )}

      {ViewDoubt && (
        <>
          <ViewModal
            data={DoubtDisplay}
            open={ViewDoubt}
            SetViewDoubt={SetViewDoubt}
            SetDoubtDisplay={SetDoubtDisplay}
            SetQuestion={SetQuestion}
            TakeDoubt={TakeDoubt}
          />
        </>
      )}
    </>
  );
}
