import axios, { AxiosResponse } from "axios";
import { backendUrl, GetBackendUrlForCategory } from "../../constants/config";
import { APIs } from ".";

export function apiRequest<T = any>(
  name: string,
  data?: any,
  params?: string[]
): Promise<AxiosResponse<T>> {
  console.log(params);
  const api = APIs?.find((api) => api.name === name);

  if (!api) {
    throw new Error("API not found");
  }
  const headers = api?.contentType
    ? { "Content-Type": api?.contentType }
    : undefined;
  let url = backendUrl + api.url;
  if (params) {
    params.forEach((param:string, index) => {
      const regex = /\/:(\w+)/ 
      url = url.replace(regex, `/${param}`);
    });
  }
  console.log(url);
  return axios({
    method: api.method,
    url,
    withCredentials: true,
    data,
    headers,
  });
}

export const apiHandler = <
  T = any, // Default to any if no type is provided
  k extends {
    name: string;
    data?: any;
    params?: string[];
    query?: { [key: string]: string };
  } = any
>({
  data,
  name,
  params,
  query,
}: k): Promise<AxiosResponse<T>> => {
  const api = APIs?.find((api) => api.name === name);

  if (!api) {
    throw new Error("API not found");
  }
  const headers = api?.contentType
    ? { "Content-Type": api?.contentType }
    : undefined;
  let url = backendUrl + api.url;
 
  if (params) {
    //add /params[0]/params[1] to the url
    url = url + "/" + params.join("/");
    // params.forEach((param, index) => {
    //   const regex = /\/:(\w+)/; // Regex to match ":kumar" and capture "kumar"
    //   const match = url.match(regex);
    //   console.log(match);

    //   const matchValWithoutSlash = match[index]?.replace("/", "");
    //   url = url.replace(matchValWithoutSlash, param);
    // });
  }
  if (query) {
    let startQuery = "?";
    Object.keys(query).forEach((key, index) => {
      if (startQuery === "?") {
        startQuery = `${startQuery}${key}=${query[key]}`;
      } else {
        startQuery = `${startQuery}&${key}=${query[key]}`;
      }
    });
    url = url + startQuery;
  }
  console.log(url);
  return axios({
    method: api.method,
    url,
    withCredentials: true,
    data,
    headers,
  });
};


export function customAPIHandler(name: string, params: string = "", data: any = null, category: string = "PRE-MEDICAL") {

  const serverUrl = GetBackendUrlForCategory(category);
  if (!serverUrl) {
    throw new Error("Server URL not found");
  }
  
  const api = APIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = serverUrl + api.url + (params ? `/${encodeURIComponent(params)}` : "");
  return axios({
    method: api.method,
    data,
    url
  });
}