import { message } from "antd";
import React, { useEffect, useState } from "react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { Board, DataState, Institute, Subject, Topic } from "./types";

import { FormikProps } from "formik";
import VaultFieldsComponent from "./vault-field-component";
import Loader from "shared-components/Loader";
import { Type } from "typescript";
interface VaultCommonComponentProps {
  formik: FormikProps<any>;
  vaultType: string;
  setAllSubTopicsData: React.Dispatch<React.SetStateAction<any[]>>;

  setIsEditMode?: (isOpen: boolean) => void;
}
const VaultCommonComponent: React.FC<VaultCommonComponentProps> = ({
  formik,
  vaultType,
  setAllSubTopicsData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<DataState>({
    boardsData: [],
    allBoardsData: [],
    subjectData: [],
    allSubTopicsData: [],
    institutesData: [],
    allTopicsData: [],
    topicsData: [],
    modulesData: [],
    disciplinesData: [],
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getVaultModalDropDownsData");
      console.log("res", res);
      if (res?.data?.isSuccess) {
        const { topics, boards, subjects, institutes, modules, disciplines } =
          res?.data?.data;
        const newData: Partial<DataState> = {}; // Use Partial to allow missing properties

        if (institutes?.length > 0) {
          newData.institutesData = institutes?.map((item: Institute) => ({
            value: item.instituteName,
            label: item.instituteName,
          }));
        }

        if (topics?.length > 0) {
          const topicsData: Topic[] = topics;
          const allSubTopics = topicsData.flatMap(
            (topic: Topic) => topic.subTopics || []
          );
          newData.allSubTopicsData = allSubTopics.map((subTopic) => ({
            value: subTopic._id,
            label: subTopic.subTopicName,
          }));
          setAllSubTopicsData(newData.allSubTopicsData);
          newData.topicsData = topicsData;
          newData.allTopicsData = topicsData;
        }

        if (boards?.length > 0) {
          newData.boardsData = boards?.map((item: Board) => ({
            value: item.board,
            label: item.board,
          }));
          newData.allBoardsData = newData.boardsData;
        }
        if (subjects?.length > 0) {
          newData.subjectData = subjects?.map((subject: Subject) => ({
            value: subject.name,
            label: subject.name,
          }));
        }
        if (modules?.length > 0) {
          newData.modulesData = modules?.map((module: any) => ({
            value: module.moduleName,
            label: module.moduleName,
          }));
        }
        if (disciplines?.length > 0) {
          newData.disciplinesData = disciplines?.map((discipline: any) => ({
            value: discipline.disciplineName,
            label: discipline.disciplineName,
          }));
        }

        setData((prevData) => ({ ...prevData, ...newData } as DataState));
      }
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    boardsData,
    allBoardsData,
    subjectData,
    allSubTopicsData,
    institutesData,
    allTopicsData,
    topicsData,
    modulesData,
    disciplinesData,
  } = data;

  return (
    <>
      <div>
        <Loader loading={loading} />
        <VaultFieldsComponent
          vaultType={vaultType}
          formik={formik}
          institutesData={institutesData}
          subjectData={subjectData}
          topicsData={topicsData}
          boardsData={boardsData}
          modules={modulesData}
          disciplines={disciplinesData}
        />
      </div>
    </>
  );
};

export default VaultCommonComponent;
