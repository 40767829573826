import React, { useEffect, useState } from "react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import {
  ApiResponseTypeData,
  ExpertTableInterface,
} from "./types";
import DoubtsTable from "./doubts-table";
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api";
import toast from "react-hot-toast";
import { ViewDoubt } from "./view-doubt";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import { useNavigate } from "react-router-dom";

const UserExpertDoubts = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<ExpertTableInterface[]>([]);
  const [DoubtDisplay, SetDoubtDisplay] = useState(null);
  const [viewDoubt, setViewDoubt] = useState<boolean>(false);
  const FetchDoubts = async () => {
    setLoading(true);
    apiRequest<ApiResponseTypeData[]>("GetOnlySolvedExpertDoubt")
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchDoubts();
  }, []);

  const handleDoubtImage = (id: string): void => {
    setViewDoubt(true);
    SetDoubtDisplay(data.find((doubt) => doubt._id === id));
  };

  const deleteDoubt = (id: string): void => {
    ExpertAPI("deleteDoubt", id)
      .then((res: any) => {
        if (res?.data.success) {
          toast.success("Doubt deleted successfully");
          FetchDoubts();
        }
        else {
          toast.error("Failed to delete doubt");
        }
      }).catch((err) => {
        console.log(err);
        toast.error("Failed to delete doubt");
      });
  };

  const history = useNavigate();
  return (
    <div>
      <>
        <div className="w-full pb-20 px-5">
          <div className="flex items-center justify-between pb-1 mt-5">
            <h2 className="text-2xl font-bold text-gray-700">User Solved Doubts </h2>
            <ButtonFill
              handleClick={() => {
                history("/expert-dashboard")
              }}
            >
              My Doubts
            </ButtonFill>
          </div>
          <DoubtsTable data={data} loading={loading} setViewDoubt={handleDoubtImage} deleteDoubt={deleteDoubt} />
        </div>
        <ViewDoubt ShowModel={viewDoubt} SetShowModel={setViewDoubt} DoubtDisplay={DoubtDisplay} SetDoubtDisplay={SetDoubtDisplay} />
      </>
    </div>
  );
};

export default UserExpertDoubts;
