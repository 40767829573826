import React from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
export default function Boardbuttons({ boards, Theme, category, unit }) {
  const router = useNavigate();

  return (
    <div className="md:w-3/4 mx-auto mt-8 gap-4 md:gap-4 flex flex-row flex-wrap justify-center">
      {boards?.map((board, index) => (
        <div
          key={index}
          className={cn(
            "border-2 z-10 text-sm md:text-xl p-2 px-4 rounded-full md:min-w-[8rem] md:font-bold text-center transition cursor-pointer text-black",
            board?.toUpperCase().trim() === unit?.toUpperCase().trim()
              ? `text-white shadow-xl bg-[${Theme.ColorScheme}] border-white `
              : `shadow-xl text-gray-600 hover:bg-gray-100 hover:shadow-none hover:border-gray-200 transition duration-300 bg-white border-gray-200`
          )}
          style={ board?.toUpperCase().trim() === unit?.toUpperCase().trim() ? { backgroundColor: Theme.ColorScheme , borderColor: 'white' } : { backgroundColor: "white" , borderColor: 'white' }}
          onClick={() =>
            router(`/bundles/${board.toLowerCase()}`)
          }
        >
          {board}
        </div>
      ))}
    </div>
  );
}

