export const columnsWithoutRender = [
    {
        title: "Board",
        dataIndex: "board",
        key: "board",
      },
      {
        title: "Access",
        dataIndex: "access",
        key: "access",
      },
      {
        title: "Topic Name",
        dataIndex: "topicName",
        key: "topicName",
      },
      // {
      //   title: "Province",
      //   dataIndex: "province",
      //   key: "province",
      // },
      {
        title:process.env.REACT_APP_CATEGORY === 'MEDSCHOOL' ?"Discipline": "Subject",
        dataIndex: "subject",
        key: "subject",
      },
      // {
      //   title: "Description",
      //   dataIndex: "description",
      //   key: "description",
      // },
]