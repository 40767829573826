import { QuestionReportingAPI } from '../../../(apis)/(shared-apis)/academics/question-reporting';
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CustomBadges from '../../../shared-components/ui/CustomBadges';
import { ManageIcon } from '../../../shared-components/ui/Icons';

export default function Page() {
    const [reports, setReports] = useState<any> ([])
    const [filteredReports, setFilteredReports] = useState([])

    const { id } = useParams();
    const GetQuestionReport = async () => {
        const response = await QuestionReportingAPI("questionReports", [`${id}`])
        setReports(response.data)
        //sorting the reports by reportedAt in descending order
        response.data.reports.sort((a: any, b: any) => new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime())
        setFilteredReports(response.data.reports)
    }

    useEffect(() => {
        GetQuestionReport()
    }, []);


    return (
        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
            {reports && reports.reports && reports.reports.length === 0 ? (
                <div className="text-center text-2xl text-gray-500 mt-10">No Reports Found</div>
            ) : (
                <>
                    <Toaster />
                    <div className="flex justify-between items-center">
                        <h1 className="text-3xl text-gray-700 font-bold">Question Reporting</h1>
                        <div className="flex items-center justify-end gap-2">
                            {reports.reports && reports.reports.length > 0 &&
                            <>
                            <CustomBadges label={`${reports.reports.filter((report: any) => report.status === "PENDING").length} Pending`} type="error" showIcon={false} textSize="lg" />
                            <CustomBadges label={`${reports.reports.filter((report: any) => report.status === "RESOLVED").length} Resolved`} type="success" showIcon={false} textSize="lg" />
                            </>
                            }
                        </div>
                    </div>
                    <section className="my-5 antialiased">
                        <div className="overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-4 py-3 w-1/6">Date</th>
                                        <th scope="col" className="px-4 py-3 w-1/4">User Report</th>
                                        <th scope="col" className="px-4 py-3 w-1/4">Problems</th>
                                        <th scope="col" className="px-4 py-3 w-1/6">Status</th>
                                        <th scope="col" className="px-4 py-3 w-1/6">Users Info</th>
                                        <th scope="col" className="px-4 py-3 w-1/6">Navigate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredReports.map((report: any, index: number) => (
                                        <tr className="bg-white border-b hover:bg-gray-50" key={index}>
                                            <td className="px-4 py-3 whitespace-nowrap">
                                                {new Date(report.reportedAt).toLocaleDateString()}
                                            </td>
                                            <td className="px-4 py-3">
                                                <div className="line-clamp-2">{report.problemText}</div>
                                            </td>
                                            <td className="px-4 py-3">
                                                <div className='flex flex-wrap gap-1'>
                                                    {report.issues.map((problem: string, idx: number) => (
                                                        <CustomBadges key={idx} label={problem} type="error" showIcon={false} textSize="xs" />
                                                    ))}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3">
                                                <CustomBadges label={report.status} type={report.status === "PENDING" ? "error" : "success"} showIcon={false} textSize="xs" />
                                            </td>
                                            <td className="px-4 py-3 line-clamp-2">
                                                <div className='flex flex-col items-center gap-2'>
                                                    <div className='font-semibold w-full'>Reported By: <span className='text-gray-500 font-normal'>{report?.username}</span></div>
                                                    {report?.status === "RESOLVED" && <div className='font-semibold w-full'>Fixed By: <span className='text-gray-500 font-normal'>{report?.fixedby || "Before New System"}</span></div>}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3">
                                                <button
                                                    className="text-blue-500 hover:text-blue-700"
                                                    onClick={() => window.open(`/editquestion/${reports.questionId}/${report._id}`, "_blank")}
                                                >
                                                    <ManageIcon color="currentColor" size="w-6 h-6" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </>
            )}
        </div>
    )
}
