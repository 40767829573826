import React from 'react'
import { useParams } from 'react-router-dom';
import useGetData from 'shared-components/custom-hook/get-data';
import GlobalTable from 'shared-components/table/GlobalTable';
import { columns } from './columns';
import BackButton from 'shared-components/ui/back-button';

const ViewMarketingCampaignUsers = () => {
    const { marketingCampaignId } =useParams<{ marketingCampaignId: string }>();
    console.log("id", marketingCampaignId)
    const { data, loading, error } = useGetData<any>("getUsersDetailsByCampaignId",marketingCampaignId );
    console.log("data", data)

    const userDetails = data?.data?.userDetails
  return (
    <div>
        <div className='flex gap-8'>
            <BackButton/>
            <h1 className=' font-bold text-xl'> Users of <span className='text-primary'>{data?.data?.name}</span>  marketing campaign</h1>
        </div>
        <GlobalTable loading={loading} data={userDetails} columns={columns}/>
    </div>
  )
}

export default ViewMarketingCampaignUsers