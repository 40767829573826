import { useEffect, useState } from "react";
import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import toast from "react-hot-toast";
import Loader from "shared-components/Loader";
import { Switcher } from "shared-components/ui/CustomForm";

const ProgressRing = ({ progress, size = 120 }) => {
  const strokeWidth = 8;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="relative inline-flex items-center justify-center">
      <svg width={size} height={size} className="transform -rotate-90">
        <circle
          className="text-gray-200"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          className="text-green-600"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
      </svg>
      <span className="absolute text-xl font-bold text-gray-700">
        {Math.round(progress)}%
      </span>
    </div>
  );
};

const FilterButton = ({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-150 ${
      active
        ? "bg-blue-600 text-white"
        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
    }`}
  >
    {children}
  </button>
);

const MyTargetView = ({ supervisor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [contactedUserIds, setContactedUserIds] = useState([]);
  const [filters, setFilters] = useState({
    status: "active", // 'active', 'inactive', 'all'
    completion: "all", // 'completed', 'in-progress', 'all'
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (supervisor?._id) {
      fetchData();
    }
  }, [supervisor]);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getUserReferralsById", [
        supervisor?._id,
      ]);
      if (response) {
        const fetchedUser = response?.data?.data;
        const fetchedContactedUserIds = fetchedUser?.contactedUserIds;
        setContactedUserIds(fetchedContactedUserIds);
        setUser(fetchedUser);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleContactUser = async (userId, index) => {
    try {
      setLoader(true);
      const body = {
        myUserId: supervisor?.userId,
        contactedPersonUserId: userId,
      };

      if (!body?.contactedPersonUserId || !body?.myUserId) {
        return toast.error("Something went wrong");
      }

      const response = await CommissionAPI("addContactedUser", [], body);
      if (response?.data?.success) {
        toast.success("Marked successfully.");
        const updated = [...contactedUserIds, userId];
        setContactedUserIds(updated);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoader(false);
    }
  };

  const filterTargets = (targets) => {
    if (!targets) return [];

    return targets.filter((target) => {
      const currentDate = new Date();
      const startDate = new Date(target?.startDate);
      const endDate = new Date(target?.endDate);
      endDate.setHours(23, 59, 59, 999);
      const isActive = startDate <= currentDate && currentDate <= endDate && target.isActive;
      const isCompleted = target?.totalTargetDone >= target?.target;

      // Filter by status
      if (filters.status === "active" && !isActive) return false;
      if (filters.status === "inactive" && isActive) return false;

      // Filter by completion
      if (filters.completion === "completed" && !isCompleted) return false;
      if (filters.completion === "in-progress" && isCompleted) return false;

      return true;
    });
  };

  if (isLoading)
    return (
      <div className="mx-auto text-center text-gray-800 pb-5">Loading...</div>
    );
  if (!user)
    return (
      <div className="mx-auto text-center text-gray-800 pb-5">
        No data available
      </div>
    );

  const filteredTargets = filterTargets(user?.targets);

  return (
    <div className="p-4  mx-auto">
      <Loader loading={loader} />

      {/* Filters Section */}
      <div className="mb-6 space-y-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="space-y-2">
            <h3 className="text-sm font-medium text-gray-700">Status</h3>
            <div className="flex gap-2">
              <FilterButton
                active={filters.status === "all"}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, status: "all" }))
                }
              >
                All
              </FilterButton>
              <FilterButton
                active={filters.status === "active"}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, status: "active" }))
                }
              >
                Active
              </FilterButton>
              <FilterButton
                active={filters.status === "inactive"}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, status: "inactive" }))
                }
              >
                Inactive
              </FilterButton>
            </div>
          </div>

          <div className="space-y-2">
            <h3 className="text-sm font-medium text-gray-700">Completion</h3>
            <div className="flex gap-2">
              <FilterButton
                active={filters.completion === "all"}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, completion: "all" }))
                }
              >
                All
              </FilterButton>
              <FilterButton
                active={filters.completion === "completed"}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, completion: "completed" }))
                }
              >
                Completed
              </FilterButton>
              <FilterButton
                active={filters.completion === "in-progress"}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, completion: "in-progress" }))
                }
              >
                In Progress
              </FilterButton>
            </div>
          </div>
        </div>
      </div>

      {filteredTargets.length > 0 ? (
        filteredTargets.map((target) => {
          const startDate = new Date(target?.startDate);
          const endDate = new Date(target?.endDate);
          endDate.setHours(23, 59, 59, 999);
          const targetCompleted = target?.totalTargetDone;
          const isTargetCompleted = targetCompleted >= target?.target;
          const targetInProgress = isTargetCompleted
            ? 0
            : target?.target - targetCompleted;
          const currentDate = new Date();
          const isActive = startDate <= currentDate && currentDate <= endDate;
          const progressPercentage = (targetCompleted / target?.target) * 100;

          return (
            <div
              key={target._id}
              className="mb-8 bg-white rounded-lg shadow-lg overflow-hidden"
            >
              {/* Header Section */}
              <div className={`bg-gray-50 p-6`}>
                <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
                  <div className="flex-1">
                    <h2
                      className={`text-2xl font-bold ${
                        isActive ? "text-green-800" : "text-gray-800"
                      }`}
                    >
                      {startDate.toLocaleString("default", { month: "long" })}{" "}
                      {startDate.getFullYear()}
                      {endDate &&
                        ` - ${endDate.toLocaleString("default", {
                          month: "long",
                        })} ${endDate.getFullYear()}`}
                    </h2>
                    <div className="flex items-center space-x-2 mt-2">
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-semibold ${
                          target?.schemeType === "REFERRAL"
                            ? "bg-green-100 text-green-800"
                            : "bg-amber-100 text-amber-800"
                        }`}
                      >
                        {target?.schemeType}
                      </span>
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-semibold ${
                          isActive
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {isActive ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-8">
                    <ProgressRing
                      progress={
                        progressPercentage && progressPercentage > 100
                          ? 100
                          : progressPercentage
                      }
                    />
                    {/* <Switcher
                      isActive={isActive}
                      onChange={() => {}}
                      for={target._id}
                      togglevalue={isActive}
                      size="small"
                      disabled
                    /> */}
                  </div>
                </div>

                {/* Stats Grid */}
                <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
                    <p className="text-sm text-gray-500">Target</p>
                    <p className="text-2xl font-bold">{target?.target}</p>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
                    <p className="text-sm text-gray-500">Completed</p>
                    <p className="text-2xl font-bold text-green-600">
                      {targetCompleted}
                    </p>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
                    <p className="text-sm text-gray-500">Remaining</p>
                    <p className="text-2xl font-bold text-blue-600">
                      {targetInProgress}
                    </p>
                  </div>
                </div>
              </div>

              {/* Table Section */}
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-y border-gray-200">
                    <tr>
                      <th className="px-4 py-3">User Name</th>
                      <th className="px-4 py-3">User Email</th>
                      <th className="px-4 py-3">Phone Number</th>
                      <th className="px-4 py-3">Bundle Purchased</th>
                      {/* <th className="px-4 py-3">Bundle Status</th> */}
                      <th className="px-4 py-3">Date</th>
                      <th className="px-4 py-3">Contacted</th>
                    </tr>
                  </thead>
                  <tbody>
                    {target?.userIds?.map((userId, idx) => {
                      const isAlreadyContacted = contactedUserIds?.includes(
                        userId?._id
                      );
                      return (
                        <tr
                          key={userId?._id}
                          className="border-b hover:bg-gray-50"
                        >
                          <td className="px-4 py-3">{userId?.fullname}</td>
                          <td className="px-4 py-3">{userId?.username}</td>
                          <td className="px-4 py-3">{userId?.phonenumber}</td>
                          <td className="px-4 py-3">N/A</td>
                          {/* <td className="px-4 py-3">
                            <span className="px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">
                              Active
                            </span>
                          </td> */}
                          <td className="px-4 py-3">
                            {new Date(
                              userId?.accountcreateddate
                            ).toLocaleDateString("en-GB")}
                          </td>
                          <td className="px-4 py-3">
                            <button
                              className={`${
                                isAlreadyContacted
                                  ? "bg-green-100 text-green-800"
                                  : "bg-cyan-600 hover:bg-cyan-700 text-white"
                              } px-4 py-2 rounded-full text-sm font-medium transition-colors duration-150 ${
                                isAlreadyContacted ? "cursor-not-allowed" : ""
                              }`}
                              disabled={isAlreadyContacted || isLoading}
                              onClick={() =>
                                handleContactUser(userId?._id, idx)
                              }
                            >
                              {isAlreadyContacted
                                ? "Contacted"
                                : "Mark as Contacted"}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center py-12">
          <h2 className="text-xl text-gray-800 font-semibold">
            No targets found matching the selected filters
          </h2>
        </div>
      )}
    </div>
  );
};

export default MyTargetView;
// import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
// import { useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import Loader from "shared-components/Loader";
// import { Switcher } from "shared-components/ui/CustomForm";

// const MyTargetView = ({ supervisor }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [user, setUser] = useState(null);
//   const [contactedUserIds, setContactedUserIds] = useState([]);
//   const [filters, setFilters] = useState({
//     schemeType: "",
//     achieved: "",
//     month: "",
//   });

//   useEffect(() => {
//     if (supervisor?._id) {
//       fetchData();
//     }
//   }, [supervisor]);

//   async function fetchData() {
//     setIsLoading(true);

//     try {
//       const response = await CommissionAPI("getUserReferralsById", [
//         supervisor?._id,
//       ]);

//       if (response) {
//         const fetchedUser = response?.data?.data;
//         const fetchedContactedUserIds = fetchedUser?.contactedUserIds;
//         setContactedUserIds(fetchedContactedUserIds);
//         setUser(fetchedUser);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }
//   const [loader, setLoader] = useState(false);
//   const handleContactUser = async (userId, index) => {
//     try {
//       setLoader(true);
//       const body = {
//         myUserId: supervisor?.userId,
//         contactedPersonUserId: userId,
//       };

//       if (!body?.contactedPersonUserId || !body?.myUserId) {
//         return toast.error("Something went wrong");
//       }

//       const response = await CommissionAPI("addContactedUser", [], body);
//       // console.log("response: ", response);
//       if (response?.data?.success) {
//         toast.success("Marked successfully.");
//         const updated = [...contactedUserIds, userId];
//         setContactedUserIds(updated);
//       }
//     } catch (error) {
//       toast.error("Something went wrong");
//     } finally {
//       setLoader(false);
//     }
//   };

//   if (isLoading)
//     return (
//       <div className="mx-auto text-center text-gray-800  pb-5">Loading...</div>
//     );
//   if (!user)
//     return (
//       <div className="mx-auto text-center text-gray-800  pb-5">
//         No data available
//       </div>
//     );

//   return (
//     <div className="px-2">
//       <Loader loading={loader} />
//       {user?.targets && user?.targets?.length > 0 ? (
//         user?.targets?.map((target: any, index: number) => {
//           const startDate = new Date(target?.startDate);
//           const endDate = new Date(target?.endDate);
//           endDate.setHours(23, 59, 59, 999);
//           const targetCompleted = target?.totalTargetDone;
//           const isTargetCompleted = targetCompleted >= target?.target;
//           const targetInProgress = isTargetCompleted
//             ? 0
//             : target?.target - targetCompleted;
//           // Determine if the target is active
//           const currentDate = new Date();
//           const isActive = startDate <= currentDate && currentDate <= endDate;
//           // const isActive = target.isActive;

//           return (
//             <div key={target._id} className={`mb-5 `}>
//               <div className="flex items-center justify-between">
//                 <h2
//                   className={`text-xl space-x-1 font-semibold ${
//                     isActive ? "text-green-800" : "text-gray-800"
//                   } mb-2`}
//                 >
//                   {startDate.toLocaleString("default", { month: "long" })}{" "}
//                   {startDate.getFullYear()}
//                   {endDate
//                     ? ` - ${endDate.toLocaleString("default", {
//                         month: "long",
//                       })} ${endDate.getFullYear()}`
//                     : ""}
//                   <span className="text-sm  font-normal">
//                     {"   "}
//                     (Target Completed: {targetCompleted}/{target?.target} -{" "}
//                     {targetInProgress} targets remaining)
//                   </span>
//                   <span
//                     className={` text-base ml-2 font-semibold ${
//                       target?.schemeType === "REFERRAL"
//                         ? "text-green-700"
//                         : "text-amber-700"
//                     }`}
//                   >
//                     {target?.schemeType}
//                   </span>
//                 </h2>
//                 <Switcher
//                   isActive={isActive}
//                   onChange={() => {}}
//                   for={target._id}
//                   togglevalue={isActive}
//                   size="small"
//                   disabled
//                 />
//               </div>
//               <table className="w-full mb-20 text-sm text-left text-gray-500">
//                 <thead className="text-xs text-gray-700 uppercase bg-gray-50">
//                   <tr>
//                     <th className="border border-gray-300 px-4 py-2">
//                       User Name
//                     </th>
//                     <th className="border border-gray-300 px-4 py-2">
//                       User Email
//                     </th>
//                     <th className="border border-gray-300 px-4 py-2">
//                       Phone Number
//                     </th>
//                     <th className="border border-gray-300 px-4 py-2">
//                       Bundle Purchased
//                     </th>
//                     <th className="border border-gray-300 px-4 py-2">
//                       Bundle Status
//                     </th>
//                     {/* <th className="border border-gray-300 px-4 py-2">
//                       Paid Amount
//                     </th>
//                     <th className="border border-gray-300 px-4 py-2">
//                       Commission Earned
//                     </th> */}
//                     <th className="border border-gray-300 px-4 py-2">
//                       Contacted
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {target?.userIds?.map((userId, idx) => {
//                     const isAlreadyContacted = contactedUserIds?.includes(
//                       userId?._id
//                     );
//                     return (
//                       <tr key={userId?._id} className="text-center">
//                         <td className="border border-gray-300 px-4 py-2">
//                           {userId?.fullname}
//                         </td>
//                         <td className="border border-gray-300 px-4 py-2">
//                           {userId?.username}
//                         </td>
//                         <td className="border border-gray-300 px-4 py-2">
//                           {userId?.phonenumber}
//                         </td>
//                         <td className="border border-gray-300 px-4 py-2">
//                           N/A
//                         </td>{" "}
//                         {/* Assuming you replace this with actual data */}
//                         <td className="border border-gray-300 px-4 py-2">
//                           Active
//                         </td>{" "}
//                         {/* Assuming you replace this with actual status */}
//                         {/* <td className="border border-gray-300 px-4 py-2">
//                           {userId?.userPaidAmount || "0"}
//                         </td>
//                         <td className="border border-gray-300 px-4 py-2">
//                           {userId?.totalAmountEarned || "0"}
//                         </td> */}
//                         <td className="border border-gray-300 px-4 py-2">
//                           <button
//                             className={`${
//                               isAlreadyContacted
//                                 ? "bg-green-500 cursor-not-allowed"
//                                 : "bg-blue-500"
//                             } text-white px-2 py-1 text-sm rounded`}
//                             disabled={isAlreadyContacted || isLoading}
//                             onClick={() =>
//                               handleContactUser(userId?._id, index)
//                             }
//                           >
//                             {isAlreadyContacted
//                               ? "Contacted"
//                               : "Mark as Contacted"}
//                           </button>
//                         </td>
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </div>
//           );
//         })
//       ) : (
//         <>
//           <h2
//             className={`text-xl text-gray-800 font-semibold text-center pb-5`}
//           >
//             No Data Found!
//           </h2>
//         </>
//       )}
//     </div>
//   );
// };

// export default MyTargetView;
