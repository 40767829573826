import { apiRequest } from "(apis)/api-interface/api-handler";
import { message, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import useGetData from "shared-components/custom-hook/get-data";
import Loader from "shared-components/Loader";
import { EyeIcon } from "shared-components/ui/Icons";
import { UserContext } from "state-management/user-context";
import { Report } from "./types";
const statusOptions = [
  {
    value: "Solved",
    label: "Solved",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
];
// types.d.ts or a similar file
const BlogReportedIssues = () => {
  const { data, loading, fetchData, setData } =
    useGetData<Report[]>("getAllBlogIssues");
  console.log("data", data);
  const [selectedGeneralStatus, setSelectedGeneralStatus] = useState<
    string | null
  >(null);
  const [filteredReports, setFilteredReports] = useState<Report[]>(data);
  const { user } = useContext(UserContext);
  console.log("user", user);

  console.log("data", data);
  // Filter reports by selected type, or show all if no type is selected

  const handleGeneralStatusFilter = (status: string) => {
    setSelectedGeneralStatus(status);

    setFilteredReports(
      status === "all"
        ? data
        : data?.filter((report) => report.status === status)
    );
  };
  useEffect(() => {
    setFilteredReports(data);
  }, [data]);
  const handleChangeStatus = async (status: string, id: string) => {
    try {
      const res = await apiRequest(
        "UpdateBlogIssueStatus",
        { status, userEmail: user.username },
        [id]
      );
      if (res?.data?.isSuccess) {
        setSelectedGeneralStatus(null);
        message.success(res?.data?.message);
        const updatedReport = res?.data?.report;
        setData((prevReportsData) =>
          prevReportsData.map((report) =>
            report._id === id? updatedReport : report
          )
        );
      }
    } catch (error) {}
  };
  return (
    <div>
      <Loader loading={loading} />
      <div className="flex flex-col md:flex-row  justify-between mr-10 w-full mb-5">
        <h1  className="text--2xl font-bold">Blog Reported  Issues</h1>
        <div className="md:mr-10">
          <Select
            className="w-full md:w-32"
            options={[{ label: "All", value: "all" }, ...statusOptions]}
            placeholder="Filter Status"
            onChange={(val) => handleGeneralStatusFilter(val)}
            value={selectedGeneralStatus}
          />
        </div>
      </div>
      <div className=" bg-white shadow-md sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="w-full mb-20 text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-4">
                  SR No.
                </th>
                {/* <th scope="col" className="px-4 py-4">
                  Status
                </th> */}
                <th scope="col" className="px-4 py-4 text-center">
                  Description
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  View
                </th>
                <th scope="col" className="px-4 py-4 text-center">
                  Updated By
                </th>

                <th scope="col" className="px-4 py-3 text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredReports &&
                filteredReports?.map((issue: any, index: number) => (
                  <tr className="border-b" key={issue?._id}>
                    <td className="px-4 py-4">{index + 1}</td>

                    <td className="px-4 py-3 text-left">{issue?.description}</td>

                    <td className="px-4 py-3 text-center">
                      <a
                        href={`http://localhost:3000/post/${issue?.postId}`}
                        target="_blank"
                      >
                        <EyeIcon />
                      </a>
                    </td>
                    <td className="px-4 py-3 text-center">{issue?.user}</td>

                    <td className="px-4 py-3 text-center">
                      <Select
                        value={issue?.status}
                        // mode="tags"
                        onChange={(status) => {
                          handleChangeStatus(status, issue?._id);
                        }}
                        placeholder="Change Status "
                        options={statusOptions}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BlogReportedIssues;
