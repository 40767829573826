import React from "react";
import { useState, useEffect, useContext } from "react";

import { message, notification, Pagination, Select, Tooltip } from "antd";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";

// @ts-ignore TODO
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "state-management/user-context";
import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler";
import { ViewModal } from "shared-pages/doubtquestion/components/view-modal";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { ChevronLeft, ChevronRight, Delete } from "@material-ui/icons";
import { AddIcon, EditIcon, ManageIcon } from "shared-components/ui/Icons";
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api";
import toast, { Toaster } from "react-hot-toast";
import Loading from "shared-components/ui/Loading";
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";

export default function ExpertSubject() {
  const { subject } = useParams();
  const { user } = useContext(UserContext);
  const [Doubts, setDoubts] = useState<any[]>([]);
  const [ViewDoubt, SetViewDoubt] = useState<Boolean>(false);
  const [Question, SetQuestion] = useState<Object | null>();
  const [DoubtWithId, SetDoubtWithId] = useState<any>();
  const [NumberofSolved, SetNumberofSolved] = useState(0);
  const [NumberofPending, SetNumberOfPending] = useState(0);

  const [DoubtDisplay, SetDoubtDisplay] = useState({});

  const [loading, setLoading] = useState(true);
  const [isRevisedViewModal, setIsRevisedViewModal] = useState(false);
  const [isRevisedViewData, setIsRevisedViewData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalDocCount, setTotalDocCount] = useState<number>(0);
  const fetchDoubts = async (pageNumber = 0) => {
    try {
      setLoading(true);
      const { data } = await apiRequest("GetSubjectiveExpertDoubts", {
        subject: subject,
        username: user?.username,
        page: pageNumber,
      });
      setTotalDocCount(data?.totalDocumentCount);
      setDoubts(data?.filteredDoubts);
      SetNumberofSolved(data.SolvedDocumentCounts);
      SetNumberOfPending(data.PendingDocumentCounts);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.username) {
      fetchDoubts(page + 1);
    }
  }, [page, user.username]);

  const handleViewDoubt = (data) => {
    apiHandler({ name: "GetQuestion", query: { question: data?._id } }).then(
      (res) => {
        if (res.data?.Error) {
          notification.error({
            message: "Error",
            description: res.data?.Error,
          });
        } else {
          SetQuestion(res.data);
        }
      }
    );
    SetViewDoubt(true);
    SetDoubtDisplay(data);
    SetDoubtWithId(data._id);
  };
  const history = useNavigate();

  const TakeDoubt = async () => {
    history(`/expert-interface/subjective/${DoubtWithId?._id}`);
    fetchDoubts();
  };

  const DeleteDoubt = async (id: string) => {
    try {
      const res = await ExpertAPI("DeleteSubjectiveDoubt", id);
      console.log(res);
      if (res.data.success) {
        toast.success("Doubt deleted successfully");
        fetchDoubts();
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error.message);
    }
  };

  const [skipModal, setSkipModal] = useState(false);
  const [skipCount, setSkipCount] = useState<number>(0);

  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });

  const [users, setAllUsers] = useState([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  async function fetchAllUsers() {
    setIsFetchingUsers(true);

    try {
      const response: any = await BasicGetApi("getUserInfo", [], null, {
        isAdmin: true,
      });
      if (response) {
        console.log("Response: ", response);
        const apiData =
          response?.data?.data && response?.data?.data?.length > 0
            ? response?.data?.data
            : [];

        const permissionsSet = new Set([
          "Expert Access",
          "Admin Expert Access",
        ]);
        const filterExpertAccessUsers = apiData?.filter((user: any) => {
          return user?.admin?.pagepermissions?.some((permission: any) =>
            permissionsSet.has(permission)
          );
        });
        setAllUsers(filterExpertAccessUsers);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Failed to Fetch Users.";
      toast.error(message);
    } finally {
      setIsFetchingUsers(false);
    }
  }

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <>
      <div className="w-full md:w-[98%] mx-auto pb-20 px-5">
        {loading && <Loading />}
        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">
            Subjective Doubts
          </h2>
          <div className="flex items-center flex-end gap-2">
            <ButtonFill
              handleClick={() => {
                setSkipModal(true);
              }}
            >
              Skip to Page
            </ButtonFill>
            <ButtonFill
              handleClick={() => {
                history(`/expert-dashboard/${subject}`);
              }}
            >
              Dashboard
            </ButtonFill>
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between gap-2 pb-1 mt-5">
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">
              Pending Questions
            </h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {totalDocCount}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">
              Solved Questions
            </h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {NumberofSolved}
            </h6>
          </div>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className=" bg-white shadow-md sm:rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        SR No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        QuestionID
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Question Text
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Tags
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doubts.map((doubt: any, index: number) => (
                      <tr className="border-b" key={doubt?._id}>
                        <td className="px-4 py-4">
                          {page === 0
                            ? index + 1
                            : page * rowsPerPage + index + 1}{" "}
                          {doubt?.feedback && (
                            <Tooltip
                              title={`Click to view doubt`}
                            >
                              <div className="bg-primary px-2 py-1 rounded border font-bold border-primary mr-2 text-sm text-white" onClick={()=>{
                                setIsRevisedViewModal(true)
                                setIsRevisedViewData(doubt)
                              }}>
                                Revised
                              </div>
                            </Tooltip>
                          )}
                        </td>
                        <td className="px-4 py-4">{doubt?.QuestionID}</td>
                        <td className="px-4 py-4 text-left">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                doubt?.QuestionText?.length > 70
                                  ? doubt?.QuestionText?.slice(0, 70) + "..."
                                  : doubt?.QuestionText,
                            }}
                          />
                        </td>
                        <td className="px-4 py-4 flex flex-wrap gap-2">
                          {doubt?.Tags?.map((tag: any) => (
                            <div className="bg-primary px-2 py-1 rounded border font-bold border-primary mr-2 text-sm text-white">
                              {tag.name}
                            </div>
                          ))}
                        </td>
                        <td className="px-4 py-3 text-center">
                          <div className="flex items-center justify-center gap-4">
                            <ManageIcon
                              size="w-6 h-6 hover:cursor-pointer hover:text-primary"
                              onClick={() => {
                                handleViewDoubt(doubt);
                                SetDoubtWithId(doubt);
                              }}
                            />
                            <EditIcon
                              size="w-6 h-6 hover:cursor-pointer hover:text-primary"
                              onClick={() => {
                                window.open(
                                  `${
                                    process.env.REACT_APP_CATEGORY !==
                                    "MEDSCHOOL"
                                      ? "https://premed-admin.parho.io"
                                      : "https://medschool-admin.parho.io"
                                  }/editQuestion/${doubt.QuestionID}`,
                                  "_blank"
                                );
                              }}
                            />
                            <Delete
                              className="w-6 h-6 hover:cursor-pointer hover:text-primary"
                              onClick={() => {
                                DeleteDoubt(doubt?._id);
                              }}
                            />
                            <AddIcon
                              size="w-6 h-6 hover:cursor-pointer hover:text-primary"
                              onClick={() => {
                                setModal({
                                  isOpen: true,
                                  data: doubt,
                                });
                                // handleViewDoubt(doubt);
                                // SetDoubtWithId(doubt);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <div className="flex items-center justify-center mt-4 text-center w-full gap-2">
                    <button
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <ChevronLeft />
                    </button>
                    {page + 1} -{" "}
                    {Math.min(totalDocCount, (page + 1) * rowsPerPage)} of{" "}
                    {totalDocCount}
                    <button
                      onClick={() => {
                        if (page < totalDocCount / rowsPerPage - 1) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {skipModal && (
        <div
          id="skip-modal"
          aria-hidden="true"
          className={`${
            skipModal ? "block" : "hidden"
          }overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full mt-8`}
        >
          <div className="relative p-4 w-full max-w-5xl max-h-full flex justify-center items-center mx-auto my-8">
            <div className="relative bg-white rounded-lg shadow-lg flex flex-col p-4">
              <div className="flex items-center justify-between p-4 pb-2 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Skip Questions
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="skip-modal"
                  onClick={() => {
                    setSkipModal(false);
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <input
                type="number"
                value={skipCount}
                placeholder="Enter to any page"
                className="p-2 border border-gray-100 dark:border-gray-600 rounded-md mt-2"
                onChange={(e) => {
                  if (parseInt(e.target.value) > totalDocCount) {
                    message.error(
                      "Page number exceeds total number of questions"
                    );
                  } else {
                    setSkipCount(parseInt(e.target.value));
                  }
                }}
              />
              <div className="flex items-center rounded-b w-full justify-between">
                <ButtonOutlined
                  handleClick={() => {
                    setSkipModal(false);
                  }}
                >
                  Back
                </ButtonOutlined>
                <ButtonFill
                  handleClick={() => {
                    fetchDoubts(skipCount);
                    setSkipModal(false);
                    setPage(skipCount - 1);
                    // SkipToPage(parseInt(skipCount));
                    // setSkipCount(0);
                  }}
                >
                  Skip
                </ButtonFill>
              </div>
            </div>
          </div>
        </div>
      )}

      {ViewDoubt && (
        <>
          <ViewModal
            data={DoubtDisplay}
            open={ViewDoubt}
            SetViewDoubt={SetViewDoubt}
            SetDoubtDisplay={SetDoubtDisplay}
            SetQuestion={SetQuestion}
            TakeDoubt={TakeDoubt}
          />
        </>
      )}

      <AllocateDoubtModal
        modal={modal}
        allUsers={users}
        closeModal={() => {}}
        setModal={setModal}
      />
         <CustomAntdModal
        isOpenModal={isRevisedViewModal}
        setIsOpenModal={setIsRevisedViewModal}
        footer={null}
        isFooter={false}
      >
  <div className="w-full">
          <div className="flex flex-col gap-4">
            {/* Add feedback component */}
            {isRevisedViewData?.Logs?.length > 0 ? (
              <>
                <h2 className="text-2xl font-bold">Feedback</h2>
                <p className="text-gray-600 text-sm">
                  Here is the feedback for the selected doubt.
                </p>
             
                {/* <Divider>Feedbacks</Divider> */}
                {isRevisedViewData?.Logs?.map(
                  (doubt: any, index: number) =>{
                    return       <div key={index} className="flex flex-col gap-2 shadow-md rounded-md my-4 p-4">
                   <div>
                   Feedback {index+ 1} from     {doubt?.expertUsername} 
                   </div>
                    <hr />
                      <div> <strong>Rating :</strong> {doubt?.rating}  {doubt?.rating && 'out of 5'}</div>
                    
                    <div className="flex  flex-col">
                      <div className="">
                      <strong >
                        Feedback :
                      </strong>

                      </div>
                    <div className="flex-grow">{doubt?.feedbackText}</div>
                    </div>
                    
                  </div>
                  }
                )}
              </>
            ) : (
              <>
                <h2 className="text-2xl font-bold">Feedback Not Available</h2>
             
              </>
            )}
          </div>
        </div>

      </CustomAntdModal>
    </>
  );
}

const AllocateDoubtModal = ({ modal, closeModal, allUsers, setModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    doubtId: "",
    allocatedUserEmail: "",
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    const data = modal?.data;
    if (data) {
      setFormData({
        doubtId: data?._id,
        allocatedUserEmail: "",
      });
    }
  }, [modal?.data]);

  const resetForm = () => {
    setFormData(initialState);
    setModal({
      isOpen: false,
      data: null,
    });
  };

  const validateForm = (formData: any) => {
    const { allocatedUserEmail, doubtId } = formData;

    if (!allocatedUserEmail || allocatedUserEmail?.trim() === "") {
      toast.error("Please select an allocated user.");
      return false;
    }
    if (!doubtId || doubtId?.trim() === "") {
      toast.error("Please select a doubt.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!validateForm(formData)) {
      return;
    }

    try {
      setIsLoading(true);
      const body = formData;

      console.log("Body: ", body);

      // return
      const { data } = await apiRequest(
        "AllocateSubjectiveDoubtToUser",
        formData
      );
      console.log("data: ", data);
      if (data) {
        toast.success("Doubt Assigned successfully.");
      }
    } catch (error) {
      console.log("error: ", error);
      const message = error?.response?.data?.message || "Failed to Assign";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={` ${
        modal?.isOpen ? "" : "hidden"
      } fixed inset-0 z-50 flex items-center justify-center bg-gray-200/40 backdrop-blur-sm`}
    >
      <Toaster />
      <div className="bg-white rounded-lg p-8 w-full max-w-lg  h-fit overflow-y-scroll">
        <h2 className="text-xl font-bold mb-4">Assign Doubt</h2>
        <form onSubmit={handleSubmit}>
          {/* User Selection */}
          <div className="mb-2">
            <label className="block mb-1 font-semibold">
              Select User Email
            </label>

            <Select
              placeholder="Select user(s)"
              onChange={(value) =>
                setFormData({ ...formData, allocatedUserEmail: value })
              }
              className="w-full mb-4"
              value={formData.allocatedUserEmail}
            >
              {allUsers.map((user: any, index: number) => (
                <Select.Option key={index} value={user.username}>
                  {user.username}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="flex justify-center space-x-4">
            <ButtonOutlined
              disabled={isLoading}
              handleClick={() => resetForm()}
            >
              Cancel
            </ButtonOutlined>
            <ButtonFill type="Submit" disabled={isLoading} margin={false}>
              {isLoading ? "Loading..." : "Submit"}
            </ButtonFill>
          </div>
        </form>
      </div>

   
    </div>
  );
};
