import { ApiInterface } from "../api-interface/types";

export const marketingApis: ApiInterface[] = [

  {
    url: "/api/marketing/filter-all",
    method: "POST",
    name: "filterUserBYSelectedFields",
  },
  {
    url: "/api/marketing/get-skus",
    method: "GET",
    name: "getAllSKUs",
  },

  {
    url: "/api/marketing/getAllCampaign",
    method: "GET",
    name: "getAllMarketingCampaigns",
},
  {
    url: "/api/marketing/getCampaignById/:id",
    method: "GET",
    name: "getMarketingCampaignById",
},
{
    url: "/api/marketing/addCampaign",
    method: "POST",
    name: "addMarketingCampaign",
},
{
    url: "/api/marketing/updateCampaign/:id",
    method: "PUT",
    name: "updateMarketingCampaign",
},
{
    url: "/api/marketing/updateCampaignActive/:id",
    method: "PATCH",
    name: "updateMarketingCampaignActive",
},
{
    url: "/api/marketing/getUsersDetailsByCampaignId/:id",
    method: "GET",
    name: "getUsersDetailsByCampaignId",
},
];
