import { ApiInterface } from "interfaces/global-interfaces";

const endpoint = "api/upload-file";

export const medSchoolGeneralApis: ApiInterface[] = [
  {
    url: `/${endpoint}/get-suggested-resources`,
    method: "GET",
    name: "getSuggesdResources",
  },
 
  {
    url: `/${endpoint}/toggle-marked-suggest-resource/:id`,
    method: "POST",
    name: "toggleMarkedSuggestResource",
  },
 
];
