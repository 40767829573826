import PricingCard from "./pricing-card";

const PricingCards = ({ filteredPricings, getUniqueStyle, selectedToggle, category, unit , recommendedId="", isUpgrade=false}) => {
  console.log("filteredPricings", filteredPricings)
  return   <div className={`${isUpgrade ? "w-full" : "md:w-3/4"} mx-auto mt-8 gap-4 md:gap-4 flex flex-row flex-wrap justify-center`}>
  {filteredPricings?.map((card, index) => (
    <PricingCard
      key={card.id || index}
      card={card}
      recommendedPricingId={recommendedId}
      GetUniqueStyle={getUniqueStyle}
      selectedToggle={selectedToggle}
      category={category}
      unit={unit}
      isUpgrade={isUpgrade}
    />
  ))}
</div>
}

export default PricingCards;