import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { MAX_COMMISSION } from "../utils";

const PromoteAssociateToManagerModal = ({ modal, setModal }) => {
  const [associateEmail, setAssociateEmail] = useState("");
  const [directorEmail, setDirectorEmail] = useState("");
  const [couponCommissionPercentage, setCouponCommissionPercentage] =
    useState("");
  const [salesCommissionPercentage, setSalesCommissionPercentage] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Close the modal
  const closeModal = () => {
    setModal({ isOpen: false });
    setError("");
    setSuccessMessage("");
  };

  // const [allCommissionUsers, setAllCommissionUsers] = useState([]);
  // console.log("allCommissionUsers: ", allCommissionUsers);
  // const fetchAllCommissionUsers = async () => {
  //   try {
  //     const commissionUsersResponse = await CommissionAPI(
  //       "getAllCommissionUsers"
  //     );
  //     if (commissionUsersResponse?.status === 200) {
  //       const commissionUsersData = commissionUsersResponse?.data?.data || [];
  //       setAllCommissionUsers(commissionUsersData);
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error);
  //     const message =
  //       error?.response?.data?.message || "Failed to update status.";
  //     toast.error(message);
  //   }
  // };

  // useEffect(() => {
  //   fetchAllCommissionUsers();
  // }, []);

  const resetForm = () => {
    setAssociateEmail("");
    setDirectorEmail("");
    setCouponCommissionPercentage("");
    setSalesCommissionPercentage("");
    setLoading(false);
    setError("");
    setSuccessMessage("");
    setModal({ isOpen: false });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setSuccessMessage("");
    // Validation logic
    if (!associateEmail.trim() || !directorEmail.trim()) {
      toast.error("All fields are required!");
      return;
    }
    if (
      isNaN(Number(couponCommissionPercentage)) ||
      Number(couponCommissionPercentage) < 0 ||
      Number(couponCommissionPercentage) > MAX_COMMISSION
    ) {
      toast.error(
        `Coupon Commission must be a number between 0 and ${MAX_COMMISSION}!`
      );
      return;
    }

    if (
      isNaN(Number(salesCommissionPercentage)) ||
      Number(salesCommissionPercentage) < 0 ||
      Number(salesCommissionPercentage) > MAX_COMMISSION
    ) {
      toast.error(
        `Sales Commission must be a number between 1 and ${MAX_COMMISSION}!`
      );
      return;
    }
    setLoading(true);
    try {
      const data = {
        associateEmail: associateEmail,
        directorEmail: directorEmail,
        couponCommissionPercentage: couponCommissionPercentage,
        salesCommissionPercentage: salesCommissionPercentage,
      };
      const response = await CommissionAPI(
        "promoteAssociateToManager",
        [],
        data
      );

      if (response?.data?.success) {
        const message =
          response?.data?.message ||
          "Associate promoted to Manager successfully.";
        toast.success(message);
        resetForm();
      }
    } catch (error) {
      setError("An error occurred while promoting the associate");
      const message =
        error?.response?.data?.message ||
        "An error occurred while promoting the associate";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-600 bg-opacity-50 z-50 ${
        modal.isOpen ? "flex" : "hidden"
      } justify-center items-center`}
    >
      <div className="bg-white p-4 rounded-md shadow-lg w-[25rem] max-w-[95%]">
        <h2 className="text-xl font-semibold mb-4">
          Promote Associate to Manager
        </h2>
        {successMessage && (
          <div className="text-green-500 mb-4">{successMessage}</div>
        )}
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="associateEmail">
              Associate Email
            </label>
            <input
              type="email"
              id="associateEmail"
              value={associateEmail}
              onChange={(e) => setAssociateEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2" htmlFor="directorEmail">
              Director Email
            </label>
            <input
              type="email"
              id="directorEmail"
              value={directorEmail}
              onChange={(e) => setDirectorEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2" htmlFor="couponCommissionPercentage">
              Coupon Commission Percentage
            </label>
            <input
              type="number"
              id="couponCommissionPercentage"
              value={couponCommissionPercentage}
              onChange={(e) => setCouponCommissionPercentage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2" htmlFor="salesCommissionPercentage">
              Sales Commission Percentage
            </label>
            <input
              type="number"
              id="salesCommissionPercentage"
              value={salesCommissionPercentage}
              onChange={(e) => setSalesCommissionPercentage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div className="flex flex-col gap-4 md:flex-row  md:justify-between md:items-center">
            <ButtonOutlined handleClick={() => closeModal()}>
              Cancel
            </ButtonOutlined>
            <ButtonFill type="Submit" disabled={loading} margin={false}>
              {loading ? "Loading.." : "Promote to Manager"}
            </ButtonFill>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PromoteAssociateToManagerModal;
