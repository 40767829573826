import { apiRequest } from "(apis)/api-interface/api-handler";
import { useEffect, useState } from "react";
import { CAMainTable } from "./table";
import AddCAModal from "./add-modal";
import { message } from "antd";


const CAManager = () => {
  const [campus_associates, setcampus_associates] = useState([]);
  const [instituteData, setInstitutesData] = useState([]);
  const [originalcampus_associates, setoriginalcampus_associates] = useState([]);
  useEffect(() => {
    apiRequest("getALLCa")
      .then((response) => {
        setcampus_associates(response.data.data)
        setoriginalcampus_associates(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  const getInstituteData = async () => {
    try {
      const res = await apiRequest("getinstitutes");
      if (res?.data?.isSuccess) {
        const resData = res?.data?.institutes;
        if (resData?.length > 0) {
          const formatedData = resData.map((item) => {
            return {
              ...item,
           label:item.instituteName,
            value: item.instituteName,
            };
          });
          setInstitutesData(formatedData);
        } else {
          setInstitutesData([]);
          message.error("No data found");
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    getInstituteData();
  }, []);
  const [showModel, setShowModel] = useState(false);

  return (
    <>
      <div className="flex justify-center text-xl font-bold">
        <h2>Campus Associates</h2>
      </div>
      <div className="flex w-full justify-center">
        <CAMainTable campus_associates={campus_associates}   instituteData={instituteData} setcampus_associates={setcampus_associates} originalcampus_associates={originalcampus_associates} setoriginalcampus_associates={setoriginalcampus_associates} setShowModel={setShowModel} />
        <AddCAModal
          setcampus_associates={setcampus_associates}
          ShowModel={showModel}
          setShowModel={setShowModel}
          instituteData={instituteData}
        />

      </div>
    </>

  )
}

export default CAManager