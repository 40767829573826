import { ApiInterface } from "../api-interface/types";

const noteapis: ApiInterface[] = [
  {
    url: "/api/notes/get",
    method: "GET",
    name: "getnotes",
  },
  {
    url: "/api/notes/add",
    method: "POST",
    name: "addnote",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/notes/update/:noteId",
    method: "PUT",
    name: "updatenote",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/notes/updateIsPublish/:noteId",
    method: "PUT",
    name: "updatenotepublish",
  },
];
const cheatsheetapis: ApiInterface[] = [
  {
    url: "/api/cheatsheets/get",
    method: "GET",
    name: "getcheatsheets",
  },
  {
    url: "/api/cheatsheets/add",
    method: "POST",
    name: "addcheatsheet",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/cheatsheets/update/:CheatsheetId",
    method: "PUT",
    name: "updatecheatsheet",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/cheatsheets/updateIsPublish/:CheatsheetId",
    method: "PUT",
    name: "updatecheatsheetpublish",
  },
];
const mnemonicsapis: ApiInterface[] = [
  {
    url: "/api/mnemonics/get",
    method: "GET",
    name: "getmnemonics",
  },
  {
    url: "/api/mnemonics/add",
    method: "POST",
    name: "addmnemonic",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/mnemonics/update/:MnemonicsId",
    method: "PUT",
    name: "updatemnemonic",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/mnemonics/updateIsPublish/:MnemonicsId",
    method: "PUT",
    name: "updatemnemonicpublish",
  },
];

const shortlistingapis: ApiInterface[] = [
  {
    url: "/api/shortlisting/get",
    method: "GET",
    name: "getshortlistings",
  },
  {
    url: "/api/shortlisting/add",
    method: "POST",
    name: "addshortlisting",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/shortlisting/update/:shortlistingId",
    method: "PUT",
    name: "updateshortlisting",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/shortlisting/updateIsPublish/:shortlistingId",
    method: "PUT",
    name: "updateshortlistingpublish",
  },
];
const topicalguidesapis: ApiInterface[] = [
  {
    url: "/api/topicalguides/addtopical",
    method: "POST",
    name: "addquide",
  },
  {
    url: "/api/topicalguides/getall-for-admin",
    method: "GET",
    name: "getguides",
  },
  {
    url: "/api/topicalguides/get-all-guides-and-modal-dropdown-data",
    method: "GET",
    name: "getAllGuidesAndModalDropdownData",
  },
  {
    url: "/api/topicalguides/updatepublish/:id",
    method: "PATCH",
    name: "Updateguidepublish",
  },
  {
    url: "/api/topicalguides/updatetopicalguide/:id",
    method: "PATCH",
    name: "Updateguide",
  },
];
const snapcoursesapis: ApiInterface[] = [
  {
    url: "/api/snapcourses/addsnap",
    method: "POST",
    name: "postSnapCourse",
    contentType: "multipart/form-data",
  },
  {
    url: "/api/snapcourses/getall",
    method: "GET",
    name: "getAllSnaps",
  },
  {
    url: "/api/snapcourses/updatestatues/:id",
    method: "PUT",
    name: "updatePublished",
  },
  {
    url: "/api/snapcourses/updatesnapcourse/:id",
    method: "PUT",
    name: "updateSnapCourse",
    contentType: "multipart/form-data",
  },
];
const essentialstuffapis: ApiInterface[] = [
  {
    url: "/api/essential/add",
    method: "POST",
    name: "addessential",
  },
  {
    url: "/api/essential/getall-publish-unpublish",
    method: "GET",
    name: "getessentials",
  },
  {
    url: "/api/essential/getAllEssentailsAndModalDropDownData",
    method: "GET",
    name: "getAllEssentailsAndModalDropDownData",
  },
  {
    url: "/api/essential/updatepublish/:id",
    method: "PUT",
    name: "updateessentialpublish",
  },
  {
    url: "/api/essential/updateessential/:id",
    method: "PUT",
    name: "updateessential",
  },
];
const forall:ApiInterface[]=[
  {
    url: "/api/vault/getVaultModalDropDownsData",
    method: "GET",
    name: "getVaultModalDropDownsData",
  },
  {
    url: "/api/vault/get-reported-vault-issue",
    method: "GET",
    name: "getVaultIssue",
  },
  {
    url: "/api/vault/update-vault-issue-status/:id",
    method: "PUT",
    name: "UpdateVaultIssueStatus",
  },
]
export const vaultapis: ApiInterface[] = [
...forall,
  ...noteapis,
  ...cheatsheetapis,
  ...mnemonicsapis,
  ...shortlistingapis,
  ...topicalguidesapis,
  ...snapcoursesapis,
  ...essentialstuffapis,
];
