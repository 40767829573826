import { useEffect, useState } from "react";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../../shared-components/ui/CustomDropDown";
import { InfoIcon } from "../../../../shared-components/ui/Icons";

import { CommissionAPI } from "../../../../(apis)/(shared-apis)/latest-commission-api";

import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Input, Label } from "../../../../shared-components/ui/CustomForm";
import { CouponModalProps } from "../../interfaces/latest-commission-interface";
import { formatNumberWithCommas, renderChips } from "../../utils";
import ReimbursementTable from "shared-pages/latest-commission/components/reimbursement-table";
import { Image, Select, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CustomBadges from "shared-components/ui/CustomBadges";

const ViewCouponDetail = () => {
  const [selectStatus, setSelectStatus] = useState("Active");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [user, setUser] = useState(null);
  const [userCompensationDetail, setUserCompensationDetail] = useState(null);
  const [reimbursements, setReimbursements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const id = params?.id;
  const [displayScreen, setDisplayScreen] = useState<string>("Sales");
  const [couponCode, setCouponCode] = useState("");
  useEffect(() => {
    fetchData();
  }, [refreshData, id]);

  async function fetchData() {
    setIsLoading(true);

    try {
      const response = await CommissionAPI("getCouponById", [id]);

      if (response) {
        const fetchedUser = response?.data?.data?.user;
        const fetchedCompensation = response?.data?.data?.compensationUser;
        const fetchedReimbursements = response?.data?.data?.reimbursements;
        setReimbursements(fetchedReimbursements);
        setUser(fetchedUser);
        setUserCompensationDetail(fetchedCompensation);
        setCouponCode(fetchedCompensation?.code);
  
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  }

  const initialModalState: {
    isOpen: boolean;
    data: any;
  } = {
    isOpen: false,
    data: null,
  };

  const [couponDetailsModal, setCouponDetailsModal] = useState<{
    isOpen: boolean;
    data: any;
  }>(initialModalState);

  return (
    <>
      <Toaster />
      <CouponModal
        modal={couponDetailsModal}
        setModal={setCouponDetailsModal}
        initialModalState={initialModalState}
      />
      {/* =========================== */}
      {/* =========================== */}
      {/*  ReimbursementForm Modal*/}
      <ReimbursementForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user={user}
        code={couponCode}
        couponId={id}
        compensationDetails={userCompensationDetail}
      />
      {/* =========================== */}
      {/* =========================== */}
      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <div className="">
            <h2 className="text-xl font-bold text-gray-500 mb-5">Commission</h2>
            {user && (
              <>
                <h2 className="text-2xl font-bold text-gray-700 flex items-center space-x-3">
                  <span className="">{user?.name}</span>{" "}
                </h2>
                <div className="my-5">
                  <CustomBadges
                    label={`CODE : ${couponCode}`}
                    type="success"
                    showIcon={true}
                    textSize="lg"
                  />
                </div>
              </>
            )}
          </div>
          <ButtonOutlined
            handleClick={() => {
              setRefreshData((prev) => prev + 1);
            }}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                {/* <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setAllUsers={setAllUsers}
                  adminUsers={adminUsers}
                /> */}
                <div className="flex items-center p-4 space-x-3">
                  <CustomDropDown
                    label="View"
                    options={["Sales", "Reimbursement"]}
                    onSelect={(selectedStatus) =>
                      // handleSelectStatusChange(selectedStatus.toString())
                      setDisplayScreen(selectedStatus)
                    }
                    width="min-w-[12rem]"
                    value={displayScreen}
                  />
                </div>

                <div className="flex items-center gap-6 my-10 mx-10">
                  <div className="p-5 shadow-lg rounded-md text-center">
                    <h1 className="font-semibold">Total Amount Earned</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.totalAmountEarned?.toFixed(2)}
                    </p>
                  </div>
                  <div className="p-5 shadow-lg rounded-md text-center">
                    <h1 className="font-semibold">Pending Balance</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.pendingBalance?.toFixed(2)}
                    </p>
                  </div>
                  <div className="p-5 shadow-lg rounded-md text-center">
                    <h1 className="font-semibold">Paid Amount</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.paidAmount?.toFixed(2)}
                    </p>
                  </div>
                  <div
                    className=" shadow-lg rounded-md  text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium  text-sm p-5 h-full text-center me-2 mb-2 cursor-pointer"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <h1 className="font-semibold">Reimbursement</h1>
                  </div>
                </div>

                {displayScreen === "Sales" && (
                  <table className="w-full mb-20 text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-4">
                          Order Id
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          Purchased User Email
                        </th>
                        <th scope="col" className="px-4 py-4 text-center">
                          At Commission %
                        </th>
                        <th scope="col" className="px-4 py-4 text-center">
                          Total Amount Earned
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {user &&
                        user?.orders?.map((order) => {
                          return (
                            <tr className="border-b" key={order?._id}>
                              <td className="px-4 py-3">{order?._id}</td>
                              <td className="px-4 py-3 text-left">
                                {order?.purchasedUserEmail}
                              </td>

                              <td className="px-4 py-3 text-center">
                                {order?.myCommissionPercentage}%
                              </td>
                              <td className="px-4 py-3 text-center">
                                {order?.totalAmountEarned}
                              </td>

                              <td className="px-4 py-3 flex items-center justify-center">
                                {new Date(
                                  order?.createdAt
                                )?.toLocaleDateString()}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}

                {displayScreen === "Reimbursement" && (
                  <>
                    <ReimbursementTable
                      reimbursements={reimbursements}
                      isPromoCode={true}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

const ReimbursementForm = (props) => {
  const [paidAmount, setPaidAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [requestAmountType, setRequestAmountType] = useState(
    "Total Pending Balance"
  );
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allCommissionUsers, setAllCommissionUsers] = useState([]);

  const fetchAllCommissionUsers = async () => {
    setIsLoading(true);
    try {
      const commissionUsersResponse = await CommissionAPI(
        "getAllCommissionUsers"
      );
      // console.log("Response: ", commissionUsersResponse);
      if (commissionUsersResponse?.status === 200) {
        const commissionUsersData = commissionUsersResponse?.data?.data || [];
        setAllCommissionUsers(commissionUsersData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchAllCommissionUsers();
  }, []);

  const resetForm = () => {
    setPaidAmount(0);
    setPendingAmount(0);
    setRequestAmountType("Total Pending Balance");
    props.setIsModalOpen(false);
  };
  useEffect(() => {
    if (props?.compensationDetails) {
      const pendingAmount = props?.compensationDetails?.pendingBalance || 0;
      setPendingAmount(pendingAmount);
      setPaidAmount(pendingAmount);
    }
  }, [props?.compensationDetails]);

  const handleInputChange = (name, value) => {
    // if (value <= pendingAmount && value >= minimumAmountUserCanRequest) {
    setPaidAmount(value);
    // }
  };

  const closeModal = () => {
    resetForm();
  };

  // ========================
  // ===Screen Shot Logic ===
  // ========================

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [file, setFile] = useState(null);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChangeImage = async (info) => {
    if (info?.file?.status === "removed") {
      setFile("");
    } else {
      const base64: any = await getBase64(info?.file?.originFileObj);

      setFile(base64);
    }
  };

  const uploadSS = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload Screenshot</div>
    </div>
  );

  const submit = async (e) => {
    e.preventDefault();
    // console.log("Inside On submit: ", paidAmount);
    if (!paidAmount || paidAmount <= 0 || paidAmount > pendingAmount) {
      toast.error("Invalid Amount");
      return;
    }
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("username", props?.user?.name);
      formData.append("code", props?.code);
      formData.append("paidAmount", paidAmount?.toString());
      formData.append("description", description);
      formData.append("couponId", props?.couponId);

      if (file) {
        formData.append("screenShot", file);
      }

      // console.log("formData: ", formData);

      const response = await CommissionAPI(
        "createPromoCodeReimbursement",
        [],
        formData
      );

      // console.log("PromoCode Reimbursement Response: ", response);
      if (response?.data?.isSuccess) {
        toast.success("Submit Successfully.");
        resetForm();
      }
    } catch (error) {
      // console.error("Error PromoCode Reimbursement :", error);
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="deleteModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.isModalOpen ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm`}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        {/* Modal content */}
        <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
          <button
            type="button"
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
            data-modal-toggle="deleteModal"
            onClick={() => closeModal()}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <p className="mb-4 text-gray-500 text-xl ddark:text-gray-300">
            Reimbursement Form
          </p>

          <form
            onSubmit={(e) => submit(e)}
            className="text-left my-5 space-y-5"
          >
            <CustomDropDown
              label="Select Request Type"
              options={[`Total Pending Balance`, "Custom Amount"]}
              onSelect={(type) => {
                setRequestAmountType(type);
                if (type === "Total Pending Balance") {
                  setPaidAmount(pendingAmount);
                }
              }}
              width="w-full"
              value={requestAmountType}
              isError={false}
              errorMessage={""}
            />
            <div className="">
              <Label>Select Email</Label>
              <Select
                showSearch
                onChange={(selectedEmail) => {
                  setEmail(selectedEmail);
                }}
                // g
                size={"large"}
                disabled={isLoading}
                className="w-full"
                value={email}
              >
                {allCommissionUsers?.map((item) => (
                  <Select.Option key={item?._id} value={item?.email}>
                    {item?.email}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {requestAmountType === "Custom Amount" ? (
              <div className="space-y-2">
                <Label>Enter Amount</Label>
                <Input
                  type="number"
                  value={paidAmount}
                  name="paidAmount"
                  onChange={handleInputChange}
                  isError={false}
                  errorMessage={""}
                />
              </div>
            ) : null}

            <textarea
              placeholder="Description"
              className="w-full p-2 rounded border border-gray-300 mt-4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <div className="md:p-2 w-full">
              <Upload
                listType="picture-card"
                fileList={file ? [{ uid: "-1", url: file, name: "" }] : []}
                onPreview={handlePreview}
                onChange={handleChangeImage}
                accept="image/*"
                maxCount={1}
              >
                {file ? null : uploadSS}
              </Upload>
              {previewImage && (
                <Image
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    // afterClose: () => setPreviewImage(""),
                  }}
                  src={previewImage}
                  style={{ display: "none" }}
                />
              )}
            </div>

            <p className="mb-4 text-gray-700 text-center font-semibold text-lg ddark:text-gray-300">
              Amount : {formatNumberWithCommas(paidAmount)}
            </p>
            <div className="flex justify-center items-center space-x-4">
              <ButtonOutlined handleClick={() => closeModal()}>
                cancel
              </ButtonOutlined>

              <ButtonFill type="Submit" disabled={isLoading}>
                {isLoading ? "Loading.." : " Submit"}
              </ButtonFill>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const CouponModal = ({
  modal,
  setModal,
  initialModalState,
}: CouponModalProps) => {

  return (
    <>
      <div
        id="deleteModal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          modal.isOpen ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
              data-modal-toggle="deleteModal"
              onClick={() => setModal(initialModalState)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            {modal?.data ? (
              <>
                <div className="text-left">
                  <p className="mb-4 text-gray-500 ddark:text-gray-300">
                    Coupon Details:
                  </p>

                  <div className="flex  space-x-2">
                    <span className="font-semibold">Coupon Code:</span>
                    <span className="">{modal?.data?.code}</span>
                  </div>
                  <div className="flex  space-x-2">
                    <span className="font-semibold">Purchased User:</span>
                    <span className="">{modal?.data?.purchasedUserEmail}</span>
                  </div>
                  <div className="flex  space-x-2">
                    <span className="font-semibold">Coupon User :</span>
                    <span className="">{modal?.data?.couponUser?.email}</span>
                  </div>
                  <div className="flex space-x-2">
                    <span className="font-semibold">Coupon User Role:</span>
                    <span className="">
                      {renderChips(modal?.data?.couponUser?.role)}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <p className=" text-gray-700 ddark:text-gray-300">
                No Coupon Details Available
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewCouponDetail;
