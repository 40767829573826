import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
    topicName: Yup.string().required("Name is required"),
    videoLink: Yup.string().url("Invalid URL").nullable(),
    learningObjectives: Yup.mixed().required(
      "Learning Objectives are required"
    ),
    instructor: Yup.string().required("Instructor is required"),
    video: Yup.mixed()
      .nullable()
      .test("filevideo", function (value) {
        const { videoLink } = this.parent;
        const formatedValue = value?.[0];

        if (!formatedValue && !videoLink) {
          return this.createError({
            message: "Please upload a video file or provide a video link.",
          });
        }
        if (formatedValue && videoLink) {
          return this.createError({
            message: "Only one of video file or video link should be provided.",
          });
        }
        if (formatedValue) {
          const fileType = formatedValue && formatedValue?.type;
          if (!["video/mp4"].includes(fileType)) {
            return this.createError({
              message: "Please upload a Video file.",
            });
          }
          const fileSizeLimit = 100 * 1024 * 1024; // 100MB in bytes
          if (formatedValue && formatedValue.size > fileSizeLimit) {
            return this.createError({
              message:
                "Please upload a Video file less than or equal to 100mb.",
            });
          }
        }
        return true;
      }),
    thumbnailImage: Yup.mixed()
      .required("Thumbnail image is required")
      .test("is-valid-type", "Invalid image type", function (value) {
        if (!value) return true; // Skip validation if no value provided
        const newVal = value?.[0]?.type;
        return ["image/jpeg", "image/jpg", "image/png"].includes(
          newVal?.toLowerCase()
        );
      }),
      subject: Yup.string()
  .nullable()
  .test("subject", "Subject is required", function (value) {
    if (process.env.REACT_APP_CATEGORY !== "MEDSCHOOL") {
      return !!value; // Subject is required for other categories
    }
    return true; // Subject is optional for MEDSCHOOL
  }),
    board: Yup.string().required("Board is required"),
    access: Yup.string().required("Access is required"),
    universities: Yup.array()
      .of(Yup.string())
      .nullable()
      .test("universities", "Universities are required", function (value) {
        if (
       process.env.REACT_APP_CATEGORY=== "MEDSCHOOL"
        ) {
          return value?.length > 0;
        }
        return true;
      }),
    year: Yup.string()
      .nullable()
      .test("year", "Year is required", function (value) {
        if (
         
         process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
        ) {
          return !!value;
        }
        return true;
      }),
  });