import { apiRequest } from '(apis)/api-interface/api-handler';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import GlobalTable from 'shared-components/table/GlobalTable';
import { EyeIcon } from 'shared-components/ui/Icons';
import { Checkbox, message, Popconfirm } from 'antd';
import Loader from 'shared-components/Loader';
interface Resource {
  key: string;
  email: string;
  name: string;
  description: string;
  fileUrl: string;
  createdAt: string;
}
const SuggestResources = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const fetchData =async () =>{
      try {
        setLoading(true)
        const res = await apiRequest('getSuggesdResources')
        console.log("res of:", res)
        if(res?.data?.isSuccess){
          setData(res?.data?.resources)
        }
      } catch (error) {
        
      }finally{
        setLoading(false)
      }
    }
    useEffect(() => {
    fetchData();
    }, []);
    const handleToggleMarked = async (resource: any) => {
      console.log("data", data)
      try {
        setLoading(true)
        const res = await apiRequest('toggleMarkedSuggestResource', null, [resource?._id])
        console.log("res of toggle", res)
        if(res?.data?.isSuccess){
          setData((prevData) =>
            prevData.map((item) =>
              item._id === resource?._id ? { ...item, marked: !item.marked } : item
            )
          )
          message.success(res?.data?.message)
        }
      } catch (error) {
        
      }finally{
        setLoading(false)
      }
    }

    const columns: ColumnsType<Resource> = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: 'Marked',
        dataIndex: 'marked',
        key: 'select',
        render: (marked: boolean, data:any) => (
          <Popconfirm
            title={`Are you sure you want ${marked?"Uncheck":"Check"} to this resource?`}
            onConfirm={() =>
            handleToggleMarked(data)
            }
            okText="Yes"
            cancelText="No"
          >
            <Checkbox checked={marked} />
          </Popconfirm>
        ),
      },
      {
        title: "File",
        dataIndex: "fileUrl",
        key: "fileUrl",
        render: (fileUrl: string) => (
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          <EyeIcon/>
          </a>
        ),
      },
      {
        title: "Submitted",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => dayjs(createdAt).format("YYYY-MM-DD hh:mm a"),
      },
    ];
  return (
    <div>
      {
        // loading ? (
        //   <Loader loading={loading}/>
        // ):(

          <GlobalTable loading={loading} columns={columns} data={data} />
        // )
      }
    </div>
  )
}

export default SuggestResources