import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useGetData from "shared-components/custom-hook/get-data";
import Loader from "shared-components/Loader";
import GlobalTable from "shared-components/table/GlobalTable";
import CustomBadges from "shared-components/ui/CustomBadges";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { ManageIcon } from "shared-components/ui/Icons";

type Report = {
  questionId: string;
  pendingCount: number;
  rejectedCount: number;
  resolvedCount: number;
};

type ReportsResponse = {
  reports: Report[];
};

const columns = [
  {
    title: "Question ID",
    dataIndex: "questionId",
    key: "questionId",
  },
  {
    title: "Pending Count",
    dataIndex: "pendingCount",
    key: "pendingCount",
    render: (pendingCount) => {
      return <CustomBadges label={pendingCount} type="warning" />;
    },
  },
  {
    title: "Rejected Count",
    dataIndex: "rejectedCount",
    key: "rejectedCount",
    render: (rejectedCount) => {
      return <CustomBadges label={rejectedCount} type="error" />;
    },
  },
  {
    title: "Resolved Count",
    dataIndex: "resolvedCount",
    key: "resolvedCount",
    render: (resolvedCount) => {
      return <CustomBadges label={resolvedCount} type="success" />;
    },
  },
  {
    title: "Manage",
    dataIndex: "manage",
    key: "manage",
    render: (manage, record) => {
      return (
        <div
          className="flex gap-2"
          onClick={() => {
            return <Navigate to={`/questionDetails/${record.questionId}`} />;
          }}
        >
          <ManageIcon
            size="w-5 h-5"
            onClick={() => {
              return <Navigate to={`/questionDetails/${record.questionId}`} />;
            }}
          />
        </div>
      );
    },
  },
];

const AuditQuestionsReports = () => {
  const navigate = useNavigate();
  const { data, loading } = useGetData<ReportsResponse>(
    "getAuditQuestionsReport"
  );
  const reportsData = data?.reports || [];
  const [filteredData, setFilteredData] = useState(data?.reports);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isSelectedFilter, setIsSelectedFilter] = useState(false);

  const options = ["All", "Pending", "Resolved", "Rejected"];
  useEffect(() => {
    let updatedData = reportsData;

    if (selectedFilter === "Pending") {
      updatedData = reportsData.filter((report) => report.pendingCount > 0);
    } else if (selectedFilter === "Resolved") {
      updatedData = reportsData.filter((report) => report.resolvedCount > 0);
    } else if (selectedFilter === "Rejected") {
      updatedData = reportsData.filter((report) => report.rejectedCount > 0);
    }

    setFilteredData(updatedData);
    setIsSelectedFilter(!isSelectedFilter)
  }, [selectedFilter, reportsData,loading]);
console.log("filteredData", filteredData)
  return (
    <>
    <Loader loading={loading} />
      <div className="flex justify-end mr-10">
        <CustomDropDown
          label="Filter"
          options={options}
          onSelect={(option) => {
            setIsSelectedFilter(!isSelectedFilter)
            setSelectedFilter(option.toString());
          }}
          width="min-w-[16rem]"
          value={selectedFilter}
          // noLabel={noLabel}
        />
      </div>
      {/* {filteredData?.length > 0 && ( */}
        <GlobalTable
          loading={loading}
          data={filteredData}
          tableName="Audit Questions Reports"
          updatedTableData={isSelectedFilter}
          columns={columns.map((column) => {
            if (column.key === "manage") {
              return {
                ...column,
                render: (manage, record) => {
                  return (
                    <div
                      className="flex gap-2"
                      onClick={() =>
                        navigate(`/questionDetails/${record.questionId}`)
                      }
                    >
                      <ManageIcon
                        size="w-5 h-5"
                        onClick={() =>
                          navigate(`/questionDetails/${record.questionId}`)
                        }
                      />
                    </div>
                  );
                },
              };
            }
            return column; // Return unchanged columns
          })}
        />
      {/* // )} */}
    </>
  );
};

export default AuditQuestionsReports;
