import React, { useEffect, useState } from "react";
import { getOverviewStats, ROlES } from "shared-pages/latest-commission/utils";

const ViewStats = ({ user, pendingOrders }) => {
  const [stats, setStats] = useState<{
    mySales: number;
    myTodaySales: number;
    todaysAssociatesSales: number;
    thisMonthSales: number;
    lastMonthSales: number;
    totalAssociates: any;
    totalReferrals: any;
    totalAssociatesSales: number;
    myTotalReferrals: number;
  }>({
    mySales: 0,
    myTodaySales: 0,
    todaysAssociatesSales: 0,
    thisMonthSales: 0,
    lastMonthSales: 0,
    totalAssociates: 0,
    totalReferrals: 0,
    totalAssociatesSales: 0,
    myTotalReferrals: 0,
  });

  useEffect(() => {
    if (user) {
      const getState: {
        mySales: number;
        myTodaySales: number;
        todaysAssociatesSales: number;
        thisMonthSales: number;
        lastMonthSales: number;
        totalAssociates: any;
        totalReferrals: any;
        totalAssociatesSales: number;
        myTotalReferrals: number;
      } = getOverviewStats(user, user?.role);

      console.log("getState: ", getState);

      if (getState) {
        setStats(getState);
      }
    }
  }, [user]);

  const isAssociate = user?.role === ROlES?.ASSOCIATE;
  const isManager = user?.role === ROlES?.MANAGER;

  return (
    <div className="flex flex-wrap items-center justify-center py-10">
      {isAssociate && (
        <>
          <RenderCard
            item={{ label: "My Sales", value: stats?.mySales }}
            key={1}
          />
          <RenderCard
            item={{
              label: "Today's Sales",
              value: stats?.myTodaySales,
            }}
            key={2}
          />
          <RenderCard
            item={{
              label: "Current Month Sales",
              value: stats?.thisMonthSales,
            }}
            key={5}
          />

          <RenderCard
            item={{
              label: "Total Referrals",
              value:
                user?.role === ROlES.ASSOCIATE
                  ? stats?.myTotalReferrals
                  : stats?.totalReferrals,
            }}
            key={7}
          />
          <RenderCard
            item={{
              label: "My Pending Sales",
              value: pendingOrders?.length || 0,
            }}
            key={7}
          />
        </>
      )}
      {!isAssociate && (
        <>
          {" "}
          <RenderCard
            item={{ label: "Total Personal Sales", value: stats?.mySales }}
            key={1}
          />
          <RenderCard
            item={{
              label: "Today's Personal Sales",
              value: stats?.myTodaySales,
            }}
            key={2}
          />
          <RenderCard
            item={{
              label: "Today's Team Sales",
              value: stats?.todaysAssociatesSales,
            }}
            key={3}
          />
          <RenderCard
            item={{
              label: "Total Team Sales",
              value: stats?.totalAssociatesSales,
            }}
            key={4}
          />
          <RenderCard
            item={{
              label: "Current Month Sales",
              value: stats?.thisMonthSales,
            }}
            key={5}
          />
          <RenderCard
            item={{ label: "Team Members", value: stats?.totalAssociates }}
            key={6}
          />
          <RenderCard
            item={{ label: "Total Referrals", value: stats?.totalReferrals }}
            key={7}
          />
        </>
      )}
    </div>
  );
};

const RenderCard = ({
  item,
  key,
}: {
  item: { label: string; value: number };
  key: number;
}) => {
  return (
    <div
      className="w-full md:max-w-xs p-2 lg:w-1/3 md:w-1/2 cursor-pointer"
      key={key}
    >
      <div className="flex flex-col p-5 overflow-hidden bg-white hover:bg-gradient-to-br  rounded-xl shadow-lg duration-300 hover:shadow-2xl group transition-all ease-in-out">
        <h1 className="text-3xl sm:text-4xl xl:text-5xl font-bold text-gray-700  ">
          {item?.value}
        </h1>
        <div className="flex flex-row justify-between ">
          <p className="font-semibold text-lg text-gray-800">{item?.label}</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-pink-600 "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ViewStats;
