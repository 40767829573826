import React, { useState, useMemo, useEffect, useContext } from "react";
// @ts-ignore
import styles from "./RibbinStyles.module.css";
import toast from "react-hot-toast";
// import { useDispatch } from "react-redux";
// import { openCart } from "@/app/redux/slices/cartHandlerSlice";
// import CoursesSelection from "@/app/(components)/UIComponents/Pricing/course-selection";
import FeaturesList from "./pricing-features-list";
import { GetSKUInformation } from "./utils";
import { UserContext } from "state-management/user-context";
import { useNavigate } from "react-router-dom";
import Cart from "./cart";
import { message } from "antd";
const PricingCard = ({
  card,
  recommendedPricingId,
  GetUniqueStyle,
  selectedToggle,
  category,
  unit,
  isUpgrade = false,
}) => {
  const style = useMemo(() => GetUniqueStyle(), [GetUniqueStyle]);
  const sortedFeatures = useMemo(() => {
    return card?.tagDetails?.sort((a, b) => a?.position - b?.position) || [];
  }, [card?.tagDetails]);

  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [modalisOpen, setModalIsOpen] = useState(false);
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [amountOfCourses, setAmountOfCourses] = useState(0);
  const Information = useMemo(
    () => GetSKUInformation(card, selectedToggle),
    [card, selectedToggle]
  );
  const [selectedCourse, setSelectedCourse] = useState([]);

  const isRecommended = recommendedPricingId === card?._id;
  const discountPercentage = Information?.[1] * 100;
  const price = Information?.[2];
  const totalAmount = Information?.[0];
const currentCategory = process.env.REACT_APP_CATEGORY
  const AddToCart = (pricing) => {
   
    console.log("pricing", pricing)
    console.log("user", user)
    try {
      if (!user.isloggedin) {
        message.info("Please Login to Add to Cart");
        return;
      }

      const currentCart = JSON.parse(localStorage.getItem("premedcart")) || [];

      // const check = CheckIfBought(pricing);
      // if (check === "Already Subscribed") {
      //   toast.error("Already Subscribed");
      //   return;
      // }
     

      if (currentCart.some((item) => item.SKUName === pricing?.SKUName)) {
        message.info("Item Already Exists in Cart");
        return;
      }

      if (
        currentCart.some(
          (item) => item.category === category && item.unit === unit
        )
      ) {
        message.info("Item of Same Category Already Exists in Cart");
        return;
      }

      if (pricing?.amountOfCourses && pricing?.amountOfCourses !== 0) {
        if (selectedCourse.length < pricing?.amountOfCourses) {
          message.info(`Please Select ${pricing?.amountOfCourses} Courses`);
          setAmountOfCourses(pricing?.amountOfCourses);
          setModalIsOpen(true);
          return;
        }
      }

      //track via google analytics
      if (currentCategory === "PRE-MEDICAL") {
        // window.gtag("event", "add_to_cart", {
        //   items: [
        //     {
        //       item_id: pricing._id,
        //       item_name: pricing.SKUName,
        //       item_category: category,
        //       item_price: price,
        //     },
        //   ],
        // });
      }

      //recommended bundle discount is 25%
      const recommendedDiscount = 0.1;
      const upgradeDiscount = 0.1;

      const newCartItem = {
        ...pricing,
        Type:
          "NORMAL PURCHASE" +
          (isRecommended ? " (Recommended)" : "") +
          (isUpgrade ? " (Upgrade)" : ""),
        Category: category.toUpperCase(),
        Unit: unit.toUpperCase(),
        TotalAmount: Information[0],
        PromotionalDiscount: Information[1],
        PromotionalAmount: isRecommended
          ? Information[2] * (1 - recommendedDiscount)
          : isUpgrade
          ? Information[2] * (1 - upgradeDiscount)
          : Information[2],
        AdditionalDiscount: isRecommended
          ? recommendedDiscount
          : isUpgrade
          ? upgradeDiscount
          : 0,
        AdditionalDiscountAmount: isRecommended
          ? Information[2] * recommendedDiscount
          : isUpgrade
          ? Information[2] * upgradeDiscount
          : 0,
        DurationType: Information[3],
        Duration: Information[4],
        courseIds: selectedCourse,
        Package: selectedToggle?.toggleValue,
        PurchaseType:
          selectedCourse.length > 0 ? "BUNDLE-&-COURSES" : "BUNDLE ONLY",
        AdditionalTag: isRecommended
          ? "RECOMMENDED"
          : isUpgrade
          ? "UPGRADE"
          : "",
        DurationTag: Information[5] || "No Tag",
      };

      const newCart = [...currentCart, newCartItem];
      localStorage.setItem("premedcart", JSON.stringify(newCart));
   
      //IF IS RECOMMANDED THEN CHECKOUT TO CART
      if (isRecommended) {
        //track via google analytics, track that he is checking out, not the item
        if (currentCategory === "PRE-MEDICAL") {
          // window.gtag("event", "checkout", {
          //   event_category: "premed-checkout",
          //   event_label: "checkout",
          //   value: price,
          //   currency: "PKR",
          // });
        }

        navigate("/checkout/cart");
      } else if (isUpgrade) {
        //track via google analytics, track that he is checking out, not the item
        if (currentCategory === "PRE-MEDICAL") {
          // window.gtag("event", "checkout", {
          //   event_category: "premed-checkout",
          //   event_label: "checkout",
          //   value: price,
          //   currency: "PKR",
          // });
        }
        navigate("/checkout/cart");
      } else {
        // dispatch(openCart());
      }
     message.success("Item Added to Cart");

      setIsOpenCart(true)
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("An error occurred while adding the item to cart");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && currentCategory === "PRE-MEDICAL") {
      //Assuming `productId` and `productName` are available
      // window.gtag("event", "view_item", {
      //   items: [
      //     {
      //       item_id: card?._id, // Unique product ID
      //       item_name: card?.SKUName, // Product name
      //       item_category: "PreMed Bundle", // Product category
      //       item_price: card?.price, // Product price
      //     },
      //   ],
      // });
    }
  }, [card?._id, card?.SKUName]);

  return (
    <>
      <div
        className={`bg-shadowboxes rounded-3xl relative py-6 shadow-xl rounded-xl min-h-[36rem] md:px-8 px-4 overflow-hidden w-full md:w-[calc(33%_-_2rem)] ${
          isRecommended ? "border-4 border-[#FF4B81] scale-105 shadow-2xl" : ""
        }`}
      >
        {Information &&
          Array.isArray(Information) &&
          typeof Information[1] === "number" &&
          Information[1] > 0 && (
            
            <div className={`${styles.ribbon} ${styles.ribbonTopLeft}`}>
              <span style={{ background: style }}>
                {`${discountPercentage?.toFixed(0)}% OFF`}
              </span>
            </div>
          )}

        <div
          className="absolute top-0 left-1/2 right-1/2 -translate-x-1/2 w-40 text-[14px] font-bold text-white flex justify-center items-center px-2 rounded-b-[20px] py-2"
          style={{ background: isRecommended ? "#FF4B81" : style }}
        >
          {isRecommended ? "Recommended" : card?.Tag}
        </div>

        <div className="flex flex-col justify-center gap-y-2 md:gap-y-0 pt-8">
          <h1 className="text-[34px] sm:text-[36px] md:text-[28px] leading-none font-extrabold text-center text-gray-800">
            {card?.SKUName}
          </h1>
          <div className="text-center mt-2 md:text-[13px] text-gray-500 text-xs">
            {selectedToggle?.toggleValue}
          </div>

          <div className="flex gap-x-2 md:gap-x-2 mt-2 items-center justify-center">
            <h4 className="text-[#6E7191] text-xl md:text-xl font-semibold">
              {price === 0 ? "" : "PKR"}
            </h4>
            <h1 className="text-[32px] md:text-[32px] font-bold text-gray-800">
              {price === 0 ? "Free" : price.toFixed()}
            </h1>
            {Information &&
              Array.isArray(Information) &&
              typeof Information[1] === "number" &&
              Information[1] > 0 && (
                <sup className="text-md text-black/100 -ml-1 md:-ml-1 relative w-fit text-gray-500">
                  {totalAmount !== 0 && <>PKR {totalAmount}</>}
                  <span className="w-full h-px -rotate-[12deg] absolute top-1/2 bottom-1/2 -translate-y-1/2 left-0 bg-gray-500" />
                </sup>
              )}
          </div>

          {totalAmount > 0 && (
            <button
              className="text-white font-bold text-md px-6 w-fit mx-auto rounded mt-2 py-1 shadow-xl hover:shadow-none transition-all duration-300"
              style={{ background: style }}
              onClick={() => AddToCart(card)}
            >
              {isRecommended
                ? "GET UPTO ADDITIONAL 25% OFF"
                : isUpgrade
                ? "GET UPTO ADDITIONAL 40% OFF"
                : `${
                    discountPercentage > 0
                      ? `Avail ${discountPercentage?.toFixed(0)}% Discount`
                      : "Subscribe Now"
                  }`}
            </button>
          )}
        </div>

        <FeaturesList features={sortedFeatures} />

        {/* {CheckIfGroupBundlesAvailable(card, selectedToggle)} */}
      </div>

      {/* <CoursesSelection
        pricing={card}
        AddToCart={AddToCart}
        modalisOpen={modalisOpen}
        setModalIsOpen={setModalIsOpen}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        amountOfCourses={amountOfCourses}
      /> */}

      <Cart openCart={isOpenCart} setIsOpenCart={setIsOpenCart}/>
    </>
  );
};

export default PricingCard;
