export default function ImageUpload({ form, setForm }) {

    const UploadImage = () => {
        document.getElementById('QuestionImage').click();
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setForm({
                ...form,
                QuestionImage: reader.result
            })
        };
        reader.readAsDataURL(file);
    }
 
    return (
        <div className='flex flex-col flex-wrap align-middle gap-2 mt-4'>
            <div className='flex flex-col flex-wrap justify-between'>
                <h6 className='text-md font-bold text-rose-500 mt-4'>Upload Question Image</h6>
                <div className={`border border-gray-300 !border-dashed rounded-md p-4 flex justify-center items-center gap-2 bg-gray-50 rounded p-4 shadow-md ${form.QuestionImage ? 'bg-green-50 border border-green-500 w-fit gap-5 flex-row justify-around' : 'flex-col'}`}>
                    <div className="flex flex-col flex-wrap items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                        </svg>
                        <button className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500" onClick={UploadImage}>Upload Image</button>
                        <input type="file" className="hidden" accept="image/png, image/jpeg" onChange={handleImageChange} id="QuestionImage" name="QuestionImage" />
                        {form.QuestionImage && (
                            <button className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500" onClick={() => { setForm({ ...form, QuestionImage: '' }) }}>Remove Image</button>
                        )}
                    </div>
                    {form.QuestionImage && (
                        <div className='flex flex-row flex-wrap items-center gap-2'>
                            <img src={form.QuestionImage} className='w-40 h-40' alt="Question" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
