import React, { useEffect, useState, useMemo, useCallback } from "react";
import BoardButtons from "./boardbuttons";
import { CheckIfSKUToBeDisplayed } from "./Pricing/utils";
import { BACKGROUND_STYLES } from "./Pricing/utils";
import ToggleSection from "./Pricing/toggles";
import PricingCards from "./Pricing/pricing-cards-list";

export default function PricingView({
  boards,
  Toggles,
  Theme,
  category,
  unit,
  pricings,
}) {
  console.log("Toggles", Toggles);
  const [selectedToggle, setSelectedToggle] = useState(Toggles?.[0] || null);
  const [filteredPricings, setFilteredPricings] = useState(pricings || []);
  const [usedStyles, setUsedStyles] = useState(new Set());

  useEffect(() => {
    if (selectedToggle && pricings?.length > 0) {
      const filtered = CheckIfSKUToBeDisplayed(pricings, selectedToggle);
      setFilteredPricings(filtered);
    }
  }, [selectedToggle, pricings]);
  useEffect(() => {
    // if(!selectedToggle){
    setSelectedToggle(Toggles?.[0]);
    // }
  }, [Toggles]);

  const getUniqueStyle = useCallback(() => {
    if (unit === "azaadi bundle") {
      return "var(--Gradients-Linear-Pink, linear-gradient(90deg, #87E709 7.58%, #34B469 34.64%, #2D5302 81.9%))";
    }

    const availableStyles = BACKGROUND_STYLES.filter(
      (style) => !usedStyles.has(style)
    );
    if (availableStyles.length > 0) {
      const style = availableStyles[0];
      setUsedStyles((prev) => new Set(prev).add(style));
      return style;
    }
    return BACKGROUND_STYLES[0];
  }, [unit, usedStyles]);

  return (
    <>
      <BoardButtons
        boards={boards}
        Theme={Theme}
        category={category}
        unit={unit}
      />
      <ToggleSection
        Toggles={Toggles}
        selectedToggle={selectedToggle}
        setSelectedToggle={setSelectedToggle}
        Theme={Theme}
      />
      <PricingCards
        filteredPricings={filteredPricings}
        getUniqueStyle={getUniqueStyle}
        selectedToggle={selectedToggle}
        category={category}
        unit={unit}
      />
    </>
  );
}
