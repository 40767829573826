import React, { useEffect, useState } from "react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { diffLines, Change } from 'diff';

const AuditedQuestionHistoryView = () => {
  const { id } = useParams();
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await apiRequest("getAuditQuestionById", null, [id]);
      if (res?.data?.data?.history?.length > 0) {
        const parsedData = res?.data?.data?.history?.map((history) => ({
          ...history,
          newQuestion: JSON.parse(history?.newQuestion),
          oldQuestion: JSON.parse(history?.oldQuestion),
        }));
        setHistoryData(parsedData);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Audit Question History</h2>
      {historyData.length === 0 ? (
        <p>No history data available.</p>
      ) : (
        historyData.map((item, index) => (
          <div key={index} className="mb-8 bg-white rounded-lg shadow-md overflow-hidden">
            <div className="bg-gray-100 px-4 py-3 border-b flex justify-between items-center">
              <h3 className="text-lg font-semibold">Change {historyData.length - index}</h3>
              <div className="text-sm text-gray-600">
                <div>{dayjs(item.dateChanged).format("MMMM D, YYYY h:mm A")}</div>
                <div>Modified by: {item.modifiedBy}</div>
              </div>
            </div>
            <div className="p-4">
              <DiffView oldData={item.oldQuestion} newData={item.newQuestion} />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

interface DiffViewProps {
  oldData: any;
  newData: any;
}

const DiffView: React.FC<DiffViewProps> = ({ oldData, newData }) => {
  const renderDiff = (key: string, oldValue: any, newValue: any) => {
    // Exclude specific fields
    const excludedFields = ['_id', 'createdAt', 'updatedAt', '__v'];
    if (excludedFields.includes(key)) return null;

    if (typeof oldValue === 'object' && oldValue !== null && typeof newValue === 'object' && newValue !== null) {
      const changedKeys = Object.keys({ ...oldValue, ...newValue }).filter(
        subKey => JSON.stringify(oldValue?.[subKey]) !== JSON.stringify(newValue?.[subKey])
      );

      if (changedKeys.length === 0) return null;

      return (
        <div key={key} className="mb-4">
          <h4 className="font-semibold mb-2 bg-gray-100 p-2 rounded">{key.charAt(0).toUpperCase() + key.slice(1)}</h4>
          {changedKeys.map(subKey => 
            renderDiff(subKey, oldValue?.[subKey], newValue?.[subKey])
          )}
        </div>
      );
    }

    if (oldValue === newValue) return null;

    // Special handling for Options array to exclude _id
    if (key === 'Options') {
      oldValue = oldValue.map(({ _id, ...rest }) => rest);
      newValue = newValue.map(({ _id, ...rest }) => rest);
    }

    const oldString = oldValue !== undefined ? JSON.stringify(oldValue, null, 2) : '';
    const newString = newValue !== undefined ? JSON.stringify(newValue, null, 2) : '';

    const changes = diffLines(oldString, newString);

    return (
      <div key={key} className="mb-4">
        <h4 className="font-semibold mb-2">{key.charAt(0).toUpperCase() + key.slice(1)}</h4>
        <pre className="bg-gray-50 p-2 rounded text-sm">
          {changes.filter(change => change.added || change.removed).map((change: Change, index: number) => (
            <span
              key={index}
              className={`block ${
                change.added
                  ? 'bg-green-100 text-green-800'
                  : change.removed
                  ? 'bg-red-100 text-red-800'
                  : ''
              }`}
            >
              {change.value.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {change.added ? '+ ' : change.removed ? '- ' : '  '}
                  {line}
                  {lineIndex < change.value.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          ))}
        </pre>
      </div>
    );
  };

  return (
    <div>
      {Object.keys({ ...oldData, ...newData })
        .map(key => renderDiff(key, oldData?.[key], newData?.[key]))
        .filter(Boolean)}
    </div>
  );
};

export default AuditedQuestionHistoryView;
