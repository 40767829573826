import { useCallback, useEffect, useState } from "react";
import { Button, Input, Menu } from "antd";
import { backendUrl } from "../../constants/config";
import GlobalTable from "../../shared-components/table/GlobalTable";
import ViewScholarship from "./ViewScholarship";
interface DataType {
  _id: string;
  Fname: string;
  Lname: string;
  Email: string;
  PhoneNumber: string;
  Status: string;
}
const ScholarShip: React.FC = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [clickedInfoData, setClickedINfoData] = useState<{}>({});
  const [filteredData, setFilteredData] = useState<DataType[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backendUrl}/PendingApplication`);
      const jsonData = await response.json();
      setData(jsonData);
      setFilteredData(jsonData);
      setLoading(false);
    } catch (error) {
      console.error("An error occurred", error);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "Fname",
      key: "name",
      render: (text: string, record: DataType) => (
        <span>
          {record.Fname} {record.Lname}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "PhoneNumber",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "status",
    },
    {
      title: "See Info",
      key: "action",
      render: (_: any, record: DataType) => (
        <Button
          type="link"
          onClick={() => {
            setClickedINfoData(record);
            setIsOpen(true);
          }}
        >
          See More Info
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div></div>
      <GlobalTable data={filteredData} columns={columns} loading={loading} />
     <ViewScholarship  isOpen={isOpen} setIsOpen={setIsOpen} clickedInfoData={clickedInfoData} fetchData={fetchData}/>
    </div>
  );
};

export default ScholarShip;
