import { formatNumberWithCommas } from "shared-pages/latest-commission/utils";

const ViewAllTargets = ({
  filteredTargets,
  isActiveFilter,
  setConfirmationModal = null,
  managerScreen = false,
  setModal = null,
}) => {
  return (
    <div>
      {" "}
      <table className="w-full mb-20 text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-4 py-4">
              Target
            </th>

            {!managerScreen && (
              <>
                <th scope="col" className="px-4 py-4">
                  Cash Prize
                </th>
                <th scope="col" className="px-4 py-4">
                  Supervisor Comission
                </th>
                <th scope="col" className="px-4 py-4">
                  Created By
                </th>
              </>
            )}

            <th scope="col" className="px-4 py-4">
              Type
            </th>
            <th scope="col" className="px-4 py-4">
              Status
            </th>

            {managerScreen && (
              <>
                <th scope="col" className="px-4 py-4">
                  Cash Prize
                </th>
                <th scope="col" className="px-4 py-4">
                  Your Commission
                </th>
                <th scope="col" className="px-4 py-4">
                  Action
                </th>
              </>
            )}
            <th scope="col" className="px-4 py-4">
              Created At
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredTargets &&
            filteredTargets?.length > 0 &&
            filteredTargets
              ?.filter((target: any) => {
                if (isActiveFilter === "All") {
                  return true;
                }
                return (
                  (isActiveFilter === "Active" && target?.isActive) ||
                  (isActiveFilter === "InActive" && !target?.isActive)
                );
              })
              ?.map((item) => (
                <tr className="border-b" key={item?._id}>
                  <td className="px-4 py-3">{item?.target}</td>
                  {!managerScreen && (
                    <>
                      <td className="px-4 py-3">
                        {formatNumberWithCommas(item?.cashPrize)}
                      </td>
                      <td className="px-4 py-3">
                        {item?.commissionPercentageForSupervisor}
                      </td>
                      <td className="px-4 py-3">{item?.createdBy}</td>
                    </>
                  )}

                  <td className="px-4 py-3">{item?.schemeType}</td>
                  <td className="px-4 py-3">
                    {item?.isActive ? (
                      <span
                        className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"
                        onClick={() => {
                          if (managerScreen) return;
                          setConfirmationModal({
                            isOpen: true,
                            action: "InActive",
                            data: item,
                          });
                        }}
                      >
                        Active
                      </span>
                    ) : (
                      <span
                        className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"
                        onClick={() => {
                          if (managerScreen) return;
                          setConfirmationModal({
                            isOpen: true,
                            action: "Active",
                            data: item,
                          });
                        }}
                      >
                        In Active
                      </span>
                    )}
                  </td>

                  {managerScreen && (
                    <>
                      <td className="px-4 py-3">
                        {formatNumberWithCommas(item?.cashPrize)}
                      </td>
                      <td className="px-4 py-3">
                        {item?.commissionPercentageForSupervisor}%
                      </td>
                      <td className="px-4 py-4 cursor-pointer">
                        <span
                          className="bg-amber-100 text-amber-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"
                          onClick={() => {
                            if (!setModal) return;
                            setModal({
                              isModalOpen: true,
                              data: item,
                            });
                          }}
                        >
                          Assign
                        </span>
                      </td>
                    </>
                  )}
                  <td className="px-4 py-3">
                    {new Date(item?.createdAt)?.toLocaleDateString()}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewAllTargets;
