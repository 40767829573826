import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react'

const FeaturesList = ({ features }) => {
    return (
        <div className="flex flex-col gap-y-2 pt-6">
            {features &&
                features?.map((feature, i) => (
                    <div key={i} className="flex gap-x-2 items-start justify-start">
                        <div
                            className="bg-white"
                            style={{
                                clipPath: "polygon(40% 0, 100% 40%, 60% 100%, 0 60%)",
                            }}
                        >
                            {feature?.logo ===
                                "Green" ? (
                                    <CheckOutlined className="text-green-500 w-4 h-4" />
                            ) : feature?.logo.trim() ===
                                "Yellow" ? (
                                    <CheckOutlined  className="text-yellow-500 w-4 h-4" />
                            ) : (
                                <CloseOutlined  className="text-red-500 w-4 h-4"/>
                            )}
                        </div>
                        <div
                            className="text-black font-normal text-xs text-black"
                            dangerouslySetInnerHTML={{
                                __html: feature?.description,
                            }}
                        />
                    </div>
                ))}
        </div>
    );
};

export default FeaturesList