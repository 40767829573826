import React, { useEffect, useState } from "react";
import { Select } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";

import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";
import { Label } from "shared-components/ui/CustomForm";
import { PromoCodeAPI } from "(apis)/(shared-apis)/medschool/promocode-apis";

const PromoCodeModal = ({ modal, closeModal, allCodes, setPromoCodes }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [users, setAllUsers] = useState([]);

  async function fetchAllUsers() {
    setIsFetchingUsers(true);

    try {
      const response: any = await BasicGetApi("getUserInfo");
      if (response) {
        console.log("Response: ", response);
        const apiData =
          response?.data?.data && response?.data?.data?.length > 0
            ? response?.data?.data
            : [];
        setAllUsers(apiData);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Failed to Fetch Users.";
      toast.error(message);
    } finally {
      setIsFetchingUsers(false);
    }
  }

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const initialState = {
    userId: "",
    name: "",
    code: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialState);

  const resetForm = () => {
    setFormData(initialState);
    closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getUserInfoByEmail = (email: string) => {
    if (!email || email?.trim() === "") return null;
    const user = users.find(
      (user: any) => user?.username?.trim() === email?.trim()
    );
    return user;
  };

  const handleUserEmailChange = (mail) => {
    const cleanEmail = mail?.toLowerCase()?.trim();
    const user = getUserInfoByEmail(cleanEmail);
    console.log("user: ", user);
    if (user) {
      const updatedFormData = {
        ...formData,
        userId: user._id,
        email: cleanEmail,
        name: user.fullname,
      };
      setFormData(updatedFormData);
    } else {
      const updatedFormData = { ...formData, email: cleanEmail, name: "" };
      setFormData(updatedFormData);
    }
  };

  const validateForm = (formData) => {
    const { name, code, email } = formData;
    if (!email) {
      toast.error("select email address.");
      return false;
    }
    if (!name) {
      toast.error("Please enter a name.");
      return false;
    }
    if (!code) {
      toast.error("Please enter a promo code.");
      return false;
    }

    if (
      allCodes.find(
        (item: any) => item?.code?.toUpperCase() === code?.toUpperCase()
      )
    ) {
      toast.error("Code already exists.");
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm(formData)) {
      return;
    }

    try {
      setIsLoading(true);
      const body = formData;

      body.code = body.code.toUpperCase();

      const response = await PromoCodeAPI("addPromoCode", [], body);
      console.log("response: ", response);
      if (response?.data?.success) {
        const data = response?.data?.data;
        setPromoCodes([data, ...allCodes]);
        toast.success("Promo code created successfully.");
        resetForm();
      } else {
        toast.error(response?.data?.message || "Failed to create promo code.");
      }
    } catch (error) {
      console.log("Error: ", error);
      toast.error("Failed to create promo code.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`${
        modal?.isModalOpen ? "" : "hidden"
      } fixed inset-0 z-50 flex items-center justify-center bg-gray-200/40 backdrop-blur-sm, ${
        isFetchingUsers ? "cursor-wait" : ""
      }`}
    >
      <div className="bg-white rounded-lg p-8 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">Create Code</h2>
        <form onSubmit={handleSubmit} className="space-y-2">
          {/* User ID */}

          <div className="">
            <Label>User Email</Label>
            <Select
              showSearch
              onChange={(item) => {
                console.log("item: ", item);
                handleUserEmailChange(item);
              }}
              // g
              size={"large"}
              // disabled={isLoading}
              className="w-full"
              value={formData.email}
            >
              {users?.map((item, index) => (
                <Select.Option key={index} value={item?.username}>
                  {item?.username}
                </Select.Option>
              ))}
            </Select>
          </div>

          {/* Name */}

          <div className="">
            <Label>Name</Label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter name"
              className="w-full mb-4 p-2 border rounded"
              disabled
            />
          </div>

          <div className="">
            <Label>Code</Label>
            <input
              type="text"
              name="code"
              value={formData.code}
              onChange={handleInputChange}
              placeholder="Enter promo code"
              className="w-full mb-4 p-2 border rounded"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center space-x-4">
            <ButtonOutlined handleClick={resetForm}>Cancel</ButtonOutlined>
            <ButtonFill type="Submit" disabled={isLoading || isFetchingUsers}>
              {isLoading ? "Loading..." : "Submit"}
            </ButtonFill>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PromoCodeModal;
