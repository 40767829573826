 

 import { apiRequest } from '(apis)/api-interface/api-handler';
import { Divider, message, Tooltip } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomButton from 'shared-components/form-fields/custom-button';
import CustomFormField from 'shared-components/form-fields/custom-form-field';
import GlobalTable from 'shared-components/table/GlobalTable';
import GlobalPublishButton from 'shared-components/ui/CustomButtons';
import { EditIcon, EyeIcon, ManageIcon } from 'shared-components/ui/Icons';
import * as Yup from "yup";

const ManageMarketingCampaign = () => {
  const [loading, setLoading] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [campaignData, setCampaigns] = React.useState([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [editData, setEditData] = React.useState(null);
const navigate = useNavigate()
const formik =useFormik({
  initialValues: {
    name: '',
  },
  validationSchema : Yup.object({
    name: Yup.string().required('Name is required'),
  }),
  onSubmit: async(values) => {
   try {
    let res 
    if(editMode){
      res = await apiRequest('updateMarketingCampaign',values, [editData._id])
    }else{
      res = await apiRequest('addMarketingCampaign',values)
    }
    if(res.data?.isSuccess){
      if (editMode) {
        // Remove the old entry and add the updated one at the top
        setCampaigns((prevCampaigns) => {
          const updatedCampaigns = prevCampaigns.filter(
            (item) => item._id !== editData._id
          );
          return [{ ...editData, ...values }, ...updatedCampaigns];
        });
      } else {
        // Prepend the new Campaign to the array
        setCampaigns((prevCampaigns) => [res.data?.Campaign, ...prevCampaigns]);
      }
      setLoading(false)
      setEditMode(false)
      formik.resetForm()
      message.success(res.data?.message)
    }
   } catch (error) { 
    message.error(error?.response.data?.message||error.message)
   }
  },
})
const handleEdit = (data) => {
  formik.setValues(data);
  setEditData(data)
    // Scroll to the top of the parent scroll container
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0
    }
  setEditMode(true)
}
const fetchData = async()=>{
  try {
    setLoading(true)
    const res =  await  apiRequest('getAllMarketingCampaigns')
    if(res.data?.length>0){
      setCampaigns(res.data)
      setLoading(false)
    
    }
   } catch (error) { 
    message.error(error?.response.data?.message||error.message)
   }finally{
    setLoading(false)
   }
}
useEffect(() => {
  fetchData()

}, []);
const handleIsPublish = async(data) => {
  try {
    // setLoading(true)
    const res = await apiRequest('updateMarketingCampaignActive',null,[data._id]);
    if (res.data?.isSuccess) {
      // setCampaigns((item:any) =>
      //     item._id === data._id? {...item, publish:!item.publish } : item
        
      // );
      fetchData()
      message.success(res.data?.message);
    }
  } catch (error) {
    message.error(error?.response.data?.message || error.message);
  }finally{
    // setLoading(false)
  }
}
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: "IS ACTIVE",
          dataIndex: "publish",
          key: "publish",
    
          render: (publish,data) => (
            <div className="flex justify-center">
              <GlobalPublishButton
                isActive={data?.publish}
                onChange={() => handleIsPublish(data)}
              />
            </div>
          ),
        },
        {
          title: "ACTIONS",
          key: "actions",
          render: (data: any) => (
            <div className="flex gap-4 justify-center">
              <div onClick={() => handleEdit(data)}>
                <EditIcon size="w-6 h-6" />
              </div>
              <Tooltip placement="topLeft" title={'View Users'} >
              <div onClick={() =>{
                navigate(`/view-marketing-campaign-users/${data._id}`)  // replace with actual route path
              }}>
                <EyeIcon size="w-6 h-6" />
                
              </div>
          </Tooltip>
          <Tooltip placement="topLeft" title={'View Links'} >
              <div onClick={() =>{
                navigate(`/view-marketing-campaign-links/${data?._id}`)  // replace with actual route path
              }}>
                <ManageIcon size="w-6 h-6" />
              </div>
              </Tooltip>
            </div>
          ),
        },
      ];
      // write formik
  return (
    <div>
      {/* Table */}
      <div ref={scrollContainerRef} style={{ height: '500px', overflowY: 'auto' }}>
      <div className='flex gap-2'>
        <div className='w-2/3'>
          <CustomFormField name='name' label='Campaign Name' formik={formik} type='text' />
        </div>
        <div className='mt-4'>
          <CustomButton text={editMode?"Update":"Add"} onClick={()=>formik.handleSubmit()} isLoading={formik.isSubmitting}/>
        </div>

      </div>
      <Divider/>
      <GlobalTable
        loading={loading ||  formik.isSubmitting}
        tableName={"Manage Marketing Campaigns"}
        columns={columns}
   
        data={campaignData}
      />
    </div>
    </div>
  )
}

export default ManageMarketingCampaign