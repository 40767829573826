import React, { useEffect, useState } from "react";
import { formatNumberWithCommas } from "shared-pages/latest-commission/utils";
import YearlySignUpsChart from "./components/YearlySignUpsChart";
import QuarterlySignUpsChart from "./components/QuarterlySignUpsChart";
import MonthlySignUpsChart from "./components/MonthlySignUpsChart";
import DailySignUpsChart from "./components/DailyChart"; // New import for daily sign-ups chart
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";
import CustomBadges from "shared-components/ui/CustomBadges";

const ViewStatistics = () => {
  const [stats, setStats] = useState<null | any>(null);
  const [medschoolStats, setMedschoolStats] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [medschoolApiLoading, setMedschoolApiLoading] = useState(false);

  const fetchSignupStatistics = async () => {
    try {
      setIsLoading(true);
      const response = await BasicGetApi("getSignupStatistics");
      if (response && response?.data?.isSuccess) {
        const data = response?.data;
        setStats(data);
      }
    } catch (error) {
      console.error("There was an error fetching the statistics!", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMedschoolStatistics = async () => {
    try {
      setMedschoolApiLoading(true);
      const response = await BasicGetApi("getMedschoolStatistics");
      if (response && response?.data?.isSuccess) {
        const data = response?.data?.data;
        setMedschoolStats(data);
      }
    } catch (error) {
      console.error("There was an error fetching the medschool statistics!", error);
    } finally {
      setMedschoolApiLoading(false);
    }
  };

  const isMedschool = process.env.REACT_APP_CATEGORY === "MEDSCHOOL";

  useEffect(() => {
    fetchSignupStatistics();
  }, []);

  useEffect(() => {
    if (isMedschool) {
      fetchMedschoolStatistics();
    }
  }, [isMedschool]);

  return (
    <>
      <div className="mx-10 font-bold text-2xl">
        {isMedschool ? "Medschool.PK" : "PreMed.PK"} Statistics
      </div>
      {isLoading ? (
        <h1 className="text-3xl font-semibold text-gray-700 text-center mt-8">
          Loading...
        </h1>
      ) : (
        <div className="space-y-8">
          <div className="text-primary font-semibold text-center my-2 text-3xl">
            Overall Stats
          </div>
          <div className="container items-center px-4 py-8 m-auto mt-5">
            <div className="flex flex-wrap items-center justify-center pb-3 lg:mx-0">
              {[
                { label: "Total Users", value: stats?.totalUsers },
                { label: "Today Signups", value: stats?.todaySignups },
                { label: "Last Week Signups", value: stats?.lastWeekSignups },
                { label: "This Month Signups", value: stats?.monthlySignups },
                { label: "Last Month Signups", value: stats?.lastMonthSignups },
                { label: "Total Orders", value: stats?.TotalOrders },
                { label: "Total Orders Today", value: stats?.TotalOrdersToday },
                { label: "Total Referrals", value: stats?.signupsViaReferral },
                { label: "Today Referrals", value: stats?.signupsViaReferralToday },
                { label: "Total Attempted Questions", value: stats?.totalAttemptedQuestions },
                { label: "Number of Attempted Questions Time", value: stats?.totalAttemptedQuestionsTime },
                { label: "Total Signups Via App", value: stats?.totalSignUpViaApp },
                { label: "Total Sales Via App", value: stats?.totalSalesViaApp },
              ]?.map((item, index) => (
                <RenderStatCard key={index} item={item} />
              ))}

              {isMedschool && !medschoolApiLoading && [
                { label: "Verified Students", value: medschoolStats?.verifiedCount },
                { label: "Unverified Students", value: medschoolStats?.unverifiedCount },
                { label: "Total Questions", value: medschoolStats?.questionCount },
                { label: "Quiz Builder Decks", value: medschoolStats?.quizBuilderDecksCount },
              ]?.map((item, index) => (
                <RenderStatCard key={index} item={item} />
              ))}
            </div>
          </div>

          <div className="shadow-xl p-4 rounded-xl">
            <h1 className="p-8 font-bold text-xl">Daily Sign-Ups Data</h1>
            <div style={{ height: 400 }}>
              {stats && <DailySignUpsChart data={stats} />}
            </div>
          </div>

          <div className="shadow-xl p-4 rounded-xl">
            <h1 className="p-8 font-bold text-xl">Yearly Sign-Ups Data</h1>
            <div style={{ height: 400 }}>
              {stats && <YearlySignUpsChart data={stats} />}
            </div>
          </div>
          <div className="shadow-xl p-4 rounded-xl">
            <h1 className="p-8 font-bold text-xl">Monthly Sign-Ups Data</h1>
            <div style={{ height: 400 }}>
              {stats && <MonthlySignUpsChart data={stats} />}
            </div>
          </div>
          <div className="shadow-xl p-4 rounded-xl">
            <h1 className="p-8 font-bold text-xl">Quarterly Sign-Ups Data</h1>
            <div style={{ height: 400 }}>
              {stats && <QuarterlySignUpsChart data={stats} />}
            </div>
          </div>

          {isMedschool && medschoolStats?.universityWiseSignupsData && (
            <div className="shadow-xl p-4 rounded-xl">
              <h1 className="p-8 font-bold text-xl">University wise signups</h1>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                {Object.entries(medschoolStats?.universityWiseSignupsData)?.map(([key, value], index) => (
                  <div className="w-full h-full p-2 cursor-pointer shadow-lg rounded-md" key={index}>
                    <h1>{key}</h1>
                    <UniversityWiseSignupsChart key={index}  data={value} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

/*
{
  'King Edward Medical University': {
    FIRST_YEAR: { Unverified: 1 },
    THIRD_YEAR: { Unverified: 2 },
    SECOND_YEAR: { Unverified: 1, Pending: 1 }
  },
  'Dow Medical College': {
    SECOND_YEAR: { Unverified: 1, Accepted: 1 },
    FIRST_YEAR: { Unverified: 2 },
    THIRD_YEAR: { Unverified: 1 },
    FOURTH_YEAR: { Pending: 1, Unverified: 1 }
  },
  'Jinnah Sindh Medical University': { FIRST_YEAR: { Unverified: 2 }, FOURTH_YEAR: { Unverified: 1 } },
  'Dow University of Health Sciences': {
    FIRST_YEAR: { Unverified: 2 },
    SECOND_YEAR: { Unverified: 1 },
    FOURTH_YEAR: { Unverified: 1 }
  },
  'Sahiwal Medical College (Public)': { FOURTH_YEAR: { Unverified: 1 } },
  'Gomal Medical College': { SECOND_YEAR: { Unverified: 1 } },
  'Rawalpindi Medical University': { SECOND_YEAR: { Unverified: 1 } },
  'Faisalabad Medical University': { FIFTH_YEAR: { Unverified: 1 } },
  'Ziauddin University': { FIRST_YEAR: { Unverified: 1 }, THIRD_YEAR: { Unverified: 1 } },
  'Liaquat University of Medical & Health Sciences': {
    FIRST_YEAR: { Pending: 1, Unverified: 1 },
    SECOND_YEAR: { Unverified: 1 }
  },
  'Shifa College of Medicine': { FIRST_YEAR: { Unverified: 1, Pending: 1 } },
  'Federal Medical and Dental College': { FIFTH_YEAR: { Unverified: 1 } },
  'DOW Medical College': { THIRD_YEAR: { Unverified: 1 }, FOURTH_YEAR: { Unverified: 1 } },
  'HITEC Institute of Medical Sciences': { FIRST_YEAR: { Unverified: 1 } },
  'Bahria University Medical and Dental College': { THIRD_YEAR: { Unverified: 1 } },
  'University of Health Sciences': { FIRST_YEAR: { Unverified: 1 } },
  'Khyber Medical University': { FIRST_YEAR: { Pending: 1, Unverified: 1 } },
  'Liaquat National Medical College': { FIRST_YEAR: { Unverified: 1 } },
  'Liaquat College of Medicine & Dentistry': { FIRST_YEAR: { Unverified: 1 } },
  'Karachi Medical and Dental College': { SECOND_YEAR: { Unverified: 1 } },
  'Army Medical College (Public)': { FOURTH_YEAR: { Unverified: 1 } },
  'University Medical & Dental College': { FIRST_YEAR: { Unverified: 1 } },
  'Rehman Medical College': { FIRST_YEAR: { Unverified: 1 } },
  'Khyber Medical College Peshawar': { FIRST_YEAR: { Unverified: 1, Pending: 1 } },
  'Saidu Dental College': { FIRST_YEAR: { Unverified: 1 } },
  'CMH Lahore Medical and Dental College': { FIRST_YEAR: { Unverified: 1 } },
  'Muhammad Medical College': { FIRST_YEAR: { Unverified: 1 } }
}
*/

const RenderStatCard = ({ item, key }: { item: { label: string; value: number }; key: number }) => {
  return (
    <div className="w-full md:max-w-xs p-2 lg:w-1/3 md:w-1/2 cursor-pointer" key={key}>
      <div className="flex flex-col px-6 py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group transition-all ease-in-out">
        <div className="flex flex-row justify-between items-center">
          <div className="px-4 py-4 bg-gray-300 rounded-xl bg-opacity-30">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 group-hover:text-gray-50" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
              <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
        <h1 className="text-3xl sm:text-4xl xl:text-5xl font-bold text-gray-700 mt-12 group-hover:text-gray-50">
          {formatNumberWithCommas(item?.value)}
        </h1>
        <div className="flex flex-row justify-between group-hover:text-gray-200">
          <p className="font-semibold text-lg text-gray-800">{item?.label}</p>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-indigo-600 group-hover:text-gray-200" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

const UniversityWiseSignupsChart = ({ key, data }: { key: number; data: any }) => {
  return (
    <div className="flex flex-col gap-2" key={key}>
      {Object.entries(data).map(([label, value]) => (
        <div key={label}>
          <h1>{label === "FIRST_YEAR" ? "1st Year" : label === "SECOND_YEAR" ? "2nd Year" : label === "THIRD_YEAR" ? "3rd Year" : label === "FOURTH_YEAR" ? "4th Year" : label === "FIFTH_YEAR" ? "5th Year" : label}</h1>
          <div className="flex flex-row gap-2">
             {/* @ts-ignore */}
            <CustomBadges type="success" label={`Accepted: ${value?.Accepted || 0}`} />  
             {/* @ts-ignore */}
            <CustomBadges type="error" label={`Unverified: ${value?.Unverified || 0}`} />
             {/* @ts-ignore */}
            <CustomBadges type="warning" label={`Pending: ${value?.Pending || 0}`} />  
             {/* @ts-ignore */}
            <CustomBadges type="info" label={`Rejected: ${value?.Rejected || 0}`} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewStatistics;
