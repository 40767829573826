'use client'
import { DoubleLeftOutlined } from '@ant-design/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
export default function BackButton() {


  const navigate = useNavigate()
  return (
    <div className="flex flex-start justify-start gap-1 z-20" onClick={() => navigate(-1)}>
      <div className='flex items-center shadow-lg rounded p-1 z-20 bg-white cursor-pointer hover:shadow-sm transition duration-300'>
        {/* <ChevronLeft size={24} className="text-primary z-20" /> */}
        <DoubleLeftOutlined  size={24} className="text-primary z-20" />
      </div>
      <button className="text-lg font-semibold text-primary z-20">Back</button>

    </div>

  )
}
