import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { MAX_COMMISSION, ROlES, ROLES_OPTION } from "../utils";
import { Input, Label } from "shared-components/ui/CustomForm";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";

const UpdateCommissionPercentageModal = (props) => {
  const [requestType, setRequestType] = useState("Update For All");
  const [selectedRole, setSelectedRole] = useState("");
  const [commissionRate, setCommissionRate] = useState<number>(0);
  const [userEmails, setUserEmails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [supervisorEmail, setSupervisorEmail] = useState("");
  const [supervisorRole, setSupervisorRole] = useState(ROlES.MANAGER);
  const resetForm = () => {
    setSelectedRole("");
    setCommissionRate(0);
    setUserEmails("");
    setIsLoading(false);
  };

  useEffect(() => {
    resetForm();
  }, []);

  const closeModal = () => {
    resetForm();
    props.setIsModalOpen(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (
      !commissionRate ||
      commissionRate <= 0 ||
      commissionRate > MAX_COMMISSION
    ) {
      toast.error("Invalid Commission Rate");
      return;
    }
    // if (!selectedRole) {
    //   toast.error("Invalid Role");
    //   return;
    // }

    setIsLoading(true);

    try {
      let body: any = {};
      if (requestType === "Update For All") {
        body = {
          role: selectedRole,
          commissionRate: parseFloat(commissionRate as unknown as string),
          requestType,
        };
      } else if (requestType === "Specific Users") {
        const emailsArray = userEmails.split("\n").map((email) => email.trim());
        body = {
          userEmails: emailsArray,
          commissionRate: parseFloat(commissionRate as unknown as string),
          requestType,
        };
      } else if (requestType === "Heirarchy") {
        body = {
          supervisorRole,
          supervisorEmail,
          commissionRate: parseFloat(commissionRate as unknown as string),
          requestType,
        };
      }

      const response = await CommissionAPI(
        "updateCommissionPercentage",
        [],
        body
      );
      // const response = await updateCommissionPercentage(body);
      if (response?.data?.success) {
        toast.success("Commission updated successfully.");
        resetForm();
        setTimeout(() => {
          props.setIsModalOpen(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating commission percentage:", error);
      toast.error("Failed to update commission.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="updateCommissionModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.isModalOpen ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000] flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm`}
    >
      <Toaster />
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
          <button
            type="button"
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="updateCommissionModal"
            onClick={() => closeModal()}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <p className="mb-4 text-gray-500 text-xl">
            Update Commission Percentage
          </p>

          <form
            onSubmit={(e) => submit(e)}
            className="text-left my-5 space-y-5"
          >
            <CustomDropDown
              label="Select Update Type"
              options={["Update For All", "Specific Users", "Heirarchy"]}
              onSelect={(type) => setRequestType(type)}
              width="w-full"
              value={requestType}
            />

            {requestType === "Update For All" && (
              <div className="space-y-2">
                <CustomDropDown
                  label="Select Role"
                  options={ROLES_OPTION}
                  onSelect={(role) => setSelectedRole(role)}
                  width="w-full"
                  value={selectedRole}
                />
              </div>
            )}

            {requestType === "Specific Users" && (
              <div className="space-y-2">
                <Label>Enter User Emails (one per line)</Label>
                <textarea
                  className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                  value={userEmails}
                  onChange={(e) => setUserEmails(e.target.value)}
                  rows={5}
                ></textarea>
              </div>
            )}
            {requestType === "Heirarchy" && (
              <>
                <div className="space-y-2">
                  <Label>Enter Supervisor Email</Label>
                  <Input
                    type="text"
                    value={supervisorEmail}
                    onChange={(name, value) => setSupervisorEmail(value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Select Supervisor Role</Label>
                  <CustomDropDown
                    label="Role"
                    noLabel
                    options={["MANAGER", "DIRECTOR"]}
                    onSelect={(value) => {
                      setSupervisorRole(value);
                    }}
                    // disabled={true}
                    width="w-full"
                    value={supervisorRole}
                    isError={false}
                    errorMessage={""}
                  />
                </div>
              </>
            )}

            <div className="space-y-2">
              <Label>Enter New Commission Rate (%)</Label>
              <Input
                type="number"
                value={commissionRate}
                name="commissionRate"
                onChange={(name, value: string) => {
                  setCommissionRate(Number(value));
                }}
                isError={false}
                errorMessage={""}
              />
            </div>

            <div className="flex justify-center items-center space-x-4">
              <ButtonOutlined handleClick={() => closeModal()}>
                Cancel
              </ButtonOutlined>
              <ButtonFill type="Submit" disabled={isLoading}>
                {isLoading ? "Loading.." : "Submit"}
              </ButtonFill>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateCommissionPercentageModal;
