import CustomBadges from "../../../../shared-components/ui/CustomBadges";
import { apiRequest } from "(apis)/api-interface/api-handler";
import toast, { Toaster } from "react-hot-toast";
import { AddIcon, DeleteIcon } from "../../../../shared-components/ui/Icons";

const QuestionTableView = ({
  filteredProducts,
  primaryButtonText,
  isLoading,
  selectedQuestionIds,
  setSelectedQuestionIds,
  isMedschool,
}) => {
  const handleQuestionId = (questionId) => {
    if (selectedQuestionIds.includes(questionId)) {
      const updateQuestions = selectedQuestionIds.filter(
        (question: string) => question !== questionId
      );
      setSelectedQuestionIds(updateQuestions);
    } else {
      const updateQuestions = [...selectedQuestionIds, questionId];
      setSelectedQuestionIds(updateQuestions);
    }
  };

  const AddQuestionToTopicForQuizBuilder = async (questionId: string) => {
    try {
      const response = await apiRequest("addQuestionToQuizBuilder", {
        questionId,
      });
      //   const response = await addQuestionToQuizBuilder(questionId);

      if (response) {
        const message = response?.data?.result?.message;
        toast.success(message);
      }
    } catch (error) {
      console.log("AddQuestion QuizBuilder error: ", error);
    }
  };
  const RemoveQuestionFromTopicForQuizBuilder = async (questionId: string) => {
    try {
      const response = await apiRequest("removeQuestionToQuizBuilder", {
        questionId,
      });
      //   const response = await removeQuestionToQuizBuilder(questionId);
      console.log("RemoveQuestion QuizBuilder Response: ", response);
    } catch (error) {
      console.log("RemoveQuestion QuizBuilder error: ", error);
    }
  };

  const selectAllQuestions = () => {
    const allQuestionIds = filteredProducts?.map((item) => item._id);
    setSelectedQuestionIds(allQuestionIds);
  };
  const removeAllQuestions = () => {
    setSelectedQuestionIds([]);
  };

  const copyQuestionIds = () => {
    const idsString = selectedQuestionIds.join("\n");
    navigator.clipboard
      .writeText(idsString)
      .then(() => {
        toast.success("Question IDs copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
        toast.error("Failed to copy question IDs");
      });
  };

  return (
    <div className="antialiased my-10 mx-auto max-w-full w-full">
      <Toaster />
      <div className="bg-white shadow-sm rounded-lg overflow-hidden space-y-5">
        <div className="flex items-center space-x-2 ">
          <h4 className="text-xl font-bold text-gray-700">Questions Found:</h4>
          <CustomBadges
            type="info"
            textSize="lg"
            rounded="md"
            label={filteredProducts?.length}
          />
        </div>

        {filteredProducts && filteredProducts.length > 0 ? (
          <>
            <div className="flex items-start lg:items-center flex-col lg:flex-row space-x-2 ">
              <div className="flex items-center  space-x-2">
                <h4 className="text-xl font-bold text-gray-700">
                  Selected Questions:
                </h4>
                <CustomBadges
                  type="info"
                  textSize="lg"
                  rounded="md"
                  label={selectedQuestionIds?.length}
                />
              </div>

              <div className=" text-gray-900 whitespace-nowrap lg:space-x-3 my-4 flex items-start lg:items-center flex-col lg:flex-row">
                <button
                  className="text-gray-900  w-64
                    bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 "
                  onClick={() => {
                    selectAllQuestions();
                  }}
                >
                  Select all questions
                </button>
                <button
                  className="text-gray-900 w w-64
                   bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  "
                  onClick={() => {
                    removeAllQuestions();
                  }}
                >
                  Remove all questions
                </button>
                <button
                  className="text-gray-900  w-64
                   bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  "
                  disabled={selectedQuestionIds?.length <= 0}
                  onClick={() => {
                    copyQuestionIds();
                  }}
                >
                  Copy Ids
                </button>
              </div>
            </div>
          </>
        ) : null}

        {filteredProducts && filteredProducts.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 mb-20">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Id
                  </th>
                  <th scope="col" className="px-4 py-3 text-center">
                    Question
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Author
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Topic
                  </th>
                  <th scope="col" className="px-4 py-3 text-center">
                    Subject
                  </th>
                  {isMedschool && (
                    <th scope="col" className="px-4 py-3 text-center">
                      Year
                    </th>
                  )}

                  <th scope="col" className="px-4 py-3 text-center">
                    Status
                  </th>
                  <th scope="col" className="px-4 py-3 text-center">
                    Edit
                  </th>
                  <th scope="col" className="px-4 py-3 text-center">
                    Quiz Builder
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((item) => (
                  <tr className="border-b" key={item?._id}>
                    <td className="px-4 py-3 text-gray-900 whitespace-nowrap">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value={selectedQuestionIds.includes(item?._id)}
                        checked={selectedQuestionIds.includes(item?._id)}
                        onChange={(e) => {
                          // console.log("value: ", e.target.checked);
                          handleQuestionId(item?._id);
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 0"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ms-2 text-sm font-medium text-gray-900"
                      >
                        {item?._id}
                      </label>

                      {/* {item?._id} */}
                    </td>
                    <td className="px-4 py-3 text-gray-900 text-start">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.QuestionText,
                        }}
                      />
                    </td>

                    <td className="px-4 py-3 text-gray-900 whitespace-nowrap">
                      {item?.Author}
                    </td>
                    <td className="px-4 py-3 text-gray-900 whitespace-nowrap">
                      {item?.meta?.category}
                    </td>
                    <td className="px-4 py-3 text-gray-900 whitespace-nowrap">
                      {item?.meta?.topic}
                    </td>
                    <td className="px-4 py-3 text-gray-900 text-center whitespace-nowrap">
                      {item?.meta?.subject}
                    </td>
                    {isMedschool && (
                      <td className="px-4 py-3 text-gray-900 text-center whitespace-nowrap">
                        {item?.meta?.year}
                      </td>
                    )}

                    <td className="px-4 py-3 text-gray-900 text-center">
                      {item?.Published ? (
                        <CustomBadges type="success" label="Active" />
                      ) : (
                        <CustomBadges type="error" label="Inactive" />
                      )}
                    </td>
                    <td className="px-4 py-4 text-center flex items-center justify-around">
                      <svg
                        onClick={() => {
                          window.open(`/editQuestion/${item?._id}`, "_blank");
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-500 hover:text-rose-500 cursor-pointer mt-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </td>
                    <td className="px-4 py-3 text-gray-900 whitespace-nowrap ">
                      <div className="flex gap-2">
                        <button
                          className="w-8 h-8 flex items-center justify-center border border-gray-200 "
                          onClick={() => {
                            AddQuestionToTopicForQuizBuilder(item?._id);
                          }}
                        >
                          <AddIcon />
                        </button>
                        <button
                          className="w-8 h-8 flex items-center justify-center border border-gray-200 "
                          onClick={() => {
                            RemoveQuestionFromTopicForQuizBuilder(item?._id);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex items-center justify-center py-10 bg-gray-50">
            {isLoading ? <h1>Loading...</h1> : <h1>No Questions Found</h1>}
          </div>
        )}
        {/* <div className="flex items-center justify-end my-5 space-x-4">
          <div className="w-52">
            <ButtonOutlined handleClick={() => {}} width="w-full">
              Cancel
            </ButtonOutlined>
          </div>
          <div className="w-52">
            <ButtonFill handleClick={() => {}} width="w-full">
              {primaryButtonText ? primaryButtonText : "Add"}
            </ButtonFill>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default QuestionTableView;
