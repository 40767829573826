import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiRequest } from '(apis)/api-interface/api-handler';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { DatePicker, Select, Space } from 'antd';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, parseISO } from 'date-fns';
import { Dayjs } from 'dayjs';

const { Option } = Select;

export default function AuthorStatistics() {
    const { author } = useParams();
    const [data, setData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [filteredStats, setFilteredStats] = useState({ added: 0, edited: 0, fixed: 0 });

    const fetchData = async () => {
        try {
            const res = await apiRequest("getEachAuthorStatistics", undefined, [author]);
            console.log(res)
            if (res?.data?.success) {
                setData(res?.data?.Questions);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [author]);

    const processData = () => {
        // All-time data processing
        const dailyData = Array.from({ length: 31 }, (_, i) => {
            const date = format(new Date(selectedYear, i), 'yyyy-MM-dd');
            const dayData = data.filter(item => item.date === date);
            return {
                date: date,
                added: dayData.reduce((sum, item) => sum + item.added, 0),
                edited: dayData.reduce((sum, item) => sum + item.edited, 0),
                fixed: dayData.reduce((sum, item) => sum + item.fixed, 0)
            };
        });

        const monthlyData = Array.from({ length: 12 }, (_, i) => {
            const month = format(new Date(selectedYear, i), 'yyyy-MM');
            const monthData = data.filter(item => item.date.startsWith(month));
            return {
                date: month,
                added: monthData.reduce((sum, item) => sum + item.added, 0),
                edited: monthData.reduce((sum, item) => sum + item.edited, 0),
                fixed: monthData.reduce((sum, item) => sum + item.fixed, 0)
            };
        });

        const yearlyData = Array.from({ length: 5 }, (_, i) => {
            const year = selectedYear - 4 + i;
            const yearData = data.filter(item => item.date.startsWith(year.toString()));
            return {
                date: year.toString(),
                added: yearData.reduce((sum, item) => sum + item.added, 0),
                edited: yearData.reduce((sum, item) => sum + item.edited, 0),
                fixed: yearData.reduce((sum, item) => sum + item.fixed, 0)
            };
        });

        // Filtered data processing
        const filteredData = data.filter(item => {
            const itemDate = parseISO(item.date);
            return (
                itemDate.getFullYear() === selectedYear &&
                (!selectedMonth || itemDate.getMonth() === selectedMonth.month())
            );
        });

        const newFilteredStats = {
            added: filteredData.reduce((sum, item) => sum + item.added, 0),
            edited: filteredData.reduce((sum, item) => sum + item.edited, 0),
            fixed: filteredData.reduce((sum, item) => sum + item.fixed, 0)
        };

        return { dailyData, monthlyData, yearlyData, newFilteredStats };
    };

    const { dailyData, monthlyData, yearlyData, newFilteredStats } = processData();

    useEffect(() => {
        setFilteredStats(newFilteredStats);
    }, [selectedMonth, selectedYear, data]);

    return (
        <div>
            <h1>Author Statistics</h1>

            <Space style={{ marginBottom: 16 }}>
                <DatePicker
                    picker="month"
                    value={selectedMonth}
                    onChange={(date: Dayjs | null) => setSelectedMonth(date)}
                />
                <Select
                    style={{ width: 120 }}
                    value={selectedYear}
                    onChange={(value) => setSelectedYear(Number(value))}
                >
                    {Array.from({ length: 5 }, (_, i) => selectedYear - 4 + i).map((year) => (
                        <Option key={year} value={year}>
                            {year}
                        </Option>
                    ))}
                </Select>
            </Space>

            <div className='bg-white p-4 rounded-lg shadow-md w-fit'>
                <h2 className='text-lg font-bold mb-2'>Filtered Statistics</h2>
                <div className='flex flex-row justify-start gap-4'>
                    <p className='bg-white p-3 flex flex-col items-center justify-center rounded-md'>Questions Added:
                        <span className='text-2xl font-bold'>{filteredStats?.added}</span>
                    </p>
                    <p className='bg-white p-3 flex flex-col items-center justify-center rounded-md'>Questions Edited:
                        <span className='text-2xl font-bold'>{filteredStats?.edited}</span>
                    </p>
                    <p className='bg-white p-3 flex flex-col items-center justify-center rounded-md'>Questions Fixed:
                        <span className='text-2xl font-bold'>{filteredStats?.fixed}</span>
                    </p>
                </div>
            </div>

            <div className='mt-4 bg-white p-4 rounded-lg shadow-md w-fit'>
                <h3 className='mt-4 mb-2 text-lg font-bold'>Daily Statistics</h3>
                <BarChart width={800} height={300} data={dailyData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="added" fill="#5F56FFFF" />
                    <Bar dataKey="edited" fill="#FF5A31FF" />
                    <Bar dataKey="fixed" fill="#FFAE00FF" />
                </BarChart>
            </div>

            {/* Daily Table */}
            {/* <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Added</th>
                        <th>Edited</th>
                    </tr>
                </thead>
                <tbody>
                    {dailyData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.added}</td>
                            <td>{item.edited}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}

            <div className='mt-4 bg-white p-4 rounded-lg shadow-md w-fit'>
                <h3 className='mt-4 mb-2 text-lg font-bold'>Monthly Statistics</h3>
                <BarChart width={800} height={300} data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="added" fill="#5F56FFFF" />
                    <Bar dataKey="edited" fill="#FF5A31FF" />
                    <Bar dataKey="fixed" fill="#FFAE00FF" />
                </BarChart>
            </div>

            {/* Monthly Table */}
            {/* <table>
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Added</th>
                        <th>Edited</th>
                    </tr>
                </thead>
                <tbody>
                    {monthlyData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.added}</td>
                            <td>{item.edited}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
 */}
            <div className='mt-4 bg-white p-4 rounded-lg shadow-md w-fit'>
                <h3 className='mt-4 mb-2 text-lg font-bold'>Yearly Statistics</h3>
                <BarChart width={800} height={300} data={yearlyData}>
                    <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="added" fill="#5F56FFFF" />
                    <Bar dataKey="edited" fill="#FF5A31FF" />
                    <Bar dataKey="fixed" fill="#FFAE00FF" />
                </BarChart>
            </div>

            {/* Yearly Table */}
            {/* <table>
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Added</th>
                        <th>Edited</th>
                    </tr>
                </thead>
                <tbody>
                    {yearlyData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.added}</td>
                            <td>{item.edited}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
        </div>
    );
}
