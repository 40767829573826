import { apiRequest } from "(apis)/api-interface/api-handler";
import { Image, message, notification } from "antd";
import { useEffect, useState } from "react";
import { EditIcon } from "shared-components/ui/Icons";
import EditCAModal from "./edit-modal";
import { CAMainTableProps } from "./types";

export function CAMainTable({
  campus_associates,
  instituteData,
  setcampus_associates,
  originalcampus_associates,
  setoriginalcampus_associates,
  setShowModel,
}: CAMainTableProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const ModulesPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const HandleSearch = (e) => {
    const searchValue = e.target.value;
    if (searchValue === "") {
      setcampus_associates(originalcampus_associates);
    } else {
      const newCampusAssociate = originalcampus_associates.filter(
        (campusassociate) => {
          return campusassociate.CAname.toLowerCase().includes(
            searchValue.toLowerCase()
          );
        }
      );
      setcampus_associates(newCampusAssociate);
    }
  };

  return (
    <div className="w-full m-12 pt-4 rounded shadow-lg bg-white">
      <div className="flex flex-wrap items-center justify-between px-4 pb-4 border-b">
        <>
          <button
            className={`relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-lg before:border before:border-gray-200 before:bg-rose-500 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max my-2`}
            onClick={() => {
              setShowModel(true);
            }}
          >
            <div className={`relative flex items-center space-x-3 text-white`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <span className=" text-base font-semibold">
                Create New Campus Associate
              </span>
            </div>
          </button>
        </>
        <div className="relative">
          <input
            type="text"
            className="w-60 rounded border-[1.5px] border-stroke bg-transparent py-2 px-4 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
            placeholder="Search"
            onChange={HandleSearch}
          />
          <div className="absolute top-0 right-0 flex items-center h-full mr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="p-2 overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="flex justify-between shadow p-4 text-sm text-left text-gray-500 ">
              <th className="px-6 pb-3 font-medium w-1/4">SR#</th>
              <th className="align-self-start px-6 pb-3 font-medium w-1/4">
                <span>Image</span>
              </th>
              <th className="align-self-start px-6 pb-3 font-medium w-1/4">
                <span>Name</span>
              </th>
              <th className="align-self-start px-6 pb-3 font-medium w-1/4">
                <span>Position</span>
              </th>
              <th className="px-6 pb-3 font-medium w-1/12">Published</th>
              <th className="px-6 pb-3 font-medium w-1/12 mx-2">Top3</th>
              <th className="px-6 pb-3 font-medium w-1/12">Priority</th>
              <th className="px-6 pb-3 font-medium w-1/12">Edit</th>
            </tr>
          </thead>

          <tbody>
            {campus_associates
              .slice(
                (currentPage - 1) * ModulesPerPage,
                currentPage * ModulesPerPage
              )
              .map((associate, index) => (
                <CARow
                instituteData={instituteData}
                  associate={associate}
                  index={index}
                  key={index}
                  campus_associates={campus_associates}
                  setcampus_associates={setcampus_associates}
                  setoriginalcampus_associates={setoriginalcampus_associates}
                />
              ))}
          </tbody>
        </table>
        <nav
          className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500 space-x-2">
            <span> Showing</span>
            <span className="font-semibold text-gray-900 ">
              {currentPage * ModulesPerPage - ModulesPerPage + 1}
            </span>
            <span> of</span>
            <span className="font-semibold text-gray-900">
              {campus_associates.length}
            </span>
          </span>
          <ul className="inline-flex items-stretch -space-x-px">
            <li>
              <span
                className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
                onClick={() => {
                  if (currentPage === 1) {
                    return;
                  }
                  handlePageChange(currentPage - 1);
                }}
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </li>
            {Array.from({
              length: Math.ceil(campus_associates.length / ModulesPerPage),
            }).map((_, index) => (
              <li key={index}>
                <div
                  className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 cursor-pointer ${
                    currentPage === index + 1
                      ? "font-semibold text-primary-600 bg-primary-50"
                      : ""
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </div>
              </li>
            ))}
            <li>
              <span 
                className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
                onClick={() => {
                  if (
                    currentPage ===
                    Math.ceil(campus_associates.length / ModulesPerPage)
                  ) {
                    return;
                  }
                  handlePageChange(currentPage + 1);
                }}
              >
                <span className="sr-only">Next</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

function CARow({
  associate,
  instituteData,
  index,
  setcampus_associates,
  setoriginalcampus_associates,
  campus_associates,
}) {
  const [showModel, setShowModel] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const handleEdit = () => {
    setShowModel(true);
  };

  const handleDelete = async () => {
    const Response = await apiRequest("deleteCA", null, [associate._id]);
    if (Response.data.success) {
      message.success(Response.data.message);
    } else {
      message.error(Response.data.message);
    }
    const Response2 = await apiRequest("getALLCa");

    if (Response2.data.success) {
      setcampus_associates(Response2.data.data);
    }
  };
  const HandlePublish = async (id) => {
    const numPublishedAssociates = campus_associates.filter(
      (associate) => associate.isPublished
    ).length;

    const newAssociates = campus_associates.map((associateItem) => {
      if (associateItem._id === id) {
        return {
          ...associateItem,
          isPublished: !associateItem.isPublished,
        };
      }
      return associateItem;
    });

    if (
      newAssociates.find((associate) => associate._id === id).isPublished &&
      numPublishedAssociates >= 3
    ) {
      const confirmed = window.confirm(
        "You have already published 3 associates. Do you want to unpublish one?"
      );
      if (!confirmed) {
        return;
      }

      const firstPublishedIndex = newAssociates.findIndex(
        (associate) => associate.isPublished
      );
      newAssociates[firstPublishedIndex].isPublished = false;
    }

    setcampus_associates(newAssociates);

    try {
      const response = await apiRequest("updatepublish", null, [id]);
      if (response.data.success) {
        message.success(response.data.message);
        const newassociate = await apiRequest("getALLCa");
        setcampus_associates(newassociate.data.data);
        setoriginalcampus_associates(newassociate.data.data);
      } else {
        message.error("CA Published Failed");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };
  const handleCheckboxChange = async () => {
    let updatedPriority = null;

    const selectedIndex = campus_associates.findIndex(
      (item) => item._id === associate._id
    );

    if (!associate.isChecked) {
      const selectedCount = campus_associates.filter(
        (item) => item.isChecked
      ).length;
      if (selectedCount < 3) {
        for (let i = 1; i <= 3; i++) {
          if (
            !campus_associates.some(
              (item) => item.priority === i && item.isChecked
            )
          ) {
            updatedPriority = i;
            break;
          }
        }
      }
    }

    const updatedAssociate = {
      ...associate,
      isChecked: !associate.isChecked,
      priority: updatedPriority,
    };

    try {
      const response = await apiRequest("updatechecked", updatedAssociate, [
        updatedAssociate._id,
      ]);
      if (response.data.success) {
        notification.success({
          message: updatedAssociate.isChecked
            ? "Associate Checked"
            : "Associate Unchecked",
        });

        let updatedAssociates = [...campus_associates];
        updatedAssociates[selectedIndex] = updatedAssociate;

        setcampus_associates(updatedAssociates);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    setSelectedCount(
      campus_associates.filter((associate) => associate.isChecked).length
    );
  }, [campus_associates]);

  return (
    <>
      <tr
        className="flex justify-between shadow p-4 text-sm text-left text-gray-500"
        key={index}
      >
        <td className="px-6 pb-3 font-medium w-1/5">{index + 1}</td>
        <td className="align-self-start px-6 pb-3 font-medium w-1/5">
          <Image
          width={50}
            height={50}
            src={`${associate.CAimage}`}
            alt="campus_associateimag"
            className="rounded-full h-12 w-12 object-cover"
          />
        </td>
        <td className="align-self-start px-6 pb-3 font-medium w-1/5">
          {associate.CAname}
        </td>
        <td className="align-self-start px-6 pb-3 font-medium w-1/5">
          {associate.CAuniversity}
        </td>
        <td className="px-6 pb-3 font-medium w-1/12">
          <label className="flex items-center flex-start cursor-pointer">
            <div className="relative ml-2">
              <input
                type="checkbox"
                className="sr-only"
                checked={associate.isPublished}
                onChange={() => {
                  HandlePublish(associate._id);
                }}
              />
              <div
                className={`block 
          ${associate.Published ? "bg-green-400" : "bg-gray-200"}
          w-12 h-6 rounded-full`}
              ></div>
              <div
                className={`dot absolute 
          ${associate.Published ? "left-1 top-1" : "left-7 top-1"}
          bg-white w-4 h-4 rounded-full transition`}
              ></div>
            </div>
          </label>
        </td>

        <td className="px-6 pb-3 font-medium w-1/12">
          <input
            type="checkbox"
            className="mr-2 ml-4 cursor-pointer"
            checked={associate.isChecked}
            onChange={handleCheckboxChange}
            disabled={!associate.isChecked && selectedCount >= 3}
          />
        </td>
        <td className="mr px-6 pb-3 font-medium w-1/12 ">
          {associate.priority ? associate.priority : "-"}
        </td>
        <td className=" px-6 pb-3 font-medium w-1/12">
          <button
            className="text-primary-600 hover:text-primary-800 focus:outline-none"
            onClick={handleEdit}
          >
            <EditIcon />
          </button>
        </td>
      </tr>
      {showModel && (
        <EditCAModal
          associate={associate}
          setcampus_associates={setcampus_associates}
          showModel={showModel}
          instituteData={instituteData}
          setShowModel={setShowModel}
        />
      )}
    </>
  );
}
