import PaymentMethods from "../(components)/PaymentMethods";
import { backendUrl } from "constants/config";
import { UserContext } from "state-management/user-context";
import { message, Radio } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";
import { customAPIHandler } from "(apis)/api-interface/api-handler";
function VerifyCouponCode(couponcode) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: backendUrl + `/api/coupons/verify-coupon-code/${couponcode}`,
  });
  return data;
}

async function requestplanSubscriptionApi(data) {
  return axios.post(`${backendUrl}/api/orders/create-plan-order`, data);
}
const CheckOutCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [email, setEmail] = useState<string>("");

  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    const currentCart = JSON.parse(localStorage.getItem("premedcart")) || [];
    setCartItems(currentCart);

    let total = 0;
    currentCart.forEach((item) => {
      total += item.PromotionalAmount;
    });
    setTotal(total);

    let discount = 0;
    currentCart.forEach((item) => {
      discount += item.TotalAmount - item.PromotionalAmount;
    });
    setDiscount(discount);
  }, []);

  const clearCart = () => {
    localStorage.removeItem("premedcart");
    setCartItems([]);
  };

  const router = useNavigate();

  const [couponValue, setCouponValue] = React.useState("");
  const [couponError, setCouponError] = React.useState(false);
  const [couponApplied, setCouponApplied] = React.useState(false);
  const [couponDiscount, setCouponDiscount] = React.useState(0);
  const applyCoupon = async () => {
    if (couponValue === "") {
      setCouponError(true);
      return;
    }
    if (couponApplied) {
      toast.error("Coupon Already Applied");
      return;
    }

    try {
      const response = await VerifyCouponCode(couponValue?.toUpperCase());
      if (response?.data?.Error == true) {
        toast.error("Invalid Coupon Code");
        setCouponError(true);
      } else {
        toast.success("Coupon Applied Successfully");
        setCouponError(false);
        setCouponApplied(true);
        setCouponDiscount(response?.data?.Amount);

        //track via google analytics, track that he is applying the coupon, not the item
        if (process.env.REACT_APP_CATEGORY === "PRE-MEDICAL") {
          //   window.gtag('event', 'apply_coupon', {
          //     'event_category': 'premed-checkout',
          //     'event_label': 'apply_coupon',
          //     'value': response?.data?.Amount,
          //     'coupon_code': couponValue,
          //   });
        }

        let total = 0;
        cartItems.forEach((item) => {
          total += item.PromotionalAmount;
        });
        setTotal(total - response?.data?.Amount * total);

        let discount = 0;
        cartItems.forEach((item) => {
          discount += item.TotalAmount - item.PromotionalAmount;
        });
        setDiscount(discount + response?.data?.Amount * total);
        setCouponError(false);
      }
    } catch (error) {
      toast.error("Failed to apply coupon");
      console.error(error);
    }
  };

  const removeItemFromCart = (_id) => {
    let cartItems = JSON.parse(localStorage.getItem("premedcart")) || [];
    let newCart = cartItems.filter((item) => item._id !== _id);
    localStorage.setItem("premedcart", JSON.stringify(newCart));
    setCartItems(newCart);
    toast.success("Item Removed from Cart");

    let total = 0;
    newCart.forEach((item) => {
      total += item.PromotionalAmount;
    });
    if (couponApplied) {
      setTotal(total - couponDiscount * total);
    } else {
      setTotal(total);
    }

    let discount = 0;
    newCart.forEach((item) => {
      discount += item.TotalAmount - item.PromotionalAmount;
    });
    if (couponApplied) {
      setDiscount(discount + couponDiscount * total);
    } else {
      setDiscount(discount);
    }
  };

  const [Processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Bank Transfer");
  const [paymentProofbase64, setPaymentProofbase64] = useState("");
  const [disabled, setDisabled] = useState(false);

  const onChangeRadio = (e) => {
    setPaymentMethod(e.target.value);
  };

  const UploadPaymentProof = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPaymentProofbase64(reader.result as string);
    };
    reader.readAsDataURL(file);
  };
  const { user } = useContext(UserContext);

  const handlePurchase = (transactionId, totalAmount) => {
    if (process.env.REACT_APP_CATEGORY === "PRE-MEDICAL") {
      //   window.gtag('event', 'purchase', {
      //     transaction_id: transactionId,
      //     affiliation: 'PreMed Bundle', // Change as needed
      //     value: totalAmount || 0, // Total purchase amount
      //     currency: 'PKR', // Adjust according to your currency
      //     items: cartItems?.map(item => ({
      //       item_id: item?._id,
      //       item_name: item?.SKUName,
      //       item_category: item?.category,
      //       item_price: item?.price,
      //       quantity: item?.quantity, // If applicable
      //     })),
      //   });
    }
  };

  const RequestSubscription = async () => {
    if (cartItems.length === 0) {
      return;
    }
    if (
      paymentProofbase64 === null ||
      paymentProofbase64 === undefined ||
      paymentProofbase64 === "" ||
      paymentProofbase64 === "data:,"
    ) {
      toast.error("Please Upload Payment Proof");
      return;
    }

    // setDisabled(true);

    try {
      // Validate User First
      let fetchedUser = null;
      const response = await BasicGetApi("getUserInfoByEmail", [], {
        email: email,
      });
     
      if (response && response?.data?.isSuccess) {
        fetchedUser = response?.data?.data;
      }
      if (!fetchedUser) {
        toast.error("Invalid User Email");
        setDisabled(false);
        return
      }
      // return;
      let planids = [];

      let Products = [];

      let courseIdsWithDuration = [];
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].courseIds.length > 0) {
          courseIdsWithDuration.push({
            courseIds: cartItems[i].courseIds,
            durationType: cartItems[i].DurationType,
            duration: cartItems[i].Duration,
          });
        }
        planids.push({
          PlanID: cartItems[i]._id,
          durationTag: cartItems[i].DurationTag || "No Tag",
          durationType: cartItems[i].DurationType,
          duration: cartItems[i].Duration,
          price: cartItems[i].TotalAmount,
          discount: cartItems[i].PromotionalDiscount,
          promotionalAmount: cartItems[i].PromotionalAmount,
          toggle: cartItems[i].Package,
          purchaseType: cartItems[i].PurchaseType,
        });
        Products.push(cartItems[i].SKUName);
      }

      const InitialAmount = cartItems.reduce(
        (total, item) => total + item.TotalAmount,
        0
      );
      const PromotionalAmount = cartItems.reduce(
        (total, item) => total + item.PromotionalAmount,
        0
      );

      const AdditionalDiscount = cartItems.reduce(
        (total, item) => total + item.AdditionalDiscount,
        0
      );
      const AdditionalDiscountAmount = cartItems.reduce(
        (total, item) => total + item.AdditionalDiscountAmount,
        0
      );

      let concat = courseIdsWithDuration.length > 0 ? "+COURSES" : "";

      cartItems.forEach((item) => {
        if (item.AdditionalTag && item.AdditionalTag !== "") {
          concat += "+" + item.AdditionalTag;
        }
      });

      const data = {
        UserID: fetchedUser?._id,
        UserName: email,
        TotalAmount: total + discount,
        CouponUsed: couponApplied,
        CouponCode: couponValue,
        DiscountAmount: discount,
        PaidAmount: total,
        PaymentMethod: paymentMethod,
        PaymentProof: paymentProofbase64,
        PlanIDs: planids,
        type: "PACKAGES-ORDER" + concat,
        planCourses: courseIdsWithDuration,
        CouponDiscount: couponDiscount,
        BREAKDOWN: {
          TotalAmount: InitialAmount,
          PromotionalAmount: PromotionalAmount,
          PromotionalDiscount: (
            (InitialAmount - PromotionalAmount) /
            InitialAmount
          ).toFixed(2),
          CouponDiscountAmount:
            couponDiscount > 0
              ? discount - (InitialAmount - PromotionalAmount)
              : 0,
          CouponDiscount: couponDiscount,
          AdditionalDiscount: AdditionalDiscount,
          AdditionalDiscountAmount: AdditionalDiscountAmount,
          TotalDiscount: discount,
          GrandTotal: total,
        },
        Products,
      };

      const Response = await requestplanSubscriptionApi(data);
      // console.log("Response requestplanSubscriptionApi: ", Response);
      if (!Response.data.success) {
        message.error(Response.data.message);
      } else {
        message.success("Your order has been placed successfully");
        const order = Response?.data?.Order;

        if (!order || !order?._id) {
          message.error("Order not found or invalid");
        } else {
          const changeStatusResponse = await customAPIHandler(
            "changeStatusForBundlePurchase",
            "",
            {
              orderId: order._id,
              newStatus: "Accepted",
              message: "Order Accepted",
            },
            "PRE-MEDICAL"
          );

          if (
            changeStatusResponse?.data?.message ===
            "Order Status Updated Successfully"
          ) {
            message.success("Order Accepted Successfully");
          } else {
            message.error("Failed to accept order");
          }
        }

        // router("/bundles/mdcat");
        clearCart();
        setProcessing(false);
        setDisabled(false);

        //track via google analytics, track that he is placing the order, not the item
        handlePurchase("premed-bundle", total);
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "An unknown error occured";
      message.error(errorMessage);
    }
    setDisabled(false);
  };

  const [paymentVideo, setPaymentVideo] = React.useState(
    "https://www.youtube.com/embed/POOAMMej1xU"
  );

  const GetDiscounts = () => {
    //[promotional discount, coupon discount, additional discount]

    const InitialAmount = cartItems.reduce(
      (total, item) => total + item.TotalAmount,
      0
    );
    const PromotionalAmount = cartItems.reduce(
      (total, item) => total + item.PromotionalAmount,
      0
    );
    const AdditionalDiscountAmount = cartItems.reduce(
      (total, item) => total + item.AdditionalDiscountAmount,
      0
    );
    const AdditionalDiscount = cartItems.reduce(
      (total, item) => total + item.AdditionalDiscount,
      0
    );

    return {
      promotionalDiscount:
        InitialAmount - PromotionalAmount - AdditionalDiscountAmount,
      couponDiscount: discount - (InitialAmount - PromotionalAmount),
      additionalDiscount: AdditionalDiscountAmount,
      promotionalDiscountPercentage: (
        (InitialAmount - PromotionalAmount - AdditionalDiscountAmount) /
        InitialAmount
      ).toFixed(2),
      couponDiscountPercentage: couponDiscount,
      additionalDiscountPercentage: AdditionalDiscount,
    };
  };

  return (
    <>
      <div className="flex flex-col">
        {!Processing ? (
          <div className="w-[90%] md:w-3/4 mx-auto mt-8 md:mt-8 flex flex-col md:flex-row justify-between gap-8">
            <div className=" w-full md:w-3/4 mx-auto">
              <h1 className="text-primary text-5xl font-bold">CART</h1>
              {/* <h3 className="text-primary text-2xl font-bold mt-4">Upto 70% off on all bundles!</h3> */}
              <h3 className="text-primary text-2xl font-bold mt-4">
                Order Summary
              </h3>
              <p className="text-black ">
                You have {cartItems.length} items in your cart
              </p>
              <div className="flex flex-col justify-start w-full">
                {cartItems &&
                  cartItems?.map((card, index) => (
                    <div
                      className="relative my-2 p-4 rounded-lg shadow-xl border border-gray-50"
                      key={index}
                    >
                      <div
                        className="absolute top-2 right-3 cursor-pointer"
                        onClick={() => removeItemFromCart(card._id)}
                      >
                        <CloseOutlined
                          className="text-primary font-bold hover:text-red-500 transition duration-300 ease-in-out"
                          size={16}
                        />
                      </div>
                      <div className="flex justify-start items-center gap-4">
                        <img
                          className="w-8 h-8 rounded-full"
                          src={
                            "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CustomImages/PreMedCircleLogo.cffae65f.png"
                          }
                          alt="cardlogo"
                        />
                        <div className="">
                          <span className="text-lg font-bold text-primary">
                            {card?.SKUName} ({card?.Unit?.toUpperCase()})
                            <span className="text-md text-gray-500 font-semibold">
                              {card.Type && card.Type !== "NORMAL PURCHASE"
                                ? ` - ${card.Type.toUpperCase()}`
                                : ""}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div>
                        <span
                          title={card?.Description}
                          className="text-sm text-gray-800"
                        >
                          {card?.Description}
                        </span>
                      </div>
                      <div className="flex flex-row justify-start items-center w-full flex-wrap gap-1">
                        <div className="text-xs text-gray-500 font-semibold">
                          Access to:
                        </div>
                        {card?.PreMedAccess &&
                          card?.PreMedAccess.map((item, index) => (
                            <div
                              key={index}
                              className={`text-xs border-r border-gray-200 text-gray-700 p-1`}
                            >
                              {item}
                            </div>
                          ))}
                      </div>
                      <div className="flex flex-row justify-between items-center w-full mt-4">
                        {/* <div className="font-bold text-md text-gray-700 border-t border-gray-200 mt-4 pt-2">Duration:{" "}{card?.PlanDuration === 0 ? "Lifetime" : `${card?.PlanDuration > 1 ? `${card?.PlanDuration} Months` : `${card?.PlanDuration} Month`}`}</div> */}
                        <div className="font-bold text-md text-gray-700 border-t border-gray-200 mt-4 pt-2">
                          Duration: {card?.Package}
                        </div>
                        <div className="flex gap-2 text-xl text-primary items-end">
                          {/* <div className="text-sm text-gray-500 line-through">PKR. {card?.Price} </div> */}
                          <div className="text-sm text-gray-500 line-through">
                            PKR. {card?.TotalAmount.toFixed()}{" "}
                          </div>
                          {/* <div className="text-lg font-bold text-gray-700"> PKR. {card?.Price - (card?.Price * card?.Discount)} </div> */}
                          <div className="text-lg font-bold text-gray-700">
                            {" "}
                            PKR. {card?.PromotionalAmount.toFixed()}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <h3 className="text-primary text-2xl font-bold mt-4">
                Refund Policy
              </h3>
              <p className="text-black">
                No Refunds are allowed once the payment is made for the selected
                bundle. However, you can change the bundle within 24 hours of
                purchase. For more details, please visit our Refund Policy Page.
              </p>
            </div>
            <div className=" w-full md:w-1/4 mx-auto">
              <div className="flex flex-col bg-white p-4 rounded-xl shadow-xl border border-gray-50">
                <div className="text-gray-800 text-lg font-semibold mb-2 mt-2">
                  Coupon Code
                </div>
                {couponError && (
                  <div className="text-red-500 text-xs font-bold">
                    Invalid Coupon Code
                  </div>
                )}

                <div className="bg-white rounded-lg shadow-xl flex justify-between items-center">
                  <input
                    placeholder="Enter Coupon Code"
                    className="w-3/4 px-4 py-2 rounded-lg focus:border-none focus:outline-none transition duration-300 ease-in-out font-bold text-black text-sm placeholder:text-gray-500"
                    value={couponValue}
                    onChange={(e) =>
                      setCouponValue(e.target.value.trim()?.toUpperCase())
                    }
                    disabled={couponApplied}
                  />
                  <button
                    className="bg-primary text-white rounded-lg px-4 py-2 hover:bg-primary transition duration-300 ease-in-out disabled:opacity-50 cursor-pointer text-center font-bold disabled:cursor-not-allowed"
                    onClick={applyCoupon}
                    disabled={couponApplied}
                  >
                    Apply
                  </button>
                </div>

                <div className="flex justify-between items-center mb-2 mt-4">
                  <div className="text-black font-semibold">Order Value</div>
                  <div className="text-gray-800">
                    {parseInt(total.toFixed()) + parseInt(discount.toFixed())}{" "}
                    PKR
                  </div>
                </div>

                {/*discount due to coupon */}
                {couponApplied && (
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-black font-semibold">
                      Coupon Discount{" "}
                      <span className="text-primary text-xs">
                        ({GetDiscounts()?.couponDiscountPercentage * 100}%)
                      </span>
                    </div>
                    <div className="text-gray-800">
                      {GetDiscounts()?.couponDiscount.toFixed()} PKR
                    </div>
                  </div>
                )}

                {/*discount due to promotional discount */}
                {GetDiscounts()?.promotionalDiscount > 0 && (
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-black font-semibold">
                      Promotional Discount{" "}
                      <span className="text-primary text-xs">
                        (
                        {Number(GetDiscounts()?.promotionalDiscountPercentage) *
                          100 || 0}
                        %)
                      </span>
                    </div>
                    <div className="text-gray-800">
                      {GetDiscounts()?.promotionalDiscount.toFixed()} PKR
                    </div>
                  </div>
                )}

                {/*discount due to additional discount */}
                {GetDiscounts()?.additionalDiscount > 0 && (
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-black font-semibold">
                      Additional Discount{" "}
                      <span className="text-primary text-xs">
                        ({GetDiscounts()?.additionalDiscountPercentage * 100}%)
                      </span>
                    </div>
                    <div className="text-gray-800">
                      {GetDiscounts()?.additionalDiscount.toFixed()} PKR
                    </div>
                  </div>
                )}

                <div className="flex justify-between items-center mb-2">
                  <div className="text-black font-semibold">Total Discount</div>
                  <div className="text-gray-800">{discount.toFixed()} PKR</div>
                </div>

                <div className="border-b-2 border-red-300 mb-2"></div>

                <div className="flex justify-between items-center mb-2">
                  <div className="text-black font-semibold text-xl">Total</div>
                  <div className={`text-primary font-bold text-xl`}>
                    {total.toFixed()}
                    PKR
                  </div>
                </div>

                <p className="text-xs text-gray-500 text-center justify-center">
                  This is a One-Time payment payable completely upfront through
                  Credit/Debit Card, Bank Transfer, JazzCash, or EasyPaisa
                </p>

                <div className="bg-white rounded-lg shadow-xl flex justify-between items-center my-4">
                  <input
                    placeholder="Enter Email "
                    className="w-3/4 px-4 py-2 rounded-lg focus:border-none focus:outline-none transition duration-300 ease-in-out font-bold text-black text-sm placeholder:text-gray-500"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value.trim()?.toLowerCase())
                    }
                  />
                </div>

                <button
                  className="bg-primary text-white rounded-lg px-4 py-2 hover:bg-primary-100 transition duration-300 ease-in-out mt-4 hover:shadow-xl cursor-pointer text-center font-bold"
                  onClick={() => {
                    if (email?.trim()?.length === 0) {
                      message.error("Please enter a valid email");
                      return;
                    }

                    setProcessing(true);
                  }}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-[90%] md:w-3/4 mx-auto mt-8 md:mt-20 flex flex-col md:flex-row justify-between gap-8">
              <div className="w-full md:w-3/4 mx-auto">
                <div className="flex flex-start items-center gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-red-500 mb-2cursor-pointer"
                    onClick={() => setProcessing(false)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
                    />
                  </svg>

                  <h1 className="text-primary text-5xl font-bold">Payment</h1>
                </div>
                <PaymentMethods paymentMethod={paymentMethod} />
                <div className="flex flex-col gap-4 mt-8 text-black">
                  By checking out you agree with our Terms of Service. We will
                  process your personal data for the fulfillment of your order
                  and other purposes as per our Privacy Policy.{" "}
                </div>
              </div>
              <div className="w-full md:w-1/4 mx-auto">
                <div className="flex flex-col bg-white p-4 rounded-xl shadow-xl border border-gray-50">
                  <div className="text-gray-800">Select Payment Method</div>

                  <div className="flex flex-col flex-start mb-4 mt-4">
                    <Radio.Group
                      onChange={(e) => {
                        setPaymentMethod(e.target.value);
                        onChangeRadio(e);
                      }}
                      value={paymentMethod}
                      className="text-black"
                    >
                      <div className="text-black">
                        {radio("Bank Transfer")}
                        {radio("Easypaisa")}
                        {radio("JazzCash")}
                      </div>
                    </Radio.Group>
                  </div>

                  <div className="flex justify-between items-center mb-2 mt-4">
                    <div className="text-black font-semibold">Order Value</div>
                    <div className="text-gray-800">
                      {parseInt(total.toFixed()) + parseInt(discount.toFixed())}{" "}
                      PKR
                    </div>
                  </div>
                  {couponApplied && (
                    <div className="flex justify-between items-center mb-2">
                      <div className="text-black font-semibold">
                        Coupon Discount{" "}
                        <span className="text-primary text-xs">
                          ({GetDiscounts()?.couponDiscountPercentage * 100}%)
                        </span>
                      </div>
                      <div className="text-gray-800">
                        {GetDiscounts()?.couponDiscount.toFixed()} PKR
                      </div>
                    </div>
                  )}

                  {/*discount due to promotional discount */}
                  {GetDiscounts()?.promotionalDiscount > 0 && (
                    <div className="flex justify-between items-center mb-2">
                      <div className="text-black font-semibold">
                        Promotional Discount{" "}
                        <span className="text-primary text-xs">
                          (
                          {Number(
                            GetDiscounts()?.promotionalDiscountPercentage
                          ) * 100}
                          %)
                        </span>
                      </div>
                      <div className="text-gray-800">
                        {GetDiscounts()?.promotionalDiscount.toFixed()} PKR
                      </div>
                    </div>
                  )}

                  {/*discount due to additional discount */}
                  {GetDiscounts()?.additionalDiscount > 0 && (
                    <div className="flex justify-between items-center mb-2">
                      <div className="text-black font-semibold">
                        Additional Discount{" "}
                        <span className="text-primary text-xs">
                          ({GetDiscounts()?.additionalDiscountPercentage * 100}
                          %)
                        </span>
                      </div>
                      <div className="text-gray-800">
                        {GetDiscounts()?.additionalDiscount.toFixed()} PKR
                      </div>
                    </div>
                  )}

                  <div className="flex justify-between items-center mb-2">
                    <div className="text-black font-semibold">
                      Total Discount
                    </div>
                    <div className="text-gray-800">
                      {discount.toFixed()} PKR
                    </div>
                  </div>

                  <div className="border-b-2 border-red-300 mb-2"></div>

                  <div className="flex justify-between items-center mb-2">
                    <div className="text-black font-semibold text-xl">
                      Total
                    </div>
                    <div className={`text-primary font-bold text-xl`}>
                      {total.toFixed()}
                      PKR
                    </div>
                  </div>

                  <p className="text-xs text-gray-500 text-center justify-center mt-2 mb-2">
                    This is a One-Time payment payable completely upfront
                    through Credit/Debit Card, Bank Transfer, JazzCash, or
                    EasyPaisa
                  </p>

                  {paymentProofbase64 && (
                    <div className="flex justify-between items-center mb-2 p-2 rounded-lg">
                      <img
                        src={paymentProofbase64}
                        alt="Payment Proof"
                        className="w-full rounded-lg object-cover h-32"
                      />
                    </div>
                  )}

                  <input
                    type="file"
                    className="border-2 border-red-500 p-2 rounded-lg"
                    hidden
                    id="screen"
                    onChange={UploadPaymentProof}
                  />
                  <button
                    className="bg-primary font-bold text-white px-4 py-2 rounded-lg hover:bg-primary transition duration-300 ease-in-out"
                    onClick={() => {
                      document.getElementById("screen").click();
                    }}
                  >
                    Upload Paid Receipt
                  </button>

                  <button
                    className="bg-primary font-bold text-white px-4 py-2 rounded-lg hover:bg-primary transition duration-300 ease-in-out mt-4 disabled:opacity-50 disabled:cursor-not-allowed "
                    onClick={RequestSubscription}
                    disabled={disabled}
                    style={{
                      opacity: disabled ? 0.5 : 1,
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {" "}
                    Complete Order
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="w-3/4 mx-auto mt-20 flex flex-col justify-between gap-8">
              <div className="font-bold text-2xl text-primary">How to Pay</div>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-3/4 rounded shadow-xl">
                  <img src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/StaticImages/PaymentTutorial.png" />
                </div>
                <iframe
                  className="w-full md:w-1/4 rounded shadow-xl"
                  src={paymentVideo}
                  title="How to purchase PreMed.PK Subscription?"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
             */}
          </>
        )}
      </div>
    </>
  );
};

const CopyIcon = (clickFunction) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-clipboard w-5 h-5"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#000000"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      onClick={() => {
        clickFunction.clickFunction();
        toast.success("Copied to Clipboard");
      }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
      <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
    </svg>
  );
};
function radio(name) {
  return (
    <div>
      <Radio value={name}>
        <div className="">
          <div className="">{name}</div>
        </div>
      </Radio>
    </div>
  );
}
export default CheckOutCart;
