
import { ApiInterface } from "interfaces/global-interfaces";

export const questionapis: ApiInterface[] = [
    {
        url: "/api/questions/add",
        method: "POST",
        name: "addQuestion",        
    },
    {
        url: "/api/questions/get-question-by-id/:id",
        method: "GET",
        name: "getQuestionById",
    },
    {
        url: "/api/questions/findDeckByQuestionId/:id",
        method: "GET",
        name: "findDeckByQuestionId",
    },
    {
        url: "/api/questions/edit/:id",
        method: "POST",
        name: "editQuestion",
    },
    {
        url: "/questions/edit/:id",
        method: "POST",
        name: "editQuestion",
    },
    {
        url: "/api/questions/getAuditedQuestionHistory/:id",
        method: "GET",
        name: "getAuditQuestionById",
    },
    {
        url: "/api/questions/getAuditedQuestions",
        method: "GET",
        name: "getAuditedQuestions",
    },
    {
        url: "/api/questions/getQuestionsStats",
        method: "GET",
        name: "getQuestionsStats",
    },
    {
        url: "/api/questions/getAddedQuestionsStats",
        method: "GET",
        name: "getAddedQuestionsStats",
    },
    {
        url: "/api/questions/add-question-to-quiz-builder",
        method: "POST",
        name: "addQuestionToQuizBuilder",
    },
    {
        url: "/api/questions/remove-question-from-quiz-builder",
        method: "POST",
        name: "removeQuestionToQuizBuilder",
    },
    {
        url: "/api/questions/getQuestionsByFilter",
        method: "GET",
        name: "getFilteredQuestions",
    },
    { 
        url: "/api/question-statistics/other-question-statistics",
        method: "GET",
        name: "OtherStatistics"
    },
    { 
        url: "/api/questions/reports",
        method: "GET",
        name: "getAuditQuestionsReport"
    },
    { 
        url: "/api/questions/get-pending-question-reports-by-question-id/:id",
        method: "GET",
        name: "getPendingReportsByQuestionId"
    },
    { 
        url: "/api/question-statistics/get-each-author-statistics/:author",
        method: "GET",
        name: "getEachAuthorStatistics"
    },
]

