import { Divider, message } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import useGetData from "shared-components/custom-hook/get-data";
import Loader from "shared-components/Loader";
import BackButton from "shared-components/ui/back-button";
import { CopyIcon } from "shared-components/ui/Icons";

interface ReportsResponse {
  name: string; // Campaign name
}

const categories = {
  premed: "Premed",
  medschool: "Medschool",
  preEngineering: "Pre-Engineering",
};

const categorizedUrls = {
  premed: [
    {
      heading: "Notes",
      url: "https://premed.pk/notes/entrance-exam/pre-medical",
    },
    {
      heading: "Essential Stuff",
      url: "https://premed.pk/essential-stuff/entrance-exam/pre-medical",
    },
    {
      heading: "Shortlistings",
      url: "https://premed.pk/shortlistings/entrance-exam/pre-medical",
    },
    {
      heading: "Guide",
      url: "https://premed.pk/guides/entrance-exam/pre-medical",
    },
    {
      heading: "Cheatsheets",
      url: "https://premed.pk/cheatsheets/entrance-exam/pre-medical",
    },
  ],
  medschool: [
    {
      heading: "Guide",
      url: "https://www.medschool.pk/guides/undergraduate/medschool",
    },
    {
      heading: "USMLE Guide",
      url: "https://www.medschool.pk/usmle-guides/undergraduate/medschool",
    },
  ],
  preEngineering: [
    {
      heading: "Notes",
      url: "https://engineering.parho.io/notes/entrance-exam/pre-engineering",
    },
    {
      heading: "Essential Stuff",
      url: "https://engineering.parho.io/essential-stuff/entrance-exam/pre-engineering",
    },
    {
      heading: "Shortlistings",
      url: "https://engineering.parho.io/shortlistings/entrance-exam/pre-engineering",
    },
    {
      heading: "Guide",
      url: "https://engineering.parho.io/guides/entrance-exam/pre-engineering",
    },
    {
      heading: "Cheatsheets",
      url: "https://engineering.parho.io/cheatsheets/entrance-exam/pre-engineering",
    },
  ],
};

const CategorySection = ({ categoryName, items, campaignName }) => (
  <div className="mb-8 shadow-md p-5">
    <h2 className="text-xl font-bold mb-4">{categoryName}</h2>
    <div className="space-y-4">
      {items.map((item, index) => (
        <div
          key={index}
          className="p-4 bg-white  rounded-lg border border-gray-200"
        >
          <h6 className="text-sm font-medium mb-2">
            {item.heading}
            <button
              onClick={() => {
                navigator.clipboard.writeText(`${item.url}/${campaignName}`);
                message.success("URL copy to clipboard!");
              }}
              className="text-gray-500 hover:text-black ml-4"
            >
              <CopyIcon />
            </button>
          </h6>
          <a
            href={`${item.url}/${campaignName}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            {`${item.url}/${campaignName}`}
          </a>
        </div>
      ))}
    </div>
  </div>
);

const MarketingCampaignUrls = () => {
  const { marketingCampaignId } = useParams<{ marketingCampaignId: string }>();
  const { data, loading } = useGetData<ReportsResponse>(
    "getMarketingCampaignById",
    marketingCampaignId
  );

  return (
    <div className="shadow-md p-5">
      <Loader loading={loading} />
      <div className="flex gap-4 my-2">
        <BackButton />
        <p className="text-xl font-bold">
          Marketing campaign url of <span className="text-primary"> {data?.name}</span>{" "}
        </p>
      </div>

      {data?.name &&
        Object.entries(categorizedUrls).map(([key, urls]) => (
          <>
            <CategorySection
              key={key}
              categoryName={categories[key]}
              items={urls}
              campaignName={data.name}
            />
            <Divider />
          </>
        ))}
    </div>
  );
};

export default MarketingCampaignUrls;
