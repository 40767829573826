import React, { useEffect, useMemo, useState } from "react";

import { Table, Input, Button, type TableProps } from "antd";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "(apis)/api-interface/api-handler";
import "./styles.css";
import Loading from "shared-components/ui/Loading";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { ManageIcon } from "shared-components/ui/Icons";

export default function ManageExperts() {
  const navigate = useNavigate();
  const [Logs, SetLogs] = useState<Record<string, any> | null>(null);
  const [selectedState, setSelectedState] = useState("Biology");
  const [searchTerm, setSearchTerm] = useState("");

  const GetLogList = async () => {
    const Response = await apiRequest("ExpertLogs", { subject: selectedState });
    SetLogs(Response.data);
  };

  useEffect(() => {
    GetLogList();
  }, [selectedState]);

  const tableData = useMemo(
    () =>
      Logs
        ? Object?.keys(Logs)?.map((email, index) => ({
          key: index,
          sr: index + 1,
          email: email,
          upload: Logs[email].Count,
        }))
        : [],
    [Logs]
  );

  const filteredTableData = useMemo(
    () =>
      tableData.filter((item) =>
        item.email.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [tableData, searchTerm]
  );

  const Subjects = ["Biology", "Chemistry", "Physics", "English", "Logical Reasoning"]


  return (
    <>
      <div className="w-full md:w-[90%] mx-auto">

        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Admin Subjective Doubts</h2>
        </div>
        <div className="flex items-center gap-2 flex-wrap">          
          {Subjects.map((subject, index) => (
            <Buttons key={index} buttonText={subject} handleClick={() => {
              setSelectedState(subject)
            }} selectedState={selectedState} subject={subject} />
          ))}
        </div>
        <Input
            placeholder="Search by email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mt-4 w-full md:w-1/2 mx-auto"
          />
      
      {!Logs && <Loading />}

      <section className="my-5 antialiased">
        <div className="mx-auto">
          <div className=" bg-white shadow-md sm:rounded-lg">
            <div className="overflow-x-auto">
              <table className="w-full mb-20 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 py-4">
                      SR No.
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Email
                    </th>
                    <th scope="col" className="px-4 py-4 text-left">
                      Solved
                    </th>
                    <th scope="col" className="px-4 py-3 text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTableData.map((item, index) => (
                    <tr className="border-b" key={item?.key}>
                      <td className="px-4 py-4">{index + 1}</td>
                      <td className="px-4 py-4">{item.email}</td>
                      <td className="px-4 py-4">{item.upload}</td>
                      <td className="px-4 py-3 text-center">
                        <div className="flex items-center justify-center gap-4">
                          <ManageIcon
                            size='w-6 h-6 hover:cursor-pointer hover:text-primary'
                            onClick={() => {
                              navigate(`/expert-dashboard/${selectedState}?email=${item.email}`)
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
}


const Buttons = ({ buttonText, handleClick, selectedState, subject }) => {
  return <>
    {selectedState === subject && <ButtonFill handleClick={handleClick}>{buttonText}</ButtonFill>}
    {selectedState !== subject && <ButtonOutlined handleClick={handleClick}>{buttonText}</ButtonOutlined>}
  </>
}