import React, { useEffect, useState } from "react";
import { Select, DatePicker, DatePickerProps } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import {
  MAX_COMMISSION,
  SCHEME_TYPES,
  SCHEME_TYPES_OPTION,
} from "shared-pages/latest-commission/utils";

const TargetModal = ({ modal, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    userIds: [],
    target: "",
    cashPrize: "",
    startDate: null,
    endDate: null,
    userType: null,
    limitTime: "1 Month",
    commissionPercentageForSupervisor: 0,
    schemeType: SCHEME_TYPES.REFERRAL,
  };
  const [formData, setFormData] = useState(initialState);

  const resetForm = () => {
    setFormData(initialState);
    closeModal();
  };

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = (formData) => {
    const { target, cashPrize, commissionPercentageForSupervisor } = formData;

    if (!target || target <= 0) {
      toast.error("Please enter a target value.");
      return false;
    }
    if (!cashPrize || cashPrize <= 0) {
      toast.error("Please enter a cash prize amount.");
      return false;
    }
    if (
      !commissionPercentageForSupervisor ||
      commissionPercentageForSupervisor < 0 ||
      commissionPercentageForSupervisor > MAX_COMMISSION
    ) {
      toast.error("Invalid Commission");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!validateForm(formData)) {
      return;
    }

    try {
      setIsLoading(true);
      const body = formData;

      const response = await CommissionAPI("createTarget", [], body);

      if (response?.data?.success) {
        toast.success("Target created successfully.");
        resetForm();
      }
    } catch (error) {
      toast.error("Failed to create target.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={` ${
        modal?.isModalOpen ? "" : "hidden"
      } fixed inset-0 z-50 flex items-center justify-center bg-gray-200/40 backdrop-blur-sm`}
    >
      <Toaster />
      <div className="bg-white rounded-lg p-8 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">Create Target</h2>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-2 space-x-3 w-full">
            <CustomDropDown
              label="Select Scheme Type"
              options={SCHEME_TYPES_OPTION}
              onSelect={(value, _) => {
                setFormData({
                  ...formData,
                  schemeType: value,
                });
              }}
              width="w-full"
              value={formData.schemeType}
              isError={false}
              errorMessage={""}
              boldLabel={true}
            />
          </div>

          {/* Target */}
          <label className="block mb-2 font-semibold">Target</label>
          <input
            type="number"
            name="target"
            value={formData.target}
            onChange={handleInputChange}
            placeholder="Enter target"
            className="w-full mb-4 p-2 border rounded"
          />

          {/* Cash Prize */}
          <label className="block mb-2 font-semibold">Cash Prize</label>
          <input
            type="number"
            name="cashPrize"
            value={formData.cashPrize}
            onChange={handleInputChange}
            placeholder="Enter cash prize"
            className="w-full mb-4 p-2 border rounded"
          />
          {/* Cash Prize */}
          <label className="block mb-2 font-semibold">
            Supervisor Commission (0-{MAX_COMMISSION})
          </label>
          <input
            type="number"
            name="commissionPercentageForSupervisor"
            value={formData.commissionPercentageForSupervisor}
            onChange={handleInputChange}
            placeholder="Enter commission percentage"
            className="w-full mb-4 p-2 border rounded"
          />

          <div className="flex items-center mb-2 space-x-3 w-full">
            <CustomDropDown
              label="Select Limit"
              options={["1 Month", "No Limit"]}
              onSelect={(value, name) => {}}
              width="w-full"
              value={formData.limitTime}
              isError={false}
              errorMessage={""}
              disabled
              boldLabel={true}
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center space-x-4">
            <ButtonOutlined handleClick={() => resetForm()}>
              Cancel
            </ButtonOutlined>
            <ButtonFill type="Submit" disabled={isLoading}>
              {isLoading ? "Loading..." : "Submit"}
            </ButtonFill>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TargetModal;
