import React from "react";
import { useState, useEffect, useContext } from "react";
// @ts-ignore
import { useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";
import Loading from "shared-components/ui/Loading";
import { ButtonOutlined } from "shared-components/ui/CustomButtons";

export default function ExpertDashboardSubject() {
  const { subject } = useParams();
  const { user } = useContext(UserContext);
  const [Doubts, setDoubts] = useState([]);
  const [loading, setloading] = useState<boolean>(false);
  //email from params
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  console.log(email)
  const fetchDoubts = async () => {
    try {
      var subjectLowercase = subject?.toLowerCase();
      if (subjectLowercase === "logical reasoning") {
        subjectLowercase = subjectLowercase.replace(/\s+/g, "-");
      }

      setloading(true);
      const response = await apiRequest("GetExpertSolvedDoubts", {
        subject: subjectLowercase,
        username: email ? email : user.username,
      });
      const data = response.data;
      setDoubts(data.result);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error fetching doubts:", error);
    }
  };

  useEffect(() => {
    fetchDoubts();
  }, [user.isloggedin, email]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full md:w-[90%] mx-auto pb-20 px-5">

        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Subjective Expert Doubts</h2>
          <div className="flex items-center flex-end gap-2">
            <ButtonOutlined
              handleClick={() => {
              }}
            >
              Questions solved by {email ? email : user.username}
            </ButtonOutlined>
          </div>
        </div>

        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className=" bg-white shadow-md sm:rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        SR No.
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Question Text
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Tags
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doubts && Doubts.length === 0 ? (
                      <div className="container text-center my-4">
                        <h5>No Doubts Solved Yet</h5>
                      </div>
                    ) : (
                      <>
                        {Doubts.map((doubt: any, index: number) => (
                          <tr className="border-b" key={doubt?._id}>
                            <td className="px-4 py-4">{index + 1}</td>
                            <td className="px-4 py-4 text-left"><div dangerouslySetInnerHTML={{ __html: doubt?.QuestionText?.length > 100 ? doubt?.QuestionText?.slice(0, 100) + "..." : doubt?.QuestionText }} /></td>
                            <td className="px-4 py-4 flex flex-wrap gap-2">{doubt?.Tags?.map((tag: any) => (
                              <div className="bg-primary px-2 py-1 rounded border font-bold border-primary mr-2 text-sm text-white">
                                {tag.name}
                              </div>
                            ))}</td>
                            <td className="px-4 py-3 text-center">
                              <div className="flex items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-500 hover:text-rose-700"
                                onClick={() => {
                                  window.open(`${doubt?.videoLink}`, "_blank");
                                }}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                                </svg>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
