import { useState } from "react";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import {
  formatNumberWithCommas,
  renderStatusChips,
  ROLES_OPTION,
} from "../../../shared-pages/latest-commission/utils";
import { renderChips } from "../../../shared-pages/latest-commission/utils";

const ReimbursementTable = ({ reimbursements, isPromoCode = false }) => {
  const [selectStatus, setSelectStatus] = useState("All");
  const [selectRole, setSelectRole] = useState("All");

  const handleSelectStatusChange = (selectedStatus) => {
    setSelectStatus(selectedStatus);
  };

  const filteredReimbursements = reimbursements.filter(
    (reimbursement) =>
      (selectStatus === "All" || reimbursement.status === selectStatus) &&
      (selectRole === "All" || selectRole === reimbursement?.role)
  );

  return (
    <>
      {" "}
      <div className="bg-white shadow-md sm:rounded-lg">
        <div className="flex items-end p-4 space-x-3">
          <CustomDropDown
            label="Status"
            options={["All", "Pending", "Approved", "Rejected"]}
            onSelect={(selectedStatus) =>
              handleSelectStatusChange(selectedStatus.toString())
            }
            width="min-w-[12rem]"
            value={selectStatus}
          />
          <CustomDropDown
            label="Role"
            options={ROLES_OPTION}
            onSelect={(selectedStatus) => setSelectRole(selectedStatus)}
            width="min-w-[12rem]"
            value={selectRole}
          />
        </div>
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Name
              </th>
              <th scope="col" className="px-4 py-4">
                Status
              </th>
              <th scope="col" className="px-4 py-4">
                Role
              </th>
              {isPromoCode ? (
                <>
                  <th scope="col" className="px-4 py-4">
                    Description
                  </th>
                </>
              ) : (
                <>
                  {" "}
                  <th scope="col" className="px-4 py-4">
                    Date Requested
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Amount Requested
                  </th>
                </>
              )}{" "}
              <th scope="col" className="px-4 py-4">
                Paid Amount
              </th>{" "}
              <th scope="col" className="px-4 py-4">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredReimbursements &&
              filteredReimbursements?.length > 0 &&
              filteredReimbursements?.map((reimbursement) => (
                <tr className="border-b" key={reimbursement?._id}>
                  <td className="px-4 py-3">{reimbursement?.name}</td>

                  <td className="px-4 py-3">
                    {renderStatusChips(reimbursement?.status)}
                  </td>
                  <td className="px-4 py-3">
                    {renderChips(reimbursement.role)}
                  </td>
                  {isPromoCode ? (
                    <>
                      {" "}
                      <td className="px-4 py-3">
                        {reimbursement?.promoCodeDetails?.description}
                      </td>{" "}
                    </>
                  ) : (
                    <>
                      <td className="px-4 py-3">
                        {new Date(
                          reimbursement?.dateRequested
                        )?.toLocaleDateString()}
                      </td>{" "}
                      <td className="px-4 py-3">
                        {formatNumberWithCommas(reimbursement?.requestedAmount)}
                      </td>
                    </>
                  )}

                  <td className="px-4 py-3">
                    {formatNumberWithCommas(reimbursement?.paidAmount | 0)}
                  </td>
                  <td className="px-4 py-3">
                    {new Date(
                      reimbursement?.approvedDate
                    )?.toLocaleDateString()}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReimbursementTable;
