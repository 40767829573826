import { apiRequest } from '(apis)/api-interface/api-handler';
import { Divider } from 'antd';
import React from 'react'
import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ManageIcon } from 'shared-components/ui/Icons';
import { formatNumberWithCommas } from 'shared-pages/latest-commission/utils';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

export default function AuditQuestionStats() {
  const [loading, setLoading] = useState<boolean>(false);
  const [topTenAttempted, setTopTenAttempted] = useState([]);
  const [difficultyStats, setDifficultyStats] = useState([]);
  const [Information, setInformation] = useState([{
    label: "Total Questions Reported",
    value1: 0,
  },
  {
    label: "Total Questions Reported Today",
    value1: 0,
  },
  {
    label: "Total Questions Reported This Week",
    value1: 0,
  },
  {
    label: "Total Questions Reported This Month",
    value1: 0,
  },
  {
    label: "Total Questions Reports Fixed Today",
    value1: 0,
  },
  {
    label: "Total Questions Reports Fixed This Week",
    value1: 0,
  }
  ])

  const [AttemptedQuestions, setAttemptedQuestions] = useState([
    {
      label: "Total Questions Options Chosen",
      value1: 0,
    },
    {
      label: "Total Questions Options Chosen Today",
      value1: 0,
    },
    {
      label: "Total Questions",
      value1: 0,
    }
  ])
  const [ReportedQuestions, setReportedQuestions] = useState([])
  const [ReportStatistics, setReportStatistics] = useState([])
  const [SubjectiveDoubtsCount, setSubjectiveDoubtsCount] = useState([])

  const [YearWiseAddEdit, setYearWiseAddEdit] = useState([])
  const [MonthWiseAddEdit, setMonthWiseAddEdit] = useState([])
  const [DayWiseAddEdit, setDayWiseAddEdit] = useState([])
  const [AuthorsAndStats, setAuthorsAndStats] = useState([])
  const [BoardWiseStats, setBoardWiseStats] = useState([])
  const filterOutliers = (data, key) => {
    const values = data.map(item => item[key]);
    const q1 = values.sort((a, b) => a - b)[Math.floor(values.length / 4)];
    const q3 = values.sort((a, b) => a - b)[Math.floor(values.length * 3 / 4)];
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    return data.filter(item => item[key] >= lowerBound && item[key] <= upperBound);
  };

  const filteredYearWiseAddEdit = filterOutliers(YearWiseAddEdit, 'adds');
  const filteredMonthWiseAddEdit = filterOutliers(MonthWiseAddEdit, 'adds');
  const filteredDayWiseAddEdit = filterOutliers(DayWiseAddEdit, 'adds');

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await apiRequest("OtherStatistics");
      if (res?.data?.success) {
        const sortedTopTen = res?.data?.Top10AttemptedQuestions.sort((a, b) => b.count - a.count);
        const sortedDifficultyStats = res?.data?.DifficultyStats
        setTopTenAttempted(sortedTopTen);
        setDifficultyStats(sortedDifficultyStats);
        setReportedQuestions(res?.data?.MostReportedQuestions?.slice(0, 5))
        setReportStatistics(res?.data?.ReportStatusCounts)
        setYearWiseAddEdit(res?.data?.yearWiseAddVSEdit)
        setMonthWiseAddEdit(res?.data?.monthWiseAddVSEdit)
        setDayWiseAddEdit(res?.data?.dayWiseAddVSEdit)
        setAuthorsAndStats(res?.data?.AuthorsAndStats)
        setInformation([{
          label: "Total Questions Reported",
          value1: res?.data?.totalQuestionsReported,
        },
        {
          label: "Total Questions Reported Today",
          value1: res?.data?.totalQuestionsReportedToday,
        },
        {
          label: "Total Questions Reported This Week",
          value1: res?.data?.totalQuestionsReportedThisWeek,
        },
        {
          label: "Total Questions Reported This Month",
          value1: res?.data?.totalQuestionsReportedThisMonth,
        },
        {
          label: "Total Questions Reports Fixed Today",
          value1: res?.data?.totalQuestionsReportedTodayFixed,
        },
        {
          label: "Total Questions Reports Fixed This Week",
          value1: res?.data?.totalQuestionsReportedThisWeekFixed,
        },
        {
          label: "Total Questions Reports Fixed This Month",
          value1: res?.data?.totalQuestionsReportedThisMonthFixed,
        },

        ])
        setAttemptedQuestions([{
          label: "Total Questions Attempted",
          value1: res?.data?.totalQuestionsOptionsChosen,
        },
        {
          label: "Total Questions Attempted Today",
          value1: res?.data?.totalQuestionsOptionsChosenToday,
        },
        {
          label: "Total Questions",
          value1: res?.data?.TotalQuestions,
        }
        ])
        setSubjectiveDoubtsCount(res?.data?.SubjectiveDoubtsCount)
        setBoardWiseStats(res?.data?.boardWiseQuestions?.filter(board => board?._id !== "" || board?._id !== "-"))
      } else {
        toast.error(res?.data?.message)
      }
      console.log("API Response: ", res);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-8">Questions Statistics Dashboard</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>


          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Difficulty Stats</h2>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              {difficultyStats.map((difficulty, index) => (
                <RenderStatCard
                  item={{
                    label: difficulty?.difficulty,
                    value1: difficulty?.count,
                  }}
                  key={index}
                />
              ))}
            </div>
          </div>

          <div className='mt-10'>
            <h2 className="font-bold text-xl text-gray-800 mb-10">Reported Questions</h2>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              {Information.map((item, index) => (
                <RenderStatCard
                  item={item}
                  key={index}
                />
              ))}
            </div>

            <h2 className="font-bold text-xl text-gray-800 mb-10">Reported Questions</h2>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              {ReportStatistics.map((item, index) => (
                <RenderStatCard
                  item={{
                    label: item?._id,
                    value1: item?.count,
                  }}
                  key={index}
                />
              ))}
            </div>

          </div>

          <div className='mt-10'>
            <h2 className="font-bold text-xl text-gray-800 mb-10">Attempted Questions</h2>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              {AttemptedQuestions.map((item, index) => (
                <RenderStatCard
                  item={item}
                  key={index}
                />
              ))}
            </div>
          </div>

          <div className='mt-10'>
            <h2 className="font-bold text-xl text-gray-800 mb-10">Subjective Doubts</h2>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              {SubjectiveDoubtsCount.map((item, index) => (
                <RenderStatCard
                  item={{
                    label: item?.subject,
                    value1: item?.SolvedCount,
                    value2: item?.SubmittedCount,
                  }}
                  type="subjective"
                  key={index}
                />
              ))}
            </div>
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Year Wise Add vs Edit</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredYearWiseAddEdit}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="adds" stroke="#8884d8" />
                <Line type="monotone" dataKey="edits" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Month Wise Add vs Edit</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredMonthWiseAddEdit}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monthYear" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="adds" stroke="#8884d8" />
                <Line type="monotone" dataKey="edits" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-xl text-gray-800 mb-10">Day Wise Add vs Edit</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredDayWiseAddEdit}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="adds" stroke="#8884d8" />
                <Line type="monotone" dataKey="edits" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}

      <div className="mt-10">
        <h2 className="font-bold text-xl text-gray-800 mb-10">Board Wise Stats</h2>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          {BoardWiseStats.map((board, index) => (
            <RenderStatCard
              item={{
                label: board?._id,
                value1: board?.count,
              }}
              key={index}
            />
          ))}
        </div>
      </div>

      <div className="mt-10">
        <h2 className="font-bold text-xl text-gray-800 mb-10">Top 10 Attempted Questions</h2>
        <table className="min-w-full">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left">Question ID</th>
              <th className="py-3 px-4 text-left">Attempts</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {topTenAttempted.map((question, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="py-3 px-4">{question.questionId}</td>
                <td className="py-3 px-4">{question.count}</td>
                <td className="py-3 px-4">
                  <a href={`/editquestion/${question.questionId}`} className="text-blue-500 hover:text-blue-700">
                    <ManageIcon size='w-6 h-6' />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-10">
        <h2 className="font-bold text-xl text-gray-800 mb-10">Authors and Stats</h2>
        <table className="min-w-full">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left">Author</th>
              <th className="py-3 px-4 text-left">Count</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {AuthorsAndStats.map((question, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="border px-4 py-3">{question?.Author}</td>
                <td className="border px-4 py-3">{question?.count}</td>
                <td className="border px-4 py-3 flex justify-center items-center">
                  {/* <a href={`/author/${question?.Author}`}><ManageIcon size='w-6 h-6' /></a> */}
                  <div className='cursor-pointer' onClick={() => {
                    window.open(`/author/${question?.Author}`, '_blank');
                  }}>
                    <ManageIcon size='w-6 h-6' />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      <div className='mt-10'>
        <h2 className="font-bold text-xl text-gray-800 mb-10">Reported Questions</h2>
        <table className="min-w-full">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left">Question ID</th>
              <th className="py-3 px-4 text-left">Reported Count</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {ReportedQuestions.map((question, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="border px-4 py-3">{question?.questionId}</td>
                <td className="border px-4 py-3">{question?.totalReports}</td>
                <td className="border px-4 py-3 flex justify-center items-center">
                  <a href={`/editquestion/${question.questionId}`}><ManageIcon size='w-6 h-6' /></a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  )
}

const RenderStatCard = ({
  item,
  key,
  type = "objective"
}: {
  item: { label: string; value1: number; value2?: number };
  key: number;
  type?: string;
}) => {
  return (
    <div
      className="w-full p-2 cursor-pointer"
      key={key}
    >
      <div className="flex flex-col px-6 py-10 overflow-hidden bg-white  rounded-xl border-2 border-gray-300 duration-300 group transition-all ease-in-out min-h-24">
        <div className="flex flex-row justify-between items-center">
          <div className='flex flex-row items-center gap-2'>

            <p className='text-xl font-extrabold text-gray-500'>{item?.label}</p>
          </div>
        </div>
        <div className="flex flex-col my-2 gap-2 ">
          <h1>
            {type === "subjective" ? "Solved Questions" : "Questions:"}
            <span className=" ml-4  text-3xl font-bold text-gray-700 mt-16 group-hover:text-gray-50">
              {" "}
              {formatNumberWithCommas(item?.value1)}
            </span>
          </h1>
          {item.value2 && (
            <h1>
              {type === "subjective" ? "Available Questions" : "Added:"}
              <span className="ml-4  text-3xl font-bold text-gray-700 mt-16 group-hover:text-gray-50">
                {" "}
                {formatNumberWithCommas(item?.value2)}
              </span>
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};