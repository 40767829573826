import { Table } from "antd";
import { EssentialStuffData } from "./types";
import { ColumnsType } from "antd/es/table";

export const columns: ColumnsType<EssentialStuffData> = [
    {
      title: "Thumbnail",
      dataIndex: "imageUrl",
      key: "imageUrl",
    },
    {
      title: "Resource Name",
      dataIndex: "resourceName",
      key: "resourceName",
    },
    {
      title: "Resource Type",
      dataIndex: "resourceType",
      key: "resourceType",
    },
    {
      title: "Board",
      dataIndex: "board",
      key: "board",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
    },
    // {
    //   title: "Subject",
    //   dataIndex: "subject",
    //   key: "subject",
    // },
    // Table.EXPAND_COLUMN,
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    // Table.SELECTION_COLUMN,
    {
      title: "Is Free for Marketing",
      dataIndex: "isFreeForMarketing",
      key: "isFreeForMarketing",
      align: "center",
      width: 100,
    },
    {
      title: "IS ACTIVE",
      dataIndex: "isPublished",
      key: "isPublished",
      align: "center",
      width: 100,
    },
    {
      title: "ACTIONS",
      key: "actions",
      align: "center",
      dataIndex: "actions",
      width: 100,
    },
  ];
  