
import React from 'react';
import CustomBadges from '../../../shared-components/ui/CustomBadges';

const TableView = ({ order }) => {

    const GetTime = (date: Date) => {
      const d = new Date(date);
      return d.toLocaleTimeString();
    };
  
    return (
      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Date of Order
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Customer Name
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Coupon Used
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Order Status
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                DiscountAmount
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Total Amount
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                PaymentMethod
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b text-gray-800" key={order._id}>
              <td className="px-4 py-3">{GetTime(order.CreationDate)}</td>
              <td className="px-4 py-3">{order.UserName}</td>
              <td className="px-4 py-3">{order.CouponCode}</td>
              <td className={`px-4 py-3 text-center`}>
                {order.OrderStatus === "Pending" && (
                  <CustomBadges label={order.OrderStatus} type="warning" />
                )}
                {order.OrderStatus === "Accepted" && (
                  <CustomBadges label={order.OrderStatus} type="success" />
                )}
                {order.OrderStatus === "Denied" && (
                  <CustomBadges label={order.OrderStatus} type="light" />
                )}
              </td>
              <td className="px-4 py-3 text-center">
                {order.DiscountAmount}
              </td>
              <td className="px-4 py-3 text-center">
                {order.PaidAmount ? order.PaidAmount : 0}
              </td>
  
              <td className="px-4 py-3 text-center">
                {order.PaymentMethod}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
    )
  }
  
  
    export default TableView;