import {
  accessData,
  provinceData,
  uniYearData,
} from "constants/constantvalues";
import React, { useEffect, useState } from "react";
import GlobalForm from "shared-components/form-fields/global-form";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import { EssentialModalProps } from "./types";
import { ModuleAPI } from "(apis)/(shared-apis)/modules-apis";
const EssentialModal: React.FC<EssentialModalProps> = ({
  formik,
  institutesData,
  setIsEditMode,
  setShowModel,
  isEditMode,
  ShowModel,
  boardsData,
  loading,
  allSubTopicsData,
  modules
}) => {

  const formData = {
    fields: [
      ...(process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
        ? [
            {
              name: "universities",
              label: "Universities",
              type: "text",
              size: 4,
              mode: "multiple",
              formContent: "select",
              options: institutesData,
              require: true,
            },
            {
              name: "year",
              label: "Year",
              type: "text",
              size: 4,
              formContent: "select",
              options: uniYearData,
              require: true,
            },
            {
              name: "modules",
              label: "Modules",
              type: "text",
              size: 4,
              formContent: "select",
              options: modules,
              require: false,
            },
          ]
        : []),
      {
        name: "province",
        label: "Province",
        type: "text",
        size: 6,
        formContent: "select",
        options: provinceData,
      },
      {
        name: "board",
        label: "Board",
        type: "text",
        size: 6,
        formContent: "select",
        require: true,
        options:
          process.env.REACT_APP_CATEGORY !== "MEDSCHOOL"
            ? boardsData
            : institutesData,
      },
      {
        name: "access",
        label: "Access",
        type: "text",
        size: 6,
        formContent: "select",
        require: true,
        options: accessData,
      },
      {
        name: "resourceName",
        label: "Resource Name",
        type: "text",
        size: 6,
        formContent: "input",
        require: true,
      },
      {
        name: "resourceType",
        label: "Resource Type",
        type: "text",
        size: 6,
        formContent: "input",
        require: true,
      },
      {
        name: "image",
        label: "Thumbnail Image",
        size: 6,
        formContent: "image",
        require: true,
      },
      {
        name: "pdf",
        label: "PDF File",
        type: "text",
        size: 6,
        formContent: "pdf",
        require: true,
      },
      {
        name: "paginations",
        label: "Pagination",
        type: "text",
        size: 12,
        formContent: "array",
        items: [
          {
            name: "subTopic",
            label: "Sub Topic Name",
            type: "text",
            size: 6,
            formContent: "select",
            require: true,
            options: allSubTopicsData,
          },
          {
            name: "startPageNo",
            label: "Start Page Number",
            type: "number",
            size: 6,
            formContent: "input",
            require: true,
          },
          {
            name: "endPageNo",
            label: "End Page Number",
            type: "number",
            size: 6,
            formContent: "input",
          },
        ],
      },
      {
        name: "description",
        label: "Description",
        type: "text",
        size: 12,
        formContent: "textarea",
        require: false,
      },
    ],
  };

  return (
    <div>
      <CustomAntdModal
        isOpenModal={ShowModel}
        title={isEditMode ? `Edit Essential` : `Add Essential `}
        setIsOpenModal={setShowModel}
        formik={formik}
      >
        <GlobalForm formData={formData} formik={formik} />
      </CustomAntdModal>
    </div>
  );
};

export default EssentialModal;
