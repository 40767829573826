import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Bundles = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/bundles/all in one')
    }, [])
    
  return (
    <div>Bundles</div>
  )
}

export default Bundles