// import { closeCart } from '@/app/redux/slices/cartHandlerSlice'
import { Drawer } from "antd";
import { DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import styles from "./Card.module.css";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
export default function PricingCartModal({ openCart, setIsOpenCart }) {
  // const openCart = true
  // const openCart = useSelector((state) => state?.cart?.openCart)
  // const dispatch = useDispatch()
  const [cartItems, setCartItems] = useState([]);

  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    const currentCart = JSON.parse(localStorage.getItem("premedcart")) || [];
    setCartItems(currentCart);

    let total = 0;
    currentCart.forEach((item) => {
      total += item.PromotionalAmount;
    });
    setTotal(total);

    let discount = 0;
    currentCart.forEach((item) => {
      discount += item.TotalAmount - item.PromotionalAmount;
    });
    setDiscount(discount);
  }, [openCart]);

  const clearCart = () => {
    localStorage.removeItem("premedcart");
    setCartItems([]);
    setTotal(0);
    setDiscount(0);
    toast.success("Cart Cleared");
    setCartItems([]);
  };

  const removeItemFromCart = (id) => {
    let cartItems = JSON.parse(localStorage.getItem("premedcart")) || [];
    let newCart = cartItems.filter((item) => item._id !== id);

    //calculate total and discount
    let total = 0;
    newCart.forEach((item) => {
      total += item.PromotionalAmount;
    });
    setTotal(total);

    let discount = 0;
    newCart.forEach((item) => {
      discount += item.TotalAmount - item.PromotionalAmount;
    });
    setDiscount(discount);

    localStorage.setItem("premedcart", JSON.stringify(newCart));
    toast.success("Item Removed from Cart");
    setCartItems(newCart);
  };

  const router = useNavigate();

  return (
    <>
      <CustomAntdModal
        title="Cart"
        isOpenModal={openCart}
        setIsOpenModal={setIsOpenCart}
        isFooter={false}
      >
        <div
          className={`flex flex-col items-start justify-start w-full bg-white h-full`}
        >
          {/* <div className={`flex flex-col items-start justify-start ${inter.className} w-full bg-white h-full`}> */}
          <div className="flex flex-col items-center justify-center border-2 border-primary rounded-full w-full">
            <h1 className="text-lg bg-primary rounded-full p-1 w-full text-white text-center font-bold border-2 border-white">
              Cart
            </h1>
          </div>
          <div
            className="flex flex-row flex-end w-full mt-4 justify-end items-center cursor-pointer p-2 gap-1"
            onClick={clearCart}
          >
            <DeleteOutlined className="text-gray-500" size={20} />
            <div className="text-gray-500 cursor-pointer">Clear Cart</div>
          </div>
          <div className="w-full">
            {cartItems &&
              cartItems?.map((card, index) => (
                <div
                  className="relative rounded shadow-md flex flex-col justify-start w-full border-2 border-primary p-2 mb-2"
                  key={index}
                >
                  <div className="font-bold text-md text-black">
                    {card?.Category?.toUpperCase()} BUNDLE{" "}
                  </div>
                  <div className="font-bold text-md text-gray-200">
                    SKU: {card?.SKUName} {"("} {card?.Unit?.toUpperCase()} {")"}
                  </div>
                  <div className="text-md text-gray-600">
                    {card?.Description}{" "}
                  </div>
                  <div className="flex flex-row flex-wrap gap-1 text-xs text-gray-500 justify-start items-center w-full mt-4">
                    Courses:
                    {card?.courseIds &&
                      card?.courseIds?.map((course, index) => (
                        <div key={index} className="text-xs text-gray-400">
                          {course}
                        </div>
                      ))}
                  </div>
                  <div className="flex flex-row justify-between items-center w-full mt-4">
                    <div className="font-bold text-md text-black">
                      Duration:
                    </div>
                    <div className="font-bold text-md text-gray-700">
                      {card?.Package}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center w-full mt-4">
                    <div className="font-bold text-lg text-black">Price</div>
                    <div className="flex gap-2 text-xl text-primary items-end">
                      <div className="text-sm text-gray-500 line-through">
                        PKR. {card?.TotalAmount?.toFixed()}{" "}
                      </div>
                      <div className="text-lg font-bold text-gray-700">
                        {" "}
                        PKR. {card?.PromotionalAmount?.toFixed()}
                      </div>
                    </div>
                  </div>
                  <div
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => removeItemFromCart(card?._id)}
                  >
                    <CloseOutlined
                      className="text-gray-500 hover:text-red-500"
                      size={20}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-row justify-between w-full mt-4">
            <div>
              <div className="font-bold text-2xl text-black">Total</div>
              <div className="font-bold text-3xl text-primary">
                {" "}
                Rs. {total?.toFixed()}{" "}
                <span className="text-lg text-black line-through">
                  Rs. {(total + discount)?.toFixed()}
                </span>
              </div>
            </div>
          </div>
          <div
            className="bg-primary text-white rounded-full p-2 w-full mt-4 cursor-pointer text-center flex justify-center items-center text-center font-bold hover:opacity-80 hover:shadow-lg transition-all"
            onClick={() => {
              if (cartItems.length === 0) {
                toast.error("Cart is Empty");
                return;
              }

              if (process.env.REACT_APP_CATEGORY === "PRE-MEDICAL") {
                // window.gtag('event', 'begin_checkout', {
                //     items: cartItems?.map(item => ({
                //         item_id: item?._id,
                //         item_name: item?.SKUName,
                //         item_category: item?.category,
                //         item_price: item?.price,
                //         quantity: item?.quantity, // If applicable
                //     })),
                //     currency: 'PKR'
                // });
              }

              router("/checkout/cart");
              setIsOpenCart(false);
              // dispatch(closeCart());
            }}
          >
            Proceed to Checkout
            <div className={styles.arrow}>&#10143;</div>
          </div>
        </div>
      </CustomAntdModal>
    </>
  );
}
