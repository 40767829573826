import { useEffect, useState } from "react";
import {
  ExternalLinkIcon,
  SearchIcon,
} from "../../../../shared-components/ui/Icons";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../../shared-components/ui/CustomDropDown";
import { CommissionAPI } from "../../../../(apis)/(shared-apis)/latest-commission-api";
import {
  CODE_TYPES,
  formatNumberWithCommas,
  renderChips,
  renderStatusChips,
} from "../../utils";

import CouponModal from "./coupon-modal";
import { Switcher } from "shared-components/ui/CustomForm";

const ViewCoupons = ({ allUsers, coupons, setCoupons }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectCodeType, setCodeType] = useState(CODE_TYPES?.CA_Code);
  const [selectRole, setSelectRole] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectStatusChange = (selectedStatus) => {
    setCodeType(selectedStatus);
  };

  const filteredData = coupons?.filter((item) => {
    // Check if code type matches or if "All" is selected
    const isCodeTypeMatch =
      selectCodeType === "All" || item.codeType === selectCodeType;
    // console.log("isCodeTypeMatch: ", isCodeTypeMatch);

    // Check if role matches or if "All" is selected, or if codeType is Promo_Code (bypass role check)
    const isRoleMatch =
      selectRole === "All" ||
      selectCodeType === CODE_TYPES.Promo_Code ||
      (item?.couponUser?.role && selectRole === item.couponUser.role);
    // console.log("isRoleMatch: ", isRoleMatch);

    // Search by email or name, ensuring couponUser exists
    const isSearchMatch =
      selectCodeType === CODE_TYPES.Promo_Code ||
      item?.couponUser?.email
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
      item?.couponUser?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
    // console.log("isSearchMatch: ", isSearchMatch);

    // Return true if all conditions match
    return isCodeTypeMatch && isRoleMatch && isSearchMatch;
  });

  const handleActionButton = (item) => {
    // setModalData(reimbursement);
    // setIsModalOpen(true);
    window.open(`/manage/commission/coupon/${item?._id}`, "_blank");
  };

  const [modal, setModal] = useState({
    isModalOpen: false,
    data: null,
  });

  const closeModal = () => {
    setModal({
      isModalOpen: false,
      data: null,
    });
  };

  return (
    <>
      {/* =========================== */}
      {/* =========================== */}
      {/*  Coupon Modal*/}
      <CouponModal
        isModalOpen={modal?.isModalOpen}
        allUsers={allUsers}
        closeModal={closeModal}
        modal={modal}
      />
      {/* =========================== */}
      {/* =========================== */}

      <div className="w-full pb-20 px-5">
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className="bg-white shadow-md sm:rounded-lg">
                <div className="">
                  <div className="flex items-center p-4 space-x-3">
                    <div className="relative w-full flex-grow">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <SearchIcon color="text-gray-400" />
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                        placeholder="Search by Order ID or Email"
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                      />
                    </div>
                    <div className="min-w-fit">
                      <ButtonFill
                        handleClick={() => {
                          setModal({
                            isModalOpen: true,
                            data: null,
                          });
                        }}
                      >
                        Add new code
                      </ButtonFill>
                    </div>
                  </div>
                  <div className="flex items-end p-4 space-x-3">
                    <CustomDropDown
                      disabled
                      label="Status"
                      options={[CODE_TYPES?.Promo_Code, CODE_TYPES?.CA_Code]}
                      onSelect={(selectedStatus) =>
                        handleSelectStatusChange(selectedStatus.toString())
                      }
                      width="min-w-[12rem]"
                      value={selectCodeType}
                    />

                    {selectCodeType === CODE_TYPES?.CA_Code && (
                      <CustomDropDown
                        label="Role"
                        options={[
                          "All",
                          "ASSOCIATE",
                          // "HEAD",
                          "MANAGER",
                          "DIRECTOR",
                          // "PROVINCIAL_DIRECTOR",
                          // "NATIONAL_DIRECTOR",
                        ]}
                        onSelect={(selectedStatus) =>
                          setSelectRole(selectedStatus)
                        }
                        width="min-w-[12rem]"
                        value={selectRole}
                      />
                    )}
                  </div>
                </div>
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        Code
                      </th>
                      {selectCodeType === CODE_TYPES?.CA_Code && (
                        <>
                          <th scope="col" className="px-4 py-4">
                            Email
                          </th>
                          <th scope="col" className="px-4 py-4">
                            Role
                          </th>
                        </>
                      )}

                      <th scope="col" className="px-4 py-4">
                        Commission
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Discount
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Active
                      </th>
                      <th scope="col" className="px-4 py-4">
                        CreatedBy
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Date
                      </th>
                      {/* <th scope="col" className="px-4 py-4">
                        Edit
                      </th> */}
                      {selectCodeType === CODE_TYPES?.Promo_Code && (
                        <th scope="col" className="px-4 py-4">
                          View
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData &&
                      filteredData?.length > 0 &&
                      filteredData?.map((item) => (
                        <tr className="border-b" key={item?._id}>
                          <td className="px-4 py-3">{item?.couponCode}</td>
                          {selectCodeType === CODE_TYPES?.CA_Code && (
                            <>
                              <td className="px-4 py-3">
                                {item?.couponUser?.email}
                              </td>
                              <td className="px-4 py-3">
                                {renderChips(item?.couponUser?.role)}
                              </td>
                            </>
                          )}

                          <td className="px-4 py-3">
                            {item?.commissionPercentage}
                          </td>

                          <td className="px-4 py-3">{item?.discount}</td>

                          <td className="px-4 py-3">
                            <div className="mx-auto w-fit">
                              <Switcher
                                for={item?._id}
                                onChange={() => {
                                  //   UpdateStatus(user?._id, user?.isPublished);
                                }}
                                isActive={true}
                                togglevalue={item?.isActive}
                                size="small"
                              />
                            </div>
                          </td>
                          <td className="px-4 py-3">{item?.createdBy}</td>
                          <td className="px-4 py-3">
                            {new Date(item.createdAt).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          {/* <td className="px-4 py-3">
                            <button
                              type="button"
                              className="flex items-center justify-center w-full text-gray-700 hover:bg-gray-100"
                              onClick={() => {
                                setModal({
                                  isModalOpen: true,
                                  data: item,
                                });
                              }}
                            >
                              <ExternalLinkIcon />
                            </button>
                          </td> */}
                          {selectCodeType === CODE_TYPES?.Promo_Code && (
                            <td className="px-4 py-3">
                              <button
                                className=""
                                onClick={() => {
                                  handleActionButton(item);
                                }}
                              >
                                <ExternalLinkIcon />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}

                    {/* {filteredData &&
                      filteredData?.length > 0 &&
                      filteredData?.map((reimbursement) => (
                        <tr className="border-b" key={reimbursement?._id}>
                          <td className="px-4 py-3">{reimbursement?.name}</td>
                          <td className="px-4 py-3">{reimbursement?.email}</td>
                          <td className="px-4 py-3">
                            {renderStatusChips(reimbursement?.status)}
                          </td>
                          <td className="px-4 py-3">
                            {renderChips(reimbursement.role)}
                          </td>
                          <td className="px-4 py-3">
                            {new Date(
                              reimbursement?.dateRequested
                            )?.toLocaleDateString()}
                          </td>
                          <td className="px-4 py-3">
                            {formatNumberWithCommas(
                              reimbursement?.requestedAmount
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <button
                              className=""
                              onClick={() => {
                                handleActionButton(reimbursement);
                              }}
                            >
                              <ExternalLinkIcon />
                            </button>
                          </td>
                        </tr>
                      ))} */}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default ViewCoupons;
