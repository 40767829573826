import React, { useContext, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Page1 from './components/page1/question-essentials';
import Page2 from './components/page2/main';
import Page3 from './components/page3/main';
import Page2SAQ from './components/saqs/main';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../state-management/user-context';
import NavigationButtons from './components/navigation';
import { modules, QuestionDefaultValues } from './utils';
import Header from './components/header';
import { apiRequest } from '(apis)/api-interface/api-handler';

export default function AddQuestion() {


    const { user } = useContext(UserContext);
    const [references, setReferences] = useState([]);
    const [page, setPage] = useState(1)
    const [options, setOptions] = useState([])
    const [form, setForm] = useState(QuestionDefaultValues)

    const location = useLocation();
    const { deckName } = location.state || {};

    useEffect(() => {
        if (deckName) {
            setForm({ ...form, deckName: deckName })
        }
    }, [deckName])

    const [disabled, setDisabled] = useState(false)

    const Submit = async () => {
        if (!Validate()) {
            setDisabled(false)
            return;
        }

        const optionWithLetters = options.map((item, index) => {
            item.OptionLetter = String.fromCharCode(65 + index);
            return item;
        })
        setDisabled(true)

        let referencePdfs = references
            .filter((reference) => reference.refId)
            .map((reference) => ({
                board: reference.board,
                startPage: reference.startPage,
                endPage: reference.endPage || null,
                refId: reference.refId,
                pdfSelectedText: reference.pdfSelectedText,
            }));

        try {            
            let tagsObject = []
            tagsObject.push({ name: form?.subject, id: 4000 })
            tagsObject.push({ name: form?.topic, id: 4000 })
            form?.subTopic &&  form?.subTopic?.map((item) => {
                tagsObject.push({ name: item, id: 4000 })
            })
            if(!user || !user.username || user.username === ""){
                toast.error("User not found")
                return;
            }
            const Response = await apiRequest("addQuestion", { form, options: optionWithLetters, references:referencePdfs, tags:tagsObject, user: user.username });
            if (Response.data.success) {
                toast.success(Response.data.message)
                ClearStates()
                setPage(1)
            }
            else {
                toast.error(Response.data.message)
            }
        }
        catch (error) {
            console.log(error)
            toast.error(error?.response?.data?.message || error.message)
        }

        setDisabled(false)
    }

    const Validate = () => {
        if (form?.deckName === '') {
            toast.error('Deck Name is required')
            return false;
        }
        if (form?.entity === '') {
            toast.error('Entity is required')
            return false;
        }
        if (form?.category === '') {
            toast.error('Category is required')
            return false;
        }
        if (form?.board?.length === 0) {
            toast.error('Board is required')
            return false;
        }
        if (form?.subject === '') {
            toast.error('Subject is required')
            return false;
        }
        if (form?.type === '') {
            toast.error('Type is required')
            return false;
        }
        if (form?.topic === "") {
            toast.error('Topic is required')
            return false;
        }
        if (form?.questionText === '') {
            toast.error('Question Text is required')
            return false;
        }
        if (options.length < 2 && !form?.isSAQ) {
            toast.error('At least two options are required')
            return false;
        }
        if (options.filter((item) => item.IsCorrect).length !== 1 && !form?.isSAQ) {
            toast.error('Only one correct option is allowed')
            return false;
        }
        return true;
    }

    const [freezing, setFreezing] = useState({
        deckNameFreezed: false,
        tagsFreezed: false,
        questionTextFreezed: false,
        questionImageFreezed: false,
        optionsFreezed: false,
        explanationTextFreezed: false,
        explanationImageFreezed: false,
        metaFreezed: false,
    })

    const ClearStates = () => {
        setForm({
            ...QuestionDefaultValues,
            questionText: '',
            QuestionImage: '',
            ExplanationText: '',
            ExplanationImage: '',
            hints: []
        })
        setOptions([])
        setReferences([])
        setModal(true)
        setNumberOfOptions(0)
    }


    const [modal, setModal] = useState(false)
    const PopulateStates = () => {
        setForm(QuestionDefaultValues)
        setOptions([])
        setReferences([])
        setForm({ ...form, QuestionImage: '' })
        setForm({ ...form, ExplanationText: '' })
        setForm({ ...form, ExplanationImage: '' })
        setForm({ ...form, hints: [] })

        if (sessionStorage.getItem('deckNameFreezed')) {
            setForm({ ...form, deckName: sessionStorage.getItem('deckNameFreezed') })
        }
        if (sessionStorage.getItem('questionTextFreezed')) {
            setForm({ ...form, questionText: sessionStorage.getItem('questionTextFreezed') })
        }
        if (sessionStorage.getItem('questionImageFreezed')) {
            setForm({ ...form, QuestionImage: sessionStorage.getItem('questionImageFreezed') })
        }
        if (sessionStorage.getItem('optionsFreezed')) {
            setOptions(JSON.parse(sessionStorage.getItem('optionsFreezed')))
            let oldoptions = JSON.parse(sessionStorage.getItem('optionsFreezed'))
            let newoptions = oldoptions.map(() => ({
                OptionText: '',
                optionImage: '',
                ExplanationText: '',
                explanationImage: '',
                IsCorrect: false    
            }))
            setOptions(newoptions)
            setNumberOfOptions(oldoptions.length)
        }
        if (sessionStorage.getItem('explanationTextFreezed')) {
            setForm({ ...form, ExplanationText: sessionStorage.getItem('explanationTextFreezed') })
        }
        if (sessionStorage.getItem('metaFreezed')) {
            setForm({ ...form, ...JSON.parse(sessionStorage.getItem('metaFreezed')) })
        }

        //populate freeze states
        setFreezing({
            deckNameFreezed: sessionStorage.getItem('deckNameFreezed') ? true : false,
            tagsFreezed: sessionStorage.getItem('tagsFreezed') ? true : false,
            questionTextFreezed: sessionStorage.getItem('questionTextFreezed') ? true : false,
            questionImageFreezed: sessionStorage.getItem('questionImageFreezed') ? true : false,
            optionsFreezed: sessionStorage.getItem('optionsFreezed') ? true : false,
            explanationTextFreezed: sessionStorage.getItem('explanationTextFreezed') ? true : false,
            explanationImageFreezed: sessionStorage.getItem('explanationImageFreezed') ? true : false,
            metaFreezed: sessionStorage.getItem('metaFreezed') ? true : false,
        })
    }

    const [numberOfOptions, setNumberOfOptions] = React.useState(0);



    return (
        <>
            <div className="w-[95%] mx-auto h-[100vh] overflow-y-auto">
                <Toaster />
                <div className="bg-stone-100 shadow-md sm:rounded-lg pt-4">
                    <Header PopulateStates={PopulateStates} form={form} setForm={setForm} />
                    {page === 1 && <Page1 form={form} setForm={setForm} modules={modules}/>}
                    {(page === 2 && !form?.isSAQ) && <Page2 numberOfOptions={numberOfOptions} setNumberOfOptions={setNumberOfOptions} options={options} setOptions={setOptions} modules={modules} />}
                    {(page === 2 && form?.isSAQ) && <Page2SAQ form={form} setForm={setForm} modules={modules} />}
                    {page === 3 && <Page3 references={references} setReferences={setReferences} form={form} setForm={setForm} modules={modules} />}
                    <FreezeContainer freezing={freezing} setFreezing={setFreezing} form={form} options={options} />
                    <NavigationButtons page={page} setPage={setPage} onSubmit={Submit} disabled={disabled} />


                </div>
            </div>


            {/*
                {modal && (
                    <div className="fixed inset-0 bg-black opacity-50 z-50"></div>
                )} */}

            {/* <Modal open={modal} toggle={() => setModal(!modal)} centered>

                    <div className="absolute top-[30%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-4 max-w-2xl w-full bg-white z-50">
                        <div className="flex flex-col justify-center items-center gap-4">
                            <h1 className="text-rose-500 text-2xl font-bold">
                                Add a new question?
                            </h1>
                            <div className="flex flex-row justify-center items-center gap-4">
                                <button
                                    className="bg-rose-500 hover:bg-rose-600 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => {
                                        PopulateStates();
                                        setModal(false);
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                                    onClick={() => setModal(false)}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal> */}
        </>
    )
}



const FreezeContainer = ({ freezing, setFreezing, form , options }) => {

    const HandleFreeze = (value: boolean, key: string) => {
        if (key === 'deckNameFreezed') {
            if (value) {
                sessionStorage.setItem(key, form?.deckName)
            }
            else {
                sessionStorage.removeItem(key)
            }
        }
        if (key === 'questionTextFreezed') {
            if (value) {
                sessionStorage.setItem(key, form?.questionText)
            }
            else {
                sessionStorage.removeItem(key)
            }
        }
        if (key === 'questionImageFreezed') {
            if (value) {
                sessionStorage.setItem(key, form?.QuestionImage)
            }
            else {
                sessionStorage.removeItem(key)
            }
        }
        if (key === 'optionsFreezed') {
            if (value) {
                sessionStorage.setItem(key, JSON.stringify(options))
            }
            else {
                sessionStorage.removeItem(key)
            }
        }
        if (key === 'explanationTextFreezed') {
            if (value) {
                sessionStorage.setItem(key, form?.ExplanationText)
            }
            else {
                sessionStorage.removeItem(key)
            }
        }
        if (key === "metaFreezed") {
            if (value) {
                sessionStorage.setItem(key, JSON.stringify({
                    type: form?.type,
                    university: form?.university,
                    year: form?.year,
                    entity: form?.entity,
                    category: form?.category,
                    board: form?.board,
                    subject: form?.subject,
                    topic: form?.topic,
                    subTopic: form?.subTopic,
                    miscTags: form?.miscTags
                }))
            }
            else {
                sessionStorage.removeItem(key)
            }
        }
    }

    return (
        <div className="mx-auto p-12 pt-0">
            <div className="overflow-hidden bg-white shadow-lg sm:rounded-lg text-semibold p-4">
                <p className='text-left text-gray-800 text-md font-bold mt-4'> Select inputs to freeze</p>

                <div className='flex flex-row flex-wrap justify-left items-center gap-4 mt-4'>
                    <div className='flex justify-center'>
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={freezing.deckNameFreezed} onChange={(e) => {
                            setFreezing({ ...freezing, deckNameFreezed: e.target.checked })
                            HandleFreeze(!freezing.deckNameFreezed, 'deckNameFreezed')
                        }} />
                        <p className='text-gray-500 text-sm ml-2'>Deck Name</p>
                    </div>
                    {/* <div className='flex justify-center'>
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={freezing.tagsFreezed} onChange={(e) => {
                            setFreezing({ ...freezing, tagsFreezed: e.target.checked })
                            HandleFreeze(!freezing.tagsFreezed, 'tagsFreezed')
                        }} />
                        <p className='text-gray-500 text-sm ml-2'>Tags</p>
                    </div> */}
                    <div className='flex justify-center'>
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={freezing.questionTextFreezed} onChange={(e) => {
                            setFreezing({ ...freezing, questionTextFreezed: e.target.checked })
                            HandleFreeze(!freezing.questionTextFreezed, 'questionTextFreezed')
                        }} />
                        <p className='text-gray-500 text-sm ml-2'>Question Text</p>
                    </div>
                    <div className='flex justify-center'>
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={freezing.questionImageFreezed} onChange={
                            (e) => {
                                setFreezing({ ...freezing, questionImageFreezed: e.target.checked })
                                HandleFreeze(!freezing.questionImageFreezed, 'questionImageFreezed')
                            }} />
                        <p className='text-gray-500 text-sm ml-2'>Question Image</p>
                    </div>
                    <div className='flex justify-center'>
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={freezing.optionsFreezed} onChange={
                            (e) => {
                                setFreezing({ ...freezing, optionsFreezed: e.target.checked })
                                HandleFreeze(!freezing.optionsFreezed, 'optionsFreezed')
                            }} />
                        <p className='text-gray-500 text-sm ml-2'>Options</p>
                    </div>
                    <div className='flex justify-center'>
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={freezing.explanationTextFreezed} onChange={
                            (e) => {
                                setFreezing({ ...freezing, explanationTextFreezed: e.target.checked })
                                HandleFreeze(!freezing.explanationTextFreezed, 'explanationTextFreezed')
                            }} />
                        <p className='text-gray-500 text-sm ml-2'>Explanation Text</p>
                    </div>
                    <div className='flex justify-center'>
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={freezing.metaFreezed} onChange={
                            (e) => {
                                setFreezing({ ...freezing, metaFreezed: e.target.checked })
                                HandleFreeze(!freezing.metaFreezed, 'metaFreezed')
                            }} />
                        <p className='text-gray-500 text-sm ml-2'>Meta Data</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
