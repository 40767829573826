import useGetData from "shared-components/custom-hook/get-data";
import GlobalTable from "shared-components/table/GlobalTable";
import CustomBadges from "shared-components/ui/CustomBadges";
import { ManageIcon } from "shared-components/ui/Icons";
import { useNavigate } from "react-router-dom";
// Define the structure for a single report
interface Report {
  username: string;
  problemText: string;
  issues: string[]; // Array of issues
  status: 'PENDING' | 'REJECTED' | 'RESOLVED'; // Possible statuses
  _id: string; // ID of the report
  reportedAt: string; // Date in string format
  action: string;
}

// Define the structure for the overall data response
interface ReportsResponse {
  reports: Report[]; // Array of reports
}

// Define the table columns

// Component to fetch and display pending reports
const PendingAuditQuestionReports = ({ questionId }) => {
  const { data, loading, error } = useGetData<ReportsResponse>("getPendingReportsByQuestionId", questionId); // Fetch data using the custom hook

  const navigate = useNavigate();
  // Filter pending reports only
  const pendingReports = data?.reports || [];

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Problem Text',
      dataIndex: 'problemText',
      key: 'problemText',
    },
    {
      title: 'Issues',
      dataIndex: 'issues',
      key: 'issues',
      render: (issues) => {
        return <div className="flex flex-wrap gap-2">
          {issues.map((issue, index) => (
            <CustomBadges key={index} label={issue} type="error" />
          ))}
        </div>
      }
    },
    {
      title: 'Reported At',
      dataIndex: 'reportedAt',
      key: 'reportedAt',
      render: (date: string) => new Date(date).toLocaleDateString(), // Format date to readable format
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <ManageIcon onClick={() => handleResolve(record._id)} />
      ),
    }
  
  ];
  
  const handleResolve = (reportId: string) => {
    //route to resolve report page questiondetails/qid/reportid
    navigate(`/editQuestion/${questionId}/${reportId}`);
    window.location.reload();
  };

  return (
    <div>
      {error ? <p>Error: {error}</p> :
        <GlobalTable
          loading={loading}
          data={pendingReports}
          columns={columns}
        />
      }
    </div>
  );
};

export default PendingAuditQuestionReports;
