import { formatNumberWithCommas } from "shared-pages/latest-commission/utils";

const ViewAllPromocodes = ({
  filteredPromoCodes,
  isActiveFilter,
  setConfirmationModal = null,
  managerScreen = false,
  setModal = null,
}) => {
  return (
    <div>
      <table className="w-full mb-20 text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-4 py-4">
              Promo Code
            </th>

            <th scope="col" className="px-4 py-4">
              Name
            </th>
            <th scope="col" className="px-4 py-4">
              Created By
            </th>
            <th scope="col" className="px-4 py-4 text-center">
              Total Signups
            </th>
            <th scope="col" className="px-4 py-4">
              Status
            </th>
            <th scope="col" className="px-4 py-4">
              Created At
            </th>

            <th scope="col" className="px-4 py-4">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredPromoCodes &&
            filteredPromoCodes.length > 0 &&
            filteredPromoCodes
              .filter((promoCode: any) => {
                if (isActiveFilter === "All") {
                  return true;
                }
                return (
                  (isActiveFilter === "Active" && promoCode.isActive) ||
                  (isActiveFilter === "InActive" && !promoCode.isActive)
                );
              })
              .map((item: any) => (
                <tr className="border-b" key={item?._id}>
                  <td className="px-4 py-3">{item?.code}</td>
                  <td className="px-4 py-3">{item?.name}</td>
                  <td className="px-4 py-3">{item?.createdBy}</td>
                  <td className="px-4 py-3 text-center">
                    {item?.signupIds?.length || 0}
                  </td>
                  <td className="px-4 py-3">
                    {item?.isActive ? (
                      <span
                        className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"
                        onClick={() => {
                          setConfirmationModal({
                            isOpen: true,
                            action: "InActive",
                            data: item,
                          });
                        }}
                      >
                        Active
                      </span>
                    ) : (
                      <span
                        className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"
                        onClick={() => {
                          setConfirmationModal({
                            isOpen: true,
                            action: "Active",
                            data: item,
                          });
                        }}
                      >
                        In Active
                      </span>
                    )}
                  </td>
                  <td className="px-4 py-3">
                    {new Date(item?.createdAt)?.toLocaleDateString()}
                  </td>

                  <td className="px-4 py-4 cursor-pointer">
                    {/* <span
                      className="bg-amber-100 text-amber-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"
                      onClick={() => {
                        if (!setModal) return;
                        setModal({
                          isModalOpen: true,
                          data: item,
                        });
                      }}
                    >
                      Update
                    </span>{" "} */}
                    <span
                      className="bg-amber-100 text-amber-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"
                      onClick={() => {
                        window.open(
                          `/manage/promocodes/${item?._id}`,
                          "_blank"
                        );
                      }}
                    >
                      View
                    </span>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewAllPromocodes;
