import { useState } from "react";
import { CrossIcon, TickFillIcon } from "../../../shared-components/ui/Icons";

import toast, { Toaster } from "react-hot-toast";

import CustomBadges from "../../../shared-components/ui/CustomBadges";
import { ConfirmationModal } from "../../../shared-components/ui/CustomModal";
import { UniVerificationAPI } from "(apis)/(shared-apis)/university-verification-apis";
import { Image } from "antd";

export const ViewRequests = ({ currentData, setData }) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    confirmationModal: false,
    confirmationModalType: "",
    formData: null,
  });
  const handleRequestSubmit = async () => {
    const body = {
      id: modal?.formData?._id,
      verificationStatus: modal?.confirmationModalType + "ed", //Accept, Reject , ed for Accepted, Rejected
      institution: modal?.formData?.institution,
      year: modal?.formData?.year,
      userId: modal?.formData?.userId,
      requestType: modal?.formData?.requestType,
    };
    setLoading(true);

    // return;
    try {
      const response = await UniVerificationAPI(
        "updateUniVerificationRequest",
        [""],
        body
      );

      if (response.status === 200) {
        const message = response?.data?.message;
        const updatedData = currentData?.map((item: any) => {
          if (item?._id === body?.id) {
            return { ...item, verificationStatus: body?.verificationStatus };
          } else {
            return item;
          }
        });
        setData(updatedData);
        toast.success(message);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong ";
      toast.error(message);
    }
    setModal({
      confirmationModal: false,
      confirmationModalType: "",
      formData: null,
    });
    setLoading(false);
  };

  const PerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const renderUrl = (url: string) => {
    if (!url) return "";
    if (!url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <>
      <div className="overflow-x-auto">
        <Toaster />
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Email
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Institution
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                year
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Verification Status
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                ID Card
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData
              ?.slice((currentPage - 1) * PerPage, currentPage * PerPage)
              ?.map((item: any) => (
                <tr className="border-b" key={item?._id}>
                  <td className="px-4 py-3">{item?.userEmail}</td>
                  <td className="px-4 py-3">{item?.institution}</td>
                  <td className="px-4 py-3">{item?.year?.replace("_", " ")}</td>
                  <td className="px-4 py-3 text-center">
                    <CustomBadges
                      type={
                        item?.verificationStatus === "Pending"
                          ? "warning"
                          : item?.verificationStatus === "Rejected"
                          ? "error"
                          : "success"
                      }
                      label={item?.verificationStatus}
                    />
                  </td>
                  <td className="px-4 py-3 text-center">
                    <Image
                      width={50}
                      height={50}
                      className="w-10 h-10 rounded-full   cursor-pointer"
                      src={renderUrl(item?.idCard)}
                    />
                  </td>

                  <td className="relative flex items-center justify-center px-4 py-3 font-semibold">
                    {item?.verificationStatus !== "Accepted" && (
                      <button
                        type="button"
                        className="flex bg-emerald-200 items-center justify-center w-full px-4 py-2 space-x-2 text-emerald-900 hover:bg-emerald-300"
                        onClick={() => {
                          setModal({
                            confirmationModal: true,
                            confirmationModalType: "Accept",
                            formData: item,
                          });
                        }}
                      >
                        <TickFillIcon />
                        <span>Accept</span>
                      </button>
                    )}
                    {item?.verificationStatus !== "Rejected" && (
                      <button
                        type="button"
                        className="flex bg-red-200 items-center justify-center w-full px-4 py-2 space-x-2 text-red-900 hover:bg-red-300"
                        onClick={() => {
                          setModal({
                            confirmationModal: true,
                            confirmationModalType: "Reject",
                            formData: item,
                          });
                        }}
                      >
                        <CrossIcon />
                        <span>Reject</span>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
          <ConfirmationModal
            active={modal?.confirmationModal}
            message={`Are you sure you want to ${modal?.confirmationModalType} this request?`}
            onConfirm={() => {
              handleRequestSubmit();
            }}
            onCancel={() => {
              setModal({
                confirmationModal: false,
                confirmationModalType: "",
                formData: null,
              });
            }}
            isIcon={false}
            loading={loading}
          />
        </table>
      </div>
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 space-x-2">
          <span> Showing</span>
          <span className="font-semibold text-gray-900 ">
            {currentPage * PerPage - PerPage + 1}
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900">
            {currentData.length}
          </span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          <li>
            <a
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          {Array.from({
            length: Math.ceil(currentData.length / PerPage),
          }).map((_, index) => (
            <li key={index}>
              <a
                className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 ${
                  currentPage === index + 1
                    ? "font-semibold text-primary-600 bg-primary-50"
                    : ""
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </a>
            </li>
          ))}
          <li>
            <a
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default ViewRequests;
