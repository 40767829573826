import { Select, notification, Upload, Button, Image, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { CategoryAPI } from "(apis)/(shared-apis)/academics/category-apis";
import { uniYearData } from "constants/constantvalues";
// import { addCategoryApi, getCategoriesApi } from "../../APIs/CategoryAPI";

const DeckGroupModal = ({
  setCategories,
  ShowModel,
  setShowModel,
  isEditMode,
  setIsEditMode,
  clickRowData,
  institutesData,
}) => {
  const [thumbnailImagePreview, setThumbnailImagePreview] = useState("");
  const [thumbnailImagePreviewOpen, setThumbnailImagePreviewOpen] =
    useState(false);

  const getBase64ImageData = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const [formData, setFormData] = useState({
    categoryName: "",
    isPublished: false,
    logo: null,
    metadata: {
      entity: "",
      category: "",
    },
    university: [],
    year: "",
  });
  const initialErrors = {
    categoryNameError: {
      error: false,
      message: "",
    },
    logoError: {
      error: false,
      message: "",
    },
  };

  const [errors, setErrors] = useState(initialErrors);
  const handleThumbnailPreview = async (file) => {
    if (!file?.url && !file.preview) {
      file.preview = await getBase64ImageData(file.originFileObj);
    }
    setThumbnailImagePreview(file?.url || file?.preview);
    setThumbnailImagePreviewOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    let { value } = e.target;
    if (name === "isPublished") {
      value = checked;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogoChange = (info) => {
    console.log("info", info);
    if (info?.file?.status === "removed") {
      setFormData({
        ...formData,
        logo: "",
      });
    } else {
      setFormData({
        ...formData,
        logo: info.fileList,
      });
    }
  };
  useEffect(() => {
    if (isEditMode) {
      const formattedLogo = clickRowData.logoUrl
        ? [
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              type: "image/png",
              isOldFile: true,
              url: clickRowData.logoUrl,
            },
          ]
        : [];
      setFormData({
        ...clickRowData,
        logo: formattedLogo,
      });
    } else {
      setFormData({
        categoryName: "",
        isPublished: false,
        logo: null,
        metadata: {
          entity: "",
          category: "",
        },
        university: [],
        year: "",
      });
    }
  }, [isEditMode, clickRowData]);
  const CheckValidity = () => {
    const newErrors = { ...errors };
    let isError = false;
    if (isEditMode) {
      if (!formData.logo) {
        newErrors.logoError.error = true;
        newErrors.logoError.message = "Logo is required";
        isError = true;
      } else {
        newErrors.logoError.error = false;
        newErrors.logoError.message = "";
      }
    } else {
      if (formData.categoryName === "") {
        newErrors.categoryNameError.error = true;
        newErrors.categoryNameError.message = "Category Name is required";
        isError = true;
      } else {
        newErrors.categoryNameError.error = false;
        newErrors.categoryNameError.message = "";
      }

      if (!formData.logo) {
        newErrors.logoError.error = true;
        newErrors.logoError.message = "Logo is required";
        isError = true;
      } else {
        newErrors.logoError.error = false;
        newErrors.logoError.message = "";
      }
    }

    setErrors(newErrors);
    return isError;
  };

  const Submit = async (e) => {
    try {
      e.preventDefault();
      if (CheckValidity()) {
        return;
      }
      const base64Logo =
        !formData.logo?.[0]?.isOldFile &&
        (await getBase64ImageData(formData.logo?.[0]?.originFileObj));
      // const formDataObj = new FormData();
      // formDataObj.append("categoryName", formData.categoryName);
      // formDataObj.append("isPublished", formData.isPublished);
      // formDataObj.append("logo",  base64Logo);

      // formDataObj.append("metadata", JSON.stringify(formData.metadata));
      const payload = {
        categoryName: formData.categoryName,
        isPublished: formData.isPublished,
        logo: base64Logo,
        metadata: {
          entity: process.env.REACT_APP_ENTITY,
          category: process.env.REACT_APP_CATEGORY,
        },
        university: formData.university,
        year: formData.year,
        _id: isEditMode ? clickRowData._id : null,
      };
      let Response = null;
      if (isEditMode) {
        Response = await CategoryAPI("updateCategory", null, payload);
      } else {
        Response = await CategoryAPI("addCategory", null, payload);
      }
      if (Response.data.success) {
        setIsEditMode(false);
        message.success(Response.data.message);
        setShowModel(false);
        setFormData({
          categoryName: "",
          isPublished: false,
          logo: null,
          metadata: {
            entity: "",
            category: "",
          },
          university: [],
          year: "",
        });
        const Response2 = await CategoryAPI("getCategories");
        if (Response2.data.success) {
          setCategories(Response2.data.data);
        }
      } else {
        message.error(Response.data.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };
  const uploadButtonThumbnail = (
    <div className="w-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload Logo</div>
    </div>
  );

  // console.log("institutesData", institutesData)
  const filteredInstitutesData = institutesData?.filter(
    (item) => item.metadata.entity === "UNDERGRADUATE"
  );

  
  return (
    <>
      <div
        id="createProductModal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          ShowModel ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
      >
        <div className="relative w-full max-w-2xl max-h-full p-4">
          <div className="relative p-4 bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
              <h3 className="text-lg font-semibold text-gray-900 ">
                {isEditMode ? "Update" : "Add New"} Deck
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-target="createProductModal"
                data-modal-toggle="createProductModal"
                onClick={() => {
                  setShowModel(false);
                }}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="space-y-3 mt-3">
              <div>
                <label className="block text-gray-900">
                  <span>Category Name *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="categoryName"
                  placeholder="Enter category name"
                  value={formData.categoryName || ""}
                  onChange={handleInputChange}
                  disabled={isEditMode}
                />
                {errors.categoryNameError.error && (
                  <span className="text-xs text-red-500">
                    {errors.categoryNameError.message}
                  </span>
                )}
              </div>

              {process.env.REACT_APP_ENTITY === "UNDERGRADUATE" && (
                <div className="flex gap-4">
                  <Select
                    placeholder="Select university"
                    style={{ width: 200 }}
                    mode="multiple"
                    // disabled={isEditMode}
                    onChange={(value) => {
                      setFormData({ ...formData, university: value });
                    }}
                    value={formData.university || []}
                  >
                    {filteredInstitutesData?.map((university) => (
                      <Select.Option
                        key={university.value}
                        value={university.value}
                      >
                        {university.value}
                      </Select.Option>
                    ))}
                  </Select>
                  <Select
                    // disabled={isEditMode}
                    placeholder="Select Year"
                    style={{ width: 200 }}
                    onChange={(value: any) => {
                      setFormData({ ...formData, year: value });
                    }}
                    value={formData.year || []}
                  >
                    {uniYearData?.map((year) => (
                      <Select.Option key={year.value} value={year.value}>
                        {year.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}

              <div>
                <label className="block text-gray-900">
                  <span>Upload Logo *</span>
                </label>
                <Upload
                  name="logo"
                  accept="image/*"
                  // showUploadList={false}
                  beforeUpload={() => false}
                  onChange={handleLogoChange}
                  listType="picture-card"
                  onPreview={handleThumbnailPreview}
                  maxCount={1}
                  fileList={formData.logo || []}
                >
                  {uploadButtonThumbnail}
                </Upload>
                {thumbnailImagePreviewOpen && (
                  <Image
                    preview={{
                      visible: thumbnailImagePreviewOpen,
                      onVisibleChange: (visible) =>
                        setThumbnailImagePreviewOpen(visible),
                      // afterClose: () => setThumbnailImagePreview(""),
                    }}
                    src={thumbnailImagePreview}
                    style={{ display: "none" }}
                  />
                )}
                {errors.logoError.error && (
                  <span className="text-xs text-red-500">
                    {errors.logoError.message}
                  </span>
                )}
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="isPublished"
                  checked={formData.isPublished}
                  onChange={handleInputChange}
                />
                <span className="text-sm">Published</span>
              </div>

              <button
                className="w-full py-1.5 rounded bg-primary text-white font-medium transition hover:bg-primary-dark focus:outline-none focus:bg-primary-dark"
                onClick={Submit}
              >
                {isEditMode ? "Update" : "Add"} Category
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeckGroupModal;
