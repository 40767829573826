import React, { useState } from "react";
import LabelComponent from "./LabelComponent";
import { Image, message, Upload, UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  getBase64ImageData,
  getErrorString,
} from "../../utility/form/functions";
import CustomFormikError from "./CustomFormikError";
import { FormikProps } from "formik";
import { imageSize, validImageTypes } from "constants/constantvalues";
interface CustomImageFieldProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  require?: boolean;
  isMultipleImage?: boolean;
  maxCount?: number;
  listType?: UploadProps["listType"];
}

const CustomImageField: React.FC<CustomImageFieldProps> = ({
  maxCount = 1,
  isMultipleImage,
  listType = "picture-card",
  label,
  name,
  formik,
  require = false,
}) => {
  const [uploadImagePreview, setUploadImagePreview] = useState(false);
  const [uploadImagePreviewUrl, setUploadImagePreviewUrl] = useState("");
  const handleThumbnailPreview = async (file) => {
    if (!file?.url && !file.preview) {
      file.preview = await getBase64ImageData(file.originFileObj);
    }
    setUploadImagePreviewUrl(file?.url || file?.preview);
    setUploadImagePreview(true);
  };
  const touched = !!formik.touched[name];
  const error = getErrorString(formik.errors[name]);
 
  return (
    <>
      <LabelComponent value={label} require={require} />
      <Upload
        listType={listType}
        fileList={formik.values[name] || []}
        onPreview={handleThumbnailPreview}
        multiple={isMultipleImage}
        onChange={(info) => {
          const { file, fileList } = info;
        
          // Log the complete info object
          console.log("info", info);
        
          // Get the file type in lowercase
          const imageType = file?.type?.toLowerCase();
        
          // Validate if the file type is jpg, jpeg, or png
         
          const isJpgOrPng = validImageTypes?.includes(imageType);
          if (!isJpgOrPng) {
            message.error('You can only upload jpeg/jpg/png files!');
            return;
          }
          // Check if the file size is less than 100MB
          const isLt100M = file?.size / 1024 / 1024 <= imageSize;
          if (!isLt100M) {
            message.error('Image must be smaller than 100MB!');
            return;
          }
        
          // Set the file list value in the form using Formik
          formik.setFieldValue(name, fileList);
        }}
        
        accept="image/*" // Accept only image files
        beforeUpload={()=> false} // Prevent automatic upload
        maxCount={isMultipleImage ? null : maxCount}
        className="w-full"
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
      <CustomFormikError name={name} touched={touched} error={error} />
      {uploadImagePreview && (
        <Image
          preview={{
            visible: uploadImagePreview,
            onVisibleChange: (visible) => setUploadImagePreview(visible),
            //   afterClose: () => setUploadImagePreviewUrl(""),
          }}
          src={uploadImagePreviewUrl}
          style={{ display: "none" }}
        />
      )}
    </>
  );
};

export default CustomImageField;
