import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableQuestion from "./draggable-component";
import { DeckGroupInterface } from "../interface";

interface TableViewProps {
    deckGroups: DeckGroupInterface[];
    setDeckGroups: React.Dispatch<React.SetStateAction<DeckGroupInterface[]>>;
    categoryName: string;
    setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
    setMode: React.Dispatch<React.SetStateAction<string>>;
    setEditData: React.Dispatch<React.SetStateAction<{}>>;
    GetCategory: () => void;
}


export const TableView = ({
    deckGroups,
    setDeckGroups,
    categoryName,
    setShowModel,
    setMode,
    setEditData,
    GetCategory
}: TableViewProps) => {

    const moveQuestion = (fromIndex: number, toIndex: number) => {
        const updatedQuestions = [...deckGroups];
        const [movedQuestion] = updatedQuestions.splice(fromIndex, 1);
        updatedQuestions.splice(toIndex, 0, movedQuestion);
        setDeckGroups(updatedQuestions);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="mt-8">
                <div className="overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold">
                    <div className="overflow-x-auto">
                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-4 text-left">
                                        SR#
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-left">
                                    Copy Deck group ID
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Group Image
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Group Name
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Type
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Published
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-center">
                                        View
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-center">
                                        Edit
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="bg-white divide-y divide-gray-200 text-gray-800">
                                {deckGroups &&
                                    deckGroups.map((deckGroup: DeckGroupInterface, index: number) => (
                                        <DraggableQuestion
                                            key={deckGroup._id}
                                            deckGroup={deckGroup}
                                            index={index}
                                            moveQuestion={moveQuestion}
                                            deckGroups={deckGroups}
                                            setDeckGroups={setDeckGroups}
                                            categoryName={categoryName}
                                            setShowModel={setShowModel}
                                            setMode={setMode}
                                            setEditData={setEditData}
                                            GetCategory={GetCategory}
                                         />
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DndProvider>

    );
};
