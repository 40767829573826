import { Select } from "antd";
import { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ReferenceAPI } from "../../../../(apis)/(shared-apis)/academics/reference-apis";
import { ButtonFill } from "../../../../shared-components/ui/CustomButtons";
import { UserContext } from "state-management/user-context";

const AddNewReference = ({ boards, subjects, GetReferences, setShowModel, mode, editData }: any) => {


    const { user } = useContext(UserContext);
    const MAX_FILE_SIZE_MB = 100;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    const initialFormData = {
        pdfName: "",
        pdfMetadata: "",
        totalPages: "",
        board: "",
        subject: "",
        createdBy: user?.username || "",
        pdfFile: null,
    };
    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        if (mode === "edit" && editData) {
            setFormData({
                pdfName: editData.pdfName,
                pdfMetadata: editData.pdfMetadata,
                totalPages: editData.totalPages,
                board: editData.board,
                subject: editData.subject,
                createdBy: editData.createdBy,
                pdfFile: editData.pdfFile
            });
        }
        if (mode === "add") {
            setFormData(initialFormData);
        }
    }, [mode, editData]);


    const initialErrorState = {
        pdfFile: false,
    };

    const [error, setError] = useState(initialErrorState);

    const handleInputChange = (e: any) => {
        const { name, value, files } = e.target;

        if (files) {
            const pdfFile = files[0];
            const fileSize = pdfFile.size;

            setError(initialErrorState);
            if (name === "pdfFile" && fileSize > MAX_FILE_SIZE_BYTES) {
                setError((prevError) => ({
                    ...prevError,
                    pdfFile: true,
                }));
                return;
            }

            setFormData((prevData) => ({ ...prevData, [name]: pdfFile }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };


    const [apiLoadingStatus, setApiLoadingStatus] = useState(false);
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const {
            pdfName,
            pdfMetadata,
            totalPages,
            board,
            subject,
            pdfFile,
        } = formData;

        if (
            !pdfName ||
            !pdfMetadata ||
            !totalPages ||
            !board ||
            !subject ||
            (mode === "add" && !pdfFile)
        ) {
            toast.error("Please fill in all the fields and upload a PDF file.");
            return;
        }

        if (user.isloggedin === false) {
            toast.error("Please login to create a reference PDF");
            return;
        }
        if (mode === "add") {
            const formDataToSend = new FormData();
            formDataToSend.append("pdfName", pdfName);
            formDataToSend.append("pdfMetadata", pdfMetadata);
            formDataToSend.append("totalPages", totalPages);
            formDataToSend.append("board", board);
            formDataToSend.append("subject", subject);
            formDataToSend.append("createdBy", user?.username || "");
            formDataToSend.append("pdfFile", pdfFile);
            try {
                setApiLoadingStatus(true);

                const response = await ReferenceAPI("createReference", [], formDataToSend);
                if (response) {
                    setApiLoadingStatus(false);
                }
                // Reset the form after successful submission
                setFormData(initialFormData);
                GetReferences()
                setShowModel(false);
                // Reset Error States
                setError(initialErrorState);
                toast.success("Reference PDF Created Successfully 🚀");
            } catch (error) {
                setApiLoadingStatus(false);
                toast.error("Server Error: Something Bad happened");
            }
        }
        else {
            const formDataToSend = new FormData();
            formDataToSend.append('pdfName', pdfName);
            formDataToSend.append('pdfMetadata', pdfMetadata);
            formDataToSend.append('totalPages', totalPages);
            formDataToSend.append('board', board);
            formDataToSend.append('subject', subject);
            formDataToSend.append('editedBy', user.username)
            if (pdfFile) {
               formDataToSend.append('pdfFile', pdfFile);
            }
      
            try {
                const response = await ReferenceAPI("updatePdf", [editData._id], formDataToSend);              
                // Reset the form after successful submission
                setFormData({
                   pdfName: '',
                   pdfMetadata: '',
                   totalPages: '',
                   board: '',
                   subject: '',
                   createdBy: "",
                   pdfFile: null,
                });
       
                setShowModel(false);
                GetReferences()

                // Reset Error States
                setError(initialErrorState);
       
                toast.success('Success message! Reference PDF Updated Successfully 🚀')
             } catch (error) {
                toast.error("Error updating reference PDF:")
                console.error('Error updating reference PDF:', error);
             }
       

        }
    };

    return (

        <div className="p-4">
            {apiLoadingStatus && <PdfUploadModal />}

            <div className="careers-formbold-main-wrapper">
                <div className="careers-formbold-form-wrapper">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-4">
                            <div className="grid grid-cols-[30%,70%] gap-4 items-center">
                                <label className="careers-formbold-form-label careers-formbold-form-label-2" htmlFor="pdfName">
                                    PDF Name:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter PDF name here"
                                    name="pdfName"
                                    value={formData.pdfName}
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter           disabled:text-gray-400 disabled:font-light"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grid grid-cols-[30%,70%] gap-4 items-center">
                                <label className="careers-formbold-form-label careers-formbold-form-label-2" htmlFor="pdfMetadata">
                                    PDF Metadata:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter PDF metadata"
                                    name="pdfMetadata"
                                    value={formData.pdfMetadata}
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter           disabled:text-gray-400 disabled:font-light"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grid grid-cols-[30%,70%] gap-4 items-center">
                                <label className="careers-formbold-form-label careers-formbold-form-label-2" htmlFor="totalPages">
                                    Total Pages:
                                </label>
                                <input
                                    type="number"
                                    placeholder="Enter total pages"
                                    name="totalPages"
                                    value={formData.totalPages}
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter           disabled:text-gray-400 disabled:font-light"
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="grid grid-cols-[30%,70%] gap-4 items-center">
                                <label
                                    className="careers-formbold-form-label careers-formbold-form-label-2"
                                    htmlFor="board"
                                >
                                    Board:
                                </label>
                                <Select
                                    placeholder="Select Board"
                                    style={{ width: "100%", height: "50px", borderRadius: "0px" }}
                                    value={formData.board}
                                    onChange={(value) =>
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            board: value,
                                        }))
                                    }
                                    showSearch
                                >
                                    {boards.length > 0 &&
                                        boards.map((item) => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>

                            <div className="grid grid-cols-[30%,70%] gap-4 items-center">
                                <label
                                    className="careers-formbold-form-label careers-formbold-form-label-2"
                                    htmlFor="subject"
                                >
                                    Subject:
                                </label>
                                <Select
                                    placeholder="Select Subject"
                                    style={{ width: "100%", height: "50px", borderRadius: "0px" }}
                                    value={formData.subject}
                                    onChange={(value) =>
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            subject: value,
                                        }))
                                    }
                                    showSearch
                                >
                                    {subjects.length > 0 &&
                                        subjects.map((item: any) => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>
                        </div>

                        <div className="mb-2 mt-2 p-4 border border-gray-200 flex flex-col gap-4 items-center">
                            <label className="careers-formbold-form-label careers-formbold-form-label-2">
                                Upload PDF:
                            </label>

                            <p
                                className={`careers-dimensionError ${error.pdfFile && "active"
                                    }`}
                            >
                                PDF file size must be less than {MAX_FILE_SIZE_MB} MB
                            </p>

                            <input
                                type="file"
                                name="pdfFile"
                                accept=".pdf"
                                onChange={handleInputChange}
                            />
                        </div>

                        <div>
                            <ButtonFill
                                type="Submit"
                                disabled={apiLoadingStatus}
                                handleClick={handleSubmit}
                            >
                                {apiLoadingStatus ? "Loading..." : "Submit"}
                            </ButtonFill>
                        </div>
                    </form>

                </div>
                <Toaster />
            </div>
        </div>
    );
};

const PdfUploadModal = () => {
    return (
        <div className={`__pdf-upload-modal _show_loading_popup `}>
            <div className="uploading__modal-content">
                <h3>PDFs are being uploaded. Please wait...</h3>
                <h5>Don't Refresh the page</h5>
            </div>
        </div>
    );
};


export default AddNewReference;

