
interface ThemeType {
    Title: string;
    Logo: string;
    ColorScheme: string;
    SecondaryColor?: string;  // Optional property for categories that have it
    adminUrl: string;
    Name: string;
    urlconcat: string;
    prefix: string;
    pricingPrefix: string;
    WhatsAppNumber: string;
    url: string;
  }
  
  const GetCategoryTheme = (category?: string): ThemeType => {

    let Theme:ThemeType = {
        'Title': "PreMed.PK",
        "Logo": "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/PreMed%20Logo%204.svg",
        "ColorScheme": "#EC5863",
        "adminUrl": "https://premed-admin.parho.io",
        "Name": "PreMed",
        "urlconcat": '/entrance-exam/pre-medical',
        "prefix": "pre-medical",
        'pricingPrefix': '/bundles/mdcat%20+%20nums',
        "WhatsAppNumber": '+923061289229',
        "url": "https://premed.pk"
    }
    if (!category) {
        return Theme
    }

    if (category.toUpperCase() === "MEDSCHOOL"){
        Theme = {
            "Title": "MedSchool.PK",
            "Logo" : "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Group%20392.svg",
            "ColorScheme": "#0C6ee9",
            "adminUrl": "https://medschool-admin.parho.io",
            'Name': 'MedSchool',
            'urlconcat': '/undergraduate/medschool',
            'prefix' : 'medschool',
            'pricingPrefix': '/bundles/all in one',
            "WhatsAppNumber": '+923394059690',
            "url": "https://medschool.pk"
        }
    }
    else if (category.toUpperCase() === "PRE-ENGINEERING"){
        Theme = {
            "Title": "PreEng.PK",
            "Logo" : "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/pre-englogo.png",
            "ColorScheme": "#3F83F8",
            "adminUrl": "https://preengineering-admin.parho.io",
            "Name": "Pre-Engineering",
            'urlconcat': '/entrance-exam/pre-engineering',
            'prefix': "pre-engineering",
            'pricingPrefix': '/bundles/all in one',
            "WhatsAppNumber": '+923394059690',
            "url": "https://engineering.parho.io"
        }
    }
    else if (category.toUpperCase() === "ALLIED-HEALTH-SCIENCES") {
        Theme = {
            "Title": "Allied Health Sciences",
            "Logo" : "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Variant=FINAL%201.svg",
            "ColorScheme": "#7257AD",
            "SecondaryColor": "#E74694",
            "adminUrl": "https://ahs-admin.parho.io",
            "Name": "AHS",
            'urlconcat': '/entrance-exam/allied-health-sciences',
            'prefix': "allied-health-sciences",
            'pricingPrefix': '/bundles/all in one',
            "WhatsAppNumber": '+923362542685',
            "url": "https://ahs.parho.io"
        }
    }
    else if (category.toUpperCase() === "INTERNATIONAL") {
        Theme = {
            "Title": "SaudiPrep",
            "Logo" : "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/SaudiPrep.png",
            "ColorScheme": "#1D9391",
            "adminUrl": "https://international-admin.parho.io",
            "Name": "SaudiPrep",
            'urlconcat': '/entrance-exam/international',
            'prefix': "international",
            'pricingPrefix': '/bundles/all in one',
            "WhatsAppNumber": '+923362542685',
            "url": "https://saudiprep.com"
        }
    }
    return Theme
}



const GetCurrentCategoryTheme = () => {
    const category = process.env.REACT_APP_CATEGORY 
    return GetCategoryTheme(category)
}

const toggleMode = (mode, dispatch , setTheme) => {
    dispatch(setTheme(mode));
    const root = document.documentElement;
    const isDarkMode = mode === 'dark';

    // Toggle class names
    root.classList.toggle('dark-mode', isDarkMode);
    root.classList.toggle('light-mode', !isDarkMode);

    // Update CSS variables
    const cssVariables = {
        '--body-color': isDarkMode ? '#000000' : '#FFFFFF',
        '--shade-black-1': isDarkMode ? '#111827' : '#f9fafb',
        '--shade-black-2': isDarkMode ? '#1f2937' : '#f3f4f6',
        '--shade-black-3': isDarkMode ? '#374151' : '#e5e7eb',
        '--shade-black-4': isDarkMode ? '#4b5563' : '#d1d5db',
        '--shade-black-5': isDarkMode ? '#6b7280' : '#9ca3af',
        '--shade-black-6': isDarkMode ? '#9ca3af' : '#6b7280',
        '--shade-black-7': isDarkMode ? '#d1d5db' : '#4b5563',
        '--shade-black-8': isDarkMode ? '#e5e7eb' : '#374151',
        '--shade-black-9': isDarkMode ? '#f3f4f6' : '#1f2937',
        '--shade-black-10': isDarkMode ? '#FFFFFF' : '#111827',
        '--white': isDarkMode ? '#000000' : '#FFFFFF',
        '--black': isDarkMode ? '#FFFFFF' : '#000000',
        '--white-opacity-85': isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.85)',
        '--svg-fill': isDarkMode ? '#FFFFFF' : '#000000',
        '--svg-stroke': isDarkMode ? '#FFFFFF' : '#000000',
        '--clay-white': isDarkMode ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
        '--clay-box-shadow': isDarkMode
            ? '0px 10px 10px 0px rgba(0, 0, 0, 0.5), inset 0px -10px 8px 0px rgba(0, 0, 0, 0.6), inset 0px 5px 10px 0px rgb(0, 0, 0)'
            : '0px 10px 10px 0px rgba(255, 255, 255, 0.5), inset 0px -10px 8px 0px rgba(255, 255, 255, 0.6), inset 0px 5px 10px 0px rgb(255, 255, 255)',
        '--shadow-boxes': isDarkMode ? '#0E1013FF' : '#F7F6F7',
    };

    Object.entries(cssVariables).forEach(([key, value]) => {
        root.style.setProperty(key, value);
    });

    document.querySelectorAll('*').forEach(element => {
        if (!Array.from(element.classList).some(cls => cls.startsWith('text-'))) {
            element.classList.add(isDarkMode ? 'text-black' : 'text-[#1f2937]');
        }
    });


    document.querySelectorAll('img').forEach(img => {
        if (img.src.includes('https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/PreMed%20Logo%204.svg') && isDarkMode) {
            img.src = 'https://premedpk-cdn.sgp1.digitaloceanspaces.com/ManulaUpload/a4abfe9b-5c8f-4f4d-a2fd-9dd55ed9bc6e.png';
        }            
        if (img.src.includes('https://premedpk-cdn.sgp1.digitaloceanspaces.com/ManulaUpload/a4abfe9b-5c8f-4f4d-a2fd-9dd55ed9bc6e.png') && !isDarkMode) {
            img.src = 'https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/PreMed%20Logo%204.svg';
        }
    });

};

export { GetCategoryTheme , GetCurrentCategoryTheme, toggleMode };