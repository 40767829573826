import OwnBundle from "./(components)/own-bundle";
import PricingView from "./(components)/pricing-view";
import { GetCurrentCategoryTheme } from "./utils";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { backendUrl } from "constants/config";
// @ts-ignore
import styles from "./(components)/bundles.module.css";
import PricingCartModal from "./(components)/Pricing/PricingCartModal";
import CustomButton from "shared-components/form-fields/custom-button";
export const runtime = "edge";
const processData = (data) => {
  if (!data.success) return { notFound: true };
  const boards = data.publishedBoards;

  const pricings =
    data?.plans?.skus.filter((pricing) => pricing.isPublished === true) || [];
  const Toggles = data.plans?.togglesAndPricings
    .filter((toggle) => toggle.active === true)
    .sort((a, b) => a.position - b.position);
  return { boards, pricings, Toggles };
};

const PricingBundles = () => {
  const { slug } = useParams(); // Gets URL parameters
  const [data, setData] = useState(null);
  const [isViewCart, setIsViewCart] = useState(false);

  const Theme = GetCurrentCategoryTheme();
  const category = Theme.prefix;
  const currentCategory = process.env.REACT_APP_CATEGORY;
  const unit = slug
    ? decodeURIComponent(slug)
    : currentCategory === "PRE-MEDICAL"
    ? "mdcat"
    : "all in one";

  const fetchData = async (category, unit) => {
    const response = await fetch(
      `${backendUrl}/api/packages/getPackages/${category}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ board: unit?.toUpperCase() }),
        cache: "no-cache",
      }
    );

    return response.json();
  };
  useEffect(() => {
    const loadData = async () => {
      const data = await fetchData(category, unit);
      if (!data.notFound) {
        setData(processData(data));
      }
    };
    loadData();
  }, [category, unit]);

  if (!data) return <div>Loading...</div>;

  const { boards, pricings, Toggles } = data;

  return (
    <div className="w-full overflow-hidden">
      {/* {category === "allied-health-sciences" && <AHSTopOrderComponents />}
      {category === "pre-engineering" && <GenericTopOrderComponent category={category} testimonialsData={formattedTestimonialsData} />}
      {category === "pre-medical" && <PreMedicalHero testimonialsData={filteredData} />}
      {category === "international" && <GenericTopOrderComponent category={category} testimonialsData={formattedTestimonialsData} />} */}
      <div className="flex justify-end mr-10">
        <CustomButton
          text="View Carts"
          onClick={() => {
            setIsViewCart(true);
          }}
        />
      </div>

      <PricingView
        boards={boards}
        Toggles={Toggles}
        Theme={Theme}
        category={category}
        unit={unit}
        pricings={pricings}
      />

      <OwnBundle Theme={Theme} />
      <PricingCartModal openCart={isViewCart} setIsOpenCart={setIsViewCart} />
    </div>
  );
};

export default PricingBundles;
