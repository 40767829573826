import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import React, { useContext, useEffect, useState } from "react";
import FullScreenPopup from "./components/full-screen-popup";
import { allowAccess } from "./utils";
import { UserContext } from "state-management/user-context";

const CheckAuthorization = ({ children, role = "DIRECTOR" }) => {
  const [popup, setPopup] = useState({
    isOpen: false,
    message: "",
    heading: "",
    canDestroy: false,
  });

  const { user: loggedInUser } = useContext(UserContext);
  async function fetchLoggedinCommissionUser() {
    try {
      const response = await CommissionAPI("getLoggedinCommissionUser");
      if (response?.data?.success) {
        const loggedInUserData = response?.data?.data;
        console.log(
          "loggedInUserData: ",
          loggedInUser,
          allowAccess(loggedInUser)
        );
        if (allowAccess(loggedInUser)) {
          return;
        } else if (loggedInUserData?.role !== role) {
          setPopup({
            isOpen: true,
            message: "Unauthorized",
            canDestroy: false,
            heading: "",
          });
          return;
        }
      }
    } catch (error) {
      console.error("Error fetching targets:", error);
    }
  }

  useEffect(() => {
    if (loggedInUser?.isloggedin) {
      fetchLoggedinCommissionUser();
    }
  }, [loggedInUser]);

  if (popup?.isOpen) {
    return <FullScreenPopup popup={popup} setPopup={setPopup} />;
  }

  return <>{children}</>;
};

export default CheckAuthorization;
