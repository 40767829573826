import React, { useEffect, useState } from "react";
// import {
//   deletePurchasedUserPlan,
//   getUserByEmail,
//   giveManualAccessUsers,
//   resetPassword,
//   deletePurchasedUserSubscriptions,
// } from "../../APIs/UserAPI";
// import { GetOrdersbyEmail } from "../../APIManager";
import { notification, Select } from "antd";
// import { getCategoryPlans, getDistintTags } from "../../APIs/PlanAPI";
// import PricingPreview from "./components/PricingPreview";
import Loading from "../../shared-components/ui/Loading";
import CustomBadges from "../../shared-components/ui/CustomBadges";
import { DeleteIcon, EditIcon } from "../../shared-components/ui/Icons";
import { Button, message, Popconfirm } from "antd";
import { ButtonFill } from "../../shared-components/ui/CustomButtons";
import toast, { Toaster } from "react-hot-toast";
import TopBar from "./components/top-bar";
import { UserAPI } from "(apis)/(shared-apis)/user-apis";
import { OrderAPI } from "(apis)/(shared-apis)/order-apis";
import { apiRequest } from "(apis)/api-interface/api-handler";
import OrderHistory from "./components/orders";
// import MyOwnBundle from "./components/BuildOwn"

export default function Page() {
  const [user, setUser] = React.useState(null);
  const [email, setEmail] = React.useState<string>("");

  const [loading, setLoading] = React.useState(false);

  const [filteredFeatures, setFilteredFeatures] = React.useState([]);
  const [subscriptions, setSubscriptions] = React.useState([]);


  const FormatDate = (date: string) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const EntitiesAndCategories = [
    {
      entity: "high-school",
      categories: ["o-levels", "a-levels", "matric", "intermediate"],
    },
    {
      entity: "entrance-exam",
      categories: ["pre-engineering", "pre-medical", "business-management"],
    },
    {
      entity: "undergraduate",
      categories: ["medschool"],
    },
  ];

  const [selectedEntity, setSelectedEntity] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedunit, setUnit] = React.useState("all in one");
  const [planloader, setPlanLoader] = React.useState(false);

  const [boards, setBoards] = React.useState([]);
  const [pricing, setPricing] = React.useState([]);

  const SelectCategory = async (unit = "all in one") => {
    const category = process.env.REACT_APP_CATEGORY;
    setPlanLoader(true);
    setPricing([]);
    if (!selectedEntity) {
      notification.error({ message: "Please select entity" });
      return;
    }
    if (!category) {
      notification.error({ message: "Please select category" });
      return;
    }
    // const response = await getCategoryPlans(category, unit);
    // if (response.data.success) {
    //   setBoards(response.data.boards);
    //   setPricing(response.data?.plans?.pricingModel);
    // }

    setPlanLoader(false);
  };

  const DaysLeft = (date: string) => {
    let d = new Date(date);
    let today = new Date();
    let diff = d.getTime() - today.getTime();
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days;
  };

  const currentDate = new Date();



  const [fetchUser, setFetchUser] = React.useState(false);

  return (
    <div>
      <div className="w-full md:w-[95%] mx-auto h-full">
        <h1 className="text-3xl text-gray-700 font-bold">User Manager</h1>
        <Toaster />

        <TopBar
          loading={loading}
          email={email}
          setEmail={setEmail}
          setUser={setUser}
          setLoading={setLoading}
          setFetchUser={setFetchUser}
          setFilteredFeatures={setFilteredFeatures}
          setSubscriptions={setSubscriptions}
        />


        {loading ? <Loading /> : null}

        {user ? (
          <div className="mt-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <AccountDetails
                user={user}
                loading={loading}
                FormatDate={FormatDate}
              />
              <OrderHistory
                email={email}
                fetchUser={fetchUser}
              />
            </div>
          </div>
        ) : (
          <div className="mt-5">No user found</div>
        )}


      </div>

    </div>
  );
}

const AccountDetails = ({ user, loading, FormatDate }) => {

  const [password, setPassword] = React.useState("");

  const ResetPassword = async () => {
    if (!password) {
      toast.error("Please enter password");
      return;
    }
    if (!user) {
      toast.error("No user found");
      return;
    }
    try {
      const payload = {
        NewPassword: password,
        username: user.username,
      };
      const response = await UserAPI("resetPassword", [""], { ...payload });
      toast.success("Password reset successfully");
      setPassword("");
    } catch (error) {
      toast.error("Failed to reset password");
    }
  };


  return (
    <div className="w-full mx-auto flex flex-col p-5 border border-gray-300 rounded">
      <h1 className="text-xl text-gray-700 font-bold mb-4 mt-4">
        Account Details
      </h1>
      <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-5 h-fit">
        <div className="flex flex-col justify-start items-start">
          <div>
            {" "}
            <strong>Email:</strong> {user?.username}
          </div>
          <div className="mt-5">
            {" "}
            <strong>Fullname:</strong> {user?.fullname}
          </div>
          <div className="mt-5">
            {" "}
            <strong>Phone Number:</strong> {user?.phonenumber}
          </div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <div>
            {" "}
            <strong>City:</strong> {user?.city}
          </div>
          <div className="mt-5">
            {" "}
            <strong>School:</strong> {user?.school}
          </div>
          <div className="mt-5">
            {" "}
            <strong>Account Created Date:</strong>{" "}
            {FormatDate(user?.accountcreateddate)}
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full mb-5 border border-gray-300 rounded overflow-hidden mt-8">
        <input
          type="text"
          placeholder="New Password"
          className="p-1 md:p-2 w-full focus:outline-none"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <button
          className="bg-rose-500 text-white p-1 md:p-2 w-[50%] md:w-[30%] font-bold hover:bg-rose-600 focus:outline-none transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={ResetPassword}
          disabled={loading || password === ""}
        >
          Reset Password
        </button>
      </div>
    </div>
  )
}


const Features = ({ filteredFeatures, FormatDate, DaysLeft, currentDate, user, setFilteredFeatures }) => {

  const handleRemoveFeature = async (feature: any) => {
    try {
      const res = await UserAPI("deletePurchasedUserPlan", [user?._id, feature.planId]);
      if (res?.data?.isSuccess) {
        toast.success(res.data.message);
        message.success(res.data.message);
        setFilteredFeatures(res?.data?.remainingPlans);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  return (
    <>
      {filteredFeatures && filteredFeatures.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          {filteredFeatures?.map((feature: any, index: number) => (
            <div
              key={index}
              className="flex flex-col relative bg-white shadow p-4 rounded"
            >
              <div className="flex flex-row justify-between items-center gap-2">
                <div className="font-bold text-lg text-gray-700">
                  {feature?.planName.trim()}
                </div>
                <div>
                  <Popconfirm
                    title="Delete the Plan?"
                    description="Are you sure to delete this Plan?"
                    onConfirm={() => handleRemoveFeature(feature)}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-red-500 cursor-pointer"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </Popconfirm>
                </div>
              </div>
              {feature?.features.length > 0 && (
                <div className="flex flex-col justify-start gap-2 mt-4">
                  <h1 className="text-sm font-bold text-gray-500 text-left">
                    Access to:{" "}
                  </h1>
                  <div className="flex flex-row gap-2 flex-wrap">
                    {feature?.features.map((access, index) => (
                      <div
                        key={index}
                        className="flex flex-row items-center gap-2 border-r-2 border-gray-400 pr-2"
                      >
                        <p className="text-sm text-gray-600">
                          {access.replace(/-/g, " ")}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex flex-row justify-between items-center gap-2 mt-4">
                <div className="flex flex-col">
                  <div className="flex text-sm  flex-row items-center text-gray-500 gap-2">
                    <h1 className="font-bold text-gray-500">
                      Subscription Bought at:{" "}
                    </h1>
                    <div>
                      {FormatDate(feature?.subscriptionStartDate)}{" "}
                    </div>
                  </div>
                  <div className="flex text-sm  flex-row items-center text-gray-500 gap-2">
                    <h1 className="font-bold text-gray-500">
                      Subscription Ends at:{" "}
                    </h1>
                    <div>{FormatDate(feature?.subscriptionEndDate)} </div>
                  </div>
                </div>

                {currentDate < new Date(feature.subscriptionEndDate) ? (
                  <div>
                    <p className="text-primary text-2xl font-bold">
                      {DaysLeft(feature.subscriptionEndDate)}
                    </p>
                    <div className="text-gray-600 text-xs">Days Left</div>
                  </div>
                ) : (
                  <div>
                    <p className="text-primary text-2xl font-bold">
                      Expired
                    </p>
                    <div className="text-gray-600 text-xs">
                      Subscription
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white shadow-md p-4 rounded-md">
          <h1 className="text-xl font-bold text-gray-800">
            No Subscriptions
          </h1>
        </div>
      )}
    </>
  )
}

const Subscriptions = ({ subscriptions, setSubscriptions, currentDate, FormatDate, DaysLeft, user }) => {

  const RemoveSubscription = async (subscription: any) => {
    try {
      const res = await UserAPI("deletePurchasedUserSubscription", [user?._id, subscription._id]);
      if (res?.data?.isSuccess) {
        toast.success(res.data.message);
        setSubscriptions(res?.data?.remainingSubscriptions);
      }


    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    }
  };

  return (
    <>
      <h1 className="text-[24px] font-[600] text-black leading-[20px] mt-6 mb-6">
        Subscriptions{" "}
      </h1>
      {subscriptions && subscriptions.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          {subscriptions?.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col relative bg-white shadow p-4 rounded"
            >
              <div className="flex flex-row justify-between items-center gap-2">
                <div className="font-bold text-lg text-gray-700">
                  {feature?.SubscriptionName.trim()}
                </div>
                <div>
                  <Popconfirm
                    title="Delete the Plan?"
                    description="Are you sure to delete this Plan?"
                    onConfirm={() => RemoveSubscription(feature)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-red-500 cursor-pointer"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </Popconfirm>
                </div>
              </div>

              <div className="flex flex-row justify-between items-center gap-2 mt-4">
                <div className="flex flex-col">
                  <div className="flex text-sm  flex-row items-center text-gray-500 gap-2">
                    <h1 className="font-bold text-gray-500">
                      Subscription Status:{" "}
                    </h1>
                    <div>{feature?.SubscriptionStatus} </div>
                  </div>
                  <div className="flex text-sm  flex-row items-center text-gray-500 gap-2">
                    <h1 className="font-bold text-gray-500">
                      Subscription Starts at:{" "}
                    </h1>
                    <div>
                      {FormatDate(feature?.SubscriptionStartDate)}{" "}
                    </div>
                  </div>
                  <div className="flex text-sm  flex-row items-center text-gray-500 gap-2">
                    <h1 className="font-bold text-gray-500">
                      Subscription Ends at:{" "}
                    </h1>
                    <div>{FormatDate(feature?.SubscriptionEndDate)} </div>
                  </div>
                </div>

                {currentDate < new Date(feature.SubscriptionEndDate) ? (
                  <div>
                    <p className="text-primary text-2xl font-bold">
                      {DaysLeft(feature.SubscriptionEndDate)}
                    </p>
                    <div className="text-gray-600 text-xs">Days Left</div>
                  </div>
                ) : (
                  <div>
                    <p className="text-primary text-2xl font-bold">
                      Expired
                    </p>
                    <div className="text-gray-600 text-xs">
                      Subscription
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white shadow-md p-4 rounded-md">
          <h1 className="text-xl font-bold text-gray-800">
            No Subscriptions
          </h1>
        </div>
      )}
    </>
  )
}

const ManualAccess = ({ user }) => {

  const [newExpiryDate, setNewExpiryDate] = React.useState("");
  const [accessLoader, setAccessLoader] = React.useState(false);
  const [accessToBeGiven, setAccessToBeGiven] = React.useState([]);
  const [accessTag, setAccessTag] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await apiRequest("getDistintTags")
        setAccessTag(response?.data?.tags);
      } catch (err) {
        console.error("Error fetching tags:", err);
      }
    };

    fetchTags();
  }, []);


  const GiveAccess = async () => {
    if (!accessToBeGiven.length) {
      toast.error("Please select access");
      return;
    }
    if (!newExpiryDate) {
      toast.error("Please select expiry date");
      return;
    }

    setAccessLoader(true);

    const payload = {
      userId: user._id,
      features: accessToBeGiven,
      expiryDate: newExpiryDate,
    };

    try {
      const res = await UserAPI("addManualAccess", [""], { ...payload });
      console.log(res);
      if (res.data.success) {
        toast.success("Access given successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }

    setAccessLoader(false);
  };


  return (

    <div className="bg-white shadow-md p-4 rounded-md mt-5">
      <h1 className="text-xl font-bold text-gray-800">
        Give Feature Access
      </h1>
      <Select
        mode="multiple"
        allowClear
        className="bg-gray-50 p-2 rounded-md"
        style={{ width: "100%" }}
        placeholder="Select Tags"
        onChange={(value) => setAccessToBeGiven(value)}
      >
        {accessTag
          .filter((tag) => !accessToBeGiven.includes(tag))
          .map((tag, index) => (
            <Select.Option key={index} value={tag}>
              {tag}
            </Select.Option>
          ))}
      </Select>

      <div className="flex justify-end mt-4 gap-5 items-center">
        <div className="flex items-center gap-5 justify-start">
          <input
            type="date"
            className="border border-gray-300 p-2 rounded-md"
            value={newExpiryDate}
            onChange={(e) => setNewExpiryDate(e.target.value)}
          />
        </div>

        <ButtonFill
          handleClick={GiveAccess}
          disabled={accessToBeGiven.length === 0 || !newExpiryDate}
          isLoading={accessLoader}
        >
          Give Access
        </ButtonFill>
      </div>
    </div>

  )
}

// {user && (
//   <>

//     <Features
//       filteredFeatures={filteredFeatures}
//       FormatDate={FormatDate}
//       currentDate={currentDate}
//       DaysLeft={DaysLeft}
//       setFilteredFeatures={setFilteredFeatures}
//       user={user}
//     />

//     <Subscriptions
//       subscriptions={subscriptions}
//       setSubscriptions={setSubscriptions}
//       user={user}
//       currentDate={currentDate}
//       DaysLeft={DaysLeft}
//       FormatDate={FormatDate}
//     />

//     <ManualAccess
//       user={user}
//     />

//     <div className="flex flex-col p-5 border border-gray-300 rounded mt-10">
//       {/* <div className="flex justify-center flex-wrap w-full gap-2 mx-auto mb-5 rounded overflow-hidden">
//         {EntitiesAndCategories.map((entity, index) => (
//           <button
//             key={index}
//             className={`p-2 w-fit md:w-[30%] 
//                     border border-gray-300 rounded 
//                     font-bold hover:bg-rose-600 hover:text-white focus:outline-none transition duration-300 ease-in-out ${selectedEntity === entity.entity
//                 ? "bg-rose-500 text-white"
//                 : "bg-white text-black"
//               }`}
//             onClick={() => setSelectedEntity(entity.entity)}
//           >
//             {entity.entity.toUpperCase().replace("-", " ")}
//           </button>
//         ))}
//       </div>

//       <div className="inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-transparent mt-4 via-gray-300 to-transparent"></div>

//       <div className="flex justify-center flex-wrap w-full gap-2 mx-auto mt-5 mb-5 rounded overflow-hidden">
//         {EntitiesAndCategories.find(
//           (entity) => entity.entity === selectedEntity
//         )?.categories.map((category, index) => (
//           <button
//             key={index}
//             className={`p-2 w-fit md:w-[30%] 
//                     border border-gray-300 rounded 
//                     font-bold hover:bg-rose-600 hover:text-white focus:outline-none transition duration-300 ease-in-out
//                     ${selectedCategory === category
//                 ? "bg-rose-500 text-white"
//                 : "bg-white text-black"
//               }
//                     `}
//             onClick={() => {
//               SelectCategory(category);
//               // setSelectedCategory(category, "all in one");
//             }}
//           >
//             {category.toUpperCase().replace("-", " ")}
//           </button>
//         ))}
//       </div>
// */}
//       <div className="inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-transparent mt-4 via-gray-300 to-transparent"></div>

//       <div className="flex justify-center flex-wrap w-full gap-2 mx-auto mt-5 mb-5 rounded overflow-hidden">
//       <ButtonFill
//         handleClick={() => {
//           SelectCategory();
//         }
//         }
//       >
//         Get Plans
//       </ButtonFill>
//       </div>

//       {/* units */}
//       <div className="flex justify-center flex-wrap w-full gap-2 mx-auto mt-5  mb-5 rounded overflow-hidden">
//         {boards &&
//           boards.map((board, index) => (
//             <button
//               key={index}
//               className={`p-2 w-fit md:w-[30%] 
//                     border border-gray-300 rounded 
//                     font-bold hover:bg-rose-600 hover:text-white focus:outline-none transition duration-300 ease-in-out
//                     ${board.toUpperCase() === selectedunit.toUpperCase()
//                   ? "bg-rose-500 text-white"
//                   : "bg-white text-black"
//                 }
//                     `}
//               onClick={() => {
//                 setUnit(board.toLowerCase());
//                 SelectCategory(board.toLowerCase());
//               }}
//             >
//               {board.toUpperCase().replace("-", " ")}
//             </button>
//           ))}
//       </div>

//       <div className="inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-transparent mt-4 mb-5 via-gray-300 to-transparent"></div>

//       {/* {pricing && pricing.length > 0 && (
//         <>
//           <PricingPreview
//             pricings={pricing}
//             category={selectedCategory}
//             unit={selectedunit}
//             user={user}
//             RetrieveUser={RetrieveUser}
//           />
//         </>
//       )} */}
//     </div>
//   </>
// )}
