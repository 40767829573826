import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { DeckGroupAPI } from "../../../(apis)/(shared-apis)/academics/deckgroups-api";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ButtonFill } from "../../../shared-components/ui/CustomButtons";
import DeckAddModal from "./components/add-deck-modal";
import TableContainer from "./components/table-view";
import BluckkUploadDeckModal from "./components/upload-bluck-deck";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";

export default function Main() {
  const { deckGroupName, categoryName } = useParams();
  console.log("deckGroupName", deckGroupName);
  const [isLoading, setIsLoading] = useState(false);

  const [decks, setDecks] = useState([]);
  const [originalDecks, setOriginalDecks] = useState([]);
  const [ShowModel, setShowModel] = useState(false);
  const [ShowNotFoundIdModel, setShowNotFoundIdShowModel] = useState(false);
  const [notFoundIds, setNotFoundIds] = useState([]);
  const [isUploadShowBluckModel, setIsUploadShowBluckModel] = useState(false);
  const GetDecks = async () => {
    const Response = await DeckGroupAPI(
      "getDecksOfDeckGroup",
      categoryName + "/" + deckGroupName
    );
    setDecks(Response?.data?.data?.decks);
    setOriginalDecks(Response?.data?.data?.decks);
  };

  useEffect(() => {
    GetDecks();
  }, []);

  const moveQuestion = (fromIndex: number, toIndex: number) => {
    const updatedDecks = [...decks];
    const [movedDecks] = updatedDecks.splice(fromIndex, 1);
    updatedDecks.splice(toIndex, 0, movedDecks);
    setDecks(updatedDecks);
  };

  const [mode, setMode] = useState("add");
  const [populated, setPopulated] = useState(null);

  const UpdateOrder = async () => {
    const orderOfIds = decks.map((deck) => deck._id);
    const Response = await DeckGroupAPI("updateDeckGroupOrder", "", {
      deckGroupName,
      payload: orderOfIds,
    });
    if (Response.data.success) {
      toast.success(Response.data.message);
      GetDecks();
    } else {
      toast.error(Response.data.message);
    }
  };

  return (
    <div className="w-[95%] mx-auto h-full">
      <Toaster />
      <div className="flex flex-row flex-wrap items-center justify-between">
        <h1 className="text-3xl text-gray-700 font-bold">Decks Manager</h1>
        <div className="flex flex-row gap-2">
          <ButtonFill
            handleClick={UpdateOrder}
            disabled={JSON.stringify(decks) === JSON.stringify(originalDecks)}
          >
            Update Order
          </ButtonFill>
          <ButtonFill
            handleClick={() => {
              setShowModel(true);
              setMode("add");
            }}
          >
            Add Deck
          </ButtonFill>
          <ButtonFill
            handleClick={() => {
              setIsUploadShowBluckModel(true);
            }}
            icon={<CloudUploadOutlined />}
          >
            Upload Bulk Deck
          </ButtonFill>
        </div>
      </div>

      <section className="my-5 antialiased">
        <div className="mx-auto">
          {isLoading ? (
            "Loading..."
          ) : (
            <div className="bg-white shadow-md sm:rounded-lg">
              <TableContainer
                decks={decks}
                setDecks={setDecks}
                GetDecks={GetDecks}
                moveQuestion={moveQuestion}
                setMode={setMode}
                prepopulateData={populated}
                setPopulated={setPopulated}
                setShowModel={setShowModel}
                deckGroupName={deckGroupName}
                categoryName={categoryName}
              />
            </div>
          )}
        </div>
      </section>

      <DeckAddModal
        deckGroupName={deckGroupName}
        ShowModel={ShowModel}
        setShowModel={setShowModel}
        GetDecks={GetDecks}
        categoryName={categoryName}
        mode={mode}
        prepopulateData={populated}
        setPopulated={setPopulated}
        setMode={setMode}
      />
      <BluckkUploadDeckModal
        ShowModel={isUploadShowBluckModel}
        setShowModel={setIsUploadShowBluckModel}
        deckGroupName={deckGroupName}
        getQuestions={GetDecks}
        SetNotFoundIds={setNotFoundIds}
        setShowNotFoundIdShowModel={setShowNotFoundIdShowModel}
      />

      <CustomAntdModal
        isOpenModal={ShowNotFoundIdModel}
        setIsOpenModal={setShowNotFoundIdShowModel}
        isFooter={false}
        title="Deck(s) not found"
        width={700}
      >

        <div className="flex flex-col gap-2">
          {notFoundIds?.map((id) => (
            <div key={id}> {id} </div>
          ))}
        </div>
      </CustomAntdModal>
    </div>
  );
}
