import { PromoCodeAPI } from "(apis)/(shared-apis)/medschool/promocode-apis";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

const ViewCodeStats = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [promocode, setPromocode] = useState(null);
  const params = useParams();
  const id = params?.id;

  useEffect(() => {
    if (id) {
      fetchPromoCodes();
    }
  }, [id]);

  async function fetchPromoCodes() {
    setIsLoading(true);
    try {
      const response = await PromoCodeAPI("getPromoCodeById", [id]);
      if (response?.data?.success) {
        const data = response?.data?.data;
        setPromocode(data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong.";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <div className="text-center py-10">Loading...</div>;

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <Toaster />

      {promocode ? (
        <div>
          {/* Promo Code Details */}
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              Code Details
            </h2>
            <div className="grid grid-cols-2 gap-4 text-gray-600">
              <p>
                <span className="font-semibold">Name:</span> {promocode.name}
              </p>
              <p>
                <span className="font-semibold">Email:</span> {promocode.email}
              </p>
              <p>
                <span className="font-semibold">Code:</span> {promocode.code}
              </p>
              <p>
                <span className="font-semibold">Created By:</span>{" "}
                {promocode.createdBy}
              </p>
              <p>
                <span className="font-semibold">Status:</span>{" "}
                <span
                  className={`px-2 py-1 rounded text-xs font-medium ${
                    promocode.isActive
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  }`}
                >
                  {promocode.isActive ? "Active" : "Inactive"}
                </span>
              </p>
              <p>
                <span className="font-semibold">Created At:</span>{" "}
                {new Date(promocode.createdAt).toLocaleDateString()}
              </p>
              <p>
                <span className="font-semibold">Updated At:</span>{" "}
                {new Date(promocode.updatedAt).toLocaleDateString()}
              </p>
            </div>
          </div>

          {/* Signup IDs Table */}
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Signups</h3>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-600 border rounded-lg">
              <thead className="bg-gray-50 border-b">
                <tr className="text-xs font-semibold text-gray-700 uppercase">
                  <th className="px-4 py-3">Full Name</th>
                  <th className="px-4 py-3">Phone Number</th>
                  <th className="px-4 py-3">University</th>
                  <th className="px-4 py-3">Year</th>
                  <th className="px-4 py-3">Signup Date</th>
                </tr>
              </thead>
              <tbody>
                {promocode?.signupIds?.length > 0 ? (
                  promocode?.signupIds.map((signup: any) => (
                    <tr key={signup._id} className="border-b hover:bg-gray-50">
                      <td className="px-4 py-2">{signup.fullname}</td>
                      <td className="px-4 py-2">{signup.phonenumber}</td>
                      <td className="px-4 py-2">{signup.university}</td>
                      <td className="px-4 py-2">{signup.year}</td>
                      <td className="px-4 py-2">
                        {new Date(signup.signupDate).toLocaleDateString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="px-4 py-3 text-center text-gray-500"
                    >
                      No signups available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500">No data available</p>
      )}
    </div>
  );
};

export default ViewCodeStats;
