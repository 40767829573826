import * as Yup from "yup";

export const paymentValidationSchema = Yup.object().shape({

    type: Yup.string().required("Type is required"),

    // Conditional validation for 'serviceProvider'
    serviceProvider: Yup.string().when("type", (type: any) => {
      if (type?.[0] === "External") {
        return Yup.string().required("Service provider is required");
      }
      return Yup.string().nullable();
    }),

    // Validation for 'amount'
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive"),
      accountNumber:Yup.string().required("Account number is required"),
      bankName: Yup.string().required("Bank name is required"),
    // Conditional validation for 'Payment' and 'PaymentBank'
    employee: Yup.string().when("type", (type: any) => {
      if (type?.[0] === "Internal") {
        return Yup.string().required("Employee is required");
      }
      return Yup.string().nullable();
    }),

    // Screenshot is optional
    screenshot: Yup.mixed().nullable(),
  });