// Constants for Environment Settings
const ENVIRONMENTS = {
  DEVELOPMENT: "DEVELOPMENT",
  PRODUCTION: "PRODUCTION",
};

const currentEnvironment = ENVIRONMENTS.PRODUCTION; // Can switch between DEVELOPMENT or PRODUCTION
const BACKEND_URLS = {
  "PRE-MEDICAL": {
    DEVELOPMENT: "http://127.0.0.1:4006",
    PRODUCTION: "https://premed-server.parho.io", 
  },
  MEDSCHOOL: {
    DEVELOPMENT: "http://127.0.0.1:4007",
    PRODUCTION: "https://medschool-server.parho.io",
  },
  "PRE-ENGINEERING": {
    DEVELOPMENT: "http://127.0.0.1:4008",
    PRODUCTION: "https://preengineering-server.parho.io",
  },
  INTERNATIONAL: {
    DEVELOPMENT: "http://127.0.0.1:4009",
    PRODUCTION: "https://international-server.parho.io",
  },
  "ALLIED-HEALTH-SCIENCES": {
    DEVELOPMENT: "http://127.0.0.1:4010",
    PRODUCTION: "https://ahs-server.parho.io",
  },
  FINANCE: {
    DEVELOPMENT: "http://127.0.0.1:4012",
    PRODUCTION: "https://finance-server.parho.io",
  },
};

const getBackendUrl = () => {
  const category = process.env.REACT_APP_CATEGORY || "PRE-MEDICAL"; // Default to "PRE-MEDICAL"
  const environment = BACKEND_URLS[category]
    ? currentEnvironment
    : ENVIRONMENTS.DEVELOPMENT; // Fallback to DEVELOPMENT if category not found
  return BACKEND_URLS[category][environment];
};

const GetBackendUrlForCategory = (category: string) => {
  const environment = BACKEND_URLS[category]
    ? currentEnvironment
    : ENVIRONMENTS.DEVELOPMENT; // Fallback to DEVELOPMENT if category not found
  return BACKEND_URLS[category][environment];
};

const backendUrl = getBackendUrl();
export { backendUrl, GetBackendUrlForCategory };
