
import { ApiInterface } from "../api-interface/types";

export const subjectapis: ApiInterface[] = [
    {
        url: "/api/subject",
        method: "GET",
        name: "getallsubjects",
    },
    {
        url: "/api/subject",
        method: "GET",
        name: "getSubjects",
    },
    {
        url: "/api/subject/add",
        method: "POST",
        name: "addSubject",
    },
    {
        url: "/api/subject/update/:id",
        method: "PUT",
        name: "updateSubject",
    },
    {
        url: "/api/subject/updateActive/:id",
        method: "PATCH",
        name: "updateSubjectActive",
    },
]