import React, { useState, useRef, useEffect, useContext } from "react";
import * as d3 from "d3";
///@ts-ignore
import Background from "./background.png";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { useParams } from "react-router-dom";
import { useTimer } from 'react-timer-hook';
import { Button, Modal } from "antd";
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api";
import toast, { Toaster } from "react-hot-toast";
import { UserContext } from "state-management/user-context";
import RecordRTC from 'recordrtc';

export default function ExpertInterface() {
    const [penColor, setPenColor] = useState("#000000");
    const [penWidth, setPenWidth] = useState(2);
    const [isRecording, setIsRecording] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isErasing, setIsErasing] = useState(false);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
    const { type, id } = useParams();
    const [DoubtGet, setDoubtGet] = useState<any>();
    const [showDoubt, setShowDoubt] = useState(false);
    const timerDuration = 180;
    const [showModal, setShowModal] = useState(false);

    const [userDoubt, setUserDoubt] = useState<any>()
    const {
        totalSeconds,
        seconds,
        minutes,      
        pause,
        restart,
    } = useTimer({ expiryTimestamp: new Date(Date.now() + timerDuration * 1000), autoStart: false, onExpire: () => console.warn('onExpire called') });

    const [recorder, setRecorder] = useState<RecordRTC | null>(null);
    const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
    const [webcamStream, setWebcamStream] = useState<MediaStream | null>(null);

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

    useEffect(() => {
        const Check = async () => {
            if (id && type === "subjective") {
                const response = await apiRequest("GetSubjectiveDoubt", {}, [id]);
                const data = response.data;
                setDoubtGet(data?.Details);
            }
            else if (id && type === "objective") {
                const response = await ExpertAPI("get-user-doubt", id);
                if (response.data.success) {
                    const data = response.data.data;
                    setUserDoubt(data)
                }
            }
        };

        Check();
    }, [id]);

    useEffect(() => {
        if (svgRef.current) {
            const svg = d3.select(svgRef.current);
            const width = svg.node()?.clientWidth || 800;
            const height = svg.node()?.clientHeight || 600;

            svg.attr("width", width).attr("height", height);

            const getPointerPosition = (event: any) => {
                const touch = event.touches ? event.touches[0] : event;
                const svgElement = svgRef.current;
                const rect = svgElement.getBoundingClientRect();
                
                const x = touch.clientX - rect.left;
                const y = touch.clientY - rect.top;
                return [x, y];
            };

            const handleStart = (event: any) => {
                event.preventDefault();
                setIsDrawing(true);
                const [x, y] = getPointerPosition(event);
                svg.append("path")
                    .attr("stroke", isErasing ? "white" : penColor)
                    .attr("stroke-width", penWidth)
                    .attr("fill", "none")
                    .attr("d", `M${x},${y}`);
            };

            const handleMove = (event: any) => {
                event.preventDefault();
                if (!isDrawing) return;
                const [x, y] = getPointerPosition(event);
                const activePath = svg.select("path:last-child");
                const d = activePath.attr("d");
                activePath.attr("d", `${d} L${x},${y}`);
            };

            const handleEnd = () => {
                setIsDrawing(false);
            };

            svg.node().addEventListener('touchstart', handleStart, { passive: false });
            svg.node().addEventListener('touchmove', handleMove, { passive: false });
            svg.node().addEventListener('touchend', handleEnd);
            
            svg.on("mousedown", handleStart);
            svg.on("mousemove", handleMove);
            svg.on("mouseup", handleEnd);
            svg.on("mouseleave", handleEnd);

            return () => {
                svg.node().removeEventListener('touchstart', handleStart);
                svg.node().removeEventListener('touchmove', handleMove);
                svg.node().removeEventListener('touchend', handleEnd);
                svg.on("mousedown", null);
                svg.on("mousemove", null);
                svg.on("mouseup", null);
                svg.on("mouseleave", null);
            };
        }
    }, [penColor, penWidth, isDrawing, isErasing]);

    const startRecording = async () => {
        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true
            });
            const webcam = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });

            const mergedStream = new MediaStream();
            screenStream.getTracks().forEach(track => mergedStream.addTrack(track));
            webcam.getTracks().forEach(track => mergedStream.addTrack(track));

            const mediaRecorder = new RecordRTC(mergedStream, {
                type: 'video',
                mimeType: 'video/mp4'
            });

            mediaRecorder.startRecording();
            setRecorder(mediaRecorder);
            setIsRecording(true);
            setScreenStream(screenStream);

            // Start timer
            const time = new Date();
            time.setSeconds(time.getSeconds() + timerDuration);
            restart(time);
        } catch (error) {
            console.error("Error starting recording:", error);
        }
    };

    const stopRecording = () => {
        if (recorder && recorder.state !== 'stopped') {
            recorder.stopRecording(() => {
                const blob = recorder.getBlob();
                setRecordedBlob(blob);
                setIsRecording(false);
                pause();
                
                if (screenStream) {
                    screenStream.getTracks().forEach(track => track.stop());
                    setScreenStream(null);
                }
            });
        }
    };

    const clearCanvas = () => {
        if (svgRef.current) {
            d3.select(svgRef.current).selectAll("path").remove();
        }
    };

    const [uploadedBlob, setUploadedBlob] = useState(null);
    const handleVideoUpload = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const blob = new Blob([reader.result], { type: file.type });
            setUploadedBlob(blob);
            setUploadedFileName(file.name);
        };

        reader.readAsArrayBuffer(file);
    };

    useEffect(() => {
        if (isRecording) {
            const time = new Date();
            time.setSeconds(time.getSeconds() + timerDuration);
            restart(time);
        }
    }, [isRecording]);

    useEffect(() => {
        if (totalSeconds <= 0) {
            stopRecording();
            setShowModal(true);
        }
    }, [totalSeconds]);

    const handleReRecord = () => {
        setShowModal(false);
        startRecording();
    };

    const handleSubmit = () => {
        setShowModal(false);
    };

    const { user } = useContext(UserContext);
    const uploadVideo = async () => {
        try {
            if (DoubtGet?.ExpireDate && type === "objective") {
                const date = new Date(DoubtGet?.ExpireDate);
                const currentDate = new Date();
                if (date < currentDate) {
                    toast.error("Doubt has expired");
                    stopRecording();
                    return;
                }
            }

            let videoToUpload: Blob | null = uploadedBlob;
            
            if (!uploadedBlob && recordedBlob) {
                videoToUpload = recordedBlob;
            }

            if (!videoToUpload) {
                toast.error("Please record or upload a video first");
                return;
            }

            if (type === "objective") {
                const formData = new FormData();
                formData.append("video", videoToUpload);
                formData.append("username", user.username);
                formData.append("DoubtId", userDoubt?._id);
                const res = await apiRequest("uploadExpertVideo", formData);
                if (res.data.success) {
                    toast.success("Your video is uploaded successfully and will be reviewed by our students");
                } else {
                    toast.error("There was an error in uploading your video");
                }    
            }
            else if (type === "subjective") {
                const formData = new FormData();
                formData.append("video", videoToUpload);
                formData.append("username", user.username);
                formData.append("DoubtId", DoubtGet?._id);
                formData.append("topic", DoubtGet?.meta?.topic);
                formData.append("subtopic", DoubtGet?.meta?.subtopic);
                formData.append("resource", "Premed QBank");
               const res = await  apiRequest("uploadSubjective", formData)
               if (res.data.success) {
                    toast.success("Your video is uploaded successfully and will be reviewed by our students");
                } else {
                    toast.error(res?.data?.message);
                }
            }
            
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex-grow relative pt-16 md:pt-0">
                <svg ref={svgRef} 
                    className="w-full h-[calc(100vh-4rem)] md:h-[200vh] border border-gray-300"
                    style={{
                        backgroundImage: `url("https://premedpk-cdn.sgp1.digitaloceanspaces.com/ManulaUpload/7cbb3822-ce01-4064-b90d-94af3ab7c9c1.png")`,
                        backgroundSize: "100% 100%",
                        backgroundRepeat: "no-repeat",
                        display: "flex",
                        flexDirection: "column",
                    }}
                ></svg>

                <div className="fixed top-0 left-0 right-0 md:absolute md:top-0 md:left-1/2 md:-translate-x-1/2 z-10">
                    <div className="flex flex-col md:flex-row flex-wrap items-center justify-center bg-white rounded-lg shadow-lg gap-2 md:gap-6 p-2 md:px-4 md:py-2 mx-2 md:mx-0">
                        <div className="flex flex-col items-center justify-center w-full md:w-auto">
                            <label className="block text-xs md:text-sm font-medium text-gray-700">Pen Settings</label>
                            <div className="flex flex-row items-center justify-center gap-2">
                                <input
                                    type="color"
                                    value={penColor}
                                    onChange={(e) => setPenColor(e.target.value)}
                                    className="mt-1 block w-8 h-8 border-2 border-white rounded-full"
                                />
                                <div className={`bg-black w-2 h-2 rounded-full ${penWidth === 1 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(1)}></div>
                                <div className={`bg-black w-3 h-3 rounded-full ${penWidth === 2 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(2)}></div>
                                <div className={`bg-black w-4 h-4 rounded-full ${penWidth === 3 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(3)}></div>
                                <div className={`bg-black w-5 h-5 rounded-full ${penWidth === 4 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(4)}></div>
                                <div className={`bg-black w-6 h-6 rounded-full ${penWidth === 5 ? "border border-rose-500" : ""}`} onClick={() => setPenWidth(5)}></div>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-center justify-center gap-2 w-full md:w-auto">
                            <button
                                onClick={clearCanvas}
                                className="cursor-pointer bg-red-500 text-white px-2 text-xs md:text-sm py-1 md:py-[0.65rem] font-bold rounded hover:bg-red-600"
                            >
                                Clear
                            </button>
                            {!isRecording && (
                                <button
                                    onClick={startRecording}
                                    className={`${isRecording ? "bg-red-500" : "bg-green-500"
                                        } text-white px-2 text-sm py-[0.65rem] font-bold rounded hover:opacity-90 cursor-pointer transition-all duration-300`}
                                    disabled={isRecording}
                                >
                                    {"Start Recording"}
                                </button>
                            )}
                            {isRecording && (
                                <>
                                    <button
                                        onClick={stopRecording}
                                        className="cursor-pointer bg-red-500 text-white px-2 text-sm py-[0.65rem] font-bold rounded hover:bg-red-600"
                                        disabled={!isRecording}
                                    >
                                        Stop Recording
                                    </button>
                                </>
                            )}                          
                            <button
                                onClick={() => setShowDoubt(!showDoubt)}
                                className="cursor-pointer bg-blue-500 text-white px-2 text-sm py-[0.65rem] font-bold rounded hover:bg-blue-600"
                            >
                                {showDoubt ? "Hide Doubt" : "Show Doubt"}
                            </button>
                            <div className="flex flex-col items-center">
                                <input type="file" accept="video/mp4" onChange={handleVideoUpload} className="hidden" id="video-upload" />
                                <label htmlFor="video-upload" className="cursor-pointer bg-blue-500 text-white px-2 text-sm py-[0.65rem] font-bold rounded hover:bg-blue-600"
                                    onClick={() => document.getElementById("video-upload")?.click()}
                                >
                                    Upload Video
                                </label>
                                {uploadedFileName && (
                                    <span className="text-xs text-gray-600 mt-1">{uploadedFileName}</span>
                                )}
                            </div>
                            {(recordedBlob || uploadedBlob) && (
                                <button
                                    onClick={uploadVideo}
                                    className="cursor-pointer bg-blue-500 text-white px-2 text-sm py-[0.65rem] font-bold rounded hover:bg-blue-600"
                                >
                                    Submit Video
                                </button>
                            )}
                        </div>

                    </div>
                </div>

                <ObjectiveDoubtViewer
                    showDoubt={showDoubt}
                    userDoubt={userDoubt}
                    type={type}
                />

                <SubjectiveDoubtViewer
                    showDoubt={showDoubt}
                    DoubtGet={DoubtGet}
                    type={type}
                />

                <TimerView
                    minutes={minutes}
                    seconds={seconds}
                />

                <Toaster position="bottom-center" />
            </div>

            {showModal && (
                <TimesUpModal
                    showModal={showModal}
                    handleReRecord={handleReRecord}
                    handleSubmit={handleSubmit}
                    setShowModal={setShowModal}
                />
            )}
        </div>

    );
}


const ObjectiveDoubtViewer = ({ showDoubt, userDoubt, type }: { showDoubt: boolean, userDoubt: any, type: string }) => {
    return (
        <div className="fixed bottom-0 left-0 right-0 md:absolute md:top-0 md:left-0 md:right-auto z-10">
            {showDoubt && type === "objective" && (
                <div className="bg-white p-4 rounded-lg shadow-lg w-full md:w-[30vw] max-h-[50vh] md:max-h-[80vh] overflow-y-auto mx-2 md:mx-0 mb-2 md:mb-0">
                    <div className="mb-4">
                        <h6>
                            <div className="flex flex-row items-center justify-start gap-2">
                                <div className="bg-gray-200 p-2 py-1 text-sm font-bold w-fit rounded">
                                    {userDoubt?.subject}
                                </div>
                                <div className="bg-gray-200 p-2 py-1 text-sm font-bold w-fit rounded">
                                    {userDoubt?.resource}
                                </div>
                            </div>
                            <span className="font-bold">User Description:</span>{" "}
                            <div
                                dangerouslySetInnerHTML={{ __html: userDoubt?.description }}
                                style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                }}
                            />
                        </h6>
                        {userDoubt.img && (
                            <img
                                src={userDoubt?.img}
                                className="w-full h-full object-cover"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

const SubjectiveDoubtViewer = ({ showDoubt, DoubtGet, type }: { showDoubt: boolean, DoubtGet: any, type: string }) => {
    return (
        <div className="fixed bottom-0 left-0 right-0 md:absolute md:top-0 md:left-0 md:right-auto z-10">
            {showDoubt && type === "subjective" && (
                <div className="bg-white p-4 rounded-lg shadow-lg w-full md:w-[30vw] max-h-[50vh] md:max-h-[80vh] overflow-y-auto mx-2 md:mx-0 mb-2 md:mb-0">
                    <div className="mb-4">
                        {DoubtGet && (
                            <h6>
                                <span className="font-bold">Question :</span>{" "}
                                <div
                                    dangerouslySetInnerHTML={{ __html: DoubtGet?.QuestionText }}
                                    style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                    }}
                                />
                            </h6>
                        )}
                        {DoubtGet && DoubtGet.QuestionImage && (
                            <img
                                src={DoubtGet?.QuestionImage}
                                className="w-full h-auto"
                                style={{
                                    marginRight: "1rem",
                                    marginBottom: "1rem",
                                    width: "150px",
                                    height: "150px",
                                }}
                            />
                        )}
                    </div>

                    <div className="mb-4">
                        {DoubtGet &&
                            DoubtGet?.Options?.map((option) => (
                                <div className={`container my-3 ${option.IsCorrect ? "bg-green-500" : "bg-white"} p-2 rounded-lg border border-gray-300`}>
                                    <h5
                                        id={
                                            !option.IsCorrect
                                                ? "bg-red-500"
                                                : "bg-green-500"
                                        }
                                    >
                                        <span>
                                            <b>Option: {option.OptionLetter}</b>
                                        </span>{" "}
                                        <div
                                            dangerouslySetInnerHTML={{ __html: option.OptionText }}
                                        />
                                    </h5>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    )
}

const TimesUpModal = ({ showModal, handleReRecord, handleSubmit, setShowModal }: { showModal: boolean, handleReRecord: () => void, handleSubmit: () => void, setShowModal: (value: boolean) => void }) => {
    return (
        <Modal
            title="Time's Up!"
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
        >
            <div className="bg-white p-4 rounded-lg shadow-lg">
                <p>Would you like to re-record or submit?</p>
                <div className="flex justify-around mt-4">
                    <button onClick={handleReRecord} className="bg-green-500 text-white px-4 py-2 rounded">Re-record</button>
                    <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded">Submit</button>
                </div>
            </div>
        </Modal>
    )
}

const TimerView = ({ minutes, seconds }: { minutes: number, seconds: number }) => {
    return (
        <div className="fixed top-0 right-0 md:absolute z-10">
            <div className="bg-white p-2 px-4 text-lg md:text-xl rounded-lg shadow-lg font-bold mr-2 mt-2">
                {minutes > 10 ? minutes : `0${minutes}`}:{seconds > 10 ? seconds : `0${seconds}`}
            </div>
        </div>
    )
}