export const CheckIfSKUToBeDisplayed = (pricing, toggle) => {
    if (!pricing || pricing.length === 0 || !toggle) {
        return;
    }
    const skuPricings = toggle.skuPricings;
    let skippableSKUsIDs = []
    skuPricings.forEach((sku) => {
        if (sku?.active === false || sku?.price === 0) {
            skippableSKUsIDs.push(sku.skuId);
        }
    });
    let filteredPricings = pricing.filter((pricing) => {
        if (skippableSKUsIDs.includes(pricing._id)) {
            return false;
        }
        return true;
    });
    //if there in pricings, but not in toggle, then also add to skippable
    let skippablePricingIDs = []
    pricing.forEach((pricing) => {
        let found = skuPricings.find((sku) => sku.skuId === pricing._id);
        if (!found) {
            skippablePricingIDs.push(pricing._id);
        }
    });
    filteredPricings = filteredPricings.filter((pricing) => {
        if (skippablePricingIDs.includes(pricing._id)) {
            return false;
        }
        return true;
    });

    return filteredPricings;
}

// export const CheckIfGroupBundlesAvailable = (pricing, toggle) => {
//     if (!pricing || !toggle) {
//         return false
//     }
//     const GroupPricing = toggle.groupBundlePricings
//     const skuId = pricing._id;
//     let groupBundle = GroupPricing.find((sku) => sku.skuId === skuId);


//     const isBundleInvalid = !groupBundle || groupBundle.active === false || groupBundle.price === 0;

//     const CalculateDiscount = (groupBundle) => {
//         //total users = numberOfUsers
//         const { price, numberOfUsers, discountPerUser } = groupBundle;
//         const discount = numberOfUsers * discountPerUser;
//         return price - (price * discount);
//     }
//     const [isPopup, setIsPopup] = useState(false);
//     const [emails, setEmails] = useState([]);

//     if (isBundleInvalid) {
//         return null
//     }

//     return (
//         <div className="flex flex-col mt-6">
//             {isPopup && <Popup setIsPopup={setIsPopup} groupBundle={groupBundle} emails={emails} setEmails={setEmails} />}
//             <div className="text-center text-sm text-gray-500">
//                 Group Bundle Available for {groupBundle?.numberOfUsers} Users
//             </div>
//             <div className="flex gap-x-2 items-center justify-center">
//                 <h4 className="text-[#6E7191] text-xl md:text-xl font-semibold">
//                     PKR
//                 </h4>
//                 <h1 className="text-[32px] md:text-[32px] font-bold text-gray-800">
//                     {CalculateDiscount(groupBundle).toFixed()}
//                 </h1>
//                 <sup className="text-md text-black/100 -ml-1 md:-ml-1 relative w-fit">
//                     {groupBundle?.price}
//                     <span className="w-full h-px -rotate-[12deg] absolute top-1/2 bottom-1/2 -translate-y-1/2 left-0 bg-black/50" />
//                 </sup>
//             </div>
//             <button
//                 className="text-white font-bold text-md px-6 w-fit mx-auto rounded mt-2 py-1 shadow-xl hover:shadow-none transition-all duration-300"
//                 style={{ background: "#FF4B81" }}
//                 onClick={() => setIsPopup(true)}
//             >
//                 Buy Group Bundle
//             </button>
//         </div>
//     )
// }

export const GetSKUInformation = (sku, toggle) => {
    //returns [price, discount% , afterDiscountPrice, type (monthly, endDate) , duration]
    if (!sku || !toggle) {
        return [0, 0, 0, "endDate", 0, "No Tag"];
    }
    let type = "endDate"
    let duration;
    let durationTag = "No Tag"
    if (typeof toggle.numberOfMonths !== "undefined") {
        type = "monthly"
        durationTag = `Monthly`
        duration = toggle.numberOfMonths
    }
    else {
        duration = toggle.endDate
        durationTag = toggle.toggleValue || "No Tag"
    }
    const skuPricings = toggle.skuPricings;
    const skuItemInToggle = skuPricings.find((skuPricing) => skuPricing.skuId === sku._id);
    if (!skuItemInToggle) {
        return [0, 0, 0, type, duration, durationTag];
    }
    let price = skuItemInToggle?.price;
    let discount = skuItemInToggle.discount;
    let afterDiscountPrice = price - (price * discount);
    return [price, discount, afterDiscountPrice, type, duration, durationTag];
}


export const RANDOM_COLORS = ["#14B614", "#5D5DFF", "#BD60FF", "#EE82EE", "#FFC65D", "#FFFF00", "#FF0000"];

export const BACKGROUND_STYLES = [
    "var(--Gradients-PreMed-Rouge, linear-gradient(90deg, #FF4B81 7.58%, #B43453 34.64%, #FE8383 81.9%))",
    "var(--Gradients-Shiny-Yellow, linear-gradient(107deg, #FF50CE 0%, #E8EB40 95.83%))",
    "var(--Gradients-Shiny-Yellow, linear-gradient(107deg, rgb(42, 231, 67) 0%, #E8EB40 95.83%))",
    "var(--gradients-niceness-20, linear-gradient(107deg, #00C2FF 5.73%, #6A0EFF 63.86%, #F8A3FF 84.5%, #F96D28 100%))",
    "var(--gradients-niceness-20, linear-gradient(90deg, #ec5863 7.58%, #58c2ec 34.64%, #1be799 81.9%))"
];
