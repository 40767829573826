import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import TransferUserModal from "./components/transfer-user-modal";
import { transferTypes } from "../utils";
import toast, { Toaster } from "react-hot-toast";

const TransferUsersScreen = () => {
  const [modal, setModal] = useState<{
    isOpen: boolean;
    type: string;
    data?: any;
    role: string | null;
    supervisorRole: string | null;
  }>({
    isOpen: false,
    type: "",
    data: null,
    role: null,
    supervisorRole: null,
  });
  const [allCommissionUsers, setAllCommissionUsers] = useState([]);

  const fetchAllCommissionUsers = async () => {
    try {
      const commissionUsersResponse = await CommissionAPI(
        "getAllCommissionUsers"
      );
      if (commissionUsersResponse?.status === 200) {
        const commissionUsersData = commissionUsersResponse?.data?.data || [];
        setAllCommissionUsers(commissionUsersData);
      }
    } catch (error) {
      console.log("Error: ", error);
      const message =
        error?.response?.data?.message || "Failed to update status.";
      toast.error(message);
    }
  };

  useEffect(() => {
    fetchAllCommissionUsers();
  }, []);

  const handleTypeClick = (
    type: string,
    role: string,
    supervisorRole: string
  ) => {
    setModal({
      isOpen: true,
      type: type,
      data: null,
      role: role,
      supervisorRole,
    });
  };

  return (
    <div>
      <Toaster />
      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-500 mb-5">
            Transfer Users Mega Dashboard
          </h2>
        </div>

        <section className="my-5 antialiased flex flex-wrap items-center gap-5 ">
          {transferTypes?.map((type) => {
            return (
              <div className="">
                <button
                  className="text-white bg-gradient-to-r from-primary-400 via-primary-500 to-primary-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-primary-300 shadow-lg shadow-primary-500/50 font-medium rounded-lg  p-6 text-center text-lg transition-all ease-in-out"
                  onClick={() => {
                    handleTypeClick(
                      type?.value,
                      type?.transferringRole,
                      type?.supervisorRole
                    );
                  }}
                >
                  {type?.name}
                </button>
              </div>
            );
          })}
        </section>
      </div>

      <TransferUserModal
        allCommissionUsers={allCommissionUsers}
        modal={modal}
        setModal={setModal}
        type={modal?.type}
        transferringRole={modal?.role}
        supervisorRole={modal?.supervisorRole}
      />
    </div>
  );
};

// const TransferUserModal = ({
//   allCommissionUsers,
// }: {
//   allCommissionUsers: any[];
// }) => {
//   return <></>;
// };

export default TransferUsersScreen;
