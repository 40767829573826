import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import React, { useEffect, useState } from "react";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { ChevronDown, ChevronUp } from "shared-components/ui/Icons";
import { getActiveData } from "shared-pages/latest-commission/utils";

const ViewUserTargetTable = ({ allUsers }) => {
  const getCurrentMonth = () => {
    const currentDate = new Date();
    return (currentDate.getMonth() + 1).toString().padStart(2, "0");
  };

  const [searchEmail, setSearchEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("All Roles");
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [isActiveFilter, setIsActiveFilter] = useState("Active");
  const [schemeTypeFilter, setSchemeTypeFilter] = useState("All Schemes");

  // State to manage the expanded row
  const [expandedRows, setExpandedRows] = useState({});

  const handleRowToggle = (targetId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [targetId]: !prev[targetId],
    }));
  };

  const getMonthFromDate = (dateString) => {
    const date = new Date(dateString);
    return (date.getMonth() + 1).toString().padStart(2, "0");
  };

  // Filter and sort logic
  // const filteredAndSortedUsers = allUsers
  //   .filter((user) => {
  //     const matchesEmail = user.email
  //       .toLowerCase()
  //       .includes(searchEmail.toLowerCase());
  //     const matchesRole =
  //       selectedRole === "All Roles" || user.role === selectedRole;

  //     console.log("selectedMonth: ", selectedMonth);
  //     const matchesMonth =
  //       !selectedMonth || // If no month is selected, show all
  //       user?.targets?.some((target) => {
  //         const targetStartDate = new Date(target.startDate);
  //         const targetEndDate = new Date(target.endDate);

  //         // Check if the selected month falls within the target's date range
  //         const selectedMonthNumber = parseInt(selectedMonth, 10);
  //         const startMonth = targetStartDate.getMonth() + 1; // Convert to 1-based
  //         const endMonth = targetEndDate.getMonth() + 1;

  //         return (
  //           selectedMonthNumber >= startMonth && selectedMonthNumber <= endMonth
  //         );
  //       });

  //     return matchesEmail && matchesRole && matchesMonth;
  //   })
  //   .flatMap((user) =>
  //     user?.targets
  //       .filter((target) => {
  //         const isActiveMatch =
  //           isActiveFilter === "All" ||
  //           (isActiveFilter === "Active" && target?.isActive) ||
  //           (isActiveFilter === "Inactive" && !target?.isActive);
  //         const schemeTypeMatch =
  //           schemeTypeFilter === "All Schemes" ||
  //           target?.schemeType === schemeTypeFilter;

  //         return isActiveMatch && schemeTypeMatch;
  //       })
  //       .map((target) => ({ ...target, user }))
  //   );

  // // Sort only if schemeTypeFilter is not "All Schemes"
  // if (schemeTypeFilter !== "All Schemes") {
  //   filteredAndSortedUsers.sort((a, b) => {
  //     if (a.schemeType === b.schemeType) {
  //       return b.totalTargetDone - a.totalTargetDone; // Sort by totalTargetDone descending
  //     }
  //     return a.schemeType === "REFERRAL" ? -1 : 1; // Sort REFERRAL before ORDER
  //   });
  // }

  const filteredAndSortedUsers = allUsers
    .filter((user) => {
      const matchesEmail = user.email
        .toLowerCase()
        .includes(searchEmail.toLowerCase());
      const matchesRole =
        selectedRole === "All Roles" || user.role === selectedRole;

      return matchesEmail && matchesRole;
    })
    .flatMap((user) =>
      user?.targets
        .filter((target) => {
          const schemeTypeMatch =
            schemeTypeFilter === "All Schemes" ||
            target?.schemeType === schemeTypeFilter;

          // Date-based isActive logic
          const startDate = new Date(target?.startDate);
          const endDate = new Date(target?.endDate);
          endDate.setHours(23, 59, 59, 999); // Ensure the end date includes the full day

          const targetCompleted = target?.totalTargetDone;
          const isTargetCompleted = targetCompleted >= target?.target;
          const targetInProgress = isTargetCompleted
            ? 0
            : target?.target - targetCompleted;

          const currentDate = new Date();
          const isActive = startDate <= currentDate && currentDate <= endDate && target?.isActive;

          // Check the active status based on the date
          const isActiveMatch =
            isActiveFilter === "All" ||
            (isActiveFilter === "Active" && isActive) ||
            (isActiveFilter === "Inactive" && !isActive);

          // Month matching logic
          const matchesMonth =
            !selectedMonth || // If no month is selected, show all
            (() => {
              const targetStartDate = new Date(target.startDate);
              const targetEndDate = new Date(target.endDate);

              const selectedMonthNumber = parseInt(selectedMonth, 10);
              const startMonth = targetStartDate.getMonth() + 1; // Convert to 1-based
              const endMonth = targetEndDate.getMonth() + 1;

              return (
                selectedMonthNumber >= startMonth &&
                selectedMonthNumber <= endMonth
              );
            })();

          return schemeTypeMatch && isActiveMatch && matchesMonth;
        })
        .map((target) => ({ ...target, user }))
    );

  console.log("filteredAndSortedUsers: ", filteredAndSortedUsers);

  return (
    <div>
      {/* Filter Inputs */}
      <div className="flex space-x-4 mb-4">
        <input
          type="text"
          placeholder="Search by Email"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
          className="border px-2 py-1 rounded"
        />

        <input
          type="month"
          placeholder="Filter by Month"
          onChange={(e) => {
            const selectedMonthValue = e.target.value.split("-")[1];
            setSelectedMonth(selectedMonthValue);
          }}
          value={`2024-${selectedMonth}`}
          className="border px-2 py-1 rounded"
        />

        <CustomDropDown
          label="Active Status"
          options={["All", "Active", "Inactive"]}
          onSelect={(status) => setIsActiveFilter(status)}
          width="min-w-[12rem]"
          value={isActiveFilter}
          noLabel
        />

        {/* New Scheme Type Filter */}
        <CustomDropDown
          label="Scheme Type"
          options={["All Schemes", "REFERRAL", "ORDER"]}
          onSelect={(type) => setSchemeTypeFilter(type)}
          width="min-w-[12rem]"
          value={schemeTypeFilter}
          noLabel
        />
      </div>

      {/* User Target Table */}
      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-4 py-2 border-b">User Name</th>
              <th className="px-4 py-2 border-b">Email</th>
              <th className="px-4 py-2 border-b">Role</th>
              <th className="px-4 py-2 border-b">Target</th>
              <th className="px-4 py-2 border-b text-center">
                Total Target Done
              </th>
              <th className="px-4 py-2 border-b">Cash Prize</th>
              <th className="px-4 py-2 border-b">My Cash Prize</th>
              <th className="px-4 py-2 border-b">Start Date</th>
              <th className="px-4 py-2 border-b">End Date</th>
              <th className="px-4 py-2 border-b">Scheme Type</th>
              <th className="px-4 py-2 border-b">See Signups</th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedUsers.length > 0 ? (
              filteredAndSortedUsers.map((target) => (
                <React.Fragment key={target._id}>
                  <tr
                    key={target._id}
                    className={
                      target.isTotalTargetDone
                        ? "bg-emerald-100 text-black"
                        : ""
                    }
                  >
                    <td className="px-4 py-2 border-b">{target.user?.name}</td>
                    <td className="px-4 py-2 border-b">{target.user?.email}</td>
                    <td className="px-4 py-2 border-b">{target.user?.role}</td>
                    <td className="px-4 py-2 border-b">{target?.target}</td>
                    <td className="px-4 py-2 border-b text-center">
                      {target?.totalTargetDone}
                    </td>
                    <td className="px-4 py-2 border-b">{target?.cashPrize}</td>
                    <td className="px-4 py-2 border-b">
                      {target?.supervisorInfo?.commission ? (
                        <>
                          {(target?.cashPrize *
                            target?.supervisorInfo?.commission) /
                            100}{" "}
                          at {target?.supervisorInfo?.commission}%
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="px-4 py-2 border-b">
                      {/* {new Date(target?.startDate).toLocaleDateString()} */}
                      {new Date(target?.startDate).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </td>
                    <td className="px-4 py-2 border-b">
                      {/* {target.endDate
                        ? new Date(target?.endDate).toLocaleDateString()
                        : "N/A"} */}
                      {target.endDate
                        ? new Date(target?.endDate).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )
                        : "N/A"}
                    </td>
                    <td
                      className={`px-4 py-2 border-b font-semibold ${
                        target?.schemeType === "REFERRAL"
                          ? "text-green-700"
                          : "text-amber-700"
                      }`}
                    >
                      {target?.schemeType}
                    </td>
                    <td className="px-4 py-2 border-b text-center font-semibold">
                      <button
                        onClick={() => handleRowToggle(target._id)}
                        className="text-blue-600"
                      >
                        {expandedRows[target?._id] ? (
                          <ChevronUp size="w-6 h-6 " />
                        ) : (
                          <ChevronDown size="w-6 h-6" />
                        )}
                      </button>
                    </td>
                  </tr>
                  {expandedRows[target?._id] && (
                    <DisplaySignups target={target} user={target.user} />
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="px-4 py-2 text-center">
                  No matching results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DisplaySignups = ({ target, user }: { target: any; user: any }) => {
  const [targets, setTargets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signups, setSignups] = useState([]);
  console.log("user: ", user);
  useEffect(() => {
    if (target?.userIds && target?.userIds?.length > 0) {
      fetchTargets();
    }
  }, [target?.userIds]);

  async function fetchTargets() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getUserSignupInfoByUserIds", [], {
        userIds: target?.userIds,
      });
      console.log("Response: ", response);
      if (response?.data?.success) {
        const data = response?.data?.data;
        setSignups(data);
      }
    } catch (error) {
      console.error("Error fetching targets:", error);
    } finally {
      setIsLoading(false);
    }
  }

  // console.log("DisplaySignups target: ", target);

  if (isLoading)
    return (
      <tr>
        <td colSpan={11} className="px-4 py-2 bg-gray-100">
          <div>
            <p>
              <strong>Loading...</strong>
            </p>
          </div>
        </td>
      </tr>
    );

  if (!target?.userIds || target?.userIds?.length <= 0) {
    return (
      <tr>
        <td colSpan={11} className="px-4 py-2 bg-gray-100">
          <div>
            <p>
              <strong>No Signups Found</strong>
            </p>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      <tr className="bg-emerald-50 border-2 border-emerald-500">
        <td className="px-4 py-3" colSpan={1}>
          No.
        </td>
        <td className="px-4 py-3" colSpan={1}>
          User Full Name
        </td>
        <td className="px-4 py-3" colSpan={2}>
          Email
        </td>
        <td className="px-4 py-3" colSpan={2}>
          Phone Number
        </td>
        <td className="px-4 py-3" colSpan={2}>
          Bundle Status
        </td>
        <td className="px-4 py-3" colSpan={2}>
          Signup Date
        </td>
        <td className="px-4 py-3" colSpan={1}>
          Contacted
        </td>
        {/* <td className="px-4 py-3" colSpan={1}></td> */}
      </tr>

      {signups &&
        signups?.length > 0 &&
        signups?.map((userId: any, index: number) => (
          <tr key={index}>
            <td className="px-4 py-3" colSpan={1}>
              {index + 1}
            </td>
            <td className="px-4 py-3" colSpan={1}>
              {userId?.fullname}
            </td>
            <td className="px-4 py-3" colSpan={2}>
              {userId?.username}
            </td>
            <td className="px-4 py-3" colSpan={2}>
              {userId?.phonenumber}
            </td>
            <td className="px-4 py-3" colSpan={2}>
              N/A
            </td>
            <td className="px-4 py-3" colSpan={2}>
              {new Date(userId?.accountcreateddate).toLocaleDateString("en-GB")}
            </td>
            <td className="px-4 py-3" colSpan={1}>
              {user && user?.contactedUserIds?.includes(userId?.userId) ? (
                <div className="flex items-center justify-center space-x-1">
                  <div className="w-2 h-2 bg-emerald-300 rounded-full"></div>
                  <span className="text-emerald-600 font-semibold">Yes</span>
                </div>
              ) : (
                <div className="flex items-center justify-center space-x-1">
                  <div className="w-2 h-2 bg-red-300 rounded-full"></div>
                  <span className="text-red-600 font-semibold">No</span>
                </div>
              )}
            </td>
            {/* <td className="px-4 py-3" colSpan={1}></td> */}
          </tr>
        ))}
    </>
  );
};

export default ViewUserTargetTable;
