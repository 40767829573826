import React from "react";
import { ManageIcon } from "shared-components/ui/Icons";
import { Delete } from "@material-ui/icons";
import dayjs from "dayjs";
import { ButtonFill } from "shared-components/ui/CustomButtons";

export default function DoubtsTable({ data, loading, setViewDoubt, deleteDoubt, type = "USER-DOUBTS", unTakeDoubt }: { data: any, loading: boolean, setViewDoubt: (id: string) => void, deleteDoubt: (id: string) => void, type?: string, unTakeDoubt?: (id: string) => void }) {
  return (
    <section className="my-5 antialiased">
      <div className="mx-auto">
        {loading ? (
          "Loading..."
        ) : (
          <div className=" bg-white shadow-md sm:rounded-lg">
            <div className="overflow-x-auto">
              <table className="w-full mb-20 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 py-4">
                      SR No.
                    </th>
                    <th scope="col" className="px-4 py-4">
                      QuestionID
                    </th>
                    <th scope="col" className="px-4 py-4 text-center">
                      Description
                    </th>
                    <th scope="col" className="px-4 py-4 text-center">
                      Resource/Subject
                    </th>
                    {type === "MY-DOUBTS" && (
                      <th scope="col" className="px-4 py-4 text-center">
                        Expiry Date
                      </th>
                    )}
                    <th scope="col" className="px-4 py-3 text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.map((pack: any, index: number) => (
                    <tr className="border-b" key={pack?._id}>
                      <td className="px-4 py-4">{index + 1}</td>
                      <td className="px-4 py-3">{pack?.questionID}</td>
                      <td className="px-4 py-3 text-left">{pack?.description}</td>
                      <td className="px-4 py-3 text-center">{pack?.questionID ? "Premed QBank" : "Own Question"} | {pack?.subject}</td>
                      {type === "MY-DOUBTS" && (
                        <td className="px-4 py-3 text-center">
                          {pack?.ExpireDate
                            ? dayjs(pack?.ExpireDate).format('DD/MM/YYYY, hh:mm a')
                            : "N/A"}
                        </td>
                      )}
                      <td className="px-4 py-3 text-center">
                        <div className="flex items-center justify-center gap-4">
                          <ManageIcon
                            size='w-6 h-6 hover:cursor-pointer hover:text-primary'
                            onClick={() => {
                              setViewDoubt(pack?._id);
                            }}
                          />
                          {type !== "MY-DOUBTS" && (
                            <Delete
                              onClick={() => deleteDoubt(pack?._id)}
                              className='w-6 h-6 hover:cursor-pointer hover:text-primary'
                            />
                          )}
                          {type === "MY-DOUBTS" && (
                            <ButtonFill
                              handleClick={() => unTakeDoubt(pack?._id)}
                            >
                              Untake
                            </ButtonFill>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
