import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ConfirmationModal } from "shared-components/ui/CustomModal";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { ButtonOutlined } from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import { formatNumberWithCommas, ROlES, SCHEME_TYPES_OPTION } from "../utils";
import TargetModal from "./components/TargetModal";
import ViewAllTargets from "./components/view-all-targets";
import FullScreenPopup from "../components/full-screen-popup";

const TargetManagement = () => {
  const [targets, setTargets] = useState([]);
  const [selectStatus, setSelectStatus] = useState("All");
  const [schemeType, setSchemeType] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [isActiveFilter, setIsActiveFilter] = useState("All");
  const [popup, setPopup] = useState({
    isOpen: false,
    message: "",
    heading: "",
    canDestroy: false,
  });

  const intitalConfirmationState = {
    isOpen: false,
    action: "",
    data: null,
  };
  const [confirmationModal, setConfirmationModal] = useState(
    intitalConfirmationState
  );

  useEffect(() => {
    fetchTargets();
  }, [refreshData]);

  async function fetchTargets() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getAllTargets");
      if (response?.data?.success) {
        const data = response?.data?.data;
        setTargets(data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "something went wrong.";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  }

  const filteredTargets = targets.filter(
    (item) =>
      (selectStatus === "All" || item.status === selectStatus) &&
      (schemeType === "All" || schemeType === item?.schemeType)
  );

  const initialModalState = {
    isModalOpen: false,
  };
  const [modal, setModal] = useState(initialModalState);
  const openModal = () => {
    setModal({
      isModalOpen: true,
    });
  };
  const closeModal = () => {
    setModal(initialModalState);
  };

  const handleStatusConfirmation = async () => {
    const id = confirmationModal?.data?._id;
    if (!confirmationModal?.data || !id) {
      toast.error("Something went wrong");
      setConfirmationModal(intitalConfirmationState);
    }

    const body = {
      id: id,
      isActive: confirmationModal?.action === "Active" ? true : false,
    };

    try {
      const response = await CommissionAPI("updateTarget", [], body);
    
      if (response?.data?.success) {
        toast.success("Successfully Updated");
        const updatedTargets = targets?.map((item) => {
          if (item?._id === id) {
            return {
              ...item,
              isActive: body?.isActive,
            };
          } else {
            return item;
          }
        });
        setTargets(updatedTargets);
      }
    } catch (error) {
  
      const message =
        error?.response?.data?.message || "Failed to update status.";
      toast.error(message);
    } finally {
      setConfirmationModal(intitalConfirmationState);
    }
  };

  if (popup?.isOpen) {
    return <FullScreenPopup popup={popup} setPopup={setPopup} />;
  }

  return (
    <>
      <Toaster />
      <TargetModal modal={modal} closeModal={closeModal} />
      <ConfirmationModal
        active={confirmationModal?.isOpen}
        onCancel={() => {
          setConfirmationModal(intitalConfirmationState);
        }}
        onConfirm={() => {
          handleStatusConfirmation();
        }}
        message={`Are you sure you want to ${confirmationModal?.action}?`}
        isIcon={false}
      />

      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-xl font-bold text-gray-500 mb-5">Targets</h2>
          <ButtonOutlined
            handleClick={() => setRefreshData((prev) => prev + 1)}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className="bg-white shadow-md sm:rounded-lg">
                <div className="flex items-end p-4 space-x-3">
                  <CustomDropDown
                    label="Active Status"
                    options={["All", "Active", "InActive"]}
                    onSelect={(status) => setIsActiveFilter(status)}
                    width="min-w-[12rem]"
                    value={isActiveFilter}
                  />
                  <CustomDropDown
                    label="Scheme Type"
                    options={SCHEME_TYPES_OPTION}
                    onSelect={(selectedStatus) => setSchemeType(selectedStatus)}
                    width="min-w-[12rem]"
                    value={schemeType}
                  />
                  <div
                    className=" min-w-fit shadow-lg rounded-md ml-auto  text-white bg-gradient-to-r from-primary-400 via-primary-500 to-primary-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-primary-300  font-medium  text-sm p-4 text-center cursor-pointer"
                    onClick={() => {
                      openModal();
                    }}
                  >
                    <h1 className="font-semibold">Create Target</h1>
                  </div>
                </div>
                <ViewAllTargets
                  filteredTargets={filteredTargets}
                  isActiveFilter={isActiveFilter}
                  setConfirmationModal={setConfirmationModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default TargetManagement;
