import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DailySignUpsChart = ({ data }) => {
  // Transform the daily signups data into the format required by Recharts
  const chartData = Object.entries(data.dailySignupsData).map(([date, signups]) => ({
    date,
    signups,
  }));

  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          angle={-45}
          textAnchor="end"
          interval={0}
          height={60}
          hide
          label={{ value: "Date", position: "insideBottom", offset: -10 }}
        />
        <YAxis
          label={{ value: "Signups", angle: -90, position: "insideLeft" }}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Line
          type="monotone"
          dataKey="signups"
          name="Daily Signups"
          stroke="#FFAD4FFF"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DailySignUpsChart;
