import { apiRequest } from "(apis)/api-interface/api-handler";
import { Select } from "antd";
import { useEffect, useState } from "react";

const ModuleDropDown = ({ value, getValue, noLabel = false }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await apiRequest("getAllModules");

      console.log("response: ", response);
      if (response.status === 200) {
        const data = response?.data?.modules || [];
        const filteredModules = data
          .filter((item: any) => item?.isPublished)
          .map((module: { moduleName: string }) => {
            return module?.moduleName;
          });
        // setModules(filteredModules);
        setData(filteredModules);
      }
    } catch (error) {
      console.error("There was an error fetching the topics!", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="my-2">
      <Select
        showSearch
        placeholder="Select Topic"
        onChange={(selectedValue) => {
          getValue("module", selectedValue);
        }}
        size={"large"}
        disabled={isLoading}
        className="w-full"
        value={value}
      >
        {data?.map((item) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ModuleDropDown;
