import React, { useEffect, useState } from "react";
import { Input, Label } from "../../../shared-components/ui/CustomForm";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import toast from "react-hot-toast";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { Select } from "antd";
import { ConfirmationModal } from "../../../shared-components/ui/CustomModal";
import { getUsersOfSpecificRole, MAX_COMMISSION, ROlES } from "../utils";

export const CommissionUserForm = (props) => {
  const InitialErrors = {
    entityError: {
      error: false,
      message: "",
    },
    categoryError: {
      error: false,
      message: "",
    },
    roleError: {
      error: false,
      message: "",
    },
    userNameError: {
      error: false,
      message: "",
    },
    reportToError: {
      error: false,
      message: "",
    },
  };

  const [errors, setErrors] = useState(InitialErrors);

  // const roleHierarchy = {
  //   ASSOCIATE: "HEAD",
  //   HEAD: "MANAGER",
  //   MANAGER: "PROVINCIAL_DIRECTOR",
  //   PROVINCIAL_DIRECTOR: "NATIONAL_DIRECTOR",
  //   NATIONAL_DIRECTOR: null,
  // };

  const roleHierarchy = {
    ASSOCIATE: "MANAGER",
    MANAGER: "DIRECTOR",
    DIRECTOR: null,
  };
  const defaultCommisionRate = {
    ASSOCIATE: 10,
    HEAD: 2.5,
    MANAGER: 5,
    DIRECTOR: 0.0,
    PROVINCIAL_DIRECTOR: 0.25,
    NATIONAL_DIRECTOR: "",
  };

  const initialState = {
    _id: "",
    name: "",
    userName: "",
    email: "",
    reportTo: {
      userId: "",
      email: "",
      name: "",
      role: props?.label ? roleHierarchy[props?.label] : roleHierarchy["HEAD"],
    },
    role: props?.label ? props?.label : "ASSOCIATE",
    commissionPercentage: props?.label
      ? defaultCommisionRate[props?.label]
      : defaultCommisionRate["ASSOCIATE"],
  };
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [reportToEmails, setReportToEmails] = useState([]);

  useEffect(() => {
    if (props?.commissionUser) {
      // console.log("Commission User: ", props?.commissionUser);
      setFormData(props?.commissionUser);
    }
  }, [props?.commissionUser]);

  const handleInputChange = (name: string, value: any) => {
    if (name === "reportToEmail") {
      setFormData({
        ...formData,
        reportTo: {
          ...formData?.reportTo,
          email: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleRoleSelect = (selectedRole) => {
    setFormData({
      ...formData,
      role: selectedRole,
      commissionPercentage: defaultCommisionRate[selectedRole],
      reportTo: {
        ...formData?.reportTo,
        role: roleHierarchy[selectedRole],
      },
    });
  };
  const getUserInfoByEmail = (email: string) => {
    if (!email || email?.trim() === "") return null;
    const user = props?.allAdminUsers?.find(
      (user: any) => user?.username?.trim() === email?.trim()
    );
    return user;
  };

  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const resetAll = () => {
    setFormData(initialState);
    props?.setModal(false);
    setConfirmationModal(false);
  };

  const invalidData = (data) => {
    let error = false;
    // name: string;
    // email: string;
    // role: string;
    // commissionPercentage: string | number;
    // reportTo?: {
    //   userId: string;
    //   email: string;
    //   name: string;
    //   role: string;
    // };
    // userId?: string;
    if (!data?.name || data?.name?.trim() === "") {
      error = true;
      toast.error("User name is required");
    } else if (!data?.email || data?.email?.trim() === "") {
      error = true;
      toast.error("User email is required");
    } else if (!data?.role || data?.role?.trim() === "") {
      error = true;
      toast.error("User role is required");
    } else if (
      !data?.commissionPercentage ||
      data?.commissionPercentage < 0 ||
      data?.commissionPercentage > MAX_COMMISSION
    ) {
      error = true;
      toast.error("Invalid commission ");
    } else if (
      data?.role !== ROlES.DIRECTOR &&
      (!data?.reportTo?.email ||
        data?.reportTo?.name?.trim() === "" ||
        !data?.reportTo?.email ||
        data?.reportTo?.name?.trim() === "" ||
        !data?.reportTo?.userId ||
        data?.reportTo?.userId?.trim() === "")
    ) {
      error = true;
      toast.error("Supervisor is required");
    }

    return error;
  };
  const Submit = async () => {
    // if (validateForm()) {
    //   return;
    // }

    const newUser = getUserInfoByEmail(formData.email);
    const reportedUser = getUserInfoByEmail(formData.reportTo?.email);
    // console.log("reportedUser: ", reportedUser);
    if (
      formData?.commissionPercentage < 0 ||
      formData?.commissionPercentage > MAX_COMMISSION
    ) {
      toast.error("Invalid Commission Rate");
      return;
    }

    try {
      // setIsLoading(true);
      const data: {
        name: string;
        email: string;
        role: string;
        commissionPercentage: string | number;
        reportTo?: {
          userId: string;
          email: string;
          name: string;
          role: string;
        };
        userId?: string;
      } = {
        name: formData.name,
        email: formData.email,
        role: formData.role,
        commissionPercentage: formData.commissionPercentage,
        reportTo: formData?.reportTo,
      };
      if (reportedUser?.fullname && reportedUser?.fullname?.trim() !== "") {
        data.reportTo.userId = reportedUser?._id;
        data.reportTo.name = reportedUser?.fullname;
      }
      if (formData.role !== ROlES?.DIRECTOR) {
        data.reportTo.role = roleHierarchy[formData.role];
      }

      if (newUser) {
        data.userId = newUser._id;
      }

      if (invalidData(data)) {
        return;
      }
      // return;
      let response = null;
      if (props?.mode === "edit") {
        const id = formData?._id;
        response = await CommissionAPI("updateCommissionUserById", [id], data);
      } else {
        response = await CommissionAPI("addCommissionUser", [], data);
      }
      if (response) {
        const message = response?.data?.message;
        props?.mode === "edit"
          ? toast.success(message ? message : "Updated Successfully 🚀")
          : toast.success(message ? message : "Added Successfully 🚀");
        resetAll();
        // console.log("response: ", response);
      }
    } catch (error) {
      // console.log("Add Commission User Error: ", error);
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setIsLoading(false);
    }

    setDisableCreateButton(false);
  };
  // console.log("🔰-formData: ", formData);

  const formattedUserEmail =
    props?.allAdminUsers?.map((user) => user?.username) || [];

  useEffect(() => {
    let formattedReportToUsers =
      props?.allAdminUsers?.map((user) => user?.username) || [];

    if (props?.allCommissionUsers) {
      // we have to set report to emails in dropdown according to the role we are adding
      // =====================
      // ===FOR ASSOCIATES====
      // =====================
      if (props?.label === ROlES.ASSOCIATE) {
        let users = getUsersOfSpecificRole(
          props?.allCommissionUsers,
          ROlES.MANAGER
        );
        formattedReportToUsers = users?.map((user) => user?.email) || [];
        // =====================
        //  =======FOR MANAGERS========
        // =====================
      } else if (props?.label === ROlES.MANAGER) {
        let users = getUsersOfSpecificRole(
          props?.allCommissionUsers,
          ROlES.DIRECTOR
        );
        formattedReportToUsers = users?.map((user) => user?.email) || [];
      } else if (props?.label === ROlES.DIRECTOR) {
        let users = getUsersOfSpecificRole(
          props?.allCommissionUsers,
          ROlES.DIRECTOR
        );
        formattedReportToUsers = users?.map((user) => user?.email) || [];
      }
    }
    setReportToEmails(formattedReportToUsers);
  }, [props?.allAdminUsers]);

  const handleUserEmailChange = (mail) => {
    const cleanEmail = mail?.toLowerCase()?.trim();
    const user = getUserInfoByEmail(cleanEmail);
    if (user) {
      const updatedFormData = {
        ...formData,
        email: cleanEmail,
        name: user.fullname,
      };
      setFormData(updatedFormData);
    } else {
      const updatedFormData = { ...formData, email: cleanEmail, name: "" };
      setFormData(updatedFormData);
    }
  };

  const isUserFound = (mail: string) => {
    const cleanEmail = mail?.toLowerCase()?.trim();
    if (!cleanEmail) return true;
    const user = getUserInfoByEmail(cleanEmail);
    return user ? true : false;
  };
  // console.log("formattedUserEmail: ", formattedUserEmail, props?.allAdminUsers);
  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props?.active ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 capitalize">
              {props?.mode === "add"
                ? `Add New ${props?.label}`
                : `Edit ${props?.label}`}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                props?.setModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="space-y-2">
            <div className="">
              <Label>User Email</Label>
              {/* <Select
                showSearch
                onChange={(selectedEmail) => {
                  handleUserEmailChange(selectedEmail);
                }}
                // g
                size={"large"}
                // disabled={isLoading}
                className="w-full"
                value={formData.email}
              >
                {formattedUserEmail?.map((item,index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select> */}
              <Input
                type="text"
                value={formData?.email}
                name="email"
                onChange={(name, selectedEmail) => {
                  handleUserEmailChange(selectedEmail);
                }}
                isError={false}
                errorMessage={""}
              />
            </div>

            <div className="space-y-2">
              <Label>User Name</Label>
              <Input
                type="text"
                value={formData?.name}
                name="name"
                disabled={isUserFound(formData?.email)}
                onChange={(name, value) => {
                  if (isUserFound(formData?.email)) return;
                  handleInputChange(name, value);
                }}
                isError={errors?.userNameError?.error}
                errorMessage={errors?.userNameError?.message}
              />
            </div>

            <div className="flex items-center space-x-3 w-full">
              <CustomDropDown
                label="Role"
                options={["ASSOCIATE", "MANAGER", "DIRECTOR"]}
                onSelect={handleRoleSelect}
                disabled={true}
                width="w-full"
                value={formData.role}
                isError={errors?.roleError?.error}
                errorMessage={errors?.roleError?.message}
              />
            </div>
            {props?.label !== ROlES?.DIRECTOR && (
              <>
                <div className="flex items-center space-x-3 w-full">
                  <CustomDropDown
                    label="Report to Role"
                    options={["ASSOCIATE", "MANAGER", "DIRECTOR"]}
                    onSelect={(selectedRole) =>
                      setFormData({
                        ...formData,
                        reportTo: {
                          ...formData?.reportTo,
                          role: selectedRole,
                        },
                      })
                    }
                    width="w-full"
                    disabled={true}
                    value={formData?.reportTo?.role}
                    isError={errors?.reportToError?.error}
                    errorMessage={errors?.reportToError?.message}
                  />
                </div>

                <div className="">
                  <Label>Report User Email</Label>
                  <Select
                    showSearch
                    onChange={(selectedEmail) => {
                      handleInputChange("reportToEmail", selectedEmail);
                    }}
                    // g
                    size={"large"}
                    // disabled={isLoading}
                    className="w-full"
                    value={formData.reportTo?.email}
                  >
                    {reportToEmails?.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </>
            )}
            <div className="space-y-2">
              <Label>Commission Percentage</Label>
              <Input
                type="number"
                placeholder="Enter Commission Percentage"
                value={formData.commissionPercentage}
                name="commissionPercentage"
                onChange={handleInputChange}
              />
            </div>

            <div className="flex items-center justify-end gap-2">
              <ButtonOutlined
                handleClick={() => {
                  if (
                    props?.commissionUser &&
                    JSON.stringify(formData) !==
                      JSON.stringify(props?.commissionUser)
                  ) {
                    setConfirmationModal(true);
                  } else if (
                    formData.userName !== "" ||
                    formData.role !== "" ||
                    formData.commissionPercentage !== 0
                  ) {
                    setConfirmationModal(true);
                  } else {
                    props?.setModal(false);
                  }
                }}
              >
                Cancel
              </ButtonOutlined>
              <ButtonFill
                isLoading={isLoading}
                handleClick={Submit}
                disabled={disableCreateButton}
              >
                {props?.mode === "add" ? "Create" : "Update"}
              </ButtonFill>
            </div>
          </div>
          <ConfirmationModal
            active={confirmationModal}
            onCancel={() => {
              setConfirmationModal(false);
            }}
            onConfirm={() => {
              setFormData(initialState);
              props?.setModal(false);
              setConfirmationModal(false);
            }}
            message={"Are you sure you want to leave this page?"}
          />
        </div>
      </div>
    </div>
  );
};

export default CommissionUserForm;
